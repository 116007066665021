import React, { useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Empty, Modal, Space, Spin } from 'antd'
import { DownOutlined, RightOutlined } from '@ant-design/icons'

import Styles from '../create-exam-batch/ExamBatch.module.scss'
import SelectExamRoomAllocationRuleModal from './SelectExamRoomAllocationRuleModal'
import ExaminationLocationTable from './ExaminationLocationTable'
import {
  downloadDoorStickersHtmlToPdfBatch,
  downloadExaminationRoomExamineeAllocateFile,
  downloadExaminationRoomFile, downloadExamineeTableBatch,
  downloadSeatHtmlToPdfBatch,
  downloadTableStickersHtmlToPdfBatch
} from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import router from '../../../routers/router'
import { downloadFile } from '../../../plugins/utils'

function ExaminationBatchRoomItem ({ batchInfo, updateBatchList, componentType }) {
  const selectExamRoomAllocationRuleModalRef = useRef()
  const [batchItemClass, setBatchItemClass] = useState('')
  const [batchCollapse, setBatchCollapse] = useState(true)
  const [locationList, setLocationList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (batchCollapse) {
      setBatchItemClass([Styles.batchItem, Styles.isCollapseActive].join(' '))
    } else {
      setBatchItemClass(Styles.batchItem)
    }
  }, [batchCollapse])

  const downloadMenuProps = [
    {
      label: '考生安排表',
      key: '0'
    },
    {
      label: '考场统计表',
      key: '1'
    },
    {
      label: '考生浏览表',
      key: '2'
    },
    {
      label: '座次表',
      key: '3'
    },
    {
      label: '桌贴',
      key: '4'
    },
    {
      label: '门贴',
      key: '5'
    }
  ]


  const toggleBatchCollapse = () => {
    setBatchCollapse(!batchCollapse)
  }

  const showSelectAllocationRuleModal = () => {
    selectExamRoomAllocationRuleModalRef.current.showModal()
  }

  const saveBatchAllocationRuleSuccess = () => {
    updateBatchList?.()
  }

  const updateLocationList = (list) => {
    setLocationList(list)
  }

  const generateExamLocationContent = (type) => {
    let content
    switch (type) {
      case 3:
        content = (
          <div className={ 'd-flex align-items-center justify-content-center flex-column' }>
            <Empty description={ '' }></Empty>
            <Button
              type={ 'primary' }
              ghost
              onClick={ showSelectAllocationRuleModal }
            >配置分配规则</Button>
          </div>
        )
        break
      case 4:
      case 5:
      case 6:
        content = (
          <ExaminationLocationTable
            batchInfo={ batchInfo }
            componentType={ componentType }
            updateLocationList={ updateLocationList }
          ></ExaminationLocationTable>
        )
        break
    }

    return content
  }

  const showDownloadDropdownMenu = (e) => {
    e.stopPropagation()
  }

  const downloadFileAction = (data) => {
    const e = data.domEvent
    e.stopPropagation()
    let request
    let params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId,
      locationIds: locationList.map(item => item.locationId)
    }

    switch (data.key) {
      case '0':
        request = downloadFileToDownloadCenter(downloadExamineeTableBatch, { examId: batchInfo.examId })
        break
      case '1':
        request = downloadFileToDownloadCenter(downloadExaminationRoomFile, params)
        break
      case '2':
        request = downloadFileToDownloadCenter(downloadExaminationRoomExamineeAllocateFile, params)
        break
      case '3':
        request = downloadFileToDownloadCenter(downloadSeatHtmlToPdfBatch, params)
        break
      case '4':
        request = downloadFileToDownloadCenter(downloadTableStickersHtmlToPdfBatch, params)
        break
      case '5':
        request = downloadFileToDownloadCenter(downloadDoorStickersHtmlToPdfBatch, params)
        break
      default:
        Modal.error({
          title: '错误',
          content: '文件类型不存在'
        })
    }
  }

  const downloadFileToDownloadCenter = (request, params) => {
    setLoading(true)
    request(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        Modal.confirm({
          type: 'success',
          title: '操作成功',
          content: '下载任务已添加，是否跳转到下载中心查看',
          onOk: () => {
            const siteId = sessionStorage.getItem('siteId')
            router.navigate(`/${ siteId }/exam/download-center`)
          }
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载考场安排数据失败'
      })
    })
  }

  const downloadBtnVisible = (visible) => {
    if (visible) {
      return (

        <Dropdown menu={ {
          placement: 'bottomLeft',
          items: downloadMenuProps,
          onClick: downloadFileAction
        } }>
          <Button
            type={ 'primary' }
            size={ 'small' }
            onClick={ showDownloadDropdownMenu }
          >
            <Space>
              下载
              <DownOutlined/>
            </Space>
          </Button>
        </Dropdown>
      )
    } else {
      return <></>
    }
  }

  return (<>
    <Spin spinning={ loading }>
      <div className={ batchItemClass }>
        <div className={ Styles.batchItemHeader } onClick={ toggleBatchCollapse }>
          <Space size={ 64 }>
            <div className={ 'text-primary' }>{ batchInfo.batchName }</div>
            <div>
              <span>考生人数：</span>
              <span>{ batchInfo.applyCount }</span>
            </div>
            <div>
              <span>考场标准人数：</span>
              <span>{ batchInfo.standardRoomNumber }</span>
            </div>
            <div>
              <span>考场数：</span>
              <span>{ batchInfo.roomNumber }</span>
            </div>
          </Space>

          <Space size={ 16 }>
            { downloadBtnVisible(batchInfo.generateCandidateNumber === 1) }
            <Button className={ 'ml-3' } size={ 'small' } type={ 'link' }>
              <RightOutlined/>
            </Button>
          </Space>

        </div>
        <div className={ Styles.batchItemBody }>
          { generateExamLocationContent(batchInfo.allocateProgress) }
        </div>

        <SelectExamRoomAllocationRuleModal
          ref={ selectExamRoomAllocationRuleModalRef }
          batchInfo={ batchInfo }
          saveBatchAllocationRuleSuccess={ saveBatchAllocationRuleSuccess }
        ></SelectExamRoomAllocationRuleModal>
      </div>
    </Spin>
  </>)
}

export default ExaminationBatchRoomItem
