import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, ConfigProvider, Descriptions, Divider, Form, InputNumber, Modal, notification, Radio, Select, Spin, Table } from 'antd'
import {
  getExaminationLocationDetailList,
  getNoAllocateExamineeByApplySite,
  getNoAllocateExamineeByMajor,
  getNotAllocationExamineeNumber,
  manualAllocateExaminee
} from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const ManualAllocationModal = forwardRef(({ locationInfo, batchInfo, insertSuccess }, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [noAllocateNum, setNoAllocateNum] = useState(-1)
  const [locationListOptions, setLocationListOptions] = useState([])
  const [selectedLocation, setSelectedLocation] = useState({})
  const [columns, setColumns] = useState([])
  const [searchType, setSearchType] = useState(2)
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRowItems, setSelectedRowItems] = useState([])
  const [selectedExamineeNum, setSelectedExamineeNum] = useState(0)

  const applySiteColumns = [
    { title: '报名点名称', key: 'applySiteName', dataIndex: 'applySiteName' },
    { title: '报名点代码', key: 'applySiteCode', dataIndex: 'applySiteCode' },
    { title: '小类名称', key: 'majorName', dataIndex: 'majorName' },
    { title: '剩余未分配人数', key: 'count', dataIndex: 'count' }
  ]

  const majorColumns = [
    { title: '大类名称', key: 'levelName', dataIndex: 'levelName' },
    { title: '大类代码', key: 'levelCode', dataIndex: 'levelCode' },
    { title: '小类名称', key: 'majorName', dataIndex: 'majorName' },
    { title: '小类代码', key: 'majorCode', dataIndex: 'majorCode' },
    { title: '剩余未分配人数', key: 'count', dataIndex: 'count' }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getInitData()
    }
  }, [modalVisible])

  useEffect(() => {
    setSelectedExamineeNum(selectedRowItems.reduce((pre, current) => {
      return pre + current.count
    }, 0))
  }, [selectedRowItems])

  useEffect(() => {
    if (modalVisible) {
      if (searchType === 2) {
        setColumns(applySiteColumns)
        getTableData('applySite')
      } else {
        getTableData('major')
        setColumns(majorColumns)
      }
    }
  }, [searchType, modalVisible])

  const getInitData = () => {
    const params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId
    }

    setLoading(true)
    Promise.all([
      getNotAllocationExamineeNumber(params),
      getExaminationLocationDetailList(params)
    ]).then(([res1, res2]) => {
      setLoading(false)
      if (res1.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: res1.msg
        })
      }

      if (res2.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: res2.msg
        })
      }

      form.setFieldValue('locationId', locationInfo.locationId)
      setSelectedLocation(res2.data?.find(item => {
        return item.locationId === locationInfo.locationId
      }))

      setNoAllocateNum(res1.data)
      setLocationListOptions(res2.data?.map(item => {
        return {
          ...item,
          label: item.locationName,
          value: item.locationId
        }
      }))
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询未分配考生数量失败'
      })
    })
  }

  const getTableData = (type) => {
    let request
    setLoading(true)
    if (type === 'applySite') {
      request = getNoAllocateExamineeByApplySite({ examId: batchInfo.examId })
    } else {
      request = getNoAllocateExamineeByMajor({ examId: batchInfo.examId })
    }

    request.then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询未分配考生列表失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setSelectedRowKeys([])
    setSelectedRowItems([])
    setTableData([])
    setSearchType(2)

    form.resetFields()
  }

  const selectLocationChange = (key, item) => {
    setSelectedLocation(item)
  }

  const searchTypeChange = (e) => {
    setColumns([])
    setSelectedRowKeys([])
    setSelectedRowItems([])
    setSearchType(e.target.value)
    form.setFieldValue('allocateChooseType', e.target.value)
  }

  const onSelectChange = (newSelectedRowKeys, newSelectedRowItems) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRowItems(newSelectedRowItems)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      const applySiteArr = selectedRowItems.map(item => item.applySiteNumber).filter(item => item)

      const params = {
        ...fields,
        examId: batchInfo.examId,
        batchId: batchInfo.batchId,
        majorCodes: selectedRowItems.map(item => item.majorCode),
        applySiteNumbers: applySiteArr.length > 0 ? applySiteArr : undefined
      }

      setLoading(true)

      manualAllocateExaminee(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          hideModal()
          notification.success({
            message: '操作成功'
          })

          insertSuccess?.(locationInfo)
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，保存批次考场分配规则失败'
        })
      })
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 800 }
        title={ '手动分配' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
        footer={ [
          <Button
            key={ 'cancel' }
            onClick={ hideModal }
          >取消</Button>,
          <Button
            type={ 'primary' }
            key={ 'confirm' }
            onClick={ onOk }
            loading={ loading }
            disabled={ selectedRowKeys.length === 0 }
          >确定</Button>
        ] }
      >
        <Spin spinning={ loading }>
          <Descriptions>
            <Descriptions.Item label={ '本场考试剩余未分配人数' }>{ noAllocateNum }</Descriptions.Item>
          </Descriptions>
          <Divider className={ 'mt-0 mb-3' }/>
          <Form
            form={ form }
            initialValues={ {
              allocateChooseType: 2,
              allocateNumber: 1
            } }
          >
            <Form.Item
              label={ '选择考点' }
              name={ 'locationId' }
              rules={ [{ required: true }] }
            >
              <Select
                disabled
                options={ locationListOptions }
                style={ { width: 180 } }
                onChange={ selectLocationChange }
              ></Select>
            </Form.Item>

            <Descriptions>
              <Descriptions.Item label={ '考场数' }>{ selectedLocation.roomNumber }</Descriptions.Item>
              <Descriptions.Item label={ '已分配考场数' }>{ selectedLocation.allocateRoom }</Descriptions.Item>
              <Descriptions.Item label={ '剩余考生容量' }>{ selectedLocation.canAllocateExaminees }</Descriptions.Item>
            </Descriptions>
            <Divider className={ 'mb-3 mt-0' }/>
            <ConfigProvider componentSize={ 'small' }>
              <Form.Item
                className={ 'mb-1' }
                name={ 'allocateChooseType' }
              >
                <Radio.Group
                  onChange={ searchTypeChange }
                  buttonStyle="solid"
                >
                  <Radio.Button value={ 2 }>按报名点查询</Radio.Button>
                  <Radio.Button value={ 1 }>按考试小类查询</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Table
                loading={ loading }
                columns={ columns }
                dataSource={ tableData }
                scroll={ {
                  y: 200
                } }
                rowKey={ record => {
                  return `${ record.applySiteNumber || '' }-${ record.levelCode || '' }-${ record.majorCode }`
                } }
                rowSelection={ {
                  type: 'checkbox',
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange
                } }
                pagination={ false }
              ></Table>
            </ConfigProvider>

            <Divider className={ 'mt-3 mb-2' }/>

            <Descriptions>
              <Descriptions.Item label={ '已选考生人数' }>{ selectedExamineeNum }</Descriptions.Item>
            </Descriptions>

            <Form.Item
              label={ '设置分配人数' }
              name={ 'allocateNumber' }
              hidden
            >
              <InputNumber min={ 1 } max={ selectedExamineeNum }></InputNumber>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default ManualAllocationModal
