import React, { useEffect, useState, useRef } from 'react'
import { Button, ConfigProvider, Modal, Table } from 'antd'

import { accountTypeMap } from '../../../common/common'
import { getOrgPayAccountList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import EditReceivingAccountModal from './EditReceivingAccountModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'
import AppButton from '../../../components/AppButton'

function ConfigReceivingAccount (props) {
  const editModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.modalVisible && props.orgItem.id) {
      getTableData()
    }
  }, [props.orgItem, props.modalVisible])

  const columns = [
    {
      title: '账户名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '户号',
      dataIndex: 'accountNumber',
      key: 'accountNumber'
    },
    {
      title: '收款单位',
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: '账户类型',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (type) => {
        return (<>
          { accountTypeMap[type] }
        </>)
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    const params = {
      id: props.orgItem.id
    }

    getOrgPayAccountList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询收款账户列表失败',
        centered: true
      })
    })
  }

  const showEditModal = () => {
    editModalRef.current.showModal()
  }

  return (
    <div>
      <ConfigProvider componentSize="small">
        <div className="mb-2 d-flex justify-content-end">
          <AppButton authCode={ 'button_jgxq-skzh-pzskzh' } type="primary" onClick={ showEditModal }>配置收款账户</AppButton>
        </div>

        <Table
          loading={ loading }
          columns={ columns }
          style={ { minHeight: '230px' } }
          dataSource={ tableData }
          rowKey={ (record) => {
            return record.accountId
          } }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <EditReceivingAccountModal
        ref={ editModalRef }
        orgItem={ props.orgItem }
        accountList={ tableData }
        insertSuccess={ getTableData }
      ></EditReceivingAccountModal>
    </div>
  )
}

export default ConfigReceivingAccount
