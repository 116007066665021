import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react'
import { Form, Input, Modal, notification, Select } from 'antd'
import { createRole, editRole } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useSelector } from 'react-redux'

const EditRoleModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [roleTagOptions, setRoleTagOptions] = useState([])
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('create')
  const [insertRoleLoading, setInsertRoleLoading] = useState(false)
  const roleTagMap = useSelector(state => state.role.roleTagMap)

  useEffect(() => {
    setRoleTagOptions(roleTagMap.map(item => {
      return {
        label: item.name,
        value: item.roleTag
      }
    }))
  }, [roleTagMap])

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('创建角色')
      } else {
        setModalTitle('编辑角色')
        setFormInitData()
      }

    }
  }, [modalVisible, props.modalType])

  useImperativeHandle(ref, () => {
    return {
      showModal: showEditRoleModal
    }
  })

  const setFormInitData = () => {
    form.setFieldsValue({
      ...props.roleItem
    })
  }

  const showEditRoleModal = () => {
    setModalVisible(true)
  }

  const hideEditRoleModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const createRoleAction = (fields) => {
    setInsertRoleLoading(true)
    const params = {
      ...fields
    }

    createRole(params).then(res => {
      setInsertRoleLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditRoleModal()
        notifyApi.success({
          message: '操作成功',
          description: `创建角色成功`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setInsertRoleLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建角色失败',
        centered: true
      })
    })
  }

  const editRoleAction = (fields) => {
    const params = {
      ...props.roleItem,
      ...fields
    }

    editRole(params).then(res => {
      setInsertRoleLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditRoleModal()
        notifyApi.success({
          message: '操作成功',
          description: `更新角色成功`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setInsertRoleLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新角色失败',
        centered: true
      })
    })
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      if (props.modalType === 'create') {
        createRoleAction(fields)
      } else {
        editRoleAction(fields)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ insertRoleLoading }
        onOk={ onOk }
        onCancel={ hideEditRoleModal }
      >
        <Form
          rootClassName="mt-3"
          form={ form }
          labelCol={ {
            span: 6
          } }
          wrapperCol={ {
            span: 16
          } }
          initialValues={ {
            name: '',
            description: '',
            roleTag: roleTagMap[0].roleTag,
          } }
        >
          <Form.Item label="角色名称" name="name" rules={ [{ required: true, message: '权限名称不能为空' }] }>
            <Input></Input>
          </Form.Item>
          <Form.Item label="角色描述" name="description" rules={ [{ required: true, message: '权限路径不能为空' }] }>
            <Input></Input>
          </Form.Item>
          <Form.Item label="角色类型" name="roleTag" rules={ [{ required: true, message: '权限路径不能为空' }] }>
            <Select options={ roleTagOptions }></Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
})

export default EditRoleModal
