import { createSlice } from '@reduxjs/toolkit'

export const envInfoSlice = createSlice({
  name: 'env',
  initialState: {
    envInfo: {}
  },
  reducers: {
    updateEnvInfo: (state, { payload }) => {
      state.envInfo = payload.envInfo
    }
  }
})

export const { updateEnvInfo } = envInfoSlice.actions

export default envInfoSlice.reducer
