import React from 'react'
import ReactDOM from 'react-dom/client'

import './assets/styles/application.scss'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import routers from './routers/router'
import { ConfigProvider, App } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import store from './store/store'
import { Provider } from 'react-redux'
import 'dayjs/locale/zh-cn'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <ConfigProvider
    componentSize="normal"
    locale={ zhCN }
  >
    <App>
      <Provider store={ store }>
        <RouterProvider router={ routers }/>
      </Provider>
    </App>
  </ConfigProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
