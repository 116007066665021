import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, notification, Table, Tag, ConfigProvider } from 'antd'
import { getAccountList, setOrgOperationAccount } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const EditOperationAccountModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [tableData, setTableData] = useState({})
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getTableData()

      setSelectedRowKeys(props.accountList.map(item => {
        return item.accountId
      }))
    }
  }, [modalVisible])

  const columns = [
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '账户名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
      render: (roleName) => {
        return (<>
          <Tag color="blue">{ roleName }</Tag>
        </>)
      }
    }
  ]

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const getTableData = () => {
    setTableDataLoading(true)
    const params = {
      pageSize: 999,
      pageNum: 1,
      orderType: 'DESC',
      orderBy: 'id',
      roleTag: 'operator'
    }

    getAccountList(params).then(res => {
      setTableDataLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setTableDataLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询权限失败',
        centered: true
      })
    })
  }

  const onOk = () => {
    const params = {
      id: props.orgItem.id,
      operationUserIdList: selectedRowKeys
    }

    setOrgOperationAccount(params).then(res => {
      if (res.code === ResponseCode.success) {
        hideModal()
        notifyApi.success({
          message: '操作成功'
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '设置财务人员失败',
        centered: true
      })
    })
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title="配置管理员"
        width={ 800 }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <ConfigProvider componentSize="small">
          <Table
            rowSelection={ {
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange
            } }
            loading={ tableDataLoading }
            rowKey={ (record) => record.accountId }
            columns={ columns }
            dataSource={ tableData.elements }
            pagination={ false }
          ></Table>
        </ConfigProvider>
      </Modal>
    </div>
  )
})

export default EditOperationAccountModal
