import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, ConfigProvider, Input, Modal, notification, Space, Spin, Table } from 'antd'
import { useSelector } from 'react-redux'

import eventBus from '../../../plugins/events'
import { generateCandidateNumber } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const initSelectedRowKeys = ['location', 'room', 'seat']

const GenerateExamineeNumberModal = forwardRef((props, ref) => {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState(initSelectedRowKeys)
  const [tableData, setTableData] = useState([
    { name: 'applySite', title: '报名点代码', start: 1, length: 2 },
    { name: 'customize', title: '自定义', value: 1 },
    { name: 'level', title: '大类代码', start: 1, length: 2 },
    { name: 'major', title: '小类代码', start: 1, length: 2 },
    { name: 'location', title: '考点代码', start: 1, length: 2, disabled: true },
    { name: 'room', title: '考场号', start: 1, length: 3, disabled: true },
    { name: 'seat', title: '座位号', start: 1, length: 2, disabled: true }
  ])

  const columns = [
    {
      width: 120,
      title: '字段名',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: '起始',
      key: 'start',
      dataIndex: 'start',
      render: (record, row) => {
        if (row.name !== 'customize') {
          return <Input
            disabled={ row.disabled }
            value={ row.start }
            onInput={ tableItemChange.bind(this, row, 'start') }
          ></Input>
        } else {
          return <Input
            value={ row.value }
            onInput={ tableItemChange.bind(this, row, 'value') }
          ></Input>
        }
      }
    },
    {
      title: '位数',
      key: 'length',
      dataIndex: 'length',
      render: (record, row) => {
        if (row.name !== 'customize') {
          return <Input
            disabled={ row.disabled }
            value={ row.length }
            onInput={ tableItemChange.bind(this, row, 'length') }
          ></Input>
        } else {
          return <></>
        }
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row, index) => {
        if (!['location', 'room', 'seat'].includes(row.name)) {
          return (
            <Space className={ 'd-flex justify-content-end' }>
              { index === 0
                ? <></>
                : <Button
                  type={ 'primary' }
                  ghost
                  onClick={ moveUp.bind(this, row, index) }
                >上移</Button> }
              { index === 3
                ? <></>
                : <Button
                  type={ 'primary' }
                  ghost
                  onClick={ moveDown.bind(this, row, index) }
                >下移</Button> }
            </Space>
          )
        }
      }
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const moveUp = (row, index) => {
    tableData.splice(index, 1)
    tableData.splice(index - 1, 0, row)

    setTableData([].concat(tableData))
  }

  const moveDown = (row, index) => {
    tableData.splice(index, 1)
    tableData.splice(index + 1, 0, row)

    setTableData([].concat(tableData))
  }

  const tableItemChange = (row, key, e) => {
    tableData.forEach(item => {
      if (row.name === item.name) {
        item[key] = e.target.value
      }
    })

    setTableData([].concat(tableData))
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const handleCommit = () => {
    const params = {
      examId: selectedExam.id,
      locationSort: 4,
      roomSort: 5,
      seatSort: 6
    }
    tableData.filter(item => {
      return item.name !== 'location' && item.name !== 'room' && item.name !== 'seat'
    }).forEach((item, index) => {
      if (selectedRowKeys.includes(item.name)) {
        if (item.name !== 'customize') {
          params[`${ item.name }Code`] = true
          params[`${ item.name }Start`] = item.start
          params[`${ item.name }Length`] = item.length
          params[`${ item.name }Sort`] = index
        } else {
          params[`${ item.name }Code`] = true
          params[`${ item.name }Value`] = item.value
          params[`${ item.name }Sort`] = index
        }
      } else {
        params[`${ item.name }Code`] = false
      }
    })

    setLoading(true)

    generateCandidateNumber(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.success({
          message: '操作成功'
        })
        eventBus.emit('needUpdateRoomList')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，创建准考证号失败'
      })
    })
  }

  const onOk = () => {
    Modal.confirm({
      title: '准考证号分配确认',
      content: '分配准考证号后无法修改，确定要分配准考证号吗？',
      onOk: () => {
        handleCommit()
      }
    })
  }

  const onSelectedChange = (keys) => {
    setSelectedRowKeys(keys)
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '生成准考证号' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
        confirmLoading={ loading }
      >
        <Spin spinning={loading}>
          <ConfigProvider componentSize={ 'small' }>
            <Table
              columns={ columns }
              dataSource={ tableData }
              rowKey={ record => record.name }
              pagination={ false }
              rowSelection={ {
                selectedRowKeys: selectedRowKeys,
                getCheckboxProps: (record) => {
                  return {
                    disabled: record.disabled,
                    name: record.name
                  }
                },
                onChange: onSelectedChange
              } }
            ></Table>
          </ConfigProvider>
        </Spin>
      </Modal>
    </div>
  )
})

export default GenerateExamineeNumberModal
