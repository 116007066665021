import { useSelector } from 'react-redux'
import Styles from './Dashboard.module.scss'
import { Button, ConfigProvider, Image, Modal, Space, Table } from 'antd'
import { getToDoList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useEffect, useState } from 'react'
import router from '../../routers/router'

function Dashboard () {
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const roleList = useSelector(state => state.role.roleList)
  const [toDoList, setToDoList] = useState([])
  const [loading, setLoading] = useState(false)
  const siteId = sessionStorage.getItem('siteId')

  useEffect(() => {
    getToDoListAction()
  }, [])

  const toDoColumns = [
    {
      title: '考试名称',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '待办类型',
      key: 'type',
      dataIndex: 'type',
      render: (record, row) => {
        return generateToDoTypeLabel(row)
      }
    },
    {
      title: '时间',
      key: 'date',
      dataIndex: 'date',
      render: (record, row) => {
        return `${ row.startTime } 至 ${ row.endTime }`
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (
          <div className={ 'd-flex justify-content-end' }>
            { generateOperation(row) }
          </div>
        )
      }
    }
  ]

  const generateToDoTypeLabel = (row) => {
    let content

    switch (row.type) {
      case 'IMPORT':
        content = '导入职位'
        break
      case 'POSITION_CHECK':
        content = '职位复核'
        break
      case 'POOR_CHECK':
        content = '贫困生审核'
        break
      case 'CHECKER':
        content = '资格审核'
        break
    }

    return content
  }

  const generateOperation = (row) => {
    let content

    switch (row.type) {
      case 'IMPORT':
        content = <Button type={ 'primary' } onClick={ navigatorToPosition.bind(this, row) }>导入职位</Button>
        break
      case 'POSITION_CHECK':
        content = <Button type={ 'primary' } onClick={ navigatorToPosition.bind(this, row) }>职位复核</Button>
        break
      case 'POOR_CHECK':
        content = <Button type={ 'primary' } onClick={ navigatorToPoorCheck.bind(this, row) }>贫困生审核</Button>
        break
      case 'CHECKER':
        content = <Button type={ 'primary' } onClick={ navigatorToExamineeCheck.bind(this, row) }>开始审核</Button>
        break
    }

    return content
  }

  const navigatorToPosition = (row) => {
    router.navigate(`/${ siteId }/exam/exam-detail?examId=${ row.id }&activeTabKey=position`)
  }

  const navigatorToPoorCheck = (row) => {
    router.navigate(`/${ siteId }/exam/poor-examinee-check?examId=${ row.id }`)
  }

  const navigatorToExamineeCheck = (row) => {
    router.navigate(`/${ siteId }/exam/exam-check-management?examId=${ row.id }`)
  }

  const getCurrentRoleName = (currentRoleId, list) => {
    return list.find(role => {
      return role.id === currentRoleId
    })?.name
  }

  const getToDoListAction = () => {
    setLoading(true)
    getToDoList({ roleTag: userInfo.currentRoleTag }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setToDoList(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询待办列表失败'
      })
    })
  }

  return (
    <div className={ 'pt-3' }>
      <div className={ Styles.userInfoWrap }>
        <div className={ 'd-flex justify-content-between' }>
          <Space>
            <div className={ Styles.userLogo }>
              <Image
                className={ 'mb-2' }
                preview={ false }
                width={ 80 }
                src={ require('../../assets/images/user-logo.png') }></Image>
            </div>
            <Space className={ 'ml-3' } direction={ 'vertical' }>
              <Space>
                <div className={ Styles.label }>用户名：</div>
                <div>{ userInfo.realName }</div>
              </Space>
              <Space>
                <div className={ Styles.label }>手机号：</div>
                <div>{ userInfo.username }</div>
              </Space>
              <Space>
                <div className={ Styles.label }>当前角色：</div>
                <div>{ getCurrentRoleName(userInfo.currentRoleId, roleList) }</div>
              </Space>
            </Space>
          </Space>
          <Space direction={ 'vertical' }>
            <Image
              width={ 180 }
              preview={ false }
              src={ require('../../assets/images/dashboard-bg2.png') }
            ></Image>
          </Space>
        </div>
      </div>
      <div className={ 'mt-3' }>
        <div className={ Styles.dashboardTitle }>待办事项</div>
        <ConfigProvider componentSize={ 'small' }>
          <Table
            loading={ loading }
            columns={ toDoColumns }
            dataSource={ toDoList }
            rowKey={ record => record.id }
            pagination={ false }
          ></Table>
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Dashboard
