import { Button } from 'antd'
import ComponentVisibleByAuth from './ComponentVisibleByAuth'

function AppButton ({ authCode, ...args }) {
  const getContent = () => {
    if (authCode) {
      return <ComponentVisibleByAuth authCode={ authCode }>
        <Button
          { ...args }
        >{ args.children }</Button>
      </ComponentVisibleByAuth>
    } else {
      return <Button
        { ...args }
      >{ args.children }</Button>
    }
  }

  return (<>
    { getContent() }
  </>)
}

export default AppButton
