import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Upload } from 'antd'

import ViewExamineeFileModal from '../../components/ViewExamineeFileModal'
import ResponseCode from '../../common/response-code'
import { uploadImg } from '../../common/request'
import { useSelector } from 'react-redux'

function FileFormField ({ value, fileName, fieldType, uploadApi, onChange, fileUploading, fileUploaded }) {
  const viewExamineeFileModalRef = useRef()
  const [currentViewFile, setCurrentViewFile] = useState({})
  const [loading, setLoading] = useState(false)
  const [uploadResult, setUploadResult] = useState({})
  const envInfo = useSelector(state => state.env.envInfo)

  useEffect(() => {
    if (loading) {
      fileUploading && fileUploading()
    } else {
      fileUploaded && fileUploaded()
    }
  }, [loading])

  const showExamineeFile = (file) => {
    setCurrentViewFile(file)
    viewExamineeFileModalRef.current.showModal()
  }

  const getFileStatus = (val) => {
    return val
      ? (<>
        <div className={ 'mr-3 text-success' }>已上传</div>
        <Button
          className={ 'mr-2' }
          type={ 'primary' }
          ghost
          size={ 'small' }
          onClick={ showExamineeFile.bind(this, {
            fileName: fileName,
            filePath: fieldType === '11' ? JSON.parse(val)[0].filePath : value
          }) }
        >查看</Button>
      </>)
      : <div className={ 'mr-3 text-primary' }>未上传</div>
  }

  const beforeUpload = (file) => {
    if (fieldType === '6') {
      if (['image/png', 'image/jpeg'].includes(file.type)) {
        if (file.size > 300 * 1024 || file.size < 30 * 1024) {
          setUploadResult({ status: 0, msg: '上传失败，文件大小必须大小30K并且小于300K。' })
          return false
        }
      } else {
        setUploadResult({ status: 0, msg: '上传失败，仅支持上传jpeg、png文件。' })
        return false
      }
    } else {
      if (['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)) {
        if (file.size > 5 * 1024 * 1024) {
          setUploadResult({ status: 0, msg: '上传失败，文件大小不能超过5M。' })
          return false
        }
      } else {
        setUploadResult({ status: 0, msg: '上传失败，仅支持上传jpeg、png、pdf文件。' })
        return false
      }
    }
    setLoading(true)
  }

  const uploadPersonImageChange = ({ file }) => {
    const res = file.response
    if (res) {
      setLoading(false)
      setUploadResult({ status: 1, msg: '上传成功' })
      if (res.code === ResponseCode.success) {
        onChange(fieldType === '11' ? JSON.stringify([res.data]) : res.data.filePath)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  return (<>
    <div className={ 'd-flex align-items-center' }>
      { getFileStatus(value) }

      <Upload
        name={ 'file' }
        action={ `${ envInfo.picDomain }/${ uploadApi }` }
        headers={ {
          authorization: window.sessionStorage.getItem('access_token')
        } }
        disabled={ loading }
        maxCount={ 1 }
        showUploadList={ false }
        beforeUpload={ beforeUpload }
        onChange={ uploadPersonImageChange }
      >
        <Button
          type={ 'primary' }
          ghost
          loading={ loading }
          size={ 'small' }
        >{ value ? '重新上传' : '上传' }</Button>
      </Upload>
      <span className={ `ml-2 text-${ uploadResult.status ? 'success' : 'danger' }` }>{ uploadResult.msg }</span>
    </div>

    <ViewExamineeFileModal
      ref={ viewExamineeFileModalRef }
      fileItem={ currentViewFile }
    ></ViewExamineeFileModal>
  </>)
}

export default FileFormField
