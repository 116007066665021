import React, { useEffect, useState } from 'react'
import { Button, Image, Modal, Upload } from 'antd'
import { uploadImg } from '../common/request'
import ResponseCode from '../common/response-code'
import { useSelector } from 'react-redux'

function AppUploadImg ({ value, onChange }) {
  const envInfo = useSelector(state => state.env.envInfo)
  const [loading, setLoading] = useState(false)
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    setImgSrc(value)
  }, [value])

  const beforeUpload = () => {
    setLoading(true)
  }

  const handleChange = ({ file }) => {
    const res = file.response

    if (res) {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        onChange?.(res.data.filePath)
        setImgSrc(res.data.filePath)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  const generateFullSrc = (path) => {
    return `${ envInfo.picDomain }/${ path }`
  }

  const deleteFile = () => {
    onChange?.('')
  }

  return (<>
    {
      imgSrc ? <Image src={ generateFullSrc(value) } style={ { height: 100 } }></Image>
        : <></>
    }
    <div className={ 'mt-2' }>
      <Upload
        name={ 'file' }
        action={ `${ envInfo.picDomain }/${ uploadImg }` }
        headers={ {
          authorization: window.sessionStorage.getItem('access_token')
        } }
        disabled={ loading }
        maxCount={ 1 }
        showUploadList={ false }
        beforeUpload={ beforeUpload }
        onChange={ handleChange }
      >
        <Button type={ 'primary' } size={'small'} loading={ loading } ghost>上传</Button>
      </Upload>
      {
        imgSrc
          ? <Button
            size={'small'}
            className={ 'ml-2' }
            type={ 'primary' }
            danger
            ghost
            onClick={ deleteFile }>删除</Button>
          : <></>
      }
    </div>
  </>)
}

export default AppUploadImg
