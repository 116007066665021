import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Modal, notification, Spin, Table, Tabs } from 'antd'

import { updateFieldsSort } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const SelectRequiredFieldsModal = forwardRef((props, ref) => {
  const [ordinaryFieldList, setOrdinaryFieldList] = useState([])
  const [ordinaryFieldSelectedRowKeys, setOrdinaryFieldSelectedRowKeys] = useState([])
  const [accessoryFieldList, setAccessoryFieldList] = useState([])
  const [accessoryFieldSelectedRowKeys, setAccessoryFieldSelectedRowKeys] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('ordinary')

  const columns = [
    {
      title: '字段名',
      key: 'fieldName',
      dataIndex: 'fieldName'
    },
    {
      title: '字段编码',
      key: 'fieldCode',
      dataIndex: 'fieldCode'
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setOrdinaryFieldList(props.fieldList.filter(item => {
        return item.fieldType !== '11'
      }))

      setOrdinaryFieldSelectedRowKeys(props.fieldList.filter(item => {
        return item.fieldType !== '11' && item.fieldIsNotBlank === '1'
      }).map(item => item.fieldCode))

      setAccessoryFieldList(props.fieldList.filter(item => {
        return item.fieldType === '11'
      }))

      setAccessoryFieldSelectedRowKeys(props.fieldList.filter(item => {
        return item.fieldType === '11' && item.fieldIsNotBlank === '1'
      }).map(item => item.fieldCode))
    }
  }, [modalVisible])

  const getActiveTableData = () => {
    if (activeTab === 'ordinary') {
      return ordinaryFieldList
    } else {
      return accessoryFieldList
    }
  }

  const setActiveTableData = (data) => {
    if (activeTab === 'ordinary') {
      return setOrdinaryFieldSelectedRowKeys(data)
    } else {
      return setAccessoryFieldSelectedRowKeys(data)
    }
  }

  const onSelectChange = (data) => {
    setActiveTableData(data)
  }

  const tabItems = [
    {
      label: '普通字段',
      key: 'ordinary',
      children: (
        <ConfigProvider componentSize={ 'small' }>
          <Table
            columns={ columns }
            dataSource={ ordinaryFieldList }
            rowSelection={{
              selectedRowKeys: ordinaryFieldSelectedRowKeys,
              onChange: onSelectChange
            }}
            rowKey={record => record.fieldCode}
            pagination={ false }
            scroll={ {
              y: 500
            } }
          ></Table>
        </ConfigProvider>
      )
    },
    {
      label: '附件字段',
      key: 'accessory',
      children: (
        <ConfigProvider componentSize={ 'small' }>
          <Table
            columns={ columns }
            dataSource={ accessoryFieldList }
            rowSelection={{
              selectedRowKeys: accessoryFieldSelectedRowKeys,
              onChange: onSelectChange
            }}
            rowKey={record => record.fieldCode}
            pagination={ false }
          ></Table>
        </ConfigProvider>
      )
    }
  ]

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    const params = {
      examId: props.examInfo.id,
      fieldsList: ordinaryFieldList.map(item => {
        return {
          ...item,
          fieldIsNotBlank: ordinaryFieldSelectedRowKeys.includes(item.fieldCode) ? '1' : '0'
        }
      }),
      fileFieldsList: accessoryFieldList.map(item => {
        return {
          ...item,
          fieldIsNotBlank: accessoryFieldSelectedRowKeys.includes(item.fieldCode) ? '1' : '0'
        }
      })
    }
    setLoading(true)
    updateFieldsSort(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.success({
          message: '操作成功'
        })
        props.insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，修改必填字段失败'
      })
    })
  }

  const activeTabOnChange = (e) => {
    setActiveTab(e)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 700 }
        title={ '选择必填字段' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
        maskClosable={ false }
      >
        <Spin spinning={ loading }>
          <Tabs items={ tabItems } onChange={ activeTabOnChange }></Tabs>
        </Spin>
      </Modal>
    </div>
  )
})

export default SelectRequiredFieldsModal
