import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Input, Modal, Tag, Table, notification, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import styles from './RoleManagement.module.scss'
import { deleteRole, getRoleList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import EditRoleModal from './EditRoleModal'
import EditRoleAuthModal from './EditRoleAuthModal'
import { updateRoleList } from '../../store/slice/role-slice'
import { authTypeMap } from '../../common/common'

function RoleManagement () {
  const dispatch = useDispatch()
  const editRoleModalRef = useRef()
  const editRoleAuthModalRef = useRef()
  const [searchForm] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableData, setTableData] = useState([])
  const [tableDataLoading, setTableDataLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [editRoleModalType, setEditRoleModalType] = useState('')
  const roleTagMap = useSelector(state => state.role.roleTagMap)
  const roleList = useSelector(state => state.role.roleList)

  useEffect(() => {
    getTableData()
  }, [roleList])

  const columns = [
    {
      title: '角色名称',
      width: '300px',
      dataIndex: 'name',
      key: 'name',
      render: (data, row) => {
        return (<>
          <div>{ row.name }</div>
          <div className={ styles.roleDescription }>{ row.description }</div>
        </>)
      }
    },
    {
      title: '角色类型',
      width: '150px',
      dataIndex: 'roleTag',
      key: 'roleTag',
      render: (roleTag) => {
        return roleTagMap.find(item => {
          return item.roleTag === roleTag
        }).name
      }
    },
    // {
    //   title: '角色权限',
    //   dataIndex: 'rolePrivileges',
    //   key: 'rolePrivileges',
    //   render: (data, row) => {
    //     return (<>
    //       <div className="mb-2 d-flex align-items-center">
    //         <div className="mr-2">页面:</div>
    //         <div>{ getRolePrivilegesTags(row, 'page') }</div>
    //       </div>
    //       <div className="mb-2 d-flex align-items-center">
    //         <div className="mr-2">菜单:</div>
    //         <div>{ getRolePrivilegesTags(row, 'menu') }</div>
    //       </div>
    //       <div className="d-flex align-items-center">
    //         <div className="mr-2">按钮:</div>
    //         <div>{ getRolePrivilegesTags(row, 'button') }</div>
    //       </div>
    //     </>)
    //   }
    // },
    {
      title: '操作',
      dataIndex: '',
      width: '220px',
      key: '',
      render: (data, row) => {
        return (
          <Space className="d-flex justify-content-end">
            <Button
              size={'small'}
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditRoleModal) }>编辑</Button>
            <Button
              size={'small'}
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, setRoleAuthModal) }>配置权限</Button>
            <Button
              size={'small'}
              type="primary"
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteRoleModal) }>删除</Button>
          </Space>
        )
      }
    }
  ]

  const getRolePrivilegesTags = (row, type) => {
    const tags = row.authoritySet.filter(item => {
      return item.type === type
    })
    const tagsLength = tags.length
    let color = authTypeMap.find(item => {
      return item.value === type
    }).color

    return tagsLength > 0 ? tags.map(item => {
      return <Tag key={ item.id } color={ color }>{ item.name }</Tag>
    }) : '无'
  }

  const handleSearch = () => {

  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateRoleModal = () => {
    setEditRoleModalType('create')
    editRoleModalRef.current.showModal()
  }

  const showEditRoleModal = () => {
    setEditRoleModalType('edit')
    editRoleModalRef.current.showModal()
  }

  const setRoleAuthModal = () => {
    editRoleAuthModalRef.current.showModal()
  }

  const showDeleteRoleModal = (roleItem) => {
    Modal.confirm({
      title: '删除权限',
      content: (
        <div>确定要删除角色【{ roleItem.name }】吗？</div>
      ),
      onOk () {
        deleteRoleAction(roleItem)
      }
    })
  }

  const deleteRoleAction = (roleItem) => {
    const params = {
      id: roleItem.id
    }

    deleteRole(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `删除角色成功`
        })
        updateRoleListAction()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除角色失败',
        centered: true
      })
    })
  }

  const getTableData = () => {
    setTableData(roleList)
  }

  const updateRoleListAction = () => {
    setTableDataLoading(true)
    getRoleList().then(res => {
      setTableDataLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        dispatch(updateRoleList({ roleList: res.data }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setTableDataLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询字典小类失败',
        centered: true
      })
    })
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <Form
          layout="inline"
          form={ searchForm }
          initialValues={ { roleName: '' } }
          autoComplete="off">
          <Form.Item
            label="角色名称"
            name="name">
            <Input placeholder="输入角色名称搜索" style={ { width: '180px' } }/>
          </Form.Item>
          <Form.Item className="d-flex justify-content-center">
            <Button type="primary" onClick={ handleSearch }>
              查询
            </Button>
          </Form.Item>
        </Form>

        <Button type="primary" icon={ <PlusOutlined/> } onClick={ openModal.bind(this, {}, showCreateRoleModal) }>新增角色</Button>
      </div>
      <Table
        columns={ columns }
        pagination={ false }
        loading={tableDataLoading}
        rowKey={ (record) => record.id }
        dataSource={ tableData }></Table>

      <EditRoleModal
        ref={ editRoleModalRef }
        modalType={ editRoleModalType }
        roleItem={ currentEditItem }
        insertSuccess={ updateRoleListAction }
      ></EditRoleModal>

      <EditRoleAuthModal
        ref={ editRoleAuthModalRef }
        roleItem={ currentEditItem }
        insertSuccess={ updateRoleListAction }>
      </EditRoleAuthModal>
    </div>
  )
}

export default RoleManagement
