import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Form, Input, Modal, notification, Space, Table } from 'antd'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import { deleteCheckAccountBatch, getCheckAccountList, resetCheckAccountPassword } from '../../common/request'
import ResponseCode from '../../common/response-code'
import EditCheckAccountModal from './EditCheckAccountModal'
import DeIdentification from '../../components/DeIdentification'
import AppButton from '../../components/AppButton'

function ExamCheckAccountManagement () {
  const [form] = Form.useForm()
  const editCheckAccountModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [loading, setLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [selectedTableItem, setSelectedTableItem] = useState([])

  const columns = [
    {
      title: '姓名',
      key: 'name',
      dataIndex: 'name',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '手机号',
      key: 'phone',
      dataIndex: 'phone',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'phone' }></DeIdentification>)
      }
    },
    {
      width: 120,
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (<>
          <Space className={ 'd-flex justify-content-end' }>
            <Button
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showEditCheckAccountModal) }
            >修改</Button>
            <Button
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showResetAccountPasswordModal) }
            >重置密码</Button>
            <AppButton
              type={ 'primary' }
              authCode={ 'button_shzhgl-sc' }
              danger
              ghost
              onClick={ showDeleteAccountModal.bind(this, row) }
            >删除</AppButton>
          </Space>
        </>)
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      setTableData([])
      getTableData()
    }
  }, [selectedExam, pageNum, pageSize])

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn?.(row)
  }

  const showEditCheckAccountModal = () => {
    editCheckAccountModalRef.current.showModal()
  }

  const showDeleteAccountModal = (authItem) => {
    Modal.confirm({
      title: '删除账号',
      content: (
        <div>确定要删除账号【{ authItem.name }】吗？</div>
      ),
      onOk () {
        deleteAccountBatchAction([authItem.accountId])
      }
    })
  }

  const showResetAccountPasswordModal = (row) => {
    Modal.confirm({
      title: '重置密码',
      content: `确定要将用户${ row.name }（${ row.phone }）密码重置为${ row.phone.slice(-6) }(手机号后6位)?`,
      onOk: () => {
        resetAccountPasswordAction(row)
      }
    })
  }

  const resetAccountPasswordAction = (row) => {
    const params = {
      userId: row.userId,
      phone: row.phone
    }

    resetCheckAccountPassword(params).then(res => {
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })

        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，重置密码失败'
      })
    })
  }

  const handleSearch = () => {
    const searchData = form.getFieldsValue()

    getTableData(searchData)
  }

  const getTableData = (searchData) => {
    const params = {
      ...searchData,
      examId: selectedExam.id,
      pageNum,
      pageSize
    }

    setLoading(true)
    getCheckAccountList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询审核人员列表失败'
      })
    })
  }

  const showDeleteAccountBatchModal = () => {
    if (selectedTableItem.length === 0) {
      Modal.error({
        title: '错误',
        content: '未选择账号'
      })
      return
    }
    Modal.confirm({
      title: '删除账号',
      content: (
        <div>确定要批量删除账号吗？</div>
      ),
      onOk () {
        deleteAccountBatchAction(selectedTableItem.map(item => item.accountId))
      }
    })
  }

  const deleteAccountBatchAction = (accountIds) => {
    const params = {
      examId: selectedExam.id,
      accountIds: accountIds
    }

    deleteCheckAccountBatch(params).then(res => {
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除账号失败',
        centered: true
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      handleSearch()
    }
  }

  return (<>
    <SelectExam>
      <Form
        layout={ 'inline' }
        form={ form }
      >
        <Form.Item
          label={ '姓名' }
          name={ 'checkName' }
          trigger={ 'onInput' }
        >
          <Input placeholder={ '输入姓名搜索' } onKeyUp={ onEnter }></Input>
        </Form.Item>
        <Form.Item
          label={ '手机号' }
          name={ 'phone' }
          trigger={ 'onInput' }
        >
          <Input placeholder={ '输入手机号搜索' } onKeyUp={ onEnter }></Input>
        </Form.Item>
        <Form.Item
        >
          <Button
            type={ 'primary' }
            onClick={ handleSearch }
          >查询</Button>
        </Form.Item>
      </Form>

      <Space className={'w-100'} direction={'vertical'} align={'end'}>
        <AppButton
          type={'primary'}
          authCode={'button_shzhgl-plsc'}
          onClick={ showDeleteAccountBatchModal }>批量删除</AppButton>
      </Space>

      <ConfigProvider componentSize={ 'small' }>
        <Table
          className={ 'mt-3' }
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.content }
          rowKey={ record => record.accountId }
          rowSelection={ {
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedTableItem(selectedRows)
            }
          } }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>
    </SelectExam>

    <EditCheckAccountModal
      ref={ editCheckAccountModalRef }
      examInfo={ selectedExam }
      accountInfo={ currentEditItem }
      insertSuccess={ getTableData }
    ></EditCheckAccountModal>
  </>)
}

export default ExamCheckAccountManagement
