import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Empty, Modal, notification, Spin } from 'antd'

import Styles from './ReuseHistoryExamSubjectModal.module.scss'
import { batchInsertSubject, getExamSubjectList, getHistorySubjectExamList } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const ReuseHistoryExamSubjectModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [examList, setExamList] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getExamListAction()
    }
  }, [modalVisible])

  const getExamListAction = () => {
    setLoading(true)
    const params = {
      pageNum: 1,
      pageSize: 999,
      orderBy: 'id',
      orderType: 'DESC'
    }

    getHistorySubjectExamList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setExamList(res.data.elements)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试列表失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const selectExamChange = (item) => {
    getExamSubjectList({ examId: item.id }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        batchInsertSubjectAction(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询科目列表失败',
        centered: true
      })
    })
  }

  const batchInsertSubjectAction = (subjectList) => {
    setLoading(true)
    batchInsertSubject(subjectList.map(item => {
      return {
        ...item,
        examId: props.examInfo.id
      }
    })).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.success({
          message: '操作成功'
        })
        props.insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，引用历史考试科目失败',
        centered: true
      })
    })
  }

  const generateExamItem = (list) => {
    if (list?.length > 0) {
      return list.map(examItem => {
        return (
          <div className={ Styles.examItem } key={ examItem.id } onClick={ selectExamChange.bind(this, examItem) }>
            { examItem.name }
          </div>
        )
      })
    } else {
      return <div style={ { height: 300 } }>
        <Empty description={ '暂无可复用考试' }/>
      </div>
    }
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '引用历史考试科目' }
        open={ modalVisible }
        confirmLoading={ loading }
        onCancel={ hideModal }
        footer={ false }
      >
        <Spin spinning={loading}>
          <div className={ 'my-3 exam-list-wrap' }>
            { generateExamItem(examList) }
          </div>
        </Spin>
      </Modal>
    </div>
  )
})

export default ReuseHistoryExamSubjectModal
