import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Cascader, Form, Input, InputNumber, Modal, notification, Spin } from 'antd'
import { getRegionTree, createExaminationSite, updateExaminationSite } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { useSelector } from 'react-redux'

const EditExaminationSiteModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const provinceList = useSelector(state => {
    return state.regionList.regionList.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: false
      }
    })
  })
  const [regionOptions, setRegionOptions] = useState(provinceList)
  const [addressItem, setAddressItem] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('新增考点')
      } else {
        setModalTitle('编辑考点')
        form.setFieldsValue({
          ...props.siteItem
        })

        if (props.siteItem?.regionIds) {
          initRegionOptions(props.siteItem.regionIds)
        }

      }
    }
  }, [modalVisible])

  useEffect(() => {
    if (addressItem && addressItem.length > 0) {
      form.setFieldsValue({
        address: addressItem?.slice(-1)[0].label,
        addressCode: addressItem?.slice(-1)[0].value,
        addressFullDetail: (addressItem || []).map(item => item.label).join('') + form.getFieldValue('addressDetail')
      })
    }
  }, [addressItem])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const getRequest = (fields) => {
    let request
    const params = {
      ...fields,
      orgId: props.orgItem.id
    }

    if (props.modalType === 'create') {
      request = createExaminationSite(params)
    } else {
      params.id = props.siteItem.id
      request = updateExaminationSite(params)
    }

    return request
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      getRequest(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        setLoading(false)
        Modal.error({
          title: '网络错误',
          content: '保存考点失败',
          centered: true
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const dataTransform = (data) => {
    return data.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: !item.childList,
        children: item.childList ? dataTransform(item.childList) : undefined
      }
    })
  }

  const initRegionOptions = (regionIds) => {
    setLoading(true)
    getRegionTree({ parent: regionIds[0] }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        regionOptions.forEach(province => {
          if (province.value === regionIds[0]) {
            province.children = dataTransform(res.data)
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const getRegionTreeAction = (targetOption) => {
    getRegionTree({ parent: targetOption.value }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        targetOption.loading = false
        targetOption.children = res.data.map(item => {
          return {
            label: item.name,
            value: item.code,
            id: item.id,
            isLeaf: !item.childList,
            childList: item.childList
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      targetOption.loading = false
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const loadRegionData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.childList) {
      targetOption.loading = false
      targetOption.children = targetOption.childList.map(item => {
        return {
          label: item.name,
          value: item.code,
          id: item.id,
          isLeaf: !item.childList,
          childList: item.childList
        }
      })

      setRegionOptions([...regionOptions])
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  const selectedAddressChange = (selectedKey, selectedItem) => {
    setAddressItem(selectedItem)
  }

  const addressDetail = (e) => {
    form.setFieldValue('addressFullDetail', (addressItem || []).map(item => item.label).join('') + e.target.value)
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            className={ 'mt-3' }
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item
              label={ '考点名称' }
              name={ 'name' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入考点名称' }></Input>
            </Form.Item>
            <Form.Item
              label={ '考点区域' }
              name={ 'regionIds' }
              rules={ [{ required: true }] }
            >
              <Cascader
                changeOnSelect
                options={ regionOptions }
                loadData={ loadRegionData }
                placeholder="请选择机构所属区域"
                onChange={ selectedAddressChange }
              ></Cascader>
            </Form.Item>
            <Form.Item
              label={ '考点地址' }
              name={ 'addressDetail' }
              rules={ [{ required: true }] }
              trigger={'onInput'}
            >
              <Input placeholder={ '请输入考点地址' } onChange={ addressDetail }></Input>
            </Form.Item>
            <Form.Item
              label={ '考场数量' }
              name={ 'maxRoom' }
              rules={ [{ required: true }] }
            >
              <InputNumber placeholder={ '请输入考考场数量' }></InputNumber>
            </Form.Item>
            <Form.Item hidden name={ 'address' }>
              <Input></Input>
            </Form.Item>
            <Form.Item hidden name={ 'addressCode' }>
              <Input></Input>
            </Form.Item>
            <Form.Item hidden name={ 'addressFullDetail' }>
              <Input></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExaminationSiteModal
