import { Editor } from '@tinymce/tinymce-react'
import { useEffect, useState } from 'react'

const sizeList = [8, 10, 10.5, 12, 14, 16, 18, 22, 24, 26, 28, 30, 32, 36, 40, 44, 48, 64, 72].map(item => {
  return `${ item }=${ item }px`
})

function TinymceFormItem ({ value, disabled, onChange }) {
  const [editorInstance, setEditorInstance] = useState(null)

  useEffect(() => {
    if (editorInstance) {
      editorInstance.setContent(value)
    }
  }, [editorInstance, value])

  const editorContentChange = () => {
    const editorContent = editorInstance.getContent()
    onChange?.(editorContent.replaceAll('<p>', '<div>')
      .replaceAll('</p>', '</div>'))
  }

  const onInstanceReady = (e, editor) => {
    setEditorInstance(editor)
  }

  return (<>
    <Editor
      init={ {
        disabled: disabled,
        height: 150,
        menubar: false,
        statusbar: false,
        language: 'zh-Hans',
        plugins: 'autolink link',
        toolbar: 'undo redo | fontfamily fontsize forecolor bold italic underline link',
        font_family_formats: '宋体; 黑体; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
        font_size_formats: sizeList.join(' ')
      } }
      onChange={ editorContentChange }
      onInit={ onInstanceReady }
    ></Editor>
  </>)
}

export default TinymceFormItem
