import React, { useEffect, useRef, useState } from 'react'
import { ConfigProvider, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getExamLocationList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import EditExaminationSiteModal from './EditExaminationSiteModal'
import AppButton from '../../../components/AppButton'

function ConfigExaminationSite (props) {
  const editExaminationSiteModalRef = useRef()
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [modalType, setModalType] = useState('')
  const [currentEditItem, setCurrentEditItem] = useState({})

  useEffect(() => {
    if (props.modalVisible && props.orgItem.id) {
      getTableData()
    }
  }, [props.orgItem, pageNum, pageSize, props.modalVisible])

  const columns = [
    {
      title: '考点名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '考点地址',
      dataIndex: 'addressFullDetail',
      key: 'addressFullDetail'
    },
    {
      title: '考场数量',
      dataIndex: 'maxRoom',
      key: 'maxRoom'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: '90px',
      render: (record, row) => {
        return (<>
            <div className="d-flex justify-content-end">
              <AppButton
                authCode={ 'button_jgxq-kd-bj' }
                className="mr-2"
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditExaminationSiteModal) }
              >编辑</AppButton>
            </div>
        </>)
      }
    }
  ]

  const getTableData = () => {
    const params = {
      orgId: props.orgItem.id,
      pageNum,
      pageSize
    }

    getExamLocationList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      }
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateExaminationSiteModal = () => {
    setModalType('create')
    editExaminationSiteModalRef.current.showModal()
  }

  const showEditExaminationSiteModal = () => {
    setModalType('edit')
    editExaminationSiteModalRef.current.showModal()
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  return (
    <div>
      <div className="mb-2 d-flex justify-content-end">
        <AppButton
          authCode={ 'button_jgxq-kd-bj' }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateExaminationSiteModal) }
        >新增考点</AppButton>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditExaminationSiteModal
        ref={ editExaminationSiteModalRef }
        siteItem={ currentEditItem }
        modalType={ modalType }
        orgItem={ props.orgItem }
        insertSuccess={ getTableData }
      ></EditExaminationSiteModal>
    </div>
  )
}

export default ConfigExaminationSite
