import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Descriptions, Divider, Modal, notification, Table } from 'antd'

import { getExaminationLocationLastRoomList, mergeLocationLastRoom } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const MergeLastRoomModal = forwardRef(({ insertSuccess, batchInfo, locationInfo, roomInfo }, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRowItems, setSelectedRowItems] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const columns = [
    {
      title: '考点编号',
      key: 'locationNumber',
      dataIndex: 'locationNumber'
    },
    {
      title: '考点名称',
      key: 'locationName',
      dataIndex: 'locationName'
    },
    {
      title: '尾考场编号',
      key: 'roomNumber',
      dataIndex: 'roomNumber'
    },
    {
      title: '尾考场人数',
      key: 'allocateNumber',
      dataIndex: 'allocateNumber'
    }
  ]

  useEffect(() => {
    if (modalVisible) {
      getTableData()
    }
  }, [modalVisible])

  const getTableData = () => {
    setLoading(true)
    const params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId
    }

    getExaminationLocationLastRoomList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data.filter(item => {
          return item.roomId !== roomInfo.roomId
        }).sort((a, b) => {
          return a.roomNumber - b.roomNumber
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询尾考场列表失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onSelectChange = (newSelectedRowKeys, newSelectedRowItems) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRowItems(newSelectedRowItems)
  }

  const onOk = () => {
    if (selectedRowItems.length === 0) {
      Modal.error({
        title: '错误',
        content: '未选择要合并的考点'
      })
      return
    }

    const params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId,
      sourceRoomId: roomInfo.roomId,
      sourceLocationId: locationInfo.locationId,
      targetRoomId: selectedRowItems[0].roomId,
      targetLocationId: selectedRowItems[0].locationId,
      mergeNumber: roomInfo.allocateNumber
    }

    mergeLocationLastRoom(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.success({
          message: '操作成功',
          description: '已合并尾考场'
        })
        insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，合并尾考场失败'
      })
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 800 }
        title={ '合并尾考场' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Descriptions className={ 'mt-3' } column={ 4 }>
          <Descriptions.Item label={ '考点名称' }>{ locationInfo.locationName }</Descriptions.Item>
          <Descriptions.Item label={ '考场编号' }>{ `第 ${ roomInfo.roomNumber } 考场` }</Descriptions.Item>
          <Descriptions.Item label={ '最大容量' }>{ `${ roomInfo.standardNumber }人` }</Descriptions.Item>
          <Descriptions.Item label={ '已分配人数' }>{ `${ roomInfo.allocateNumber }人` }</Descriptions.Item>
        </Descriptions>
        <Divider className={ 'mt-0' } dashed/>
        <div>本考场人员可以合并至以下考场：</div>
        <Table
          loading={loading}
          columns={ columns }
          dataSource={ tableData }
          pagination={ false }
          rowKey={ record => record.roomId }
          rowSelection={ {
            type: 'radio',
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          } }
        ></Table>
      </Modal>
    </div>
  )
})

export default MergeLastRoomModal
