import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

import Styles from './ExamineeInfoModal.module.scss'
import { getExamineeCandidateScore } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ReportCard from './ReportCard'

function ExamineePublicResult (props) {
  const [candidateScore, setCandidateScore] = useState([])
  const [initDataLoading, setInitDataLoading] = useState(true)

  useEffect(() => {
    if (props.modalVisible) {
      getExamineeCandidateScoreData(props.examineeItem?.id || props.examineeItem?.applyInfoId)
    }
  }, [props.modalVisible])

  const getExamineeCandidateScoreData = () => {
    const params = {
      identityNumber: props.examineeItem.idCard,
      candidateName: props.examineeItem.name,
      examId: props.examineeItem.examId
    }
    getExamineeCandidateScore(params).then(res => {
      if (res.code === ResponseCode.success) {
        setCandidateScore(Object.keys(res.data).filter(key => {
          return !['_id', 'examId'].includes(key)
        }).map(key => {
          return {
            label: key,
            value: res.data[key]
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生操作记录失败'
      })
    }).finally(() => {
      setInitDataLoading(false)
    })
  }

  return (
    <div className={ 'd-flex justify-content-between' }>
      <div className={ Styles.sampleTable }>
        { Object.keys(candidateScore).length ? <div className={ Styles.blank }></div> : '' }
        <ReportCard
          candidateScore={ candidateScore }
          status={ initDataLoading }
        ></ReportCard>
      </div>
    </div>
  )
}

export default ExamineePublicResult
