import { createSlice } from '@reduxjs/toolkit'

const dataTransform = (data) => {
  return data.map(item => {
    return {
      label: item.name,
      value: item.code,
      id: item.id,
      isLeaf: !item.childList,
      children: item.childList ? dataTransform(item.childList) : undefined
    }
  })
}

export const regionListSlice = createSlice({
  name: 'region-list',
  initialState: {
    regionList: [],
    loadedRegion: []
  },
  reducers: {
    updateRegionList: (state, { payload }) => {
      state.regionList = payload.regionList
    },
    updateChildRegion: (state, { payload }) => {
      state.regionList = state.regionList.map(region => {
        if ((region.code || region.value) === payload.regionCode) {
          region.children = payload.regionData
        }

        return region
      })

      state.loadedRegion.push(payload.regionCode)
    }
  }
})

export const { updateRegionList, updateChildRegion } = regionListSlice.actions

export default regionListSlice.reducer
