import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Modal, Radio, Spin } from 'antd'
import { useSelector } from 'react-redux'
import { checkPosition, checkPositionBatch } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const PositionCheckModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [positionItems, setPositionItems] = useState([])
  const selectedExam = useSelector(state => state.examInfo.selectedExam)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (positionItemList) => {
    setPositionItems(positionItemList)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const positionGroupBySiteNumber = (positionList) => {
    const positionGroup = {}
    positionList.forEach(positionItem => {
      if (positionGroup[positionItem.siteNumber]) {
        positionGroup[positionItem.siteNumber].push(positionItem._id)
      } else {
        positionGroup[positionItem.siteNumber] = [positionItem._id]
      }
    })

    return Object.keys(positionGroup).map(siteNumber => {
      return {
        siteNumber,
        ids: positionGroup[siteNumber]
      }
    })
  }

  const getRequest = (searchData) => {
    let request
    if (positionItems.length === 1) {
      request = checkPosition({
        examId: selectedExam.id,
        siteNumber: positionItems[0].siteNumber,
        ids: positionItems.map(positionItem => positionItem._id),
        ...searchData
      })
    } else {
      request = checkPositionBatch({
        examId: selectedExam.id,
        checkList: positionGroupBySiteNumber(positionItems),
        ...searchData
      })
    }

    return request
  }

  const onOk = () => {
    const searchData = form.getFieldsValue()
    setLoading(true)
    getRequest(searchData).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        props.insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，职位复核失败'
      })
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ `${ positionItems.length > 1 ? '批量' : '' }职位复核` }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <div className={ 'px-4 ' }>
            <div className={ 'my-3 pl-3' }>是否确认复核通过职位?</div>
            <Form
              form={ form }
              labelCol={ {
                span: 6
              } }
              wrapperCol={ {
                span: 18
              } }
              initialValues={ {
                state: 2,
                checkContent: ''
              } }
            >
              <Form.Item
                label={ '职位复核' }
                name={ 'state' }>
                <Radio.Group>
                  <Radio value={ 4 }>通过</Radio>
                  <Radio value={ 3 }>不通过</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={ (prevValues, curValues) => {
                  return prevValues.state !== curValues.state
                } }>
                { ({ getFieldValue }) => {
                  return getFieldValue('state') === 3
                    ? (<Form.Item
                      label={ '不通过原因' }
                      name={ 'checkContent' }>
                      <Input.TextArea rows={ 4 }></Input.TextArea>
                    </Form.Item>)
                    : <></>
                } }
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  )
})

export default PositionCheckModal
