import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, ConfigProvider, Form, Input, Modal, notification, Row, Select, Space, Table, Upload } from 'antd'
import {
  deleteExaminee,
  getExamineeList,
  getExamineeUnit,
  getExamPositionList,
  resetExamineeApplyStatus,
  uploadExamineesPayStatusExcel
} from '../../common/request'
import ResponseCode from '../../common/response-code'
import { examCheckStatus, payStatusMap, takeExamCheckMap } from '../../common/common'
import ExamineeInfoModal from '../../components/examinee/ExamineeInfoModal'
import EditExamineeInfoModal from './EditExamineeInfoModal'
import SelectExam from '../../components/select-exam/SelectExam'
import { useSelector } from 'react-redux'
import ComponentDisabled from '../../components/ComponentDisabled'
import AppButton from '../../components/AppButton'
import { throttle } from '../../plugins/utils'
import DeIdentification from '../../components/DeIdentification'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'
import PageStatus from '../../components/PageStatus'

function ExamineeManagement () {
  const defaultOption = { label: '全部', value: '' }
  const [form] = Form.useForm()
  const examineeInfoModalRef = useRef()
  const editExamineeInfoModalRef = useRef()
  const envInfo = useSelector(state => state.env.envInfo)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [modalType, setModalType] = useState('')
  const [unitOptions, setUnitOptions] = useState([defaultOption])
  const [positionOptions, setPositionOptions] = useState([defaultOption])
  const [positionLoading, setPositionLoading] = useState(false)
  const [pageStatus, setPageStatus] = useState({ text: '', status: '' })

  const payStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '未缴费',
      value: 0
    },
    {
      label: '缴费成功',
      value: 1
    },
    {
      label: '贫困生免费',
      value: 2
    }
  ]

  const saveStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    ...Object.keys(examCheckStatus).reduce((prev, key) => {
      prev.push({ value: Number(key), label: examCheckStatus[key].label })
      return prev
    }, [])
  ]

  const takeExamCheckOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '未确认',
      value: 0
    },
    {
      label: '已确认',
      value: 1
    }
  ]

  const columns = [
    {
      title: '报名序号',
      width: 140,
      fixed: 'left',
      key: 'applyNo',
      dataIndex: 'applyNo'
    },
    {
      title: '姓名',
      width: 100,
      fixed: 'left',
      dataIndex: 'examineesName',
      key: 'examineesName',
      render: (record) => {
        return (<DeIdentification
          value={ record }
          type={ 'name' }
        ></DeIdentification>)
      }
    },
    {
      title: '身份证号',
      width: 170,
      fixed: 'left',
      dataIndex: 'idCard',
      key: 'idCard',
      render: (record) => {
        return (<DeIdentification
          value={ record }
          type={ 'idCard' }
        ></DeIdentification>)
      }
    },
    {
      title: '报名点名称',
      width: 130,
      dataIndex: 'siteName',
      key: 'siteName'
    },
    {
      title: '报名点代码',
      width: 120,
      dataIndex: 'siteCode',
      key: 'siteCode'
    },
    {
      title: '报考单位',
      width: 170,
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: '报考单位代码',
      width: 120,
      dataIndex: 'unitCode',
      key: 'unitCode'
    }
    , {
      title: '报考职位',
      width: 100,
      dataIndex: 'positionName',
      key: 'positionName'
    },
    {
      title: '报考职位代码',
      width: 120,
      dataIndex: 'positionCode',
      key: 'positionCode'
    },
    {
      title: '审核状态',
      width: 100,
      dataIndex: 'checkStatus',
      key: 'checkStatus',
      render: (record) => {
        return (<div className={ examCheckStatus[record]?.className }>{ examCheckStatus[record]?.label }</div>)
      }
    },
    {
      title: '支付状态',
      width: 100,
      dataIndex: 'payStatus',
      key: 'payStatus',
      render: (record) => {
        return (<div className={ payStatusMap[record]?.className }>{ payStatusMap[record]?.label }</div>)
      }
    },
    {
      title: '笔试确认状态',
      width: 100,
      dataIndex: 'takeExamCheck',
      key: 'takeExamCheck',
      render: (record) => {
        return (<div className={ takeExamCheckMap[record]?.className }>{ takeExamCheckMap[record]?.label }</div>)
      }
    },
    {
      title: '操作',
      width: 180,
      fixed: 'right',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (<>
          <Space
            direction={ 'vertical' }
            align={ 'end' }
            className={ 'py-1 d-flex justify-content-end' }
          >
            <Space>
              <AppButton
                authCode={ 'button_ksgl-ksxxwh-xq' }
                type={ 'primary' }
                ghost
                onClick={ openModal.bind(this, row, showExamineeViewModal) }
              >详情</AppButton>
              <AppButton
                authCode={ 'button_ksgl-ksxxwh-xg' }
                type={ 'primary' }
                ghost
                onClick={ openModal.bind(this, row, showEditExamineeInfoModal) }
              >修改</AppButton>
            </Space>
            <Space>
              <ComponentDisabled
                disabledTip={ '已缴费，禁止操作' }
                disabled={ selectedExam.payType === 1 && row.payStatus > 0 }
              >
                <AppButton
                  authCode={ 'button_ksgl-ksxxwh-zzshzt' }
                  type={ 'primary' }
                  ghost
                  onClick={ openModal.bind(this, row, showResetApplyStatusModal) }
                >重置审核状态</AppButton>
              </ComponentDisabled>
              <AppButton
                authCode={ 'button_ksgl-ksxxwh-sc' }
                type={ 'primary' }
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteExamineeModal) }
              >删除</AppButton>
            </Space>
          </Space>
        </>)
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageSize, pageNum])

  useEffect(() => {
    if (selectedExam.id) {
      getExamineeUnitAction()
    }
  }, [selectedExam])

  const getTableData = (fields) => {
    const searchData = fields || form.getFieldsValue()
    setPageStatus({ status: 'loading', text: '正在加载考生数据' })
    const params = {
      ...searchData,
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum: pageNum,
      pageSize: pageSize
    }

    getExamineeList(params).then(res => {
      setPageStatus({ status: '', text: '' })
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(() => {
      setPageStatus({ status: '', text: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生信息失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showExamineeViewModal = () => {
    setModalType('view')
    examineeInfoModalRef.current.showModal()
  }

  const showEditExamineeInfoModal = () => {
    editExamineeInfoModalRef.current.showModal()
  }

  const getExamineeUnitAction = () => {
    getExamineeUnit({ id: selectedExam.id }).then((res) => {
      if (res.code === ResponseCode.success) {
        let unitOption
        const initUint = res.data.map(item => {
          return {
            label: item.unitName + '_' + item.unitCode,
            value: item.unitCode
          }
        })

        unitOption = [defaultOption, ...initUint]
        form.setFieldValue('positionId', '')

        setUnitOptions(unitOption)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取初始数据失败'
      })
    })
  }

  const getExamPositionListAction = (unitCode) => {
    setPositionLoading(true)
    const params = {
      pageNum: 1,
      pageSize: 9999,
      examId: selectedExam.id,
      unitNameOrCode: unitCode
    }

    getExamPositionList(params).then(res => {
      setPositionLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setPositionOptions([defaultOption, ...res.data.elements.map(item => {
          return {
            ...item,
            label: item.positionName + '_' + item.positionCode,
            value: item.positionCode
          }
        })])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setPositionLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询职位数据失败',
        centered: true
      })
    })
  }

  const showResetApplyStatusModal = (row) => {
    Modal.confirm({
      title: '重置考生审核状态',
      content: `确定要重置考生【${ row.examineesName }】的审核状态吗？重置后将退回到审核人员处，需要重新审核。`,
      onOk: () => {
        resetExamineeApplyStatusAction(row.applyInfoId)
      }
    })
  }

  const resetExamineeApplyStatusAction = (applyInfoId) => {
    resetExamineeApplyStatus({ id: applyInfoId }).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，重置考生审核状态失败'
      })
    })
  }

  const showDeleteExamineeModal = (row) => {
    Modal.confirm({
      title: '删除考生',
      content: `确定要删除考生【${ row.examineesName }】吗？`,
      onOk: () => {
        deleteExamineeAction(row.applyInfoId)
      }
    })
  }

  const deleteExamineeAction = (applyInfoId) => {
    deleteExaminee({ id: applyInfoId }).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，删除考生失败'
      })
    })
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      getTableData()
    }
  }

  const onValuesChange = (field, allFields) => {
    if (field.hasOwnProperty('unitCode')) {
      getExamPositionListAction(field['unitCode'])
      form.setFieldValue('positionId', '')
    }

    getTableData(allFields)
  }

  const filterOption = (input, option) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }

  const getTableColumns = (columnList) => {
    return columnList.filter(item => {
      if (selectedExam.isCheck === 0 && item.key === 'checkStatus') {
        return false
      } else if (selectedExam.payType === 0 && item.key === 'payStatus') {
        return false
      } else if (selectedExam.takeExamCheck === 0 && item.key === 'takeExamCheck') {
        return false
      } else {
        return true
      }
    })
  }

  const beforeUpload = () => {
    setPageStatus({ text: '正在导入数据', status: 'loading' })
  }

  const handleChange = ({ file }) => {
    if (file?.response?.code === 200) {
      Modal.success({
        title: '操作成功',
        content: file.response?.msg
      })
      getTableData()
    } else {
      Modal.error({
        title: '错误',
        content: file.response?.msg
      })
    }
  }

  return (
    <div>
      { contextHolder }

      <SelectExam>
        <PageStatus
          text={ pageStatus.text }
          pageStatus={ pageStatus.status }
        >
          <div className={ 'd-flex align-items-center' }>
            <Form
              form={ form }
              initialValues={ {
                payStatus: '',
                saveStatus: '',
                takeExamCheck: '',
                unitCode: '',
                positionId: ''
              } }
              onValuesChange={ throttle(onValuesChange, 300) }
            >
              <Row gutter={ 10 }>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '报考单位' }
                    name={ 'unitCode' }
                  >
                    <Select
                      showSearch
                      options={ unitOptions }
                      filterOption={ filterOption }
                    >
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '报考职位' }
                    name={ 'positionCode' }
                    dependencies={ ['unitCode'] }
                  >
                    <Select
                      showSearch
                      disabled={ !form.getFieldValue('unitCode') || positionLoading }
                      options={ positionOptions }
                      filterOption={ filterOption }
                      loading={ positionLoading }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '报名序号' }
                    name={ 'examineesNumber' }
                    trigger={ 'onInput' }
                  >
                    <Input
                      placeholder={ '输入报名序号搜索' }
                      onKeyUp={ onEnter }
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '考生姓名' }
                    name={ 'name' }
                    trigger={ 'onInput' }
                  >
                    <Input
                      placeholder={ '输入考生姓名搜索' }
                      onKeyUp={ onEnter }
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '身份证号' }
                    name={ 'idCard' }
                    trigger={ 'onInput' }
                  >
                    <Input
                      placeholder={ '输入身份证号搜索' }
                      onKeyUp={ onEnter }
                    ></Input>
                  </Form.Item>
                </Col>
                {
                  selectedExam.payType === 1
                    ? <Col span={ 6 }>
                      <Form.Item
                        label={ '支付状态' }
                        name={ 'payStatus' }
                      >
                        <Select
                          options={ payStatusOptions }
                        ></Select>
                      </Form.Item>
                    </Col>
                    : <></>
                }
                {
                  selectedExam.takeExamCheck === 1
                    ? <Col span={ 6 }>
                      <Form.Item
                        label={ '笔试确认状态' }
                        name={ 'takeExamCheck' }
                      >
                        <Select
                          options={ takeExamCheckOptions }
                        ></Select>
                      </Form.Item>
                    </Col>
                    : <></>
                }
                {
                  selectedExam.isCheck === 1
                    ? <Col span={ 6 }>
                      <Form.Item
                        label={ '审核状态' }
                        name={ 'saveStatus' }
                      >
                        <Select
                          options={ saveStatusOptions }
                        ></Select>
                      </Form.Item>
                    </Col>
                    : <></>
                }
              </Row>
            </Form>
          </div>
          <div className={ 'd-flex justify-content-end' }>
            <ComponentVisibleByAuth authCode={ 'button_ksxxwh-drkszfzt' }>
              <Upload
                name={ 'file' }
                action={ `${ envInfo.picDomain }/${ uploadExamineesPayStatusExcel }` }
                headers={ {
                  authorization: window.sessionStorage.getItem('access_token')
                } }
                data={ {
                  examId: selectedExam.id
                } }
                maxCount={ 1 }
                showUploadList={ false }
                beforeUpload={ beforeUpload }
                onChange={ handleChange }
              >
                <Button type={ 'primary' }>导入考生支付状态</Button>
              </Upload>
            </ComponentVisibleByAuth>
          </div>

          <ConfigProvider componentSize={ 'small' }>
            <Table
              columns={ getTableColumns(columns) }
              loading={ pageStatus.status === 'loading' }
              dataSource={ tableData.elements }
              scroll={ {
                x: 1360
              } }
              rowKey={ (record) => record.userId }
              pagination={ {
                showTotal: (total) => {
                  return `共 ${ total } 条`
                },
                size: 'normal',
                showSizeChanger: true,
                total: tableData.totalElements,
                pageSize: pageSize,
                current: pageNum,
                onChange: paginationChange
              } }
            ></Table>
          </ConfigProvider>
        </PageStatus>
      </SelectExam>

      <ExamineeInfoModal
        ref={ examineeInfoModalRef }
        modalType={ modalType }
        examineeItem={ currentEditItem }
        activeTabs={ ['examineeInfo', 'applyFormTable', 'examCertificate', 'interviewNotice', 'publicResult'] }
      ></ExamineeInfoModal>

      <EditExamineeInfoModal
        ref={ editExamineeInfoModalRef }
        examineeItem={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditExamineeInfoModal>
    </div>
  )
}

export default ExamineeManagement
