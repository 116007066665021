import { createSlice } from '@reduxjs/toolkit'

export const userInfoSlice = createSlice({
  name: 'user-info',
  initialState: {
    userInfo: {},
    currentUserOrgId: null,
    userAuth: []
  },
  reducers: {
    updateUserInfo: (state, { payload }) => {
      state.userInfo = payload.userInfo
    },
    updateUserAuth: (state, { payload }) => {
      state.userAuth = payload.userAuth
    }
  }
})

export const { updateUserInfo, updateUserAuth } = userInfoSlice.actions

export default userInfoSlice.reducer
