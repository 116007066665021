import React, { useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Space, Table, Upload } from 'antd'
import { useSelector } from 'react-redux'

import SelectExaminationLocationModal from './SelectExaminationLocationModal'
import EditSelectedExamLocationModal from './EditSelectedExamLocationModal'
import { downloadNonStandardAllocateRoom, saveExamLocationInfo, saveLocationChooses, uploadNonStandardAllocateRoom, uploadPositionCheckPerson } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

function ConfigExamNonstandardBatchLocation (props) {
  const selectExaminationLocationModalRef = useRef()
  const editSelectedExamLocationModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [tableData, setTableData] = useState([])
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '考点名称',
      key: 'locationName',
      dataIndex: 'locationName'
    },
    {
      title: '考点地址',
      key: 'address',
      dataIndex: 'address'
    },
    {
      title: '最大考场数量',
      key: 'maxRoomNumber',
      dataIndex: 'maxRoomNumber'
    },
    {
      title: '考点编号',
      key: 'locationNumber',
      dataIndex: 'locationNumber'
    },
    {
      title: '已选考场数量',
      key: 'canRoomNumber',
      dataIndex: 'canRoomNumber'
    },
    {
      title: '考场起始编号',
      key: 'startNumber',
      dataIndex: 'startNumber'
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (
          <Button
            type={ 'primary' }
            ghost
            onClick={ showEditExaminationRoomConfig.bind(this, row) }
          >修改</Button>
        )
      }
    }
  ]

  const showEditExaminationRoomConfig = (row) => {
    setCurrentEditItem(row)
    editSelectedExamLocationModalRef.current.showModal()
  }

  const showSelectExaminationLocationModal = () => {
    selectExaminationLocationModalRef.current.showModal()
  }

  const saveSelectedExaminationLocation = (list) => {
    setTableData(list)
  }


  const updateLocationItem = (locationItem) => {
    setTableData(tableData.map(item => {
      if (item.locationId === locationItem.locationId) {
        return locationItem
      } else {
        return item
      }
    }))
  }

  const preStep = () => {
    props.preStep?.()
  }

  const clearTableData = () => {
    setTableData([])
  }

  const nextStep = () => {
    const params = {
      examId: selectedExam.id,
      batchId: props.batchInfo.batchId,
      locationList: tableData,
      roomNumber: tableData.reduce((pre, cur) => {
        return pre + cur.canRoomNumber
      }, 0)
    }

    saveLocationChooses(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        props.nextStep?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考点分配失败'
      })
    })
  }

  return (
    <div className={ 'pt-3' }>
      <div className={ 'font-weight-600' }>
        <span>本批次批次参考考生共</span>
        <span className={ 'mx-2 text-primary' }>{ props.batchInfo?.applyCount }</span>
        <span>人</span>
      </div>

      {
        tableData.length === 0
          ? (
            <div className={ 'my-3 d-flex justify-content-between' }>
              <Button
                type={ 'primary' }
                onClick={ showSelectExaminationLocationModal }
              >选择考点</Button>
            </div>
          )
          : (
            <div className={ 'my-3 d-flex justify-content-end' }>
              <Space>
                <Button
                  type={ 'primary' }
                  danger
                  ghost
                  onClick={ clearTableData }
                >清除考点数据</Button>
              </Space>
            </div>
          )
      }

      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData }
          rowKey={ record => record.locationId }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <Space className={ 'mt-3 d-flex justify-content-center' }>
        <Button
          type={ 'primary' }
          onClick={ preStep }
        >上一步</Button>
        <Button
          type={ 'primary' }
          disabled={ tableData.length === 0 }
          onClick={ nextStep }
        >保存考点数据</Button>
      </Space>

      <SelectExaminationLocationModal
        ref={ selectExaminationLocationModalRef }
        saveSelectedItem={ saveSelectedExaminationLocation }
      ></SelectExaminationLocationModal>

      <EditSelectedExamLocationModal
        ref={ editSelectedExamLocationModalRef }
        locationInfo={ currentEditItem }
        saveLocationItem={ updateLocationItem }
      ></EditSelectedExamLocationModal>
    </div>
  )
}

export default ConfigExamNonstandardBatchLocation
