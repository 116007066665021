import { createSlice } from '@reduxjs/toolkit'

export const positionDictSlice = createSlice({
  name: 'position-dict',
  initialState: {
    positionDict: {}
  },
  reducers: {
    updatePositionDic: (state, { payload }) => {
      state.positionDict[payload.dictKey] = payload.dictValue
    }
  }
})

export const { updatePositionDic } = positionDictSlice.actions

export default positionDictSlice.reducer
