import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Modal, Spin, Input, Row, Col, Select, message, notification } from 'antd'
import { getExamDictData, getExamPositionTemplate, getExamClass, createExamPosition, updateExamPosition } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { useDispatch, useSelector } from 'react-redux'

import { updatePositionDic } from '../../../../store/slice/position-dict-slice'

const EditExamPositionModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [messageApi, messageContextHolder] = message.useMessage()
  const positionDict = useSelector(state => state.positionDict.positionDict)
  const [modalVisible, setModalVisible] = useState(false)
  const [formItems, setFormItems] = useState([])
  const [modalTitle, setModalTitle] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [examClassInfo, setExamClassInfo] = useState([])
  const [notifyApi, contentHolder] = notification.useNotification()
  const [selectedApplySite, setSelectedApplySite] = useState({})

  useEffect(() => {
    if (modalVisible) {
      getPositionTemplate()
      getExamClassAction()
      if (props.modalType === 'create') {
        setModalTitle('新增职位')
      } else {
        setModalTitle('编辑职位')
        form.setFieldsValue({
          ...props.positionItem
        })
        setSelectedApplySite({ siteNumber: props.positionItem.siteNumber })
      }
    }
  }, [modalVisible])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const getExamClassAction = () => {
    getExamClass({ examId: props.examInfo.id }).then(res => {
      if (res.code === ResponseCode.success) {
        if (Object.keys(res.data).length > 0) {
          setExamClassInfo(res.data)
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询考试科目失败',
        centered: true
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const getPositionTemplate = () => {
    getExamPositionTemplate({ id: props.examInfo.id }).then(res => {
      if (res.code === ResponseCode.success) {
        if (res.data.indexTemplateSet) {
          setFormItems(res.data.indexTemplateSet)
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询职位字段失败',
        centered: true
      })
    })
  }

  const SelectFormItem = ({ formItem, value, onChange }) => {
    const [options, setOption] = useState([])
    const [selectDisabled, setSelectDisabled] = useState(false)

    const getSelectOptions = (field) => {
      getExamDictData({ dictCode: field }).then(res => {
        if (res.code === ResponseCode.success && res.data) {
          dispatch(updatePositionDic({ dictKey: field, dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        Modal.error({
          title: '网络错误',
          content: '查询字典数据失败',
          centered: true
        })
      })
    }

    useEffect(() => {
      if (formItem.fieldJoinSelect) {
        if (positionDict[formItem.fieldJoinSelect]) {
          setOption(positionDict[formItem.fieldJoinSelect].map(dict => {
            return {
              label: dict.dictLabel,
              value: dict.dictLabel,
              dictVal: dict.dictValue
            }
          }))
        } else {
          getSelectOptions(formItem.fieldJoinSelect)
        }
      }
    }, [positionDict])

    useEffect(() => {
      if (formItem.examSelection) {
        if (formItem.examSelection === 'site') {
          if (props.modalType === 'edit') {
            setSelectDisabled(true)
          }
          setOption(props.examInfo.applySiteList.map(site => {
            return {
              label: site.name,
              value: site.name,
              id: site.id,
              siteNumber: site.number,
              siteCode: site.applySiteCode
            }
          }))
        }

        if (formItem.examSelection === 'level') {
          setOption(examClassInfo.map(level => {
            return {
              label: level.levelName,
              value: level.levelName,
              code: level.levelCode
            }
          }))
        }
      }
    }, [])

    const onDropdownVisibleChange = () => {
      if (formItem.examSelection === 'major') {
        const levelCode = form.getFieldValue('levelCode')

        if (levelCode) {
          setOption(examClassInfo.find(level => {
            return level.levelCode === levelCode
          }).majorList.map(major => {
            return {
              label: major.majorName,
              value: major.majorName,
              code: major.majorCode,
              subjectList: major.subjectList
            }
          }))
        } else {
          messageApi.open({
            type: 'error',
            content: '121'
          })
        }
      }
    }

    const onSelect = (key, option) => {
      onChange?.(key, option)
    }

    return (<>
      <Select
        mode={
        ['subjectName', 'subjectCode'].includes(formItem.fieldCode)
          ? 'multiple'
          : false
      }
        value={ value }
        disabled={ selectDisabled }
        options={ options }
        onChange={ onChange }
        onSelect={ onSelect }
        onDropdownVisibleChange={ onDropdownVisibleChange }
      ></Select>
    </>)
  }

  const selectFormChange = (formItem, selectKey, selectItem) => {
    if (formItem.fieldCode === 'siteName' && selectKey) {
      form.setFieldValue('siteCode', selectItem.siteCode)
      form.setFieldValue('siteId', selectItem.id)
      setSelectedApplySite(selectItem)
    }

    if (formItem.fieldCode === 'levelName' && selectKey) {
      form.setFieldValue('levelCode', selectItem.code)
      form.resetFields(['majorName', 'majorCode', 'subjectName', 'subjectCode'])
    }

    if (formItem.fieldCode === 'majorName' && selectKey) {
      form.setFieldValue('majorCode', selectItem.code)
      form.setFieldValue('subjectName', selectItem.subjectList.map(item => item.subjectName))
      form.setFieldValue('subjectCode', selectItem.subjectList.map(item => item.subjectCode))
    }


  }

  const getFormContent = (item) => {
    if ((item.fieldType === '2' || item.examSelection) && item.fieldCode !== 'subjectName') {
      return <SelectFormItem formItem={ item } onChange={selectFormChange.bind(this, item)}></SelectFormItem>
    } else {
      return <Input disabled={ item.fieldCode === 'subjectName' }></Input>
    }
  }

  const getFormItem = (formItems) => {
    return formItems.sort((a, b) => {
      return a.fieldSort - b.fieldSort
    }).map((item, index) => {
      return (
        <Col span={ ['siteId', 'siteCode', 'levelCode', 'majorCode', 'subjectCode'].includes(item.fieldCode) ? 0 : 24 } key={ index }>
          <Form.Item
            name={ item.fieldCode }
            label={ item.fieldName }
            hidden={ ['siteId', 'siteCode', 'levelCode', 'majorCode', 'subjectCode'].includes(item.fieldCode) }
            rules={ [{ required: true, message: `${ item.fieldName }不能为空` }] }
          >
            { getFormContent(item) }
          </Form.Item>
        </Col>
      )
    })
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      if (props.modalType === 'create') {
        createExamPositionAction(fields)
      } else {
        updateExamPositionAction(fields)
      }
    })
  }

  const createExamPositionAction = (fields) => {
    const params = {
      examId: props.examInfo.id,
      position: {
        ...fields,
        siteNumber: selectedApplySite.siteNumber
      }
    }

    createExamPosition(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '创建职位失败',
        centered: true
      })
    })
  }

  const updateExamPositionAction = (fields) => {
    const params = {
      examId: props.examInfo.id,
      id: props.positionItem._id,
      position: {
        ...fields,
        siteNumber: selectedApplySite.siteNumber
      }
    }

    updateExamPosition(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '更新职位失败',
        centered: true
      })
    })
  }

  return (
    <div ref={ ref }>
      { contentHolder }
      { messageContextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ modalLoading }>
          <Form
            className="mt-4"
            form={ form }
            labelCol={ {
              span: 8
            } }
            labelWrap={ true }
            wrapperCol={ {
              span: 12
            } }
          >
            <Row gutter={ 20 }>
              { getFormItem(formItems) }
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamPositionModal
