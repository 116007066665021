import { useSelector } from 'react-redux'

function DeIdentification ({ value, type }) {
  const userInfo = useSelector(state => state.userInfo.userInfo)

  const replaceLetter = (value, start, end) => {
    const word = value.split('')
    word.fill('*', start, end)
    return word.join('')
  }

  const getResult = (value, type) => {
    if (!!userInfo.deIdentification) {
      switch (type) {
        case 'name':
        case '姓名':
          return replaceLetter(value, 1, value.length)
        case 'idCard':
        case '身份证号':
          return replaceLetter(value, 4, value.length - 3)
        case 'email':
          const [prefix, suffix] = value.split('@')
          return `${ replaceLetter(prefix, 3, prefix.length) }@${ suffix }`
        case 'phone':
          return replaceLetter(value, 4, value.length - 4)
        default:
          return value
      }
    } else {
      return value
    }
  }

  return (<>
    { getResult(value, type) }
  </>)
}

export default DeIdentification
