import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Form, Input, Modal, notification, Space, Spin } from 'antd'

import { confirmPasswordValidate, passwordValidate } from '../../plugins/validates'
import PersonVerification from '../../components/person-verification/PersonVerification'
import { getSmsCode, findPwd } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useSelector } from 'react-redux'

const FindPasswordModal = forwardRef((props, ref) => {
  const personVerificationRef = useRef()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [smsCodeLoading, setSmsCodeLoading] = useState('default')
  const [smsCodeBtnContent, setSmsCodeBtnContent] = useState('获取验证码')
  const envInfo = useSelector(state => state.env.envInfo)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setSmsCodeLoading('default')
    setSmsCodeBtnContent('获取验证码')
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      findPwd({
        ...fields,
        area: envInfo.name
      }).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功',
            description: '设置密码成功'
          })
          hideModal()
          props.logout?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，修改密码失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const openPersonVerification = () => {
    form.validateFields(['phone']).then(() => {
      personVerificationRef.current.openModal()
    }).catch((err)=> {
      console.error(err)
    })
  }

  const personVerificationSuccess = () => {
    setSmsCodeLoading('loading')
    getSmsCode({ phone: form.getFieldValue('phone') }, 'findPassword').then(res => {
      if (res.code === ResponseCode.success) {
        setSmsCodeLoading('disabled')
        countdownTimer()
      } else {
        setSmsCodeLoading('default')
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setSmsCodeLoading('default')
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取验证码失败'
      })
    })
  }

  const countdownTimer = (second) => {
    second = second || 60
    setSmsCodeBtnContent(`${ second }s`)

    setTimeout(() => {
      if (second > 1) {
        second--
        countdownTimer(second)
      } else {
        setSmsCodeBtnContent('获取验证码')
        setSmsCodeLoading('default')
      }
    }, 1000)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 600 }
        title={ '找回密码' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={loading}>
          <Form
            className={ 'my-4' }
            form={ form }

            initialValues={ {
              phone: '',
              smsCode: '',
              newPassword: '',
              confirmPassword: ''
            } }
          >
            <Form.Item
              label={ '手机号' }
              name={ 'phone' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '输入手机号' }></Input>
            </Form.Item>
            <Form.Item
              label={ '验证码' }
              name={ 'smsCode' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [{ required: true }] }
            >
              <Space.Compact style={ { width: '100%' } }>
                <Input placeholder="输入验证码"/>
                <Button
                  type="primary"
                  loading={ smsCodeLoading === 'loading' }
                  disabled={ smsCodeLoading === 'disabled' }
                  onClick={ openPersonVerification }>
                  <span style={ { width: '90px' } }>{ smsCodeBtnContent }</span>
                </Button>
              </Space.Compact>
            </Form.Item>
            <Form.Item
              label={ '设置密码' }
              name={ 'newPassword' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [
                {
                  required: true,
                  message: '请输入新密码'
                },
                { validator: passwordValidate }
              ] }
            >
              <Input type={ 'password' } placeholder={ '输入新密码' }></Input>
            </Form.Item>
            <Form.Item
              label={ '确认密码' }
              name={ 'confirmPassword' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [
                {
                  required: true,
                  message: '请再次输入新密码'
                },
                confirmPasswordValidate.bind(this, 'newPassword')
              ] }
            >
              <Input type={ 'password' } placeholder={ '再次输入新密码' }></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <PersonVerification
        ref={ personVerificationRef }
        checkSuccess={ personVerificationSuccess }
      ></PersonVerification>
    </div>
  )
})

export default FindPasswordModal
