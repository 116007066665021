import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, notification, Spin, Table, Transfer } from 'antd'
import { sampleTableMap } from '../../../../common/common'
import { getTemplateFields, updateExamCertificate, updateInterviewNotice } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { difference } from 'lodash'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import { arrayMoveImmutable } from 'array-move'

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={ {
      cursor: 'grab',
      color: '#999'
    } }
  />
))

const SortableItem = SortableElement((props) => <tr { ...props } />)
const SortableBody = SortableContainer((props) => <tbody { ...props } />)

const SelectFieldsModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [fieldList, setFieldList] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedField, setSelectedField] = useState([])

  const columns = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle/>
    },
    {
      title: '字段名',
      key: 'fieldName',
      dataIndex: 'fieldName'
    },
    {
      title: '字段编码',
      key: 'fieldCode',
      dataIndex: 'fieldCode'
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getFieldList()
      setSelectedField((props.fileItem.fields?.map(field => field.fieldCode) || []))
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setFieldList([])
    setSelectedField([])
  }

  const getFieldList = () => {
    if (props.fileItem.fileType === sampleTableMap.apply.key) {
      setFieldList(props.fileItem.fields)
    } else {
      setLoading(true)
      getRequest().then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          setFieldList(res.data)
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，获取字段失败'
        })
      })
    }
  }

  const getRequest = () => {
    let requestList = []

    switch (props.fileItem.fileType) {
      case sampleTableMap.examinationRoom.key:
        requestList = getTemplateFields({ type: 2 })
        break
      case sampleTableMap.interviewNotice.key:
        requestList = getTemplateFields({ type: 3 })
        break
    }

    return requestList
  }

  const onOk = () => {
    if (selectedField.length === 0) {
      Modal.error({
        title: '错误',
        content: '未选择字段'
      })
      return
    }

    setLoading(true)
    let request
    const params = {
      examId: props.examInfo.id,
      template: props.fileItem.content,
      fieldsList: selectedField.map((key, index) => {
        return {
          ...fieldList.find(field => field.fieldCode === key),
          fieldSort: index
        }
      })
    }

    switch (props.fileItem.fileType) {
      case sampleTableMap.examinationRoom.key:
        request = updateExamCertificate(params)
        break
      case sampleTableMap.interviewNotice.key:
        request = updateInterviewNotice(params)
    }

    setLoading(true)
    request.then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: `保存样表文件【${ props.fileItem.fileName }】失败`,
        centered: true
      })
    })
  }

  const onChange = (nextTargetKeys) => {
    setSelectedField(nextTargetKeys)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(selectedField.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )

      setSelectedField(newData)
    }
  }

  const DraggableContainer = (props) => {
    return <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  }

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = selectedField.findIndex((key) => {
      return key === restProps['data-row-key']
    })
    return <SortableItem index={ index } { ...restProps } />
  }


  return (<Modal
    width={ 900 }
    title={ '选择字段' }
    open={ modalVisible }
    onOk={ onOk }
    onCancel={ hideModal }
  >
    <Spin spinning={ loading }>
      <Transfer
        titles={ ['字段列表', '已选字段'] }
        dataSource={ fieldList }
        targetKeys={ selectedField }
        rowKey={ (record) => {
          return record.fieldCode
        } }
        showSearch={ true }
        onChange={ onChange }
        filterOption={ (inputValue, item) => {
          return (item.fieldName.indexOf(inputValue) !== -1 || item.fieldCode.indexOf(inputValue) !== -1)
        } }
      >
        { ({
             direction,
             filteredItems,
             onItemSelect,
             onItemSelectAll,
             selectedKeys: listSelectedKeys,
             disabled: listDisabled
           }) => {
          const rowSelection = {
            getCheckboxProps: (item) => ({
              disabled: listDisabled || item.disabled
            }),
            onSelectAll (selected, selectedRows) {
              const treeSelectedKeys = selectedRows
                .filter((item) => !item.disabled)
                .map(({ key }) => key)
              const diffKeys = selected
                ? difference(treeSelectedKeys, listSelectedKeys)
                : difference(listSelectedKeys, treeSelectedKeys)
              onItemSelectAll(diffKeys, selected)
            },
            onSelect (item, selected) {
              onItemSelect(item.key, selected)
            },
            selectedRowKeys: listSelectedKeys
          }
          return <Table
            size="small"
            scroll={ {
              y: 400
            } }
            rowKey={ (record) => {
              return record.fieldCode
            } }
            style={ { minHeight: '260px', pointerEvents: listDisabled ? 'none' : undefined } }
            pagination={ false }
            dataSource={ filteredItems }
            rowSelection={ rowSelection }
            columns={ direction === 'left' ? columns.slice(1) : columns }
            onRow={ ({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              }
            }) }
            components={ direction === 'left'
              ? null
              : {
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow
                }
              } }
          ></Table>
        } }
      </Transfer>
    </Spin>
  </Modal>)
})

export default SelectFieldsModal
