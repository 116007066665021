import React, { useEffect, useRef, useState } from 'react'
import Styles from './rule-tree.module.scss'
import { Button, Form, Input, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import ResponseCode from '../../../../common/response-code'
import { getUid } from '../../../../plugins/utils'
import { getExamDictData } from '../../../../common/request'
import { FieldTypeMap, RelationTypeMap, RuleLogicMap } from '../../../../common/common'
import { updateDictInfo } from '../../../../store/slice/dict-slice'
import RuleLogic from './RuleLogic'
import RuleContent from './RuleContent'

function RuleItem (props) {
  const [form] = Form.useForm()
  const ruleContent = useRef()
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [options, setOptions] = useState([])
  const [selectedRule, setSelectedRule] = useState({})
  const [ruleChildList, setRuleChildList] = useState([])

  const ruleRelationOption = [
    {
      label: '并且',
      value: RelationTypeMap.and
    },
    {
      label: '或者',
      value: RelationTypeMap.or
    }
  ]

  useEffect(() => {
    if (props.ruleItem) {
      if (props.ruleItem.children?.length > 0) {
        setRuleChildList(props.ruleItem.children)
      }

      initRuleData()
    }
  }, [props.ruleItem])

  useEffect(() => {
    setOptions(props.fieldList)
  }, [props.fieldList])

  const initRuleData = async () => {
    let dictData = []
    if (props.ruleItem.formData) {
      if (props.ruleItem.formData.fieldType === FieldTypeMap.select) {
        dictData = await getDataDict()
      }

      if (props.ruleItem.formData.fieldType === FieldTypeMap.cascader) {
        ruleContent.current.getRegionTreeAction({ value: props.ruleItem.formData.selectContentName[0] })
      }

      setFormData(dictData)
    }
  }

  const getDataDict = async () => {
    let dictData = []
    const dictCode = props.ruleItem.formData.fieldJoinSelect
    if (dictCode && !dictInfo[dictCode]) {
      await getExamDictData({ dictCode: dictCode }).then(res => {
        if (res.code === ResponseCode.success) {
          dictData = res.data
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        Modal.error({
          title: '网络错误',
          content: '查询字典数据失败',
          centered: true
        })
      })

      await dispatch(updateDictInfo({ dictKey: dictCode, dictValue: dictData }))
    } else {
      dictData = dictInfo[dictCode]
    }

    return dictData
  }

  const dataTransform = (data) => {
    return data.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: !item.childList,
        children: item.childList ? dataTransform(item.childList) : undefined
      }
    })
  }

  const setFormData = (dictData) => {
    form.setFieldsValue({
      ruleLogicType: props.ruleItem.formData.ruleLogicType,
      contentLogicType: props.ruleItem.formData.contentLogicType,
      ruleName: props.ruleItem.formData.ruleName,
      selectContentName: props.ruleItem.formData.selectContentName,
      ruleItem: {
        fieldType: props.ruleItem.formData.fieldType,
        fieldName: props.ruleItem.formData.fieldName,
        fieldCode: props.ruleItem.formData.fieldCode,
        fieldJoinSelect: props.ruleItem.formData.fieldJoinSelect
      },
      selectContentItems: generateSelectContentItems(props.ruleItem.formData, dictData)
    })

    setSelectedRule({
      field: {
        fieldType: props.ruleItem.formData.fieldType,
        fieldName: props.ruleItem.formData.fieldName,
        fieldCode: props.ruleItem.formData.fieldCode,
        fieldJoinSelect: props.ruleItem.formData.fieldJoinSelect
      }
    })

    setTimeout(() => {
      props.ruleItem.formData = form.getFieldsValue()
    })
  }

  const generateSelectContentItems = (field, dictData) => {
    let selectContentItems = field.selectContentItems

    switch (field.fieldType) {
      case FieldTypeMap.select:
        selectContentItems = field.selectContentName.map(fieldName => {
          return {
            dictLabel: fieldName,
            dictValue: dictData.find(dict => {
              return dict.dictLabel === fieldName
            }).dictValue
          }
        })
        break
      case FieldTypeMap.dateRangePicker:
        selectContentItems = field.selectContentName.map(date => {
          return dayjs(date).format('YYYY-MM-DD').toString()
        })
        break
    }

    return selectContentItems
  }

  const getRegionItems = (regionCodeArr, areaDict, list) => {
    let areaItems = list || []
    if (regionCodeArr.length === 0) {
      return list
    }
    const currentRegionCode = regionCodeArr.shift()
    const currentRegionItem = areaDict.find(dict => {
      return dict.code === currentRegionCode
    })
    areaItems.push({
      label: currentRegionItem.name,
      code: currentRegionItem.code
    })

    return getRegionItems(regionCodeArr, currentRegionItem.children, areaItems)
  }

  const deleteCurrentRule = (childRule) => {
    props.deleteRuleItem && props.deleteRuleItem(childRule)
  }

  const deleteRuleItem = (childRule) => {
    props.ruleItem.children = props.ruleItem.children.filter(item => {
      return item.code !== childRule.code
    })

    setRuleChildList(props.ruleItem.children)
  }

  const generateChildRule = (rule) => {
    const childItems = form.getFieldValue('selectContentName')

    if (childItems && childItems.length > 0) {
      rule.children = getChildRuleItem(rule)

      setRuleChildList(rule.children)
    }

    ruleUpdate()
  }

  const getChildRuleItem = (rule) => {
    let children

    switch (selectedRule.field.fieldType) {
      case FieldTypeMap.select:
        children = form.getFieldValue('selectContentItems').map(dict => {
          return {
            parent: {
              code: rule.code,
              label: dict.dictLabel,
              dictValue: dict.dictValue
            },
            code: getUid(),
            formData: {
              ruleLogicType: RelationTypeMap.and
            }
          }
        })
        break
      case FieldTypeMap.input:
        children = [{
          parent: {
            code: rule.code,
            label: form.getFieldValue('selectContentName')
          },
          code: getUid(),
          formData: {
            ruleLogicType: RelationTypeMap.and
          }
        }]
        break
      case FieldTypeMap.dateRangePicker:
        children = [{
          parent: {
            code: rule.code,
            label: form.getFieldValue('selectContentName').map(date => {
              return dayjs(date).from('YYYY-MM-dd').toString()
            }).join('~')
          },
          code: getUid(),
          formData: {
            ruleLogicType: RelationTypeMap.and
          }
        }]
        break
      case FieldTypeMap.cascader:
        children = [{
          parent: {
            code: rule.code,
            label: form.getFieldValue('selectContentItems').map(item => {
              return item.label
            }).join('/')
          },
          code: getUid(),
          formData: {
            ruleLogicType: RelationTypeMap.and
          }
        }]
        break
      default:
        children = []
    }

    return children
  }

  const ruleUpdate = () => {
    props.ruleUpdate && props.ruleUpdate()
  }

  const generateChildren = (childList) => {
    if (childList?.length > 0) {
      return (
        <div className={ Styles.ruleItemChildRule }>
          {
            childList.map(childItem => {
              return (
                <RuleItem
                  key={ childItem.code }
                  ruleItem={ childItem }
                  fieldList={ props.fieldList }
                  ruleUpdate={ ruleUpdate }
                  deleteRuleItem={ deleteRuleItem }
                  disabled={ props.disabled }
                ></RuleItem>
              )
            })
          }
        </div>
      )
    } else {
      return (<></>)
    }
  }

  const onFormChange = (fieldName, key, item) => {
    if (!props.ruleItem.formData) {
      props.ruleItem.formData = {}
    }
    if (fieldName !== 'generateChildRule') {
      props.ruleItem.formData[key] = form.getFieldValue(key)
    }

    if (fieldName === 'ruleName') {
      setSelectedRule(item)
      form.setFieldValue('ruleItem', item.field)
      props.ruleItem.formData['ruleItem'] = item.field
      props.ruleItem.formData['contentLogicType'] = RuleLogicMap.include
      props.ruleItem.formData['selectContentName'] = undefined
      props.ruleItem.formData['selectContentItems'] = undefined
      form.resetFields(['contentLogicType', 'selectContentName', 'selectContentItems'])
    }

    if (fieldName === 'selectContentName') {
      if ([FieldTypeMap.select, FieldTypeMap.dateRangePicker].includes(selectedRule.field.fieldType)) {
        form.setFieldValue('selectContentItems', item.map(data => {
          return data.dict
        }))
        props.ruleItem.formData['selectContentItems'] = item.map(data => {
          return data.dict
        })
      }

      if (selectedRule.field.fieldType === FieldTypeMap.cascader) {
        form.setFieldValue('selectContentItems', item)
        props.ruleItem.formData['selectContentItems'] = item
      }

      if (ruleChildList?.length > 0) {
        props.ruleItem.children = getChildRuleItem(props.ruleItem)
        setRuleChildList(props.ruleItem.children)
      }
    }

  }

  const formFieldsChange = (field) => {
    if (props.ruleItem.formData) {
      props.ruleItem.formData[field[0].name[0]] = form.getFieldValue(field[0].name[0])
    }
  }

  return (<>
    <div className={ Styles.ruleItem } key={ props.ruleItem.code }>
      <div className={ Styles.ruleItemHeader }>
        <div>{ props.ruleItem.parent.code === 'root' ? '' : '子规则：' }{ props.ruleItem.parent.label }</div>
        {
          !props.disabled && <div>
            <Button
              className={ 'mr-2' }
              type="primary"
              size="small"
              ghost
              onClick={ generateChildRule.bind(this, props.ruleItem) }
            >创建子规则</Button>
            <Button
              type="primary"
              size="small"
              ghost
              danger
              onClick={ deleteCurrentRule.bind(this, props.ruleItem) }
            >删除</Button>
          </div>
        }
      </div>
      <div className={ Styles.ruleItemBody }>
        <Form
          form={ form }
          layout={ 'inline' }
          initialValues={ {
            ruleLogicType: RelationTypeMap.and,
            contentLogicType: RuleLogicMap.include
          } }
          onFieldsChange={ formFieldsChange }
        >
          <Form.Item
            name={ 'ruleLogicType' }
            hidden={ props.ruleItem.parent.code === 'root' }
          >
            <Select
              disabled={ props.disabled }
              options={ ruleRelationOption }
              onChange={ onFormChange.bind(this, 'ruleLogicType') }
            ></Select>
          </Form.Item>
          <Form.Item name={ 'ruleName' }>
            <Select
              disabled={ props.disabled }
              options={ options }
              placeholder={ '请选择' }
              style={ { minWidth: '150px' } }
              onChange={ onFormChange.bind(this, 'ruleName') }
            ></Select>
          </Form.Item>
          <Form.Item name={ 'contentLogicType' }>
            <RuleLogic
              disabled={ props.disabled }
              ruleItem={ selectedRule.field }
              onChange={ onFormChange.bind(this, 'contentLogicType') }
            ></RuleLogic>
          </Form.Item>
          <Form.Item name={ 'selectContentName' }>
            <RuleContent
              disabled={ props.disabled }
              ref={ ruleContent }
              ruleItem={ selectedRule.field }
              onChange={ onFormChange.bind(this, 'selectContentName') }
            ></RuleContent>
          </Form.Item>
          <Form.Item name={ 'ruleItem' } hidden>
            <Input disabled={ props.disabled }></Input>
          </Form.Item>
          <Form.Item name={ 'selectContentItems' } hidden>
            <Input disabled={ props.disabled }></Input>
          </Form.Item>
        </Form>
        { generateChildren(ruleChildList) }
      </div>
    </div>
  </>)
}

export default RuleItem
