import { Button, Checkbox, Form, Modal, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { findExamPublicityData, getExamPublicityInfo, updateShowStatistic } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import dayjs from 'dayjs'

function SettingApplyPublicity () {
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({
    elements: []
  })
  const [showStatistic, setShowStatistic] = useState(false)
  const [examPublicityInfo, setExamPublicityInfo] = useState({
    showStatistic: false,
    showFields: []
  })

  const columns = [
    {
      title: '报考单位',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode'
    },
    {
      title: '报考职位',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode'
    },
    {
      title: '报名人数',
      key: 'applyCount',
      dataIndex: 'applyCount'
    },
    {
      title: '待审核',
      key: 'treatCheckCount',
      dataIndex: 'treatCheckCount'
    },
    {
      title: '审核通过',
      key: 'checkCount',
      dataIndex: 'checkCount'

    },
    {
      title: '审核未通过',
      key: 'notPassCheckCount',
      dataIndex: 'notPassCheckCount'
    },
    {
      title: '已缴费',
      key: 'payCount',
      dataIndex: 'payCount'
    },
    {
      title: '笔试确认',
      key: 'confirmCount',
      dataIndex: 'confirmCount'
    },
    {
      title: '计划招考',
      key: 'p_zprs',
      dataIndex: 'p_zprs'
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getExamPublicityInfoAction()
    }
  }, [selectedExam])

  useEffect(() => {
    if (examPublicityInfo.showStatistic && examPublicityInfo.showFields.length > 0 && selectedExam.id) {
      getExamPublicityDataAction()
    }
  }, [examPublicityInfo, selectedExam, pageSize, pageNum])

  const getExamPublicityDataAction = () => {
    findExamPublicityData({
      examId: selectedExam.id,
      pageNum: pageNum,
      pageSize: pageSize
    }).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询报名公示信息失败'
      })
    })
  }

  const getExamPublicityInfoAction = () => {
    setLoading(true)
    const params = {
      id: selectedExam.id
    }

    getExamPublicityInfo(params).then(res => {
      setLoading(false)
      if (res.code == ResponseCode.success) {
        setExamPublicityInfo(res.data)
        form.setFieldsValue({
          showStatistic: !!res.data.showStatistic,
          showFields: res.data.showFields
        })
        setShowStatistic(!!res.data.showStatistic)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
   }).catch(err => {
     console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询报名公示信息配置失败 '
      })
    })
 }

  const filterColumnsByExamConfig = (columnList, fieldList = []) => {
    if (selectedExam.payType !== 1) {
      columnList = columnList.filter(column => {
        return column.key !== 'payCount'
      })
    }

    if (selectedExam.takeExamCheck !== 1) {
      columnList = columnList.filter(column => {
        return column.key !== 'confirmCount'
      })
    }

    if (fieldList.length === 0) {
      return columnList
    } else {
      return columnList.filter(column => {
        return fieldList.includes(column.key)
      })
    }
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const updateShowStatisticAction = (statisticStatus) => {
    const fields = form.getFieldsValue()

    if (statisticStatus === 1 && fields.showFields?.length === 0) {
      Modal.error({
        title: '错误',
        content: '未选择显示字段'
      })
      return
    }

    updateShowStatistic({
      id: selectedExam.id,
      showStatistic: statisticStatus,
      showFields: statisticStatus === 1 ? fields.showFields : []
    }).then(res => {
      if (res.code == ResponseCode.success) {
        getExamPublicityInfoAction()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，更新失败'
      })
    })
  }

  const getControlButton = () => {
    if (examPublicityInfo.showStatistic) {
      return (<Space>
        <Button type={'primary'} onClick={ updateShowStatisticAction.bind(this, 1) }>更新数据</Button>
        <Button type="primary" danger onClick={ updateShowStatisticAction.bind(this, 0) }>关闭公示</Button>
      </Space>)
    } else {
      return (<>
        <Button type={'primary'} onClick={ updateShowStatisticAction.bind(this, 1) }>开启公示</Button>
      </>)
    }
  }

  return (<div>
    <Form
      form={ form }
    >
      <Form.Item label={ '公示字段' } name={ 'showFields' } rules={ [{ required: true, message: '请选择要展示的报名公示字段' }] }>
        <Checkbox.Group options={ filterColumnsByExamConfig(columns).map(item => {
          return {
            label: item.title,
            value: item.key
          }
        }) }></Checkbox.Group>
      </Form.Item>
      <Form.Item label={ '' }>
        { getControlButton(examPublicityInfo.showStatistic, showStatistic) }
      </Form.Item>
    </Form>

    {
      examPublicityInfo.showStatistic
        ? (<>
          <div className={'d-flex align-items-center mb-3'} style={{ fontSize: 20, fontWeight: 'bold' }}>职位报名情况公示数据</div>
          <Space>
            <div>更新时间：</div>
            <div>{ dayjs(tableData.elements[0]?.createTime).format('YYYY-MM-DD HH:mm:ss') }</div>
          </Space>
          <Table
            loading={ loading }
            columns={ filterColumnsByExamConfig(columns, examPublicityInfo.showFields).map(column => {
              if (column.key === 'p_zprs') {
                return {
                  title: '计划招考',
                  key: 'planCount',
                  dataIndex: 'planCount'
                }
              } else {
                return column
              }
            }) }
            dataSource={ tableData.elements }
            rowKey={ (record) => {
              return `${ record.id }_${ record.unitCode }_${ record.positionCode }`
            } }
            pagination={ {
              showTotal: (total) => {
                return `共 ${ total } 条`
              },
              size: 'normal',
              showSizeChanger: true,
              total: tableData.totalElements,
              pageSize: pageSize,
              current: pageNum,
              onChange: paginationChange
            } }
          ></Table>
        </>)
        : <></>
    }
  </div>)
}

export default SettingApplyPublicity
