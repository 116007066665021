import { App, Form, Input, Modal, Spin } from 'antd'
import { forwardRef, useImperativeHandle, useState } from 'react'
import RichEditorForm from '../../../components/RichEditorForm'
import { createAnnouncement, getAnnouncementDetail, updateAnnouncement } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const CreateAnnouncementModal = forwardRef((props, ref) => {
  const { model, notification } = App.useApp()
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState('create')
  const [loading, setLoading] = useState(false)
  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (type, row) => {
    setModalVisible(true)
    setModalType(type)
    if (type === 'edit') {
      getAnnouncementDetailAction(row.id)
    }
  }

  const getAnnouncementDetailAction = (id) => {
    setLoading(true)
    getAnnouncementDetail({ id }).then(res => {
      if (res.code === ResponseCode.success) {
        form.setFieldsValue({
          title: res.data.title,
          content: res.data.content,
          id: res.data.id
        })
      } else {
        model.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      model.error({
        title: '错误',
        content: '网络错误，获取公告详情失败'
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const hideModal = () => {
    setModalVisible(false)
    form.setFieldsValue({
      title: '',
      content: ''
    })
  }

  const getModalTitle = (type) => {
    if (type === 'create') {
      return '新增公告'
    } else {
      return '编辑公告'
    }
  }

  const getRequest = (values) => {
    if (modalType === 'create') {
      return createAnnouncement({
        ...values,
        orgId: props.orgItem.id,
        status: 0
      })
    } else {
      return updateAnnouncement({
        ...values,
        orgId: props.orgItem.id,
      })
    }
  }

  const onOk = () => {
    form.validateFields().then(values => {
      setLoading(true)
      getRequest(values).then((res) => {
        if (res.code === ResponseCode.success) {
          hideModal()
          notification.success({
            message: '操作成功',
            description: `${ modalType === 'create' ? '新增' : '更新' }公告成功`
          })
          props.insertSuccess?.()
        }
      }).catch(err => {
        console.error(err)
        model.error({
          title: '错误',
          content: '网络错误，新增公告失败'
        })
      }).finally(() => {
        setLoading(false)
      })
    })
  }

  return (
    <Modal
      width={ 900 }
      title={ getModalTitle(modalType) }
      open={ modalVisible }
      confirmLoading={ loading }
      onOk={ onOk }
      onCancel={ hideModal }
    >
      <Spin spinning={ loading }>
        <Form
          form={ form }
          initialValues={ {
            title: '',
            content: '',
            id: ''
          } }
        >
          <Form.Item
            noStyle
            name={ 'id' }
          ></Form.Item>
          <Form.Item
            label={ '公告标题' }
            name={ 'title' }
            rules={ [
              {
                required: true,
                message: '请输入公告标题'
              }
            ] }
          >
            <Input placeholder={ '请输入公告标题' }></Input>
          </Form.Item>
          <Form.Item
            label={ '公告内容' }
            name={ 'content' }
            rules={ [
              {
                required: true,
                message: '请输入公告内容'
              }
            ] }
          >
            <RichEditorForm></RichEditorForm>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
})

export default CreateAnnouncementModal
