import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Input, InputNumber, Modal, Table } from 'antd'
import { getExaminationLocationList } from '../../../common/request'
import { useSelector } from 'react-redux'
import ResponseCode from '../../../common/response-code'
import { locationNumberRegExp, startNumberRegExp } from '../../../plugins/validates'

const SelectExaminationLocationModal = forwardRef((props, ref) => {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedTableItem, setSelectedTableItem] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getTableData()
    }
  }, [modalVisible])

  useEffect(() => {
    setSelectedTableItem(tableData.filter(item => {
      return selectedTableItem.some(selectedItem => {
        return selectedItem.locationId === item.locationId
      })
    }))
  }, [tableData])

  const columns = [
    {
      title: '考点名称',
      key: 'locationName',
      dataIndex: 'locationName'
    },
    {
      title: '考点地址',
      key: 'address',
      dataIndex: 'address'
    },
    {
      width: 140,
      title: '最大考场数',
      key: 'maxRoomNumber',
      dataIndex: 'maxRoomNumber'
    },
    {
      title: '考点编号',
      key: 'locationNumber',
      dataIndex: 'locationNumber',
      render: (record, row) => {
        return <Input
          value={ row.locationNumber }
          min={ 0 }
          onInput={ locationNumberChange.bind(this, row) }
          style={ { width: 80 } }
        ></Input>
      }
    },
    {
      title: '分配考场数',
      key: 'canRoomNumber',
      dataIndex: 'canRoomNumber',
      render: (record, row) => {
        return <InputNumber
          value={ row.canRoomNumber }
          min={ 0 }
          onChange={ canRoomNumberChange.bind(this, row) }
          style={ { width: 80 } }
        ></InputNumber>
      }
    },
    {
      title: '考场起始编号',
      key: 'startNumber',
      dataIndex: 'startNumber',
      render: (record, row) => {
        return <Input
          value={ row.startNumber }
          onInput={ startNumberChange.bind(this, row) }
          style={ { width: 80 } }
        ></Input>
      }
    }
  ]

  const canRoomNumberChange = (row, value) => {

    if (Number(value) > row.maxRoomNumber) {
      value = row.maxRoomNumber
    }

    if (Number(value) < 0) {
      value = 0
    }

    row.canRoomNumber = value
    setTableData([].concat(tableData))
  }

  const startNumberChange = (row, e) => {
    const value = e.target.value
    row.startNumber = value
    setTableData([].concat(tableData))
  }

  const locationNumberChange = (row, e) => {
    const value = e.target.value

    row.locationNumber = value
    setTableData([].concat(tableData))
  }

  const getTableData = () => {
    setLoading(true)
    getExaminationLocationList({ orgId: selectedExam.orgId }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data.map(item => {
          return {
            ...item,
            canRoomNumber: 1,
            startNumber: '001',
            locationNumber: '01'
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取考点列表失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setSelectedTableItem([])
    setSelectedRowKeys([])
  }

  const containsDuplicate = (array) => {
    return array.length !== [...new Set(array)].length
  }

  const onOk = () => {
    if (selectedTableItem.length === 0) {
      Modal.error({
        title: '错误',
        content: '未选择考点'
      })
      return
    }

    const validateResult = selectedTableItem.every(item => {
      if (!item.locationNumber) {
        Modal.error({
          title: '错误',
          content: `考点【${ item.locationName }】未设置考点编号`
        })
        return
      }

      if (!locationNumberRegExp.test(item.locationNumber)) {
        Modal.error({
          title: '错误',
          content: `考点【${ item.locationName }】编号格式错误，范围01~99`
        })
        return
      }

      if (!item.canRoomNumber) {
        Modal.error({
          title: '错误',
          content: `考点【${ item.locationName }】未分配考场数量`
        })
        return
      }

      if (!item.startNumber) {
        Modal.error({
          title: '错误',
          content: `考点【${ item.locationName }】未设置考场起始编号`
        })
        return
      }

      if (!startNumberRegExp.test(item.startNumber)) {
        Modal.error({
          title: '错误',
          content: `考点【${ item.locationName }】考场起始编号格式错误，范围001~999`
        })
        return
      }
      return item.startNumber && item.canRoomNumber
    })

    if (!validateResult) {
      return
    }

    if (containsDuplicate(selectedTableItem.map(item => item.locationNumber))) {
      Modal.error({
        title: '错误',
        content: '考点编号重复'
      })
      return
    }

    props.saveSelectedItem?.(selectedTableItem)
    hideModal()
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 1000 }
        title={ '选择考点' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <ConfigProvider componentSize={ 'small' }>
          <Table
            columns={ columns }
            loading={ loading }
            dataSource={ tableData }
            rowKey={ record => record.locationId }
            pagination={ false }
            rowSelection={ {
              type: 'checkbox',
              selectedRowKeys: selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRowKeys(selectedRowKeys)
                setSelectedTableItem(selectedRows)
              }
            } }
          ></Table>
        </ConfigProvider>
      </Modal>
    </div>
  )
})

export default SelectExaminationLocationModal
