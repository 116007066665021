import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useSelector } from 'react-redux'

import { createOrgWorker } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { phoneValidate } from '../../../plugins/validates'

const CreateOrgWorkerModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const roleList = useSelector(state => {
    return state.role.roleList.filter(item => item.roleTag === 'organization').map(role => {
      return {
        label: role.name,
        value: role.id
      }
    })
  })
  const [modalVisible, setModalVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const createOrgWorkerAction = (fields) => {
    const params = {
      ...fields,
      organizationId: props.orgItem.id
    }

    createOrgWorker(params).then(res => {
      if (res.code === ResponseCode.success) {
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '创建工作人员失败',
        centered: true
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      createOrgWorkerAction(fields)
    }).catch(err => {
      console.error(err)
    })
  }

  const onCancel = () => {
    hideModal()
  }

  return (
    <div ref={ ref }>
      <Modal
        title="新增工作人员"
        centered
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ onCancel }
      >
        <div className="mt-3">
          <Form
            form={ form }
            labelCol={ {
              span: 6
            } }
            wrapperCol={ {
              span: 16
            } }
            initialValues={ {
              name: '',
              phone: ''
            } }
          >
            <Form.Item
              label="工作人员"
              name="name"
              rules={ [{ required: true, message: '工作人员不能为空' }] }
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="手机号"
              name="phone"
              rules={ [{ trigger: 'submit', validator: phoneValidate }] }
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="角色"
              name="roleId"
              rules={ [{ required: true, message: '请选择工作人员角色' }] }
            >
              <Select
                options={ roleList }
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
})

export default CreateOrgWorkerModal
