const generateImage = (path, height) => {
  return `<div class="img-wrap" style="width: 100%;${ height }">
      <div class="img" style="background: url('${ path }') no-repeat"></div>
    </div>`
}

const generateSubjectList = (subjectListInfo) => {
  let html = ''
  const tableHead = ['考试科目', '考试日期', '考试时间', '准考证号', '考场号', '座位号']
  html += `<table 
    border="0" 
    class="inner-table" 
    cellpadding="0" 
    cellspacing="0" 
    style="width: 100%;"
    >`
  html += '<thead><tr>'
  tableHead?.forEach(item => {
    html += `<th style="color: #222;font-weight: normal">${ item }</th>`
  })
  html += '</tr></thead>'

  html += '<tbody>'
  subjectListInfo?.forEach(tableData => {
    const tableBody = [
      tableData.subjectName ? tableData.subjectName : '',
      tableData.examDate ? tableData.examDate : '',
      tableData.startTime + '-' + tableData.endTime,
      tableData.candidateNumber ? tableData.candidateNumber : '',
      tableData.roomNumber ? tableData.roomNumber : '',
      tableData.seatNumber ? tableData.seatNumber : ''
    ]
    html += '<tr>'
    tableBody.forEach(item => {
      html += `<td style="padding: 8px; color: #222;text-align: center;">${ item }</td>`
    })
    html += '</tr>'
  })


  html += '</tbody>'

  html += '</table>'
  return html
}

const getRegExp = (value) => {
  return `{{${ value }}}`
}

const baseStyle = `<style>
  p {
    height: auto;
    margin: 0;
  }
  table {
    margin: 0 auto;
  }
  td, tr {
    word-break: break-all!important;
    padding: 0!important;
  }
  .inner-table {
    width: 100%;
    font-family: inherit;
    font-size: inherit;
  }
  .inner-table thead {
    width: 100%
  }
  .inner-table thead tr > th {
    border-bottom: solid windowtext 1.0pt;
    font-size: inherit;
  }
  .inner-table td {
    font-size: inherit;
    padding: 4px 6px!important;
  }
  .inner-table tr:not(:last-child) > td {
    border-bottom: solid windowtext 1.0pt;
  }
  .inner-table tr > th:not(:last-child),
  .inner-table tr > td:not(:last-child) {
    border-right: solid windowtext 1.0pt;
  }
  .img-wrap {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .img-wrap .img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: contain !important;
    background-position: center !important;
  }
  .table-wrap {
    display: flex;
  }
</style>`

const transformRoomNum = (roomNum) => {
  let x = ''

  if (typeof roomNum === 'number') {
    const length = (roomNum + '').length
    x = new Array(3 - length).fill(0).join('')
  }

  return roomNum ? x + roomNum : ''
}

export const generateAdmissionTicket = (template, fieldList, applyInfo, fileDomain) => {
  let html = `${ baseStyle }${ template || '' }`

  fieldList?.forEach(field => {
    if (field.fieldType === '6') {
      html = html.replaceAll(getRegExp(field.fieldCode), '{{img_@_placeholder}}')
      html.match(/<td(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?<p(.|\n|\r)[^>]*?><span(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?{{img_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?<\/span><\/p>/g)?.forEach(item => {
        const rowspanNumber = item.match(/rowspan="(\d*)"/)?.[1] || 1
        const matchResult = item.match(/height:(.[^(<|>)]*)(\d*)(pt|cm|px);/)
        const heightNumber = matchResult?.[1].trim()
        const heightStr = matchResult[0]?.replace(heightNumber, Number(heightNumber) * Number(rowspanNumber))
        html = html.replaceAll(/<p(.|\n|\r)[^>]*?><span(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?{{img_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?<\/span><\/p>/g, generateImage(`${ fileDomain }/${ applyInfo[field.fieldCode] }`, heightStr, rowspanNumber))
      })
    } else if (field.fieldCode === 'subjectList') {
      html = html.replaceAll(getRegExp(field.fieldCode), '{{table_@_placeholder}}')
      html.match(/<span(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?{{table_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?<\/span>/g)?.forEach(item => {
        let divStr = item.replace('<span', '<div class="table-wrap"')
        divStr = divStr.replace('</span', '</div')
        divStr = divStr.replace(/(?<=(>))((.|\n|\r)[^(<|>)]*?)?{{table_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?(?=(<))/g, generateSubjectList(applyInfo.subjectList))
        html = html.replaceAll(item, divStr)
      })
    } else if (field.fieldCode === 'roomNumber') {
      html = html.replaceAll(getRegExp(field.fieldCode), transformRoomNum(applyInfo[field.fieldCode]) || '')
    } else {
      html = html.replaceAll(getRegExp(field.fieldCode), applyInfo[field.fieldCode] || '')
    }
  })

  html = html.replaceAll(/padding: 0cm 5.4pt 0cm 5.4pt;/g, '')
  html = html.replaceAll(/<strong/g, '<b')
  html = html.replaceAll(/<\/strong/g, '</b')

  return html
}
