import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, notification, Space, Table } from 'antd'

import { deleteRegionDict, getRegionList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import CountyRegionDictTable from './CountyRegionDictTable'
import EditCityRegionModal from './EditCityRegionModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'

function CityRegionDictTable (props) {
  const editCityRegionModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [editRegionLevel, setEditRegionLevel] = useState('')
  const [modalType, setModalType] = useState('')
  const [needReloadRow, setNeedReloadRow] = useState({})

  useEffect(() => {
    getTableData()
  }, [props.expanded])

  useEffect(() => {
    if (props.rowKey === props.needReloadRow.id) {
      getTableData()
    }
  }, [props.needReloadRow])

  const columns = [
    {
      title: '市级区域名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '市级区域编码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '操作',
      width: '240px',
      dataIndex: '',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <ComponentVisibleByAuth authCode={'button_zdgl-dyzd-bj'}>
            <Space className="d-flex justify-content-end">
              <Button
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditCountyRegionModal) }
              >添加县级行政区</Button>
              <Button
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditCityRegionModal) }
              >编辑</Button>
              <Button
                type="primary"
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteCityModal) }
              >删除</Button>
            </Space>
          </ComponentVisibleByAuth>

        </>)
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    const params = { parent: props.parentRegion.code }

    getRegionList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询区域数据失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem({
      ...row,
      parent: props.parentRegion.code
    })
    fn && fn(row)
  }

  const showEditCountyRegionModal = (row) => {
    setModalType('create')
    setEditRegionLevel('county')
    editCityRegionModalRef.current.showModal(row.code)
  }

  const showEditCityRegionModal = () => {
    setModalType('edit')
    setEditRegionLevel('city')
    editCityRegionModalRef.current.showModal(props.parentRegion.code)
  }

  const showDeleteCityModal = (row) => {
    Modal.confirm({
      title: '删除市级区域',
      centered: true,
      content: (
        <div>确定要删除市级区域【{ row.name }】吗？</div>
      ),
      onOk () {
        deleteCityRegionAction(row)
      }
    })
  }

  const deleteCityRegionAction = (row) => {
    setLoading(true)
    deleteRegionDict({ id: row.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '删除区域数据失败',
        centered: true
      })
    })
  }

  const updateExpandedTable = () => {
    if (editRegionLevel === 'city') {
      getTableData()
    } else {
      setNeedReloadRow(currentEditItem)
    }
  }

  return (<>
    { contextHolder }
    <Table
      sticky
      loading={ loading }
      columns={ columns }
      dataSource={ tableData }
      rowKey={ (record) => record.id }
      pagination={ false }
      expandable={ {
        expandedRowRender: (record, index, indent, expanded) => {
          return <CountyRegionDictTable
            parentRegion={ record }
            rowKey={ record.id }
            needReloadRow={ needReloadRow }
            expanded={ expanded }
          ></CountyRegionDictTable>
        }
      } }
    ></Table>

    <EditCityRegionModal
      modalType={ modalType }
      ref={ editCityRegionModalRef }
      regionItem={ currentEditItem }
      regionLevel={ editRegionLevel }
      insertSuccess={ updateExpandedTable }
    ></EditCityRegionModal>
  </>)
}

export default CityRegionDictTable
