import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Cascader, DatePicker, Input, Modal, Select } from 'antd'

import { getExamDictData, getRegionTree } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { updateDictInfo } from '../../../../store/slice/dict-slice'
import { FieldTypeMap } from '../../../../common/common'

const RuleContent = forwardRef(({ ruleItem, disabled, value, onChange }, ref) => {
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const provinceList = useSelector(state => {
    return state.regionList.regionList.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: false
      }
    })
  })

  const [options, setOptions] = useState([])
  const [regionOptions, setRegionOptions] = useState(provinceList)

  useImperativeHandle(ref, () => {
    return {
      getRegionTreeAction
    }
  })

  useEffect(() => {
    if (ruleItem) {
      if (ruleItem.fieldType === FieldTypeMap.select) {
        if (ruleItem.fieldJoinSelect && !dictInfo[ruleItem.fieldJoinSelect]) {
          getSelectOptions(ruleItem.fieldJoinSelect)
        } else {
          setSelectOptions(dictInfo[ruleItem.fieldJoinSelect])
        }
      }
    }
  }, [ruleItem])

  const getSelectOptions = (field) => {
    getExamDictData({ dictCode: field }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        dispatch(updateDictInfo({ dictKey: field, dictValue: res.data }))
        setSelectOptions(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询字典数据失败',
        centered: true
      })
    })
  }

  const setSelectOptions = (dictList) => {
    setOptions(dictList.map(dict => {
      return {
        label: dict.dictLabel,
        value: dict.dictLabel,
        dict
      }
    }))
  }

  const loadRegionData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.childList) {
      targetOption.loading = false
      targetOption.children = targetOption.childList.map(item => {
        return {
          label: item.name,
          value: item.code,
          id: item.id,
          isLeaf: !item.childList,
          childList: item.childList
        }
      })

      setRegionOptions([...regionOptions])
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  const getRegionTreeAction = (targetOption) => {
    getRegionTree({ parent: targetOption.value }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        targetOption.loading = false
        targetOption.children = res.data.map(item => {
          return {
            label: item.name,
            value: item.code,
            id: item.id,
            isLeaf: !item.childList,
            childList: item.childList
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      targetOption.loading = false
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const getRuleContent = (ruleItem) => {
    switch (ruleItem?.fieldType) {
      case FieldTypeMap.input:
        return (<>
          <Input
            disabled={ disabled }
            value={ value }
            onChange={ onChange }
            style={ { width: '240px' } }
          ></Input>
        </>)
        break
      case FieldTypeMap.select:
        return (<>
          <Select
            disabled={ disabled }
            mode={ 'multiple' }
            value={ value }
            options={ options }
            onChange={ onChange }
            style={ { width: '240px' } }
          ></Select>
        </>)
        break
      case FieldTypeMap.dateRangePicker:
        return (<>
          <DatePicker.RangePicker
            disabled={ disabled }
            value={ value }
            onChange={ onChange }
            style={ { width: '240px' } }
            format="YYYY-MM-DD"
          ></DatePicker.RangePicker>
        </>)
        break
      case FieldTypeMap.cascader:
        return (<>
          <Cascader
            disabled={ disabled }
            changeOnSelect
            value={ value }
            options={ regionOptions }
            loadData={ loadRegionData }
            placeholder="请选择机构所属区域"
            style={ { width: '240px' } }
            onChange={ onChange }
          ></Cascader>
        </>)
        break
      default:
        return (<Input disabled={ disabled }></Input>)
    }

    return (<></>)
  }

  return (<div ref={ ref }>
    { getRuleContent(ruleItem) }
  </div>)
})

export default RuleContent
