import axios from 'axios'
import router from '../routers/router'
import { notification } from 'antd'

import ResponseCode from '../common/response-code'

const CancelToken = axios.CancelToken
const requestPending = []

// const BASE_URL = 'https://manage.bjpass.com/'
const BASE_URL = 'http://47.106.205.246:92/'

const removeRepeatedRequest = function (config) {
  let i = 0

  for (const req of requestPending) {
    if (req.id === config.url + '&' + config.method) {
      req.cancel()
      requestPending.splice(i, 1)
    }
    i++
  }
}

const removeAllRequest = function () {
  for (const req of requestPending) {
    req.cancel()
  }
}

const gotoLoginPage = function () {
  const replacePath = window.encodeURIComponent(router.state.location.pathname)
  const siteId = sessionStorage.getItem('siteId')
  const loginPage = localStorage.getItem('login-page') || 'login'

  if (replacePath.indexOf(`/${ siteId }/${ loginPage }`) < 0) {
    router.navigate(`/${ siteId }/${ loginPage }?redirect=${ replacePath }`)
  } else {
    router.navigate(`/${ siteId }/${ loginPage }`)
  }
}

const setLogout = function () {
  console.log('reset data')
}

export const Axios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : process.env.REACT_APP_URL,
  timeout: 3 * 60 * 1000
})

/**
 * POST传参序列化(添加请求拦截器)
 * 在发送请求之前做某件事
 */
Axios.interceptors.request.use(config => {
  if (window.sessionStorage.getItem('access_token') && !config.headers.Authorization) {
    config.headers.Authorization = window.sessionStorage.getItem('access_token')
  }

  config.cancelToken = new CancelToken((c) => {
    requestPending.push({ id: config.url + '&' + config.method, cancel: c })
  })
  return config
}, error => {
  return console.error(error)
})

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(res => {
  // removeRepeatedRequest(res.config)
  // 对响应数据做些事，
  // console.log('Axios.interceptors.response', res.data)
  if (res.data && res.data.code === ResponseCode.authFailed) {
    removeAllRequest()
    gotoLoginPage()
    notification.open({
      type: 'warning',
      message: '登录失效',
      description: 'Token过期，请重新登录'
    })
    return res.data || {}
  }
  return res.data || {}
}, error => {
  // console.log('error', error)
  if (error.response) {
    if (error.response.status === ResponseCode.authFailed) {
      removeAllRequest()
      gotoLoginPage()
      notification.open({
        type: 'warning',
        message: '登录失效',
        description: 'Token过期，请重新登录'
      })
    } else {
      throw new Error('unknown response code')
    }
    // 返回 response 里的错误信息
    return Promise.reject(error.response.data)
  } else {
    // Cancel request
    return console.error(new Error('request canceled'))
  }
})

export default Axios
