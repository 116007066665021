import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Space, Table } from 'antd'

import { getExaminationAllocationLocationList, resetLocationAllocation } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import ExaminationRoomTable from './ExaminationRoomTable'
import ManualAllocationModal from './ManualAllocationModal'

function ExaminationLocationTable ({ batchInfo, componentType, updateLocationList }) {
  const manualAllocationModalRef = useRef()
  const [tableData, setTableData] = useState({})
  const [loading, setLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [needReloadRow, setNeedReloadRow] = useState({})

  useEffect(() => {
    getTableData()
  }, [])

  const columns = [
    {
      title: '考点名称',
      key: 'locationName',
      dataIndex: 'locationName'
    },
    {
      title: '考点编号',
      key: 'locationNumber',
      dataIndex: 'locationNumber'
    },
    {
      title: '考点地址',
      key: 'locationAddress',
      dataIndex: 'locationAddress'
    },
    {
      title: '考点参考人数',
      key: 'locationAllocateNum',
      dataIndex: 'locationAllocateNum'
    },
    {
      title: '考点考场数量',
      key: 'locationRoomNum',
      dataIndex: 'locationRoomNum'
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        if (componentType !== 'view' && batchInfo.allocateType === 2) {
          return (
            <Space className={ 'd-flex justify-content-end' }>
              {
                row.locationAllocateNum === 0
                  ? <></>
                  : <Button
                    type={ 'primary' }
                    danger
                    ghost
                    onClick={ showResetAllocationModal.bind(this, row) }
                  >重新分配</Button>
              }
              <Button
                type={ 'primary' }
                onClick={ showManualAllocationModal.bind(this, row) }
              >手动分配</Button>
            </Space>
          )
        } else {
          return <></>
        }
      }
    }
  ]

  const showManualAllocationModal = (row) => {
    setCurrentEditItem(row)
    manualAllocationModalRef.current.showModal()
  }

  const showResetAllocationModal = (row) => {
    Modal.confirm({
      title: '考点重新分配考场安排',
      content: `确定要重新分配考点【${ row.locationName }】的考场安排吗？`,
      onOk: () => {
        resetLocationAllocationAction(row)
      }
    })
  }

  const resetLocationAllocationAction = (row) => {
    const params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId,
      locationId: row.locationId
    }

    setLoading(true)
    resetLocationAllocation(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        manualAllocateSuccess(row)
        notification.success({
          message: '操作成功',
          description: `已重置考点【${row.locationName}】考场分配`
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，重置考点考场分配失败'
      })
    })
  }

  const getTableData = () => {
    const params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId
    }

    setLoading(true)
    getExaminationAllocationLocationList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
        updateLocationList?.(res.data.locationList)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考点列表失败'
      })
    })
  }

  const manualAllocateSuccess = (row) => {
    getTableData()
    setNeedReloadRow(row)
  }

  return (
    <div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          loading={ loading }
          dataSource={ tableData.locationList }
          rowKey={ record => record.locationId }
          expandable={ {
            expandedRowRender: (record, index, indent, expanded) => {
              return <ExaminationRoomTable
                batchInfo={ batchInfo }
                locationInfo={ record }
                rowKey={ record.id }
                needReloadRow={ needReloadRow }
                expanded={ expanded }
                componentType={ componentType }
              ></ExaminationRoomTable>
            }
          } }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <ManualAllocationModal
        ref={ manualAllocationModalRef }
        batchInfo={ batchInfo }
        locationInfo={ currentEditItem }
        insertSuccess={ manualAllocateSuccess }
      ></ManualAllocationModal>
    </div>
  )
}

export default ExaminationLocationTable
