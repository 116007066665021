import React, { useEffect, useRef, useState } from 'react'
import { Modal, notification, Space, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { deleteSubject, getExamSubjectList } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import EditExamSubjectModal from './EditExamSubjectModal'
import ReuseHistoryExamSubjectModal from './ReuseHistoryExamSubjectModal'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import { useSelector } from 'react-redux'
import eventBus from '../../../../plugins/events'
import AppButton from '../../../../components/AppButton'

function SettingSubject () {
  const editSubjectModalRef = useRef()
  const reuseHistoryExamSubjectModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableData, setTableData] = useState([])
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [modalType, setModalType] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }

  }, [selectedExam])

  const columns = [
    {
      title: '科目名称',
      dataIndex: 'subjectName',
      key: 'subjectName'
    },
    {
      title: '科目代码',
      dataIndex: 'subjectCode',
      key: 'subjectCode'
    },
    {
      title: '考试日期',
      dataIndex: 'examDate',
      key: 'examDate'
    },
    {
      title: '考试时段',
      dataIndex: 'examTime',
      key: 'examTime',
      render: ((record, row) => {
        if (row.startTime) {
          return (<>
            <span>{ row.startTime }</span>
            <span>~</span>
            <span>{ row.endTime }</span>
          </>)
        } else {
          return <></>
        }
      })
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (<>
          <Space className="d-flex align-items-center justify-content-end">
            <AppButton
              size={ 'small' }
              authCode={ 'button_ksxq-kskm-bj' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditSubjectModal) }
            >编辑</AppButton>
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <AppButton
                size={ 'small' }
                authCode={ 'button_ksxq-kskm-bj' }
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteSubjectModal) }
              >删除</AppButton>
            </ComponentDisabled>
          </Space>
        </>)
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    getExamSubjectList({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询科目列表失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateSubjectModal = () => {
    setModalType('create')
    editSubjectModalRef.current.showModal()
  }

  const showEditSubjectModal = () => {
    setModalType('edit')
    editSubjectModalRef.current.showModal()
  }

  const showReuseHistoryExamSubjectModal = () => {
    reuseHistoryExamSubjectModalRef.current.showModal()
  }

  const showDeleteSubjectModal = (row) => {
    Modal.confirm({
      title: '删除科目',
      content: (
        <div>确定要删除科目【{ row.subjectName }】吗？</div>
      ),
      onOk () {
        deleteSubjectAction(row)
      }
    })
  }

  const deleteSubjectAction = (row) => {
    const params = {
      examId: selectedExam.id,
      id: row.id
    }

    deleteSubject(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: '已删除科目'
        })
        getTableData()
        eventBus.emit('updateExamMonitor')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除科目失败',
        centered: true
      })
    })
  }

  const insertSuccess = () => {
    getTableData()
    eventBus.emit('updateExamMonitor')
  }

  return (
    <div>
      { contextHolder }

      <Space className="mb-2 d-flex align-items-center justify-content-end">
        <ComponentDisabled
          disabledTip={ '报名已开始，禁止修改' }
          disabled={ selectedExam.examinationStatus > 0 }
        >
          <AppButton
            authCode={ 'button_ksxq-kskm-yylskskm' }
            type="primary"
            onClick={ openModal.bind(this, {}, showReuseHistoryExamSubjectModal) }
          >引用历史考试科目</AppButton>
          <AppButton
            authCode={ 'button_ksxq-kskm-tjkm' }
            type="primary"
            icon={ <PlusOutlined/> }
            onClick={ openModal.bind(this, {}, showCreateSubjectModal) }
          >添加科目</AppButton>
        </ComponentDisabled>
      </Space>
      <Table
        loading={ loading }
        columns={ columns }
        dataSource={ tableData }
        rowKey={ (record) => record.id }
        pagination={ false }
      ></Table>

      <EditExamSubjectModal
        ref={ editSubjectModalRef }
        modalType={ modalType }
        examInfo={ selectedExam }
        subjectItem={ currentEditItem }
        insertSuccess={ insertSuccess }
      ></EditExamSubjectModal>

      <ReuseHistoryExamSubjectModal
        ref={ reuseHistoryExamSubjectModalRef }
        examInfo={ selectedExam }
        insertSuccess={ insertSuccess }
      ></ReuseHistoryExamSubjectModal>
    </div>
  )
}

export default SettingSubject
