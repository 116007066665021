import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Input, Modal, notification, Radio, Table } from 'antd'

import { getExamApplyFieldList, getExamPositionFieldList, updateExamApplyFieldList } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { useSelector } from 'react-redux'

const ConfigApplyFieldsModal = forwardRef((props, ref) => {
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchKey, setSearchKey] = useState('')
  const [selectedRowItems, setSelectedRowItems] = useState([])
  const [radioKey, setRadioKey] = useState('')

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getTableData()
    }
  }, [modalVisible])

  const columns = [
    {
      title: '字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        return dictInfo['sys_fieldKind'].find(item => {
          return item.dictValue === record
        })?.dictLabel
      }
    },
    {
      title: '表单类型',
      dataIndex: 'fieldFromType',
      key: 'fieldFromType',
      render: (record) => {
        return Number(record) === 2 ? '报名字段' : '职位字段'
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    Promise.all([
      getExamApplyFieldList({ examId: props.examInfo.id }),
      getExamPositionFieldList({ orgId: props.examInfo.orgId })
    ]).then(([applyFieldRes, positionFieldRes]) => {
      setLoading(false)
      if (applyFieldRes.code !== ResponseCode.success || positionFieldRes.code !== ResponseCode.success) {
        notifyApi.error({
          message: '错误',
          description: '查询报名字段数据失败'
        })
        return
      }

      setTableData([].concat(applyFieldRes.data.filter(field => {
        return !props.selectedFieldList.some(item => {
          return item.fieldCode === field.fieldCode
        })
      }), positionFieldRes.data.filter(field => {
        return !props.selectedFieldList.some(item => {
          return item.fieldCode === field.fieldCode
        })
      }).map(field => {
        return {
          ...field,
          fieldFromType: '1'
        }
      })))
    }).catch((err) => {
      setLoading(false)
      notifyApi.error({
        message: '错误',
        description: err
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setSelectedRowKeys([])
    setSelectedRowItems([])
  }

  const onOk = () => {
    setLoading(true)
    const params = {
      examId: props.examInfo.id,
      fieldsList: selectedRowItems.filter(item => {
        return item.fieldType !== '11'
      }),
      fileFieldsList: selectedRowItems.filter(item => {
        return item.fieldType === '11'
      }).map(item => {
        return {
          ...item,
          fileSize: String(item.fileSize)
        }
      })
    }

    updateExamApplyFieldList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })

        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '设置报名字段失败',
        centered: true
      })
    })
  }

  const filterTableData = (list, radio, name) => {
    return list.filter(item => {
      switch (radio) {
        case 'applyFields':
          return item.fieldFromType === '2'
          break
        case 'positionFields':
          return item.fieldFromType === '1'
          break
        case 'fileFormField':
          return item.fieldType === '11'
          break
        default:
          return true
      }
    }).filter(item => {
      if (name) {
        return item.fieldName.indexOf(name) > -1
      } else {
        return true
      }
    })
  }

  const onSearchInput = (e) => {
    setSearchKey(e.target.value)
  }

  const handleSearch = (value) => {
    setSearchKey(value)
  }

  const onSelectedChange = (keys, items) => {
    setSelectedRowKeys(keys)
    setSelectedRowItems(items)
  }

  const radioKeyChange = (e) => {
    setSearchKey('')
    setRadioKey(e.target.value)
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title="配置报名字段"
        width={ '800px' }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <div className={ 'mb-3 d-flex align-items-center justify-content-between' }>
          <div className={ 'd-flex align-items-center' }>
            <span>筛选：</span>
            <Radio.Group
              value={ radioKey }
              size={ 'small' }
              onChange={ radioKeyChange }
              buttonStyle="solid"
            >
              <Radio.Button value={ '' }>全部</Radio.Button>
              <Radio.Button value={ 'applyFields' }>报名字段</Radio.Button>
              <Radio.Button value={ 'positionFields' }>职位字段</Radio.Button>
              <Radio.Button value={ 'fileFormField' }>附件</Radio.Button>
            </Radio.Group>
          </div>

          <Input.Search
            value={ searchKey }
            placeholder={ '请输入字段名或代码搜索' }
            onInput={ onSearchInput }
            onSearch={ handleSearch }
            style={ { width: '280px' } }
          >
          </Input.Search>
        </div>
        <ConfigProvider componentSize={ 'small' }>
          <Table
            loading={ loading }
            columns={ columns }
            dataSource={ filterTableData(tableData, radioKey, searchKey) }
            scroll={ {
              y: 400
            } }
            rowSelection={ {
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedChange
            } }
            pagination={ false }
            rowKey={ record => record.id }
          ></Table>
        </ConfigProvider>
      </Modal>
    </div>
  )
})

export default ConfigApplyFieldsModal
