import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification } from 'antd'

import { auditPoorApply } from '../../common/request'
import ResponseCode from '../../common/response-code'

const PoorExamineeCheckNoPassModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    props.updateLoadingStatus(loading)
  }, [loading])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      const params = {
        id: props.examineeItem.id,
        status: -1,
        ...fields
      }

      auditPoorApply(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，提交审核结果失败'
        })
      })

    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '审核不通过' }
        centered={ true }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Form
          form={ form }
        >
          <Form.Item
            label={ '拒绝原因' }
            name={ 'noApprovedReason' }
            rules={ [{ required: true }] }
          >
            <Input.TextArea maxLength={ 200 } showCount></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
})

export default PoorExamineeCheckNoPassModal
