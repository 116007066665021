import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Radio, Select, Space, Spin } from 'antd'
import { getExaminationAllocationSelectLocation, saveBatchAllocationRule } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const SelectExamRoomAllocationRuleModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [lastRoomRule, setLastRoomRule] = useState(1)
  const [locationOptions, setLocationOptions] = useState([])
  const [allocateType, setAllocateType] = useState(1)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getBatchLocationList()
    }
  }, [modalVisible])

  const getBatchLocationList = () => {
    const params = {
      examId: props.batchInfo.examId,
      batchId: props.batchInfo.batchId
    }

    setLoading(true)
    getExaminationAllocationSelectLocation(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setLocationOptions(res.data.locationList?.map(item => {
          return {
            label: item.locationName,
            value: item.locationId
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取考点列表失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setAllocateType(1)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      const params = {
        ...fields,
        examId: props.batchInfo.examId,
        batchId: props.batchInfo.batchId
      }

      saveBatchAllocationRule(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          hideModal()
          notifyApi.success({
            message: '操作成功'
          })

          props.saveBatchAllocationRuleSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，保存批次考场分配规则失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const whereLastRootChange = (e) => {
    setLastRoomRule(e.target.value)
  }

  const generateSelectLocationFormItem = (type) => {
    if (type === 3) {
      return (
        <Form.Item
          label={ '排序规则' }
          name={ 'firstLocation' }
          rules={ [{ required: true }] }
        >
          <Select
            options={ locationOptions }
            style={ { width: 180 } }
          ></Select>
        </Form.Item>
      )
    } else {
      return (<></>)
    }
  }

  const allocateTypeChange = (e) => {
    setAllocateType(e.target.value)
  }

  const whereLastRootFormVisible = visible => {
    if (visible) {
      return (<>
        <Form.Item
          label={ '尾考场位置' }
          name={ 'whereLastRoot' }
          rules={ [{ required: true }] }
        >
          <Radio.Group onChange={ whereLastRootChange }>
            <Radio value={ 1 }>顺序</Radio>
            <Radio value={ 2 }>考点末尾</Radio>
            <Radio value={ 3 }>指定考点</Radio>
          </Radio.Group>
        </Form.Item>
        { generateSelectLocationFormItem(lastRoomRule) }
      </>)
    } else {
      return <></>
    }
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 620 }
        title={ '配置考场分配规则' }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            form={ form }
            className={ 'my-4' }
            labelCol={ {
              span: 5
            } }

            wrapperCol={ {
              span: 18
            } }
            initialValues={ {
              allocateType: 1,
              sort: 0,
              whereLastRoot: 1
            } }
          >
            <Form.Item
              label={ '考生分配规则' }
              name={ 'allocateType' }
            >
              <Radio.Group onChange={ allocateTypeChange }>
                <Radio value={ 1 }>自动分配</Radio>
                <Radio value={ 2 }>手动分配</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={ '排序规则' }
              name={ 'sort' }
              rules={ [{ required: true }] }
            >
              <Radio.Group>
                <Radio value={ 0 }>打乱次序</Radio>
                <Radio value={ 1 }>不打乱次序</Radio>
                <Radio value={ 2 }>按工作单位排序</Radio>
              </Radio.Group>
            </Form.Item>
            { whereLastRootFormVisible(allocateType === 1) }
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default SelectExamRoomAllocationRuleModal
