import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { FieldTypeMap, RuleLogicMap } from '../../../../common/common'

const initOptions = [
  {
    label: '包含',
    value: RuleLogicMap.include
  },
  {
    label: '不包含',
    value: RuleLogicMap.exclude
  },
  {
    label: '等于',
    value: RuleLogicMap.equal
  }
]

function RuleLogic ({ value, ruleItem, disabled, onChange }) {
  let [logicOptions, setLoginOptions] = useState(initOptions.slice(0, 2))

  useEffect(() => {
    if (ruleItem) {
      if (ruleItem.fieldType === FieldTypeMap.input) {
        setLoginOptions(initOptions.slice(0, 3))
      } else {
        setLoginOptions(initOptions.slice(0, 2))
      }
    }
  }, [ruleItem])

  const resetLogic = () => {
    onChange(RuleLogicMap.include)
  }

  return (<>
    <Select
      disabled={disabled}
      value={ value }
      options={ logicOptions }
      onChange={ onChange }
      style={ { width: '90px' } }
    ></Select>
  </>)
}

export default RuleLogic
