import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ConfigProvider, Form, Input, Modal, notification, Select, Space, Table, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditAuthModal from './EditAuthModal'
import ResponseCode from '../../common/response-code'
import { authTypeMap } from '../../common/common'
import { getAuthList, deleteAuth, getAuthPages } from '../../common/request'
import { updateAuthList } from '../../store/slice/auth-list-slice'
import { throttle } from '../../plugins/utils'

function AuthorityManagement () {
  const editAuthRef = useRef()
  const [searchForm] = Form.useForm()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(true)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [editAuthModalType, setEditAuthModalType] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    getTableData()
  }, [pageSize, pageNum])


  const columns = [
    {
      title: '权限名称',
      width: '280px',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '权限类型',
      dataIndex: 'type',
      key: 'type',
      render: (type, row) => {
        return authTypeMap.filter(item => item.value === type).map((item, index) => {
          return <Tag color={ item.color } key={ `${ row.name }_${ index }` }>{ item.label }</Tag>
        })
      }
    },
    {
      title: '权限编码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '权限路径',
      dataIndex: 'path',
      key: 'path'
    },
    {
      title: '操作',
      dataIndex: '',
      width: '220px',
      key: '',
      render: (data, row) => {
        return (
          <Space className="d-flex justify-content-end">
            <Button
              size={ 'small' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditAuthModal) }
            >编辑</Button>
            <Button
              size={ 'small' }
              type="primary"
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteAuthModal) }
            >删除</Button>
          </Space>
        )
      }
    }
  ]

  const getTableData = () => {
    const searchData = searchForm.getFieldsValue()
    setLoading(true)
    const params = {
      ...searchData,
      pageSize,
      pageNum
    }

    getAuthPages(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询权限列表失败'
      })
    })
  }

  const updateAuthListAction = () => {
    getAuthList().then(res => {
      if (res.code === ResponseCode.success && res.data) {
        dispatch(updateAuthList({ authList: res.data }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询权限失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateAuthModal = () => {
    setEditAuthModalType('create')
    editAuthRef.current.showModal()
  }

  const showEditAuthModal = () => {
    setEditAuthModalType('edit')
    editAuthRef.current.showModal()
  }

  const showDeleteAuthModal = (authItem) => {
    Modal.confirm({
      title: '删除权限',
      content: (
        <div>确定要删除字典【{ authItem.name }】吗？</div>
      ),
      onOk () {
        deleteAuthAction(authItem)
      }
    })
  }

  const deleteAuthAction = (authItem) => {
    const params = {
      id: authItem.id
    }

    deleteAuth(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `删除权限成功`
        })
        reloadAuthList()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除权限失败',
        centered: true
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const reloadAuthList = () => {
    getTableData()
    updateAuthListAction()
  }

  const onSearch = () => {
    setPageNum(1)
    getTableData()
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <Form
          layout="inline"
          form={ searchForm }
          initialValues={ {
            name: '',
            type: ''
          } }
          autoComplete="off"
          onFieldsChange={ throttle(onSearch, 300) }
        >
          <Form.Item
            label="权限名称"
            name="name">
            <Input placeholder="输入权限名称搜索" style={ { width: '180px' } }/>
          </Form.Item>
          <Form.Item
            label="权限类型"
            name="type">
            <Select
              options={ [{ label: '全部', value: '' }, ...authTypeMap] }
              style={ { width: 80 } }
            ></Select>
          </Form.Item>
        </Form>

        <Button type="primary" icon={ <PlusOutlined/> } onClick={ openModal.bind(this, {}, showCreateAuthModal) }>创建权限</Button>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditAuthModal
        ref={ editAuthRef }
        modalType={ editAuthModalType }
        authItem={ currentEditItem }
        insertSuccess={ reloadAuthList }
      ></EditAuthModal>
    </div>
  )
}

export default AuthorityManagement
