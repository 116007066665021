import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, Table, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { deleteApplyFormField, getExamDictData, getTemplateFieldList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { updateDictInfo } from '../../store/slice/dict-slice'
import { useDispatch, useSelector } from 'react-redux'
import EditApplyFormFieldModal from '../../components/EditApplyFormFieldModal'
import AppButton from '../../components/AppButton'

function ApplyFieldManagement () {
  const editApplyFormFieldModalRef = useRef()
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [modalType, setModalType] = useState('')
  const [fieldTypeOptions, setFieldTypeOptions] = useState([])
  const [searchForm] = Form.useForm()

  useEffect(() => {
    getFieldTypeList()
    getTableData()
  }, [pageNum, pageSize])

  const columns = [
    {
      title: '排序',
      dataIndex: 'fieldSort',
      key: 'fieldSort'
    },
    {
      title: '字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        return fieldTypeOptions.find(item => {
          return item.dictValue === record
        })?.dictLabel
      }
    },
    {
      title: '是否启用',
      dataIndex: 'state',
      key: 'state',
      render: (record) => {
        return Number(record) === 1 ? '启用' : '禁用'
      }
    },
    {
      title: '操作',
      width: '220px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <div className="d-flex justify-content-end">
            <AppButton
              authCode={ 'button_bmzdgl-bj' }
              className="mr-2"
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditApplyFormFieldModal) }
            >编辑</AppButton>
            <AppButton
              authCode={ 'button_bmzdgl-sc' }
              type="primary"
              danger
              ghost
              onClick={ openModal.bind(this, row, showDeleteApplyFormFieldModal) }
            >删除</AppButton>
          </div>
        </>)
      }
    }
  ]

  const getFieldTypeList = () => {
    if (dictInfo['sys_fieldKind']) {
      setFieldTypeOptions(dictInfo['sys_fieldKind'])
    } else {
      getExamDictData({ dictCode: 'sys_fieldKind' }).then(res => {
        if (res.code === ResponseCode.success) {
          setFieldTypeOptions(res.data)
          dispatch(updateDictInfo({ dictKey: 'sys_fieldKind', dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const handleSearch = () => {
    setPageNum(1)
    getTableData()
  }

  const getTableData = () => {
    const params = {
      fieldLevel: 'S',
      orderBy: 'fieldSort',
      orderType: 'ASC',
      pageNum,
      pageSize
    }
    const { fieldName, fieldCode } = searchForm.getFieldsValue()
    fieldName && (params.fieldName = fieldName.trim())
    fieldCode && (params.fieldCode = fieldCode.trim())

    getTemplateFieldList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '查询报名字段列表失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateApplyFormFieldModal = () => {
    setModalType('create')
    editApplyFormFieldModalRef.current.showModal()
  }

  const showEditApplyFormFieldModal = () => {
    setModalType('edit')
    editApplyFormFieldModalRef.current.showModal()
  }

  const showDeleteApplyFormFieldModal = (row) => {
    Modal.confirm({
      title: '删除报名字段',
      centered: true,
      content: `确定要删除报名字段【${ row.fieldName }】吗？`,
      onOk: () => {
        deleteApplyFormFieldAction([row.id])
      }
    })
  }

  const deleteApplyFormFieldAction = (idList) => {
    deleteApplyFormField({ ids: idList }).then(res => {
      if (res.code === ResponseCode.success) {
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '删除报名字段列表失败'
      })
    })
  }

  return (
    <div>
      <div className="mb-2 d-flex justify-content-between">
        <div>
          <Form
            layout="inline"
            form={ searchForm }
            initialValues={
              {
                fieldName: '',
                fieldCode: ''
              }
            }
            autoComplete="off">
            <Form.Item
              label="字段名称"
              name="fieldName">
              <Input placeholder="输入字段名称搜索" style={ { width: '180px' } }/>
            </Form.Item>
            <Form.Item
              label="字段编码"
              name="fieldCode">
              <Input placeholder="输入字段名称搜索" style={ { width: '180px' } }/>
            </Form.Item>
            <Form.Item className="d-flex justify-content-center">
              <Button type="primary" onClick={ handleSearch }>
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
        <AppButton
          authCode={ 'button_bmzdgl-xzbmzd' }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateApplyFormFieldModal) }
        >新增报名字段</AppButton>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditApplyFormFieldModal
        ref={ editApplyFormFieldModalRef }
        fieldItem={ currentEditItem }
        modalType={ modalType }
        fieldLevel={ 'S' }
        insertSuccess={ getTableData }
      ></EditApplyFormFieldModal>
    </div>
  )
}

export default ApplyFieldManagement
