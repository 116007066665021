import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { Cascader, Form, Input, InputNumber, Modal, notification, Spin } from 'antd'
import { getRegionTree, updateExamApplySite } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const EditExamApplySiteModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const provinceList = useSelector(state => {
    return state.regionList.regionList.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: false
      }
    })
  })
  const [regionOptions, setRegionOptions] = useState(provinceList)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        ...props.siteItem
      })
      initRegionOptions(props.siteItem.regionIds)
    }
  }, [modalVisible])

  const dataTransform = (data) => {
    return data.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: !item.childList,
        children: item.childList ? dataTransform(item.childList) : undefined
      }
    })
  }

  const initRegionOptions = (regionIds) => {
    setLoading(true)
    getRegionTree({ parent: regionIds[0] }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        regionOptions.forEach(province => {
          if (province.value === regionIds[0]) {
            province.children = dataTransform(res.data)
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const loadRegionData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.childList) {
      targetOption.loading = false
      targetOption.children = targetOption.childList.map(item => {
        return {
          label: item.name,
          value: item.code,
          id: item.id,
          isLeaf: !item.childList,
          childList: item.childList
        }
      })

      setRegionOptions([...regionOptions])
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  const getRegionTreeAction = (targetOption) => {
    getRegionTree({ parent: targetOption.value }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        targetOption.loading = false
        targetOption.children = res.data.map(item => {
          return {
            label: item.name,
            value: item.code,
            id: item.id,
            isLeaf: !item.childList,
            childList: item.childList
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      targetOption.loading = false
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const selectedAddressChange = (selectedKey, selectedItem) => {
    if (selectedItem?.length > 0) {
      form.setFieldValue('name', selectedItem[selectedItem.length - 1].label)
      form.setFieldValue('address', (selectedItem || []).map(item => item.label).join(''))
    }
  }

  const onOk = () => {
    form.validateFields().then(field => {
      setLoading(true)
      const params = {
        id: props.siteItem.id,
        ...field
      }

      updateExamApplySite(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch((err) => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '网络错误',
          content: '更新报名点失败',
          centered: true
        })
      })
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ '编辑报名点' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item
              label={ '所属区域' }
              name={ 'regionIds' }
              rules={ [{ required: true }] }
            >
              <Cascader
                disabled
                changeOnSelect
                options={ regionOptions }
                loadData={ loadRegionData }
                placeholder="请选择机构所属区域"
                onChange={ selectedAddressChange }
              ></Cascader>
            </Form.Item>
            <Form.Item
              label={ '报名点名称' }
              name={ 'name' }
              rules={ [{ required: true }] }
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label={ '报名点代码' }
              name={ 'applySiteCode' }
              rules={ [{ required: true }] }
            >
              <InputNumber></InputNumber>
            </Form.Item>
            <Form.Item
              label={ '排序' }
              name={ 'sortCode' }
              hidden
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label={ '报名点地址' }
              name={ 'address' }
              hidden
            >
              <Input></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamApplySiteModal
