import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InputNumber, Col, ConfigProvider, DatePicker, Form, Input, Modal, notification, Radio, Row, Select, Spin, Table, Checkbox } from 'antd'

import ResponseCode from '../common/response-code'
import { createApplyFormField, getDictList, getExamDictData, getTreeData, updateApplyFormField, updateExamApplyFormField } from '../common/request'
import { updateDictInfo } from '../store/slice/dict-slice'
import EditFieldTypeTableHeadModal from './EditFieldTypeTableHeadModal'
import dayjs from 'dayjs'

const EditApplyFormFieldModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [notifyApi, contextHolder] = notification.useNotification()
  const editFieldTypeTableHeadModalRef = useRef()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [treeDataOptions, setTreeDataOptions] = useState([])
  const [selectOptions, setSelectOptions] = useState([])
  const [fieldTypeOptions, setFieldTypeOptions] = useState([])
  const [fieldAutoOptions, setFieldAutoOptions] = useState([])
  const [fieldType, setFieldType] = useState()
  const [fieldFilling, setFieldFilling] = useState()
  const [tableColumns, setTableColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if (modalVisible) {
      getFieldTypeList()

      if (props.modalType === 'create') {
        setModalTitle('新增报名字段')
      } else {
        setModalTitle('编辑报名字段')
        form.setFieldsValue({
          ...props.fieldItem
        })

        setFieldType(props.fieldItem.fieldType)
        setFieldFilling(props.fieldItem.fieldFilling)
        if (props.fieldItem.selectContent) {
          let dataItem = {}
          setTableColumns(props.fieldItem.selectContent.split('|').map(item => {
            let column = {}
            item.split('-').forEach((data, index) => {
              if (index === 0) {
                column.name = data
              }

              if (index === 1) {
                column.type = data
              }

              if (index === 2) {
                column.content = data
              }

              if (index === 3) {
                column.description = data
              }
            })
            return column
          }).map(data => {
            dataItem[data.name] = data
            return generateTableColumns(data)
          }))

          setDataSource([dataItem])
        }
      }
    }
  }, [modalVisible])

  useEffect(() => {
    if (fieldFilling === '2') {
      getFieldAutoOptions()
      form.setFieldValue('maintenance', props.fieldItem?.fieldFilling || '2')
    }
  }, [fieldFilling])

  useEffect(() => {
    if (fieldType === '4') {
      getSelectOptions()
    }
    if (fieldType === '9') {
      getTreeDataOptions()
    }

    if (fieldType === '11') {
      form.setFieldValue('attachmentFormat', props.fieldItem?.attachmentFormat || ['jpg', 'png', 'pdf'])
      form.setFieldValue('fileSize', String(props.fieldItem?.fileSize) || '5120')
    }
  }, [fieldType])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const getSelectOptions = () => {
    const params = {
      orderBy: 'id',
      pageNum: 1,
      pageSize: 9999
    }

    getDictList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setSelectOptions(res.data.elements.map(item => {
          return {
            label: item.dictName,
            value: item.dictCode
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '查询字段类型字典失败'
      })
    })
  }

  const transformDictData = (data) => {
    return data.map(item => {
      return {
        label: item.dictLabel,
        value: item.dictValue
      }
    })
  }

  const getFieldTypeList = () => {
    setFieldTypeOptions(transformDictData(dictInfo['sys_fieldKind']))
  }

  const getFieldAutoOptions = () => {
    if (dictInfo['sys_autoGetField']) {
      setFieldAutoOptions(transformDictData(dictInfo['sys_autoGetField']))
    } else {
      getExamDictData({ dictCode: 'sys_autoGetField' }).then(res => {
        if (res.code === ResponseCode.success) {
          setFieldAutoOptions(transformDictData(res.data))
          dispatch(updateDictInfo({ dictKey: 'sys_autoGetField', dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch(() => {
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setFieldType(undefined)
    setFieldFilling(undefined)
    setTableColumns([])
    setDataSource([])
  }

  const generateParams = (field) => {
    let params = {
      examId: props.examInfo?.id
    }

    if (props.fieldItem.fieldType === '11') {
      params.fileFieldsList = [
        {
          ...props.fieldItem,
          ...field
        }
      ]
    } else {
      params.fieldsList = [
        {
          ...props.fieldItem,
          ...field
        }
      ]
    }

    return params
  }

  const onOk = () => {
    form.validateFields().then(field => {
      setLoading(true)
      let request
      let params = {
        ...field,
        fieldFormType: '2',
        fieldLevel: props.fieldLevel,
        maintenance: '1',
        fieldSort: field.fieldSort || 99,
        orgId: props.orgItem?.id,
        fieldSize: String(field.fieldSize)
      }

      if (props.modalType === 'create') {
        request = createApplyFormField(params)
      } else {
        if (props.isExamField) {
          params = generateParams(field)

          request = updateExamApplyFormField(params)
        } else {
          params.id = props.fieldItem?.id
          request = updateApplyFormField(params)
        }
      }

      request.then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        setLoading(false)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '保存报名字段失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const fieldTypeChange = (value) => {
    setFieldType(value)
  }

  const fieldFillingChange = (e) => {
    setFieldFilling(e.target.value)
  }

  const getAutoFillingFormContent = (fillingType) => {
    if (fillingType === '2') {
      return (<>
        <Col span={ 12 }>
          <Form.Item
            label={ '自动获取字段' }
            name={ 'fieldAuto' }
            rules={ [{ required: true }] }
          >
            <Select
              options={ fieldAutoOptions }
              style={ { width: '220px' } }
            ></Select>
          </Form.Item>
        </Col>
        <Col span={ 12 }>
          <Form.Item
            label={ '允许修改' }
            name={ 'maintenance' }
            rules={ [{ required: true }] }
          >
            <Radio.Group>
              <Radio value={ '2' }>是</Radio>
              <Radio value={ '1' }>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </>)
    }
  }

  const getTreeDataOptions = () => {
    const params = {
      orderBy: 'id',
      pageNum: 1,
      pageSize: 9999
    }

    getTreeData(params).then(res => {
      if (res.code === ResponseCode.success) {
        setTreeDataOptions(res.data.map(item => {
          return {
            label: item.dictLabel,
            value: item.dictCode
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch((err) => {
      console.error(err)
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '查询数据树列表数据失败'
      })
    })
  }

  const showEditTableHeadFieldModal = () => {
    editFieldTypeTableHeadModalRef.current.showModal(dataSource)
  }

  const generateTableColumns = (data) => {
    return {
      title: data.name,
      dataIndex: data.name,
      key: data.name,
      render: () => {
        let content

        switch (data.type) {
          case 'a':
            content = <Input
              defaultValue={ '示例' }
              disabled
            ></Input>
            break
          case 's':
            content = <Select
              defaultValue={ '示例' }
              disabled
            ></Select>
            break
          case 't':
            content = <DatePicker
              defaultValue={ dayjs(new Date()) }
              disabled
            ></DatePicker>
            break
        }

        return content
      }
    }
  }

  const updateTableHeaderData = (headerData) => {
    let dataItem = {}
    setTableColumns(headerData.map(data => {
      dataItem[data.name] = data
      return generateTableColumns(data)
    }))

    setDataSource([dataItem])

    if (headerData.length > 0) {
      form.setFieldValue('selectContent', headerData.map(item => {
        return `${ item.name }-${ item.type }-${ item.content || '' }-${ item.description || '' }`
      }).join('|'))
    } else {
      form.setFieldValue('selectContent', '')
    }
  }

  const filterOption = (input, option) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }

  const getFieldTypeFormContent = (type) => {
    let content
    switch (type) {
      case '2':
      case '3':
        content = (<>
          <Col span={ 12 }>
            <Form.Item
              label={ '最大字数' }
              name={ 'fieldNumber' }
            >
              <InputNumber></InputNumber>
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item
              labelCol={ { span: 4 } }
              wrapperCol={ { span: 18 } }
              label={ '填写示例' }
              name={ 'fieldExample' }
            >
              <Input.TextArea></Input.TextArea>
            </Form.Item>
          </Col>
        </>)
        break
      case '4':
        content = (<>
          <Col span={ 12 }>
            <Form.Item
              label={ '关联数据' }
              name={ 'fieldJoinSelect' }
              rules={ [{ required: true }] }
            >
              <Select
                showSearch
                options={ selectOptions }
                filterOption={ filterOption }
              ></Select>
            </Form.Item>
          </Col>
        </>)
        break
      case '7':
        content = (<>
          <Col span={ 24 }>
            <Form.Item
              labelCol={ { span: 4 } }
              wrapperCol={ { span: 18 } }
              label={ '表头字段' }
              name={ 'selectContent' }
              rules={ [{ required: true }] }
            >
              <div>
                <Button
                  type={ 'primary' }
                  ghost
                  onClick={ showEditTableHeadFieldModal }
                >编辑表头</Button>
                <ConfigProvider componentSize={ 'small' }>
                  {
                    tableColumns.length > 0
                      ? <Table
                        className={ 'mt-2' }
                        columns={ tableColumns }
                        dataSource={ dataSource }
                        rowKey={ () => new Date().getTime() }
                        pagination={ false }
                      ></Table>
                      : <></>
                  }
                </ConfigProvider>
              </div>
            </Form.Item>
          </Col>
        </>)
        break
      case '9':
        content = (<>
          <Col span={ 12 }>
            <Form.Item
              label={ '树关联数据' }
              name={ 'fieldJoinSelect' }
              rules={ [{ required: true }] }
            >
              <Select options={ treeDataOptions }></Select>
            </Form.Item>
          </Col>

        </>)
        break
      case '11':
        content = (<>
          <Col span={ 12 }>
            <Form.Item
              label={ '文件类型' }
              name={ 'attachmentFormat' }
              rules={ [{ required: true }] }
            >
              <Checkbox.Group>
                <Checkbox value={ 'jpg' }>jpg</Checkbox>
                <Checkbox value={ 'png' }>png</Checkbox>
                <Checkbox value={ 'pdf' }>pdf</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={ 12 }>
            <Form.Item
              label={ '文件大小' }
              name={ 'fileSize' }
              rules={ [{ required: true }] }
            >
              <Radio.Group>
                <Radio value={ '2048' }>2M</Radio>
                <Radio value={ '5120' }>5M</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </>)
        break
      default:
        content = <></>
    }

    return content
  }

  return (
    <div>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        width={ 680 }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            className={ 'mt-3' }
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            initialValues={ {
              fieldIsNotBlank: '1',
              fieldFilling: '1',
              state: 1
            } }
          >
            <Row>
              <Col span={ 12 }>
                <Form.Item
                  label={ '字段编码' }
                  name={ 'fieldCode' }
                >
                  <Input
                    disabled
                    placeholder={ '系统自动生成' }
                    style={ { width: '220px' } }
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={ '字段名称' }
                  name={ 'fieldName' }
                  rules={ [{ required: true }] }
                >
                  <Input
                    disabled={ props.isExamField }
                    placeholder={ '例：工作单位' }
                    style={ { width: '220px' } }
                  ></Input>
                </Form.Item>
                <Form.Item
                  hidden={ props.isExamField }
                  label={ '排序' }
                  name={ 'fieldSort' }
                >
                  <InputNumber style={ { width: '220px' } }></InputNumber>
                </Form.Item>
              </Col>
              <Col span={ 12 }>
                <Form.Item
                  label={ '字段状态' }
                  name={ 'state' }
                  hidden={ props.isExamField }
                  rules={ [{ required: true }] }
                >
                  <Radio.Group>
                    <Radio value={ 1 }>启用</Radio>
                    <Radio value={ 0 }>禁用</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={ '是否必填' }
                  name={ 'fieldIsNotBlank' }
                  hidden={ props.isExamField }
                  rules={ [{ required: true }] }
                >
                  <Radio.Group>
                    <Radio value={ '1' }>是</Radio>
                    <Radio value={ '0' }>否</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={ '填写方式' }
                  name={ 'fieldFilling' }
                  rules={ [{ required: true }] }
                >
                  <Radio.Group
                    style={ { width: '220px' } }
                    onChange={ fieldFillingChange }
                  >
                    <Radio value={ '1' }>考生填写</Radio>
                    <Radio value={ '2' }>自动获取</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              { getAutoFillingFormContent(fieldFilling) }
              <Col span={ 12 }>
                <Form.Item
                  label={ '字段类型' }
                  name={ 'fieldType' }
                  rules={ [{ required: true }] }
                >
                  <Select
                    options={ fieldTypeOptions }
                    style={ { width: '220px' } }
                    onChange={ fieldTypeChange }
                  ></Select>
                </Form.Item>
              </Col>
              { getFieldTypeFormContent(fieldType) }
              <Col span={ 24 }>
                <Form.Item
                  labelCol={ { span: 4 } }
                  wrapperCol={ { span: 18 } }
                  label={ '填写说明' }
                  name={ 'fieldExplain' }
                >
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>

      <EditFieldTypeTableHeadModal
        ref={ editFieldTypeTableHeadModalRef }
        insertSuccess={ updateTableHeaderData }
      ></EditFieldTypeTableHeadModal>
    </div>
  )
})

export default EditApplyFormFieldModal
