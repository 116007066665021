import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, Button } from 'antd'
import ExamineeAdmissionTicket from '../../components/examinee/ExamineeAdmissionTicket'
import { useSelector } from 'react-redux'

const ViewExamineeInfoModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [examineeInfo, setExamineeInfo] = useState(null)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (examineeItem) => {
    setExamineeInfo(examineeItem)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  return (
    <Modal
      width={ 1000 }
      title="查看考生信息"
      open={ modalVisible }
      onCancel={ hideModal }
      footer={ [
        <Button key={ 'close' } type={'primary'} onClick={ hideModal }>关闭</Button>
      ] }
    >
      <ExamineeAdmissionTicket
        ignoreCheck={ props.ignoreCheck }
        examineeItem={ {
          examId: selectedExam.id,
          checkStatus: 1,
          idCard: examineeInfo?.idCard,
          examineesName: examineeInfo?.name
        } }
        modalVisible={ modalVisible }
      ></ExamineeAdmissionTicket>
    </Modal>
  )
})

export default ViewExamineeInfoModal
