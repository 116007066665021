import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Space, Spin } from 'antd'
import { useSelector } from 'react-redux'

import Styles from '../ExaminationAllocation.module.scss'
import ExaminationBatchRoomItem from './ExaminationBatchRoomItem'
import {
  examinationAllocationFinish,
  examinationAllocationReset,
  getExaminationRoomAllocationInfo
} from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import GenerateExamineeNumberModal from './GenerateExamineeNumberModal'
import eventBus from '../../../plugins/events'
import ExaminationNonstandardBatchRoomItem from './ExaminationNonstandardBatchRoomItem'

function ExaminationRoomAllocation (props) {
  const generateExamineeNumberModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [examBatchList, setExamBatchList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getExamAllocationListAction()

    eventBus.addListener('needUpdateRoomList', eventListener)

    return () => {
      eventBus.removeListener('needUpdateRoomList', eventListener)
    }
  }, [])

  const eventListener = () => {
    getExamAllocationListAction()
  }

  const getExamAllocationListAction = () => {
    getExaminationRoomAllocationInfo({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setExamBatchList(res.data.examRoomAllocateInfoVOList)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试批次详情失败'
      })
    })
  }

  const allocationFinish = () => {
    setLoading(true)
    examinationAllocationFinish({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        props.nextStep?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考试分配信息失败'
      })
    })
  }

  const allocationAgain = () => {
    setLoading(true)
    examinationAllocationReset({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        props.resetStep?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考试分配信息失败'
      })
    })
  }

  const generateExamBatchContent = (list) => {
    return list.map(batch => {
      if (batch.batchType === 1) {
        return <ExaminationBatchRoomItem
          key={ batch.batchId }
          batchInfo={ batch }
          updateBatchList={ getExamAllocationListAction }
          componentType={ props.componentType }
        ></ExaminationBatchRoomItem>
      } else {
        return <ExaminationNonstandardBatchRoomItem
          key={ batch.batchId }
          batchInfo={ batch }
          updateBatchList={ updateNonstandarBatchList }
          componentType={ props.componentType }
        ></ExaminationNonstandardBatchRoomItem>
      }
    })
  }

  const updateNonstandarBatchList = () => {
    getExamAllocationListAction()
    props.nextStep?.()
  }

  const allocateBtnVisible = (visible) => {
    if (visible) {
      return (
        <Button
          type={ 'primary' }
          onClick={ allocationFinish }
        >完成分配</Button>
      )
    } else {
      return <></>
    }
  }

  const generateControlBtn = () => {
    if (props.componentType === 'view' && examBatchList.length > 0 && examBatchList[0].generateCandidateNumber === 0) {
      return (
        <Button type={ 'primary' } onClick={ showGenerateExamineeNumberModal }>生成准考证号</Button>
      )
    } else {
      return <></>
    }
  }

  const showGenerateExamineeNumberModal = () => {
    generateExamineeNumberModalRef.current.showModal()
  }

  return (
    <div>
      <Spin spinning={ loading }>
        <div className={ [Styles.pageTitle, 'd-flex align-items-center justify-content-between'].join(' ') }>
          <span>考场分配</span>
          { generateControlBtn(examBatchList) }
        </div>
        { generateExamBatchContent(examBatchList) }

        <div className={ 'mt-3 d-flex justify-content-center' }>
          <Space align={ 'center' }>
            { allocateBtnVisible(props.componentType !== 'view' && examBatchList.every(item => {
              return item.batchType !== 2
            })) }
            <Button
              disabled={ selectedExam.examinationStatus >= 2 }
              type={ 'primary' }
              onClick={ allocationAgain }
              ghost
              danger
            >重新分配</Button>
          </Space>
        </div>
      </Spin>

      <GenerateExamineeNumberModal
        ref={ generateExamineeNumberModalRef }
      ></GenerateExamineeNumberModal>
    </div>
  )
}

export default ExaminationRoomAllocation
