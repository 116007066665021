import React, { useEffect, useState } from 'react'
import { InputNumber } from 'antd'

function ExamineesNumberRange ({ value, onInput }) {
  const [startNum, setStartNum] = useState('')
  const [endNum, setEndNum] = useState('')

  useEffect(() => {
    if (value && value.length > 0) {
      setStartNum(value[0])
      setEndNum(value[1])
    }
  }, [value])

  useEffect(() => {
    onInput?.([startNum, endNum])
  }, [startNum, endNum])

  const startNumChange = (value) => {
    setStartNum(value)
  }

  const endNumChange = (value) => {
    setEndNum(value)
  }

  return (
    <div className={ 'd-flex align-items-center' }>
      <InputNumber style={{ width: '120px' }} value={ startNum } onChange={ startNumChange }></InputNumber>
      <span className={'mx-2'}>~</span>
      <InputNumber style={{ width: '120px' }} value={ endNum } onChange={ endNumChange }></InputNumber>
    </div>
  )
}

export default ExamineesNumberRange
