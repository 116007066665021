import React, { forwardRef, useEffect, useState } from 'react'
import { Modal } from 'antd'

import { getApplyPositionList, getExamPositionList } from '../common/request'
import ResponseCode from '../common/response-code'

const ExamPositionList = forwardRef((props, ref) => {
  const [positionList, setPositionList] = useState([])

  useEffect(() => {
    props.loadSuccess?.(positionList)
  }, [positionList])

  useEffect(() => {
    if (props.examId) {
      getPositionList()
    }
  }, [props.examId, props.unitCode])

  const getPositionList = () => {
    const params = {
      examId: props.examId,
      unitCode: props.unitCode
    }
    getApplyPositionList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setPositionList(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询职位列表失败'
      })
    })
  }

  return (
    <div ref={ref} ></div>
  )
})

export default ExamPositionList
