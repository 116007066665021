import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, ConfigProvider, Form, Input, Modal, Table, notification, Spin } from 'antd'
import { phoneValidate } from '../../../../plugins/validates'
import { updatePositionCheckPerson } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { getUid } from '../../../../plugins/utils'

const EditCheckPersonModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contentHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [tableData, setTableData] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.positionItemList.length > 1) {
        setModalTitle('批量配置审核人员')
      } else {
        setModalTitle('配置审核人员')
        setTableData(props.positionItemList[0].checkPersonList?.map(item => {
          return {
            ...item,
            id: getUid()
          }
        }) || [])
      }
    }
  }, [modalVisible])

  const columns = [
    {
      title: '姓名',
      dataIndex: 'checkName',
      key: 'checkName'
    },
    {
      title: '手机号',
      dataIndex: 'checkPhone',
      key: 'checkPhone'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: '80px',
      key: 'operation',
      render: (record, row) => {
        return (
          <div className={ 'd-flex align-items-center justify-content-end' }>
            <Button
              ghost
              danger
              type={ 'primary' }
              onClick={ deleteCheckPerson.bind(this, row) }
            >删除</Button>
          </div>
        )
      }
    }
  ]

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setTableData([])
  }

  const deleteCheckPerson = (row) => {
    setTableData(tableData.filter(item => {
      return row.checkPhone !== item.checkPhone
    }))
  }

  const addCheckPerson = () => {
    form.validateFields().then(fields => {
      if (tableData.find(item => {
        return item.checkPhone === fields.checkPhone
      })) {
        Modal.error({
          title: '错误',
          content: '手机号已存在，请勿重复添加'
        })
        return
      }
      setTableData([].concat(tableData, [{
        ...fields,
        id: getUid()
      }]))
    }).catch(err => {
      console.error(err)
    })
  }

  const onOk = () => {
    setModalLoading(true)

    const params = {
      checkPersonList: tableData,
      examId: props.examInfo.id,
      ids: props.positionItemList.map(item => {
        return item._id
      })
    }

    updatePositionCheckPerson(params).then(res => {
      setModalLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setModalLoading(false)
      Modal.error({
        title: '网络错误',
        content: '保存审核人员配置失败',
        centered: true
      })
    })
  }

  const handleReset = () => {
    if (props.positionItemList.length > 1) {
      setTableData([])
    } else {
      setTableData(props.positionItemList[0].checkPersonList)
    }
  }

  return (
    <div ref={ ref }>
      { contentHolder }

      <Modal
        title={ modalTitle }
        width={ '800px' }
        open={ modalVisible }
        onOk={ onOk }
        okText={ '保存' }
        onCancel={ hideModal }
        footer={ [
          <Button key="back" onClick={ hideModal }>
            取消
          </Button>,
          <Button type="primary" ghost onClick={ handleReset }>
            重置
          </Button>,
          <Button type="primary" loading={ modalLoading } onClick={ onOk }>
            保存
          </Button>
        ] }
      >
        <Spin spinning={ modalLoading }>
          <ConfigProvider componentSize={ 'small' }>
            <div className={ 'my-3 d-flex' }>
              <Form
                form={ form }
                layout={ 'inline' }
              >
                <Form.Item
                  name={ 'checkName' }
                  label={ '姓名' }
                  rules={ [{ required: true, message: '姓名不能为空' }] }
                >
                  <Input placeholder={ '请输入姓名' }></Input>
                </Form.Item>
                <Form.Item
                  name={ 'checkPhone' }
                  label={ '手机号' }
                  required
                  rules={ [{ validator: phoneValidate }] }
                >
                  <Input placeholder={ '请输入手机号' }></Input>
                </Form.Item>
              </Form>

              <Button
                type={ 'primary' }
                onClick={ addCheckPerson }
              >添加</Button>
            </div>

            <Table
              columns={ columns }
              dataSource={ tableData }
              rowKey={ (record) => record.id }
              pagination={ false }
            ></Table>
          </ConfigProvider>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditCheckPersonModal
