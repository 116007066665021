import React, { cloneElement, Children } from 'react'
import { Space, Tooltip } from 'antd'

function ComponentDisabled (props) {
  const componentDisabled = (disabled) => {
    if (!disabled) {
      return <Space>{
        props.children
      }</Space>
    } else {
      return <Space>{
        Children.map(props.children, child => {
          if (child) {
            return <Tooltip title={ props.disabledTip }>{
              cloneElement(child, {
                disabled: true
              })
            }</Tooltip>
          } else {
            return <div></div>
          }
        })
      }</Space>
    }
  }

  return (<>
    { componentDisabled(props.disabled) }
  </>)
}

export default ComponentDisabled
