import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Tabs, DatePicker, Form, Input, Modal, Select, Skeleton, Space, Spin, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import {
  getExamApplyTemplate,
  getExamDetail,
  getExamDictData,
  getExamineeApplyInfo,
  getExamPositionList, getTreeData, getTreeDict, updateExamineeInfo,
  uploadFile,
  uploadPersonImage
} from '../../common/request'
import ResponseCode from '../../common/response-code'
import { updateDictInfo } from '../../store/slice/dict-slice'
import { FieldTypeMap } from '../../common/common'
import { getUid } from '../../plugins/utils'
import TableFormField from './TableFormField'
import FileFormField from './FileFormField'
import RegionFormField from './RegionFormField'
import TreeDataFormField from './TreeDataFormField'

const EditExamineeInfoModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [fileForm] = Form.useForm()
  const dispatch = useDispatch()
  const [notifyApi, contextHolder] = notification.useNotification()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initDataLoading, setInitDataLoading] = useState(false)
  const [templateField, setTemplateField] = useState([])
  const [applyInfo, setApplyInfo] = useState({})
  const [formItems, setFormItems] = useState([])
  const [positionFormFields, setPositionFormFields] = useState([])
  const [formFileItems, setFormFileItems] = useState([])
  const [siteOptions, setSiteOptions] = useState([])
  const [examInfo, setExamInfo] = useState({})
  const [positionList, setPositionList] = useState([])
  const [currentSitePosition, setCurrentSitePosition] = useState([])
  const [activeTab, setActiveTab] = useState('applyField')
  const [currentSiteUnitOptions, setCurrentSiteUnitOptions] = useState([])
  const systemFields = ['applyDateTime', 'reviewer', 'reviewDate']

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getExamineeApplyInfoAction()
    }
  }, [modalVisible])

  useEffect(() => {
    setSiteOptions(examInfo.applySiteList?.map(item => {
      return {
        ...item,
        label: item.name,
        value: item.applySiteCode
      }
    }))
  }, [examInfo])

  useEffect(() => {
    if (modalVisible) {
      form.setFieldValue('siteCode', props.examineeItem.siteCode)
      form.setFieldValue('siteName', props.examineeItem.siteName)
      positionFormFields.forEach(field => {
        form.setFieldValue(field.fieldCode, applyInfo.applyInfo[field.fieldCode])
      })
      filterUnitOptions(props.examineeItem.siteCode)
      filterPositionOptions(props.examineeItem.unitCode)
    }
  }, [applyInfo, positionFormFields, modalVisible])

  useEffect(() => {
    responseTransform()
  }, [templateField])

  useEffect(() => {
    formFileItems.forEach(field => {
      if (field.fieldType === '6') {
        fileForm.setFieldValue(field.fieldCode, applyInfo.applyInfo[field.fieldCode])
      } else {
        fileForm.setFieldValue(field.fieldCode, applyInfo.applyFiles?.[field.fieldCode])
      }
    })
  }, [formFileItems])

  useEffect(() => {
    if (modalVisible) {
      formItems.forEach(field => {
        if (field.fieldType === '5') {
          form.setFieldValue(field.fieldCode, applyInfo.applyInfo[field.fieldCode] ? dayjs(new Date(applyInfo.applyInfo[field.fieldCode])) : '')
        } else if (field.fieldType === '7') {
          form.setFieldValue(field.fieldCode, applyInfo.applyInfo[field.fieldCode]?.map(item => {
            return {
              ...item,
              id: getUid()
            }
          }))
        } else {
          form.setFieldValue(field.fieldCode, applyInfo.applyInfo[field.fieldCode])
        }
      })

      form.setFieldValue('positionId', applyInfo.positionId)
      form.setFieldValue('positionNumber', applyInfo.positionNumber)

    }
  }, [formItems, positionFormFields, modalVisible])

  const responseTransform = () => {
    if (templateField.fields) {
      setFormItems(templateField.fields.filter(item => {
        preLoadDictInfo(item)

        return item.fieldType !== '1' && item.fieldType !== '6'
      }))

      setPositionFormFields(templateField.fields.filter(field => {
        return field.fieldFromType === '1'
      }))

      setFormFileItems([].concat(templateField.fields.filter(item => {
        return item.fieldType === '6'
      }), templateField.fileFields || []))
    }

    setInitDataLoading(false)
  }

  const preLoadDictInfo = (item) => {
    if (item.fieldJoinSelect) {
      if (item.fieldType === FieldTypeMap.select) {
        getDictInfo(item.fieldJoinSelect)
      } else {
        getTreeDataAction(item.fieldJoinSelect)
      }
    }
  }

  const getDictInfo = (fieldJoinSelect) => {
    if (!dictInfo[fieldJoinSelect]) {
      getExamDictData({ dictCode: fieldJoinSelect }).then(res => {
        if (res.code === ResponseCode.success) {
          dispatch(updateDictInfo({ dictKey: fieldJoinSelect, dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const getTreeDataAction = (fieldJoinSelect) => {
    if (!dictInfo[fieldJoinSelect]) {
      getTreeDict({ dictCode: fieldJoinSelect }).then(res => {
        if (res.code === ResponseCode.success) {
          dispatch(updateDictInfo({ dictKey: fieldJoinSelect, dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setActiveTab('applyField')
    setModalVisible(false)
    form.resetFields()
    fileForm.resetFields()
  }

  const getExamineeApplyInfoAction = () => {
    setInitDataLoading(true)
    const params = {
      applyInfoId: props.examineeItem.applyInfoId,
      examId: props.examineeItem.examId,
      positionNumber: props.examineeItem.positionNumber,
      userId: props.examineeItem.userId
    }

    const positionListParams = {
      pageNum: 1,
      pageSize: 9999,
      examId: props.examineeItem.examId
    }

    Promise.all([
      getExamineeApplyInfo(params),
      getExamApplyTemplate({ examId: props.examineeItem.examId }),
      getExamDetail({ id: props.examineeItem.examId }),
      getExamPositionList(positionListParams)
    ]).then(([applyInfoRes, templateFieldRes, examInfoRes, positionListRes]) => {
      if (applyInfoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '查询考生报名信息失败'
        })
        return
      }

      if (templateFieldRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '查询考试报名字段失败'
        })
        return
      }

      if (examInfoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '查询考试详情失败',
          centered: true
        })
        return
      }

      if (positionListRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '查询考试职位信息失败',
          centered: true
        })
        return
      }

      setTemplateField(templateFieldRes.data)
      setApplyInfo(applyInfoRes.data)
      setExamInfo(examInfoRes.data)
      setPositionList(positionListRes.data?.elements.map(item => {
        return {
          label: `${ item.positionCode }_${ item.positionName }`,
          value: item._id,
          ...item
        }
      }))
    }).catch(err => {
      setInitDataLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生报名信息失败'
      })
    })
  }

  const generateExamineeApplyFormItems = (fieldList) => {
    return fieldList.map(item => {
      return (<>
          {
            systemFields.includes(item.fieldAuto)
              ? <Form.Item
                noStyle
                key={ item.fieldCode }
                name={ item.fieldCode }
                label={ item.fieldName }
              ></Form.Item>
              : <Form.Item
                key={ item.fieldCode }
                label={ item.fieldName }
                name={ item.fieldCode }
                rules={ getFormItemRule(item) }
              >
                { generateFormComponent(item) }
              </Form.Item>
          }
        </>
      )
    })
  }

  const generateOtherPositionFormItems = (fieldList) => {
    return fieldList.filter(item => {
      return !['unitName', 'unitCode', 'positionName', 'positionCode'].includes(item.fieldCode)
    }).map(item => {
      return (<Form.Item
          key={ item.fieldCode }
          label={ item.fieldName }
          name={ item.fieldCode }
          rules={ getFormItemRule(item) }
        >
          <Input disabled style={ { width: '560px' } }></Input>
        </Form.Item>)
    })
  }

  const generateFormComponent = (field) => {
    let component

    switch (field.fieldType) {
      case FieldTypeMap.input:
        component = <Input
          placeholder={ `请输入` }
          style={ { width: '560px' } }
        ></Input>
        break
      case FieldTypeMap.textarea:
        component = <Input.TextArea
          placeholder={ `请输入` }
          style={ { width: '560px' } }
        ></Input.TextArea>
        break
      case FieldTypeMap.select:
        component = <Select
          options={ dictInfo[field.fieldJoinSelect]?.map(dict => {
            return {
              label: dict.dictLabel,
              value: dict.dictValue
            }
          }) }
          style={ { width: '560px' } }
          placeholder={ `请选择` }
        >
        </Select>
        break
      case FieldTypeMap.dateRangePicker:
        component = <DatePicker
          style={ { width: '560px' } }
          format={ 'YYYY-MM-DD' }
        ></DatePicker>
        break
      case FieldTypeMap.table:
        const columnsData = field.selectContent.split('|').map(item => {
          return {
            title: item.split('-')[0],
            key: item.split('-')[0],
            dataIndex: item.split('-')[0],
            type: item.split('-')[1],
            fieldJoinSelect: item.split('-')[2],
            description: item.split('-')[3]
          }
        })

        component = (
          <TableFormField
            columnsData={ columnsData }
            fieldItem={ templateField.fields?.find(item => {
              return item.fieldCode === field.fieldCode
            }) }
          ></TableFormField>
        )
        break
      case FieldTypeMap.treeData:
        component = <TreeDataFormField
          fieldJoinSelect={ field.fieldJoinSelect }
        ></TreeDataFormField>
        break
      case FieldTypeMap.cascader:
        component = <RegionFormField></RegionFormField>
        break
    }

    return component
  }

  const fileUploading = () => {
    setLoading(true)
  }

  const fileUploaded = () => {
    setLoading(false)
  }

  const filterUnitOptions = (siteCode) => {
    let obj = {}
    positionList.filter(positionItem => {
      return positionItem.siteCode === siteCode
    }).forEach(positionItem => {
      obj[positionItem.unitCode] = {
        label: `${ positionItem.unitCode }_${ positionItem.unitName }`,
        value: positionItem.unitCode,
        siteCode: positionItem.siteCode
      }
    })

    setCurrentSiteUnitOptions(Object.values(obj))
  }

  const currentSiteChange = (key, item) => {
    form.setFieldValue('siteName', item.label)
    form.setFieldValue('siteCode', item.applySiteCode)
    form.setFieldValue('positionId', null)
    positionFormFields.forEach(field => {
      form.setFieldValue(field.fieldCode, null)
    })
    filterUnitOptions(item.applySiteCode)
  }

  const filterPositionOptions = (unitCode) => {
    setCurrentSitePosition(positionList.filter(positionItem => {
      return positionItem.unitCode === unitCode
    }))
  }

  const unitCodeChange = (unitCode, item) => {
    form.setFieldValue('unitName', item.label.split('_')[1])
    form.setFieldValue('positionId', null)
    form.setFieldValue('positionNumber', null)
    positionFormFields.forEach(field => {
      if (!['unitCode', 'unitName'].includes(field.fieldCode)) {
        form.setFieldValue(field.fieldCode, null)
      }
    })
    filterPositionOptions(unitCode)

  }

  const generateFileFormContent = (field) => {
    switch (field.fieldType) {
      case '6':
        return <FileFormField
          fileName={ '个人照片' }
          fieldType={ field.fieldType }
          uploadApi={ uploadPersonImage }
          fileUploading={ fileUploading }
          fileUploaded={ fileUploaded }
        ></FileFormField>
        break
      case '11':
        return <FileFormField
          fileName={ field.fieldName }
          fieldType={ field.fieldType }
          uploadApi={ uploadFile }
          fileUploading={ fileUploading }
          fileUploaded={ fileUploaded }
        ></FileFormField>
        break
    }
  }

  const getFormItemRule = (field) => {
    let rule

    switch (field.fieldType) {
      case FieldTypeMap.input:
        rule = [{ required: true, min: 1 }]
        break
      default:
        rule = [{ required: true }]
    }

    return field.fieldIsNotBlank === '1' ? rule : []
  }

  const generateFileList = (list) => {
    if (list.length > 0) {
      return (
        <div>
          <Form
            form={ fileForm }
            className={ 'mt-2' }
            labelCol={ {
              span: 4
            } }
          >
            {
              formFileItems.map((item, index) => {
                return (
                  <Form.Item
                    label={ item.fieldName }
                    name={ item.fieldCode }
                    rules={ getFormItemRule(item) }
                    key={ index }
                  >
                    { generateFileFormContent(item) }
                  </Form.Item>
                )
              })
            }
          </Form>
        </div>
      )
    } else {
      return <></>
    }
  }

  const positionIdChange = (key, item) => {
    form.setFieldValue('positionNumber', item.positionNumber)
    positionFormFields.forEach(field => {
      if (!['unitCode', 'unitName'].includes(field.fieldCode)) {
        form.setFieldValue(field.fieldCode, item[field.fieldCode])
      }
    })
  }

  const tabItems = [
    {
      label: '报名字段',
      key: 'applyField',
      forceRender: true,
      children: (<>
        <Form
          form={ form }
          className={ 'mt-3' }
          labelCol={ {
            span: 6
          } }

          wrapperCol={ {
            span: 16
          } }
        >
          <Form.Item
            label={ '报名点' }
            name={ 'siteCode' }
            required={ [{ required: true }] }
          >
            <Select
              options={ siteOptions }
              style={ { width: '560px' } }
              onChange={ currentSiteChange }
            ></Select>
          </Form.Item>
          <Form.Item
            name={ 'siteName' }
            hidden
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            noStyle
            name={ 'unitName' }
          ></Form.Item>
          <Form.Item
            label={ '报考单位' }
            name={ 'unitCode' }
            required={ [{ required: true }] }
          >
            <Select
              options={ currentSiteUnitOptions }
              style={ { width: '560px' } }
              placeholder={ '请选择报考单位' }
              onChange={ unitCodeChange }
            ></Select>
          </Form.Item>
          <Form.Item
            label={ '报考职位' }
            name={ 'positionId' }
            required={ [{ required: true }] }
          >
            <Select
              options={ currentSitePosition }
              style={ { width: '560px' } }
              placeholder={ '请选择职位' }
              onChange={ positionIdChange }
            ></Select>
          </Form.Item>
          <Form.Item
            name={ 'positionNumber' }
            hidden
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name={ 'positionName' }
            hidden
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name={ 'positionCode' }
            hidden
          >
            <Input></Input>
          </Form.Item>
          { generateOtherPositionFormItems(positionFormFields) }
          { generateExamineeApplyFormItems(formItems) }
        </Form>
      </>)
    },
    {
      label: '附件',
      key: 'fileField',
      forceRender: true,
      children: (<>
        { generateFileList(formFileItems) }
      </>)
    }
  ]

  const activeTabChange = (key) => {
    setActiveTab(key)
  }

  const initLoading = (initLoading) => {
    if (initLoading) {
      return (
        <Spin spinning={ true }>
          <Space className={ 'mt-4 mb-3 d-flex justify-content-center' }>
            <Skeleton.Button active={ true }/>
            <Skeleton.Avatar active={ true }/>
            <Skeleton.Input active={ true }/>
          </Space>
          <Space className={ 'mb-3 d-flex justify-content-center' }>
            <Skeleton.Button active={ true }/>
            <Skeleton.Avatar active={ true }/>
            <Skeleton.Input active={ true }/>
          </Space>
          <Space className={ 'mb-3 d-flex justify-content-center' }>
            <Skeleton.Button active={ true }/>
            <Skeleton.Avatar active={ true }/>
            <Skeleton.Input active={ true }/>
          </Space>
          <Space className={ 'mb-3 d-flex justify-content-center' }>
            <Skeleton.Button active={ true }/>
            <Skeleton.Avatar active={ true }/>
            <Skeleton.Input active={ true }/>
          </Space>
          <Space className={ 'mb-3 d-flex justify-content-center' }>
            <Skeleton.Button active={ true }/>
            <Skeleton.Avatar active={ true }/>
            <Skeleton.Input active={ true }/>
          </Space>
        </Spin>
      )
    } else {
      return (
        <Spin spinning={ loading }>
          <Tabs
            items={ tabItems }
            activeKey={ activeTab }
            onChange={ activeTabChange }
          ></Tabs>
        </Spin>
      )
    }
  }

  const onOk = () => {
    Promise.all([
      form.validateFields(),
      fileForm.validateFields()
    ]).then(([applyFields, fileFormFields]) => {
      const params = {
        ...applyInfo,
        positionId: applyFields['positionId'],
        positionNumber: applyFields['positionNumber'],
        applyInfo: {
          siteCode: applyFields['siteCode'],
          siteName: applyFields['siteName']
        },
        applyFiles: {}
      }
      templateField.fields?.forEach(field => {
        switch (field.fieldType) {
          case '1':
            params.applyInfo[field.fieldCode] = applyFields[field.fieldCode]
            break
          case '5':
            params.applyInfo[field.fieldCode] = applyFields[field.fieldCode]
              ? dayjs(applyFields[field.fieldCode]).format(applyInfo.applyInfo[field.fieldCode].indexOf(' ') > -1 ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD').toString()
              : ''
            break
          case '6':
            params.applyInfo[field.fieldCode] = fileFormFields[field.fieldCode]
            break
          default:
            params.applyInfo[field.fieldCode] = applyFields[field.fieldCode]
            if (field.fieldAuto) {
              params[field.fieldAuto] = applyFields[field.fieldCode]
            }
        }
      })

      templateField.fileFields?.forEach(field => {
        params.applyFiles[field.fieldCode] = fileFormFields[field.fieldCode]
      })

      setLoading(true)

      updateExamineeInfo(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {

      })
    }).catch((err) => {
      if (formFileItems.find(item => {
        return item.fieldCode === err.errorFields[0].name[0]
      })) {
        Modal.error({
          title: '错误',
          content: err.errorFields[0].errors[0]
        })
        setActiveTab('fileField')

        return
      }

      if (formItems.find(item => {
        return item.fieldCode === err.errorFields[0].name[0]
      })) {
        Modal.error({
          title: '错误',
          content: err.errorFields[0].errors[0]
        })
        setActiveTab('applyField')

        return
      }
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        width={ 1000 }
        title={ '修改考生报名信息' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        { initLoading(initDataLoading) }
      </Modal>
    </div>
  )
})

export default EditExamineeInfoModal
