import React, { useEffect, useState } from 'react'
import { Modal, notification, Spin, Steps } from 'antd'
import { useSelector } from 'react-redux'

import ResponseCode from '../../common/response-code'
import { clearManualAllocationData, examinationAllocationReset, getExaminationAllocationProcess, updateManualAllocateStatus } from '../../common/request'
import SelectExam from '../../components/select-exam/SelectExam'
import ExaminationLocationAllocation from './exam-loaction-allocation/ExaminationLocationAllocation'
import ExaminationBatchList from './create-exam-batch/ExaminationBatchList'
import ExaminationRoomAllocation from './exam-room-allocation/ExaminationRoomAllocation'
import ValidateAllocateStatus from '../../components/ValidateAllocateStatus'
import eventBus from '../../plugins/events'
import AppButton from '../../components/AppButton'

function ExaminationAllocationWrap (props) {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)
  const [stepNum, setStepNum] = useState(-1)
  const [allocateProgress, setAllocateProgress] = useState()

  const stepItems = [
    { title: '创建考试批次' },
    { title: '考点分配' },
    { title: '考场分配' },
    { title: '考点维护' }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getExaminationAllocationProcessAction()
    }
  }, [selectedExam])

  const getExaminationAllocationProcessAction = () => {
    setLoading(true)
    getExaminationAllocationProcess({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setAllocateProgress(res.data.allocateProgress)
        switch (res.data.allocateProgress) {
          case 0:
            setStepNum(0)
            break
          case 1:
          case 2:
            setStepNum(1)
            break
          case 3:
          case 4:
          case 5:
            setStepNum(2)
            break
          case 6:
            setStepNum(3)
            break
          default:
            setStepNum(-1)
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试分配进度失败'
      })
    })
  }

  const nextStep = () => {
    setStepNum(pre => {
      return (pre + 1)
    })
  }

  const preStep = () => {
    setStepNum(pre => {
      return (pre - 1)
    })
  }

  const resetStep = () => {
    setStepNum(0)
  }

  const stepTo = (data) => {
    setStepNum(data)
  }

  const generateStepContent = (step) => {
    let content
    switch (step) {
      case 0:
        content = (<ExaminationBatchList
          nextStep={ nextStep }
        ></ExaminationBatchList>)
        break
      case 1:
        content = (<ExaminationLocationAllocation
          nextStep={ nextStep }
          preStep={ preStep }
          stepTo={ stepTo }
        ></ExaminationLocationAllocation>)
        break
      case 2:
        content = (
          <ExaminationRoomAllocation
            nextStep={ nextStep }
            resetStep={ resetStep }
          ></ExaminationRoomAllocation>
        )
        break
      case 3:
        content = (<ExaminationRoomAllocation
          nextStep={ nextStep }
          resetStep={ resetStep }
          componentType={ 'view' }
        ></ExaminationRoomAllocation>)
        break
      default:
        content = (
          <Spin spinning={ loading }>
            <div style={ { height: 300 } }></div>
          </Spin>
        )
    }

    return content
  }

  const clearAllocationData = () => {
    setLoading(true)
    examinationAllocationReset({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        getExaminationAllocationProcessAction()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考试分配信息失败'
      })
    })
  }

  const resetConfirmModal = (fn) => {
    Modal.confirm({
      title: '重新分配',
      content: '确定要重新分配吗？',
      onOk: () => {
        fn?.()
      }
    })
  }

  return (
    <div>
      <SelectExam>
        <ValidateAllocateStatus
          allocateType={ 'auto' }
          allocateProgress={allocateProgress}
          updateAllocateProgress={ getExaminationAllocationProcessAction }
        >
          <div className={'d-flex align-items-center justify-content-end'}>
            <AppButton authCode={ 'button_kcxtfp-cxfp' } danger onClick={ resetConfirmModal.bind(this, clearAllocationData) }>重新分配</AppButton>
          </div>
          <div className={ 'mt-4 mb-3 d-flex justify-content-center' }>
            <div className={ 'w-75' }>
              <Steps
                current={ stepNum }
                labelPlacement="vertical"
                items={ stepItems }
              />
            </div>
          </div>
          { generateStepContent(stepNum) }
        </ValidateAllocateStatus>
      </SelectExam>
    </div>
  )
}

export default ExaminationAllocationWrap
