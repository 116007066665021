import { Image, Button, Upload, Space, notification, Modal, Spin } from 'antd'
import { uploadPersonImage } from '../common/request'
import ResponseCode from '../common/response-code'
import { useState } from 'react'
import { useSelector } from 'react-redux'

function UploadImageFormItem ({ value, onChange }) {
  const envInfo = useSelector(state => state.env.envInfo)
  const [loading, setLoading] = useState(false)

  const beforeUpload = (file) => {
    const fileSize_KB = file.size / 1024
    if (fileSize_KB < 30 || fileSize_KB > 300) {
      Modal.error({
        title: '错误',
        content: '上传头像大小必需大于30KB且小于300KB以内，请检查上传图片'
      })
      return false
    }

    setLoading(true)
  }

  const uploadTemplateSuccess = ({ file }) => {
    const res = file.response

    if (res) {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        onChange?.(res.data.filePath)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  return (<Space className={ 'd-flex align-items-center' }>
    <Image
      src={ `${ envInfo.picDomain }/${ value }` }
      height={ 200 }
    ></Image>
    <Upload
      name={ 'file' }
      action={ `${ envInfo.picDomain }/${ uploadPersonImage }` }
      headers={ {
        authorization: window.sessionStorage.getItem('access_token')
      } }
      maxCount={ 1 }
      showUploadList={ false }
      beforeUpload={ beforeUpload }
      onChange={ uploadTemplateSuccess }
    >
      <Button
        type={ 'primary' }
        ghost
      >上传</Button>
    </Upload>

    <Spin
      spinning={ loading }
      fullscreen={ 'true' }
    ></Spin>
  </Space>)
}

export default UploadImageFormItem
