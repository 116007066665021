import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Modal, notification, Space, Table } from 'antd'
import { deleteExamineeAccount, getExamineeAccountList, getExamineeApplyRecord } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ExamineeApplyRecordModal from './ExamineeApplyRecordModal'
import EditExamineeAccountModal from './EditExamineeAccountModal'
import AppButton from '../AppButton'
import { throttle } from '../../plugins/utils'
import ExamineeAccountDetailModal from './ExamineeAccountDetailModal'
import DeIdentification from '../DeIdentification'

function ExamineeAccountManagement ({ modalVisible, orgItem, authCodePrefix = 'button_kszcxx' }) {
  const [form] = Form.useForm()
  const examineeApplyRecordModalRef = useRef(null)
  const editExamineeAccountModalRef = useRef(null)
  const examineeAccountDetailModalRef = useRef(null)
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()

  const columns = [
    {
      title: '姓名',
      width: 90,
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '所属机构',
      width: 250,
      key: 'orgName',
      dataIndex: 'orgName'
    },
    {
      width: 200,
      title: '身份证号',
      key: 'idCard',
      dataIndex: 'idCard',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'idCard' }></DeIdentification>)
      }
    },
    {
      width: 150,
      title: '手机号',
      key: 'phone',
      dataIndex: 'phone',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'phone' }></DeIdentification>)
      }
    },
    {
      title: '邮箱',
      key: 'email',
      dataIndex: 'email',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'email' }></DeIdentification>)
      }
    },
    {
      width: 180,
      title: '注册时间',
      key: 'registerTime',
      dataIndex: 'registerTime'
    },
    {
      width: 180,
      title: '上一次登录时间',
      key: 'lastLoginTime',
      dataIndex: 'lastLoginTime',
    },
    {
      width: 280,
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      fixed: 'right',
      render: (record, row) => {
        return (
          <Space align={ 'end' }>
            <AppButton
              size={ 'small' }
              authCode={ `${ authCodePrefix }-bkjl` }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showExamineeApplyRecordModal) }
            >报考记录</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ `${ authCodePrefix }-xq` }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showExamineeAccountDetailModal) }
            >详情</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ `${ authCodePrefix }-xg` }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showEditExamineeAccountModal) }
            >修改</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ `${ authCodePrefix }-sc` }
              type={ 'primary' }
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteExamineeAccountModal) }
            >删除</AppButton>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    getTableData()
  }, [pageSize, pageNum, orgItem, modalVisible])

  const getTableData = (allFields) => {
    const searchData = allFields || form.getFieldsValue()

    const params = {
      ...searchData,
      examId: searchData.examId === '' ? undefined : searchData.examId,
      pageNum,
      pageSize,
      orgId: orgItem ? orgItem.id : undefined
    }

    setLoading(true)
    getExamineeAccountList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生账号列表失败'
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn?.(row)
  }

  const showEditExamineeAccountModal = () => {
    editExamineeAccountModalRef.current.showModal()
  }

  const showExamineeApplyRecordModal = () => {
    examineeApplyRecordModalRef.current.showModal()
  }

  const showExamineeAccountDetailModal = () => {
    examineeAccountDetailModalRef.current.showModal()
  }

  const showDeleteExamineeAccountModal = (row) => {
    Modal.confirm({
      title: '删除考生账号',
      content: '确定要删除考生账号吗',
      type: 'warning',
      onOk: () => {
        deleteExamineeAccountAction(row)
      }
    })
  }

  const getExamineeApplyRecordAction = (row, fn) => {
    setLoading(true)
    const params = {
      pageNum: 1,
      pageSize: 10,
      userId: row.id
    }

    getExamineeApplyRecord(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        fn?.(res.data.elements)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生报考记录失败'
      })
    })
  }

  const deleteExamineeAccountAction = (row) => {
    getExamineeApplyRecordAction(row, (recordList) => {
      if (recordList.length === 0) {
        deleteExamineeAccount({ id: row.id }).then(res => {
          if (res.code === ResponseCode.success) {
            getTableData()
            notifyApi.success({
              message: '操作成功',
              description: '已删除考生账号'
            })
          } else {
            Modal.error({
              title: '错误',
              content: res.msg
            })
          }
        }).catch(err => {
          setLoading(false)
          console.error(err)
          Modal.error({
            title: '错误',
            content: '网络错误，删除考生账号失败'
          })
        })
      } else {
        Modal.warning({
          title: '无法删除',
          content: '考生存在报考记录，无法删除'
        })
      }
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const handleSearch = (fields) => {
    setPageNum(1)
    getTableData(fields)
  }

  return (
    <div>
      { contextHolder }
      <div>
        <Form
          layout={ 'inline' }
          form={ form }
          initialValues={ {
            name: '',
            idCard: '',
            phone: '',
            examId: ''
          } }
          onValuesChange={ throttle(handleSearch, 500) }
        >
          <Space wrap size={ 8 }>
            <Form.Item
              label={ '姓名' }
              name={ 'name' }
              trigger={ 'onInput' }
            >
              <Input placeholder={ '请输入姓名' }></Input>
            </Form.Item>
            <Form.Item
              label={ '身份证' }
              name={ 'idCard' }
            >
              <Input placeholder={ '请输入身份证' }></Input>
            </Form.Item>
            <Form.Item
              label={ '手机号' }
              name={ 'phone' }
            >
              <Input placeholder={ '请输入手机号' }></Input>
            </Form.Item>
            <Form.Item
              label={ '邮箱' }
              name={ 'email' }
            >
              <Input placeholder={ '请输入邮箱' }></Input>
            </Form.Item>
          </Space>
        </Form>
      </div>

      <Table
        className={ 'mt-3' }
        scroll={ {
          x: 1530
        } }
        loading={ loading }
        columns={ columns }
        dataSource={ tableData.elements }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
        rowKey={ record => record.id }
      ></Table>

      <ExamineeApplyRecordModal
        ref={ examineeApplyRecordModalRef }
        examineeInfo={ currentEditItem }
      ></ExamineeApplyRecordModal>

      <EditExamineeAccountModal
        ref={ editExamineeAccountModalRef }
        examineeInfo={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditExamineeAccountModal>
      <ExamineeAccountDetailModal
        ref={ examineeAccountDetailModalRef }
        examineeInfo={ currentEditItem }
      ></ExamineeAccountDetailModal>
    </div>
  )
}

export default ExamineeAccountManagement
