import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Spin } from 'antd'

import { createRegionDict, updateRegionDict } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const EditCityRegionModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  let regionLevelLabel = ''

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (props.regionLevel === 'city') {
      regionLevelLabel = '市级'
    } else {
      regionLevelLabel = '县级'
    }

    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle(`新增${ regionLevelLabel }区域`)
      } else {
        setModalTitle(`编辑${ regionLevelLabel }区域`)

        form.setFieldsValue({
          name: props.regionItem.name,
          code: props.regionItem.code
        })
      }
    }
  }, [modalVisible])

  const showModal = (parentRegionCode) => {
    form.setFieldValue('parent', parentRegionCode)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const getRequest = (fields) => {
    let request
    const params = {
      ...fields,
      level: props.regionLevel
    }

    if (props.modalType === 'create') {
      request = createRegionDict(params)
    } else {
      params.id = props.regionItem.id

      request = updateRegionDict(params)
    }

    return request
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      let request = getRequest(fields)

      request.then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        setLoading(false)
        Modal.error({
          title: '网络错误',
          content: '保存区域数据失败',
          centered: true
        })
      })
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        centered
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }

          >
            <Form.Item
              label={ '父级区域编码' }
              name={ 'parent' }
            >
              <Input disabled></Input>
            </Form.Item>
            <Form.Item
              label={ '区域编码' }
              name={ 'code' }
              rules={ [{ required: true, message: '请输入区域编码' }] }
            >
              <Input placeholder={ '请输入区域编码' }></Input>
            </Form.Item>
            <Form.Item
              label={ '区域名称' }
              name={ 'name' }
              rules={ [{ required: true, message: '请输入区域名称' }] }
            >
              <Input placeholder={ '请输入区域名称' }></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditCityRegionModal
