import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'

import SelectExam from '../../components/select-exam/SelectExam'
import ApplyPublishProcess from './ApplyPublishProcess'
import ExaminationAllocationProcess from './ExaminationAllocationProcess'
import PublicResultProcess from './PublicResultProcess'
import PublicInterviewProcess from './PublicInterviewProcess'
import { getExamProcessTime } from '../../common/request'
import ResponseCode from '../../common/response-code'
import eventBus from '../../plugins/events'

function ExamPublish () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [examStatus, setExamStatus] = useState({})

  const processMap = ['apply', 'examinationRoom', 'publicResult', 'interviewNotice']

  useEffect(() => {
    if (selectedExam.id) {
      getExamProcessTimeAction()
    }
  }, [selectedExam])

  const updateExaminationStatusAction = () => {
    getExamProcessTimeAction()
    eventBus.emit('reloadExamDetail', selectedExam.id)
  }

  const getExamProcessTimeAction = () => {
    getExamProcessTime({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setExamStatus(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误查询考试状态失败',
        centered: true
      })
    })
  }

  const generateProcessItem = (processList) => {
    if (processList?.length > 0) {
      return processMap.filter(key => {
        return processList.includes(key)
      })?.map(key => {
        if (key === 'apply') {
          return <ApplyPublishProcess
            examStatus={ examStatus }
            key={ key }
            updateExamStatus={ updateExaminationStatusAction }
          ></ApplyPublishProcess>
        } else if (key === 'examinationRoom') {
          return <ExaminationAllocationProcess
            examStatus={ examStatus }
            key={ key }
            updateExamStatus={ updateExaminationStatusAction }
          ></ExaminationAllocationProcess>
        } else if (key === 'publicResult') {
          return <PublicResultProcess
            examStatus={ examStatus }
            key={ key }
            updateExamStatus={ updateExaminationStatusAction }
          ></PublicResultProcess>
        } else if (key === 'interviewNotice') {
          return <PublicInterviewProcess
            examStatus={ examStatus }
            key={ key }
            updateExamStatus={ updateExaminationStatusAction }
          ></PublicInterviewProcess>
        } else {
          Modal.error({
            title: '错误',
            content: '流程类型不存在，请检查流程字段配置'
          })
          return <></>
        }
      })
    }
  }

  return (<>
    <SelectExam>
      { generateProcessItem(selectedExam.process) }
    </SelectExam>
  </>)
}

export default ExamPublish
