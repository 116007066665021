import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Modal, Descriptions, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import RuleTree from './RuleTree'
import { FieldTypeMap } from '../../../../common/common'
import { updatePositionsAutoRules } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import dayjs from 'dayjs'

const ConfigCheckRuleModal = forwardRef((props, ref) => {
  const ruleTreeRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [isBatchSetting, setIsBatchSetting] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const areaTypeMap = {
    0: 'province',
    1: 'city',
    2: 'district'
  }

  useEffect(() => {
    if (modalVisible) {
      setIsBatchSetting(props.positionItemList.length > 1)
      if (props.positionItemList.length === 1) {
        generateRuleTreeData()
      }

      setDisabled(props.examInfo.examinationStatus > 0)
    }
  }, [modalVisible])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const generateRuleTreeData = () => {
    ruleTreeRef.current.generateRuleTreeData(props.positionItemList[0])
  }

  const getDescriptions = (positionList) => {
    if (positionList.length === 1) {
      return (
        <Descriptions className="mt-3" column={ 4 } title="">
          <Descriptions.Item label="单位名称">{ props.positionItemList[0].unitName }</Descriptions.Item>
          <Descriptions.Item label="单位代码">{ props.positionItemList[0].unitCode }</Descriptions.Item>
          <Descriptions.Item label="职位名称">{ props.positionItemList[0].positionName }</Descriptions.Item>
          <Descriptions.Item label="职位代码">{ props.positionItemList[0].positionCode }</Descriptions.Item>
        </Descriptions>
      )
    } else {
      return  (<></>)
    }
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    ruleTreeRef.current.resetRules()
  }

  const addRuleTree = () => {
    ruleTreeRef.current.addRule()
  }

  const getRuleSelectContent = (rule) => {
    let selectContent = []

    switch (rule.formData?.ruleItem?.fieldType) {
      case FieldTypeMap.input:
        if (rule.formData?.selectContentName) {
          selectContent = rule.formData?.selectContentName.replaceAll('、', '|').split('|').map(item => {
            return {
              fieldKey: rule.formData?.ruleItem?.fieldCode,
              value: item
            }
          })
        }
        break
      case FieldTypeMap.select:
        if (rule.formData?.selectContentItems) {
          selectContent = rule.formData?.selectContentItems.map(item => {
            return {
              fieldKey: rule.formData?.ruleItem?.fieldCode,
              label: item.dictLabel,
              value: item.dictValue
            }
          })
        }
        break
      case FieldTypeMap.dateRangePicker:
        if (rule.formData?.selectContentName?.length > 0) {
          selectContent = [{
            fieldKey: rule.formData?.ruleItem?.fieldCode,
            value: rule.formData?.selectContentName.map(date => {
              return dayjs(date).format('YYYY-MM-DD').toString()
            }).join(',')
          }]
        }
        break
      case FieldTypeMap.cascader:
        if (rule.formData?.selectContentItems?.length > 0) {
          selectContent = [{
            fieldKey: rule.formData?.ruleItem?.fieldCode,
            value: JSON.stringify(rule.formData?.selectContentItems.map((item, index) => {
              return {
                code: item.value,
                name: item.label,
                type: areaTypeMap[index]
              }
            }))
          }]
        }
        break
      default:
        selectContent = []
    }

    return selectContent
  }

  const transformRuleTreeData = (list) => {
    return list.filter(rule => {
      return rule.formData
    }).map(rule => {
      return {
        fieldCode: rule.formData?.ruleItem?.fieldCode,
        fieldName: rule.formData?.ruleItem?.fieldName,
        fieldType: rule.formData?.ruleItem?.fieldType,
        fieldJoinSelect: rule.formData?.ruleItem?.fieldJoinSelect,
        contentLogicType: rule.formData?.contentLogicType,
        ruleLogicType: rule.formData?.ruleLogicType,
        parent: rule.parent,
        selectContent: getRuleSelectContent(rule),
        childRule: rule.children?.length > 0 ? transformRuleTreeData(rule.children) : []
      }
    })
  }

  const getRuleData = () => {
    const ruleData = ruleTreeRef.current.getRuleTree()

    return {
      positionIdList: props.positionItemList.map(item => {
        return item._id
      }),
      checkRuleList: transformRuleTreeData(ruleData.children)
    }
  }

  const onOK = () => {
    const params = getRuleData()

    updatePositionsAutoRules(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '更新职位自动审核条件失败',
        centered: true
      })
    })
  }

  const getModalTitle = (isBatch) => {
    return isBatch ? '批量配置自动审核规则' : '配置自动审核规则'
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={getModalTitle(isBatchSetting)}
        open={ modalVisible }
        width="900px"
        onOk={ onOK }
        onCancel={ hideModal }
        footer={ disabled ? null : undefined }
      >
        { getDescriptions(props.positionItemList) }

        <div className="d-flex align-items-center justify-content-between">
          <div>报名条件(设置条件后系统将自动驳回不满足的报名表信息)</div>
          {
            !disabled &&
            <Button
              type="primary"
              icon={ <PlusOutlined/> }
              onClick={ addRuleTree }
            >新增规则</Button>
          }
        </div>

        <div className="mt-2">
          <RuleTree ref={ ruleTreeRef } disabled examInfo={ props.examInfo }></RuleTree>
        </div>
      </Modal>
    </div>
  )
})

export default ConfigCheckRuleModal
