import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Select } from 'antd'
import { createAccount, getCreateAccountRoleList, updateAccount } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useSelector } from 'react-redux'

const EditAccountModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [roleOptions, setRoleOptions] = useState([])
  const roleList = useSelector(state => state.role.roleList)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle( '创建账号')
      } else {
        setModalTitle( '编辑账号')
        form.setFieldsValue({
          name: props.authItem.name,
          phone: props.authItem.phone,
          roleId: props.authItem.roleId
        })
      }
      getRoleList()
    }
  }, [modalVisible, props.modalType])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setSaveLoading(false)
    form.resetFields()
  }

  const getRoleList = () => {
    getCreateAccountRoleList().then(res => {
      if (res.code === ResponseCode.success) {
        const availableOptionRoles = res.data.map(role => {
          return role.roleTag
        })

        setRoleOptions(roleList.map(role => {
          return {
            label: role.name,
            value: role.id,
            disabled: !availableOptionRoles.includes(role.roleTag)
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    })
  }

  const onSave = () => {
    form.validateFields().then(fields => {
      setSaveLoading(true)
      if (props.modalType === 'create') {
        createAccountAction(fields)
      } else {
        updateAccountAction(fields)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const createAccountAction = (fields) => {
    const params = {
      ...fields
    }

    createAccount(params).then(res => {
      setSaveLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notifyApi.success({
          message: '操作成功',
          description: '已创建账号'
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setSaveLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建账号失败',
        centered: true
      })
    })
  }

  const updateAccountAction = (fields) => {
    const params = {
      ...fields,
      accountId: props.authItem.accountId
    }

    updateAccount(params).then(res => {
      setSaveLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notifyApi.success({
          message: '操作成功'
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setSaveLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新账号失败',
        centered: true
      })
    })
  }

  return (
    <div ref={ref}>
      { contextHolder }

      <Modal
        width="480px"
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ saveLoading }
        onOk={ onSave }
        onCancel={ hideModal }
      >
        <Form
          className="mt-3"
          form={ form }
          labelCol={ {
            span: 8
          } }
          wrapperCol={ {
            span: 12
          } }
        >
          <Form.Item
            label="手机号"
            name="phone"
            rules={ [ { required: true, message: '手机号不能为空' } ] }
          >
            <Input disabled={ props.modalType === 'edit' } placeholder="请输入账户名"></Input>
          </Form.Item>
          <Form.Item
            label="账户名"
            name="name"
            rules={ [ { required: true, message: '账户名不能为空' } ] }
          >
            <Input placeholder="请输入账户名"></Input>
          </Form.Item>
          <Form.Item
            label="角色"
            name="roleId"
            rules={ [ { required: true, message: '请选择账户角色' } ] }
          >
            <Select options={ roleOptions } placeholder="请选择账户角色"></Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
})

export default EditAccountModal
