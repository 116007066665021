import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Space, Table } from 'antd'
import { deleteReceivingAccount, getReceivingAccountList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { PlusOutlined } from '@ant-design/icons'
import EditReceivingAccountModal from './EditReceivingAccountModal'
import { accountTypeMap } from '../../common/common'
import AppButton from '../../components/AppButton'

function ReceivingAccountManagement () {
  const editReceivingAccountModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentEditItem, setCurrentEditItem] = useState()
  const [modalType, setModalType] = useState('')

  useEffect(() => {
    getTableData()
  }, [])

  const columns = [
    {
      title: '账户名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '户号',
      dataIndex: 'accountNumber',
      key: 'accountNumber'
    },
    {
      title: '收款单位',
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: '账户类型',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (record) => {
        return accountTypeMap[record]
      }
    },
    {
      width: 120,
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (<>
          <Space className={ 'd-flex align-items-center justify-content-end' }>
            <AppButton
              size={ 'small' }
              authCode={ 'button_skzhgl-bj' }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showEditReceivingAccountModal) }
            >编辑</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ 'button_skzhgl-sc' }
              type={ 'primary' }
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteReceivingAccountModal) }
            >删除</AppButton>
          </Space>
        </>)
      }
    }
  ]

  const getTableData = () => {
    const params = {
      orderBy: 'id',
      pageNum: pageNum,
      pageSize: pageSize
    }

    getReceivingAccountList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询收款账户列表失败',
        centered: true
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateReceivingAccountModal = () => {
    setModalType('create')
    editReceivingAccountModalRef.current.showModal()
  }

  const showEditReceivingAccountModal = () => {
    setModalType('edit')
    editReceivingAccountModalRef.current.showModal()
  }

  const showDeleteReceivingAccountModal = (row) => {
    Modal.confirm({
      title: '删除收款账户',
      content: `确定要删除收款账户【${ row.name }】吗？`,
      onOk: () => {
        deleteReceivingAccountAction(row)
      }
    })
  }

  const deleteReceivingAccountAction = (row) => {
    deleteReceivingAccount({ accountId: row.accountId }).then(res => {
      if (res.code === ResponseCode.success) {
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除收款账户失败',
        centered: true
      })
    })
  }

  return (
    <div>
      <div className="mb-3 d-flex align-items-center justify-content-end">
        <AppButton
          authCode={ 'button_skzhgl-xzskzh' }
          type={ 'primary' }
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateReceivingAccountModal) }
        >新增收款账户</AppButton>
      </div>

      <Table
        columns={ columns }
        dataSource={ tableData.elements }
        rowKey={ (record) => record.accountId }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }>
      </Table>

      <EditReceivingAccountModal
        ref={ editReceivingAccountModalRef }
        accountItem={ currentEditItem }
        modalType={ modalType }
        insertSuccess={ getTableData }
      ></EditReceivingAccountModal>
    </div>
  )
}

export default ReceivingAccountManagement
