import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, Table, Tag } from 'antd'

import { getOrgOperationAccountList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import EditOperationAccountModal from './EditOperationAccountModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'
import AppButton from '../../../components/AppButton'

function ConfigOperationPersonnel (props) {
  const editModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.modalVisible && props.orgItem.id) {
      getTableData()
    }
  }, [props.orgItem, props.modalVisible])

  const columns = [
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '账户名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
      render: (roleName) => {
        return (<>
          <Tag color="blue">{ roleName }</Tag>
        </>)
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    const params = {
      id: props.orgItem.id
    }

    getOrgOperationAccountList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询运维人员列表失败',
        centered: true
      })
    })
  }

  const showEditModal = () => {
    editModalRef.current.showModal()
  }

  return (
    <div>
      <ConfigProvider componentSize="small">
        <div className="mb-2 d-flex justify-content-end">
          <AppButton authCode={ 'button_jgxq-gly-pzgly' } type="primary" onClick={ showEditModal }>配置管理员</AppButton>
        </div>

        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData }
          rowKey={ (record) => {
            return record.accountId
          } }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <EditOperationAccountModal
        ref={ editModalRef }
        orgItem={ props.orgItem }
        accountList={ tableData }
        insertSuccess={ getTableData }
      ></EditOperationAccountModal>
    </div>
  )
}

export default ConfigOperationPersonnel
