import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Modal } from 'antd'
import ReportCard from '../../components/examinee/ReportCard'

const ImportExamScoreModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [examineeItem, setExamineeItem] = useState({})

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (examineeItemInfo) => {
    setExamineeItem(examineeItemInfo)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const transformCandidateScore = (data) => {
    return Object.keys(data).filter(key => {
      return !['_id', 'examId'].includes(key)
    }).map(key => {
      return {
        label: key,
        value: data[key]
      }
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 1020 }
        title={ '考生成绩' }
        open={ modalVisible }
        footer={ [
          <Button
            key={ 'close' }
            type={ 'primary' }
            onClick={ hideModal }
          >关闭</Button>
        ] }
        onCancel={ hideModal }
      >
        <ReportCard
          candidateScore={ transformCandidateScore(examineeItem) }
        ></ReportCard>
      </Modal>
    </div>
  )
})

export default ImportExamScoreModal
