import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Table, notification, ConfigProvider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { deleteOrgWorker, getWorkerList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import CreateOrgWorkerModal from './CreateOrgWorkerModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'
import AppButton from '../../../components/AppButton'

function ConfigWorker (props) {
  const createOrgWorkerModalRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.modalVisible && props.orgItem.id) {
      getTableData()
    }
  }, [props.orgItem, props.modalVisible])

  const columns = [
    {
      title: '工作人员',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <div className="d-flex justify-content-end">
            <AppButton
              authCode={ 'button_jgxq-gzry-bj' }
              type="primary"
              danger
              ghost
              onClick={ openModal.bind(this, row, showDeleteWorkerModal) }
            >删除</AppButton>
          </div>
        </>)
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)

    const params = {
      id: props.orgItem.id
    }

    getWorkerList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建工作人员失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    fn && fn(row)
  }

  const showCreateWorkerModal = () => {
    createOrgWorkerModalRef.current.showModal()
  }

  const showDeleteWorkerModal = (row) => {
    Modal.confirm({
      title: '删除工作人员',
      centered: true,
      content: (
        <div>确定要删除工作人员【{ row.name }】吗？</div>
      ),
      onOk () {
        deleteOrgWorkerAction(row)
      }
    })
  }

  const deleteOrgWorkerAction = (row) => {
    const params = {
      organizationId: props.orgItem.id,
      userIds: [row.accountId]
    }

    deleteOrgWorker(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除失败',
        centered: true
      })
    })
  }

  return (
    <div>
      { contextHolder }

      <ConfigProvider componentSize="small">
        <ComponentVisibleByAuth authCode={ 'button_jgxq-gzry-bj' }>
          <div className="mb-2 d-flex justify-content-end">
            <Button
              type="primary"
              icon={ <PlusOutlined/> }
              onClick={ openModal.bind(this, {}, showCreateWorkerModal) }
            >新增工作人员</Button>
          </div>
        </ComponentVisibleByAuth>
        <Table
          loading={ loading }
          columns={ columns }
          style={ { minHeight: '230px' } }
          dataSource={ tableData }
          rowKey={ (record) => {
            return record.accountId
          } }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <CreateOrgWorkerModal
        ref={ createOrgWorkerModalRef }
        orgItem={ props.orgItem }
        insertSuccess={ getTableData }
      ></CreateOrgWorkerModal>
    </div>
  )
}

export default ConfigWorker
