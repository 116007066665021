import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Modal, notification, Spin, Table } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { insertExamPosition } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={ {
      cursor: 'grab',
      color: '#999'
    } }
  />
))

const SortableItem = SortableElement((props) => <tr { ...props } />)
const SortableBody = SortableContainer((props) => <tbody { ...props } />)

const FieldSortModal = forwardRef((props, ref) => {
  const [fieldList, setFieldList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle/>
    },
    {
      title: '字段名',
      key: 'fieldName',
      dataIndex: 'fieldName'
    },
    {
      title: '字段编码',
      key: 'fieldCode',
      dataIndex: 'fieldCode'
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setFieldList(props.fieldList.filter(item => {
        return item.fieldType !== '11'
      }).map((item, index) => {
        return {
          ...item,
          index
        }
      }))
    }
  }, [modalVisible])

  const setActiveTableData = (data) => {
    return setFieldList(data)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(fieldList.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )

      setActiveTableData(newData)
    }
  }

  const DraggableContainer = (props) => {
    return <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  }

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = fieldList.findIndex((x) => x.index === restProps['data-row-key'])
    return <SortableItem index={ index } { ...restProps } />
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    const params = {
      examId: props.examInfo.id,
      positionTemplate: {
        indexTemplateSet: fieldList.map((item, index) => {
          return {
            ...item,
            fieldSort: index
          }
        })
      }
    }

    setLoading(true)
    insertExamPosition(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '设置考试职位字段失败',
        centered: true
      })
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 700 }
        title={ '字段排序' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
        maskClosable={ false }
      >
        <Spin spinning={ loading }>
          <ConfigProvider componentSize={ 'small' }>
            <Table
              columns={ columns }
              dataSource={ fieldList }
              rowKey="index"
              pagination={ false }
              scroll={ {
                y: 500
              } }
              components={ {
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow
                }
              } }
            ></Table>
          </ConfigProvider>
        </Spin>
      </Modal>
    </div>
  )
})

export default FieldSortModal
