import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, Button } from 'antd'

const ViewParamsModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const dataFormatter = (json) => {
    let formatted = ''
    let pad = 0
    let PADDING = '  ' // （缩进）可以使用'\t'或不同数量的空格
    if (typeof json !== 'string') {
      json = JSON.stringify(json)
    } else {
      json = JSON.parse(json)
      json = JSON.stringify(json)
    }

    json = json.replace(/([\{\}])/g, '\r\n$1\r\n')// 在花括号前后添加换行
    .replace(/([\[\]])/g, '\r\n$1\r\n')// 在方括号前后添加新行
    .replace(/(\,)/g, '$1\r\n')// 在逗号后添加新行
    .replace(/(\r\n\r\n)/g, '\r\n')// 删除多个换行
    .replace(/\r\n\,/g, ',')// 删除逗号前的换行
    .replace('\\"', '"')
    .replace(/\:\r\n\{/g, ':{')
    .replace(/\:\r\n\[/g, ':[')
    .replace(/\:/g, ': ')
    json.split('\r\n').forEach((node, index) => {
      let i = 0,
        indent = 0,
        padding = ''
      if (node.match(/\{$/) || node.match(/\[$/)) {
        indent = 1
      } else if (node.match(/\}/) || node.match(/\]/)) {
        if (pad !== 0) {
          pad -= 1
        }
      } else {
        indent = 0
      }
      for (i = 0; i < pad; i++) {
        padding += PADDING
      }
      formatted += padding + node + '\r\n'
      pad += indent
    })
    return formatted
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '操作参数' }
        width={ 1000 }
        open={ modalVisible }
        onCancel={ hideModal }
        footer={ [
          <Button
            key={ 'close' }
            type={ 'primary' }
            onClick={ hideModal }
          >关闭</Button>
        ] }
      >
        <pre style={ { minHeight: '100px', wordBreak: 'break-all' } }>
          { props.params && dataFormatter(props.params) }
        </pre>
      </Modal>

    </div>
  )
})

export default ViewParamsModal
