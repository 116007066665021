import { useSelector } from 'react-redux'
import { difference } from 'lodash'
import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react'
import { Modal, notification, Transfer, Tag, Table } from 'antd'

import { editRoleAuth } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { authTypeMap } from '../../common/common'

const EditAuthRoleModal = forwardRef((props, ref) => {
  const authList = useSelector(state => state.authList.authList)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedAuth, setSelectedAuth] = useState([])
  const [insertRoleAuthLoading, setInsertRoleAuthLoading] = useState(false)


  useEffect(() => {

  }, [props.roleItem])

  useEffect(() => {
    if (modalVisible) {
      setSelectedAuth(authList.filter((auth) => {
        return props.roleItem.authoritySet.some(item => {
          return item.id === auth.id
        })
      }).map(item => item.id))
    }
  }, [modalVisible, props.roleItem])

  useImperativeHandle(ref, () => {
    return {
      showModal: showEditRoleAuthModal
    }
  })

  const showEditRoleAuthModal = () => {
    setModalVisible(true)
  }

  const hideEditRoleAuthModal = () => {
    setModalVisible(false)
  }

  const updateRoleAuthAction = () => {
    const params = {
      roleId: props.roleItem.id,
      authorityIdList: selectedAuth
    }

    editRoleAuth(params).then(res => {
      setInsertRoleAuthLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditRoleAuthModal()
        notifyApi.success({
          message: '操作成功',
          description: `更新角色权限成功`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setInsertRoleAuthLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新角色权限失败',
        centered: true
      })
    })
  }

  const transferTableColumns = [
    {
      title: '权限名称',
      dataIndex: 'name'
    },
    {
      title: '权限类型',
      dataIndex: 'type',
      render: (type, row) => {
        return authTypeMap.filter(item => item.value === type).map((item, index) => {
          return <Tag color="blue" color={item.color} key={`${row.name}_${index}`}>{ item.label }</Tag>
        })
      }
    }
  ]

  const onChange = (nextTargetKeys) => {
    setSelectedAuth(nextTargetKeys)
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title="配置角色权限"
        width="800px"
        okText="保存"
        cancelText="取消"
        open={ modalVisible }
        confirmLoading={ insertRoleAuthLoading }
        onOk={ updateRoleAuthAction }
        onCancel={ hideEditRoleAuthModal }
      >
        <div className="mt-3">
          <Transfer
            titles={['权限列表', '已选权限']}
            dataSource={ authList }
            targetKeys={ selectedAuth }
            rowKey={ (record) => {
              return record.id
            } }
            showSearch={ true }
            onChange={ onChange }
            filterOption={ (inputValue, item) =>
              item.name.indexOf(inputValue) !== -1
            }
          >
            { ({
                 direction,
                 filteredItems,
                 onItemSelect,
                 onItemSelectAll,
                 selectedKeys: listSelectedKeys,
                 disabled: listDisabled
               }) => {
              const rowSelection = {
                getCheckboxProps: (item) => ({
                  disabled: listDisabled || item.disabled,
                }),
                onSelectAll (selected, selectedRows) {
                  const treeSelectedKeys = selectedRows
                    .filter((item) => !item.disabled)
                    .map(({ key }) => key)
                  const diffKeys = selected
                    ? difference(treeSelectedKeys, listSelectedKeys)
                    : difference(listSelectedKeys, treeSelectedKeys)
                  onItemSelectAll(diffKeys, selected)
                },
                onSelect ({ key }, selected) {
                  onItemSelect(key, selected)
                },
                selectedRowKeys: listSelectedKeys
              }
              return <Table
                size="small"
                scroll={{
                  y: 400
                }}
                rowKey={ (record) => {
                  return record.id
                } }
                style={{ minHeight: '260px', pointerEvents: listDisabled ? 'none': undefined }}
                pagination={ false }
                dataSource={ filteredItems }
                rowSelection={ rowSelection }
                columns={ transferTableColumns }
                onRow={ ({ key, disabled: itemDisabled }) => ({
                  onClick: () => {
                    if (itemDisabled || listDisabled) return
                    onItemSelect(key, !listSelectedKeys.includes(key))
                  }
                }) }
              ></Table>
            } }
          </Transfer>
        </div>
      </Modal>
    </div>
  )
})

export default EditAuthRoleModal
