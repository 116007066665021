import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification } from 'antd'
import { updateExamineeCheckStatus } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useSelector } from 'react-redux'

const RefuseCheckModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const userInfo = useSelector(state => state.userInfo.userInfo)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      const params = {
        ...fields,
        applyInfoId: props.examineeItem.applyInfoId || props.examineeItem.id,
        auditStatus: -1,
        optLock: props.applyInfo.optLock
      }

      updateExamineeCheckStatus(params, userInfo.currentRoleTag).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，修改审核状态失败'
        })
      })

    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '提示' }
        centered={ true }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Form
          form={ form }
        >
          <Form.Item
            label={ '拒绝原因' }
            name={ 'noApprovedReason' }
            rules={ [{ required: true }] }
          >
            <Input.TextArea maxLength={ 200 } showCount></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
})

export default RefuseCheckModal
