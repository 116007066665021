import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'antd'

import QrcodeWrapImg from '../assets/images/qrcode-wrap.png'

const GenerateQrcodeModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [qrcodeImg, setQrcodeImg] = useState('')

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (data) => {
    const img = new Image()
    img.src = data
    img.onload = () => {
      setQrcodeImg(img)
      setModalVisible(true)
    }
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const AppQrCode = ({ size = 100, bgImg, icon }) => {
    const canvasRef = useRef(null)
    const [canvas, setCanvas] = useState(null)

    useEffect(() => {
      if (canvasRef) {
        setCanvas(canvasRef.current)

        generateQrcode(canvasRef.current, canvasRef.current.getContext('2d'))
      }
    }, [])

    const generateQrcode = (canvas, ctx) => {
      if (bgImg) {
        const wrapImg = new Image()
        wrapImg.src = bgImg
        wrapImg.onload = () => {
          ctx.drawImage(wrapImg, 0, 0, size + 20, size + 50)
          ctx.drawImage(qrcodeImg, 15, 15, size - 10, size - 10)
        }
      } else {
        ctx.fillStyle = '#ffffff'
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        ctx.drawImage(qrcodeImg, 10, 10, size, size)
      }

      // if (icon) {
      //   const iconImg = new Image()
      //   iconImg.src = LogoImg
      //   iconImg.onload = () => {
      //     ctx.drawImage(iconImg, (size + 20) / 2, (size + 20) / 2, 30, 30)
      //   }
      // }
    }

    const downloadImg = () => {
      const url = canvas.toDataURL()
      const a = document.createElement('a')
      a.download = `报名端二维码_${ size }.png`
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    return (<div className={ 'd-flex align-items-center flex-column' }>
      <div
        className={ 'p-2' }
        style={ {
          border: '1px solid #ddd',
          borderRadius: 6,
          background: '#f6f5f5'
        } }
      >
        <canvas
          ref={ canvasRef }
          width={ size + 20 }
          height={ bgImg ? size + 50 : size + 20 }
          style={ {
            width: size + 20,
            height: bgImg ? size + 50 : size + 20
          } }
        ></canvas>
      </div>
      <Button
        className={ 'mt-3' }
        type={ 'primary' }
        onClick={ downloadImg }
      >下载</Button>
    </div>)
  }

  return (<Modal
    width={ 800 }
    title={ '二维码物料下载' }
    open={ modalVisible }
    onCancel={ hideModal }
    footer={ [
      <Button
        key={ 'close' }
        type={ 'primary' }
        onClick={ hideModal }
      >关闭</Button>
    ] }
  >
    <Row gutter={ 20 }>
      <Col
        className={ 'd-flex align-items-flex-end justify-content-center' }
        span={ 8 }
      >
        <AppQrCode size={ 150 }></AppQrCode>
      </Col>
      <Col
        className={ 'd-flex align-items-flex-end justify-content-center' }
        span={ 8 }
      >
        <AppQrCode
          size={ 150 }
          bgImg={ QrcodeWrapImg }
        ></AppQrCode>
      </Col>
    </Row>
  </Modal>)
})

export default GenerateQrcodeModal
