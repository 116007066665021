import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Modal, notification, Space, Spin, Descriptions, Row, Col, Image } from 'antd'

import PoorExamineeCheckNoPassModal from './PoorExamineeCheckNoPassModal'
import { auditPoorApply, findApplyDetail } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useSelector } from 'react-redux'

const ExamineeInfoModal = forwardRef((props, ref) => {
  const poorExamineeCheckNoPassModalRef = useRef()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [examineeCheckInfo, setExamineeCheckInfo] = useState({})
  const envInfo = useSelector((state) => state.env.envInfo)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'view') {
        setModalTitle('免收费考生信息')
      } else {
        setModalTitle('免收费考生资格审核信息')
      }

      getPoorExamineeDetail()
    }
  }, [modalVisible])

  useEffect(() => {

  }, [])

  const getPoorExamineeDetail = () => {
    findApplyDetail({ id: props.examineeItem.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setExamineeCheckInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，获取考生审核信息失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const checkControlBarVisible = (visible) => {
    if (visible) {
      return (<>
        <div className={ 'd-flex align-items-center justify-content-center' }>
          <Space size={ 48 }>
            <Button
              type={ 'primary' }
              ghost
              danger
              onClick={ showNoPassModal }
            >不通过</Button>
            <Button
              type={ 'primary' }
              loading={ loading }
              onClick={ checkPass }
            >通过</Button>
          </Space>
        </div>
      </>)
    } else {
      return <></>
    }
  }

  const showNoPassModal = () => {
    poorExamineeCheckNoPassModalRef.current.showModal()
  }

  const checkPass = () => {
    Modal.confirm({
      title: '贫困生审核',
      content: '确认贫困生审核通过吗？',
      onOk: () => {
        setLoading(true)
        const params = {
          id: props.examineeItem.id,
          status: 2
        }

        auditPoorApply(params).then(res => {
          setLoading(false)
          if (res.code === ResponseCode.success) {
            notification.success({
              message: '操作成功'
            })
            insertSuccess()
          } else {
            Modal.error({
              title: '错误',
              content: res.msg
            })
          }
        }).catch(err => {
          console.error(err)
          setLoading(false)
          Modal.error({
            title: '错误',
            content: '网络错误，提交审核结果失败'
          })
        })
      }
    })
  }

  const insertSuccess = () => {
    props.insertSuccess?.()
    hideModal()
  }

  const updateLoadingStatus = (status) => {
    setLoading(status)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 1000 }
        title={ modalTitle }
        open={ modalVisible }
        footer={ [] }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Descriptions>
            <Descriptions.Item label={ '姓名' }>{ examineeCheckInfo.name }</Descriptions.Item>
            <Descriptions.Item label={ '身份证号' }>{ examineeCheckInfo.idCard }</Descriptions.Item>
            <Descriptions.Item label={ '退费金额' }>{ examineeCheckInfo.refundAmount }</Descriptions.Item>
          </Descriptions>

          <Row gutter={ 20 }>
            <Col span={ 12 }>
              <Descriptions>
                <Descriptions.Item label={ '身份证正面' }>
                  <Image src={ `${ envInfo.picDomain }/${ examineeCheckInfo.idCardFront?.filePath }` }></Image>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={ 12 }>
              <Descriptions>
                <Descriptions.Item label={ '身份证反面' }>
                  <Image src={ `${ envInfo.picDomain }/${ examineeCheckInfo.idCardReverse?.filePath }` }></Image>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Descriptions>
            <Descriptions.Item label={ '证明材料' }>
              <div>
                <Row>

                  {
                    examineeCheckInfo.evidenceList?.map(fileItem => {
                      return <Col span={ 8 } key={ fileItem.filePath }>
                        <Image src={ `${ envInfo.picDomain }/${ fileItem.filePath }` }></Image>
                      </Col>
                    })
                  }
                </Row>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Spin>

        { checkControlBarVisible(props.modalType === 'check') }
      </Modal>

      <PoorExamineeCheckNoPassModal
        ref={ poorExamineeCheckNoPassModalRef }
        examineeItem={ props.examineeItem }
        insertSuccess={ insertSuccess }
        updateLoadingStatus={ updateLoadingStatus }
      ></PoorExamineeCheckNoPassModal>
    </div>
  )
})

export default ExamineeInfoModal
