import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { DatePicker, Form, Input, Modal, notification, Radio, Select, Spin, TimePicker } from 'antd'
import dayjs from 'dayjs'

import { sectionToChinese } from '../../../plugins/utils'
import { createExaminationBatch } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { examBatchMap } from '../../../common/common'

const EditExamBatchModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showExamTimeForm, setShowExamTimeForm] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [batchTypeOptions, setBatchTypeOptions] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldValue('name', `第${ sectionToChinese(props.batchList.length + 1) }批次`)

      setBatchTypeOptions(Object.keys(examBatchMap).map(key => {
        return {
          label: examBatchMap[key],
          value: Number(key)
        }
      }))
    }
  }, [modalVisible, props.batchList])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setShowExamTimeForm(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      const params = {
        ...fields,
        examId: props.examInfo.id,
        batchNumber: props.batchList.length + 1
      }

      if (fields.examDate && fields.examTimeRange?.length > 0) {
        params.startTime = dayjs(fields.examTimeRange[0]).valueOf()
        params.endTime = dayjs(fields.examTimeRange[1]).valueOf()
        params.examDate = dayjs(fields.examDate).valueOf()
      }

      delete params.examTimeRange

      createExaminationBatch(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          hideModal()
          notifyApi.success({
            message: '操作成功'
          })
          props.insertSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，创建考试批次失败'
        })
      })
    })
  }

  const replaceExamTimeChange = (e) => {
    setShowExamTimeForm(e.target.value)
  }

  const examTimeFormVisible = (visible) => {
    if (visible) {
      return (<>
        <Form.Item
          label={ '考试日期' }
          name={ 'examDate' }
          rules={[{required: true}]}
        >
          <DatePicker format="YYYY-MM-DD"></DatePicker>
        </Form.Item>
        <Form.Item
          label={ '考试日期' }
          name={ 'examTimeRange' }
          rules={[{required: true}]}
        >
          <TimePicker.RangePicker format="HH:mm"></TimePicker.RangePicker>
        </Form.Item>
      </>)
    } else {
      return <></>
    }
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        width={700}
        title={ '新建考试批次' }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            className={ 'my-4' }
            form={ form }
            labelCol={ { span: 8 } }
            wrapperCol={ { span: 12 } }
            initialValues={ {
              batchType: 1,
              isReplaceExamDate: 0,
              examTimeRange: []
            } }
          >
            <Form.Item
              label={ '考试批次' }
              name={ 'name' }
              rules={ [{ required: true }] }
            >
              <Input disabled></Input>
            </Form.Item>
            <Form.Item
              label={ '类型' }
              name={ 'batchType' }
              rules={ [{ required: true }] }
            >
              <Select options={ batchTypeOptions }></Select>
            </Form.Item>
            <Form.Item
              label={ '是否替换科目考试时间' }
              name={ 'isReplaceExamDate' }
              rules={ [{ required: true }] }
            >
              <Radio.Group onChange={replaceExamTimeChange}>
                <Radio value={0}>否</Radio>
                <Radio value={1}>是</Radio>
              </Radio.Group>
            </Form.Item>
            { examTimeFormVisible(showExamTimeForm) }
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamBatchModal
