import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import { Modal, Form, Input, notification, Spin } from 'antd'

import ResponseCode from '../../../common/response-code'
import { createApplySite, updateApplySite } from '../../../common/request'
import RegionCascader from '../../../components/RegionCascader'

const EditOrgApplySiteModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('新建报名点')
      } else {
        setModalTitle('编辑报名点')
        form.setFieldsValue({
          name: props.applySiteItem.name,
          applySiteCode: props.applySiteItem.applySiteCode,
          address: props.applySiteItem.address,
          regionIds: props.applySiteItem.regionIds,
          sortCode: props.applySiteItem.sortCode
        })
      }
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const getRequest = (fields) => {
    let request
    let params = {
      ...fields,
      orgId: props.orgItem.id
    }

    if (props.modalType === 'create') {
      request = createApplySite(params)
    } else {
      params.id = props.applySiteItem.id
      request = updateApplySite(params)
    }

    return request
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      getRequest(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        setLoading(false)
        Modal.error({
          title: '网络错误',
          content: '保存报名点失败',
          centered: true
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const onCancel = () => {
    hideModal()
  }

  const applySiteCodeChange = (e) => {
    const regx = /\D/g
    form.setFieldValue('applySiteCode', e.target.value.replace(regx, ''))
    form.setFieldValue('sortCode', Number(e.target.value.replace(regx, '')))
  }

  const selectedRegionChange = (key, items) => {
    form.setFieldValue('address', items.map(region => region.label).join(''))
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        centered
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ onCancel }
      >
        <Spin spinning={ loading }>
          <div className="mt-3">
            <Form
              form={ form }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              initialValues={ {
                name: ''
              } }
            >
              <Form.Item
                label="报名点名称"
                name="name"
                rules={ [{ required: true }] }
              >
                <Input placeholder="请输入报名点名称"></Input>
              </Form.Item>
              <Form.Item
                label="报名点代码"
                name="applySiteCode"
                required
                rules={ [{ required: true }] }
              >
                <Input
                  placeholder="请输入报名点代码"
                  style={{ width: '100%' }}
                  onChange={ applySiteCodeChange }
                ></Input>
              </Form.Item>
              <Form.Item
                label="报名点区域"
                name="regionIds"
                rules={ [{ required: true }] }
              >
                <RegionCascader
                  placeholder={ '请选择区域' }
                  onChange={ selectedRegionChange }
                ></RegionCascader>
              </Form.Item>
              <Form.Item name="address" hidden>
                <Input></Input>
              </Form.Item>
              <Form.Item name="sortCode" hidden>
                <Input></Input>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditOrgApplySiteModal
