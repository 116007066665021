import React, { useEffect, useState } from 'react'
import { Cascader, Modal } from 'antd'
import { useSelector } from 'react-redux'

import { getRegionTree } from '../common/request'
import ResponseCode from '../common/response-code'

const dataTransform = (data) => {
  return data?.map(item => {
    return {
      label: item.name,
      value: item.code,
      isLeaf: item.level === 'district' || !item.childList,
      children: !(item.level === 'district' || !item.childList)
        ? dataTransform(item.childList) : []
    }
  })
}

function RegionCascader (props) {
  const regionList = useSelector(state => state.regionList.regionList)
  const loadedRegion = useSelector(state => state.regionList.loadedRegion)
  const [regionOptions, setRegionOptions] = useState(regionList.map(item => {
    return {
      label: item.name || item.label,
      value: item.code || item.value,
      id: item.id,
      isLeaf: false
    }
  }))

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      initRegionOptions(props.value)
    }
  }, [props.value])

  const handleRequest = (params, fn) => {
    getRegionTree(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        fn?.(res)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const initRegionOptions = (regionIds) => {
    if (loadedRegion.includes(regionIds[0])) {
      return
    }

    const params = { parent: regionIds[0] }

    handleRequest(params, (res) => {
      const regionData = dataTransform(res.data)

      regionOptions.forEach(province => {
        if (province.value === regionIds[0]) {
          province.children = regionData
        }
      })

      setRegionOptions([...regionOptions])
    })
  }

  const getRegionTreeAction = (targetOption) => {
    const params = { parent: targetOption.value }
    handleRequest(params, (res) => {
      const regionData = dataTransform(res.data)
      targetOption.loading = false
      targetOption.children = regionData

      setRegionOptions([...regionOptions])
    })
  }

  const loadRegionData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.children) {
      targetOption.loading = false
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  return (
    <>
      <Cascader
        { ...props }
        changeOnSelect
        options={ regionOptions }
        loadData={ loadRegionData }
      ></Cascader>
    </>
  )
}

export default RegionCascader
