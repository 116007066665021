import {
  LoadingOutlined,
  UserSwitchOutlined,
  LockOutlined,
  PoweroffOutlined
} from '@ant-design/icons'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Breadcrumb, Layout, Menu, theme, Space, Modal } from 'antd'
import { Outlet, useParams, useLocation } from 'react-router-dom'
import './Home.scss.css'
import Logo from '../../assets/images/logo.png'
import router, { homeRouters } from '../../routers/router'
import ResponseCode from '../../common/response-code'
import NetworkError from '../network-error/NetworkError'
import { updateAuthList } from '../../store/slice/auth-list-slice'
import { updateRoleList, updateRoleTagMap } from '../../store/slice/role-slice'
import { updateUserAuth, updateUserInfo } from '../../store/slice/user-info-slice'
import { updateRegionList } from '../../store/slice/region-list-slice'
import {
  getAccountDetail,
  getAuthList, getOrgList,
  getRegionList,
  getRoleList,
  getRoleTagList
} from '../../common/request'
import ToggleAccountModal from './ToggleAccountModal'
import { updateExamList, updateSelectedExam } from '../../store/slice/exam-info-slice'
import EditUserPasswordModal from './EditUserPasswordModal'
import { updateOrgList } from '../../store/slice/org-slice'
import AppLoading from '../../components/AppLoading'
import UpdateInitPasswordModal from '../../components/UpdateInitPasswordModal'
import { USER_PASSWORD_IS_INITIAL } from '../../common/common'
import { menuItems } from './menuItems'
const { Header, Sider, Content } = Layout

const Home = () => {
  const updateInitPasswordModalRef = useRef(null)
  const editUserPasswordModalRef = useRef(null)
  const toggleAccountModalRef = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const routerParams = useParams()
  const baseBreadcrumbItem = { title: '首页' }
  const { token: { colorBgContainer } } = theme.useToken()
  const [collapsed, setCollapsed] = useState(false)
  const [breadcrumbItems, setBreadcrumbItems] = useState([])
  const [initDataLoading, setInitDataLoading] = useState(true)
  const [content, setContent] = useState(<AppLoading/>)
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const userAuth = useSelector(state => state.userInfo.userAuth)
  const envInfo = useSelector(state => state.env.envInfo)
  const [currentAccount, setCurrentAccount] = useState('')
  const [accountList, setAccountList] = useState([])
  const [currentURLKey, setCurrentURLKey] = useState(window.location.pathname.split('exam/')[1])
  let timeout = null
  let timer = 0
  const cycleTime = 1000

  const defaultActiveKeys = [
    'exam-management',
    'examinee-management',
    'exam-check',
    'examination-room-allocation',
    'poor-examinee-management',
    'examinee-management-root',
    'basic',
    'system-auth-management',
    'log-record'
  ]

  const getBreadcrumbItems = () => {
    const currentRouterBreadcrumb = homeRouters.find(router => {
      return location.pathname === `/${ routerParams.siteId }/exam/${ router.path }`
    }).breadcrumb.map(key => {
      return { title: key }
    })

    return [baseBreadcrumbItem, ...currentRouterBreadcrumb]
  }

  useEffect(() => {
    if (userInfo.accountList?.length > 0) {
      setAccountList(userInfo.accountList.map(account => {
        return {
          ...account,
          value: account.accountId,
          label: `${ account.name }(${ account.roleName })`
        }
      }))
      setCurrentAccount(userInfo.accountList.find(account => {
        return account.accountId === userInfo.currentAccountId
      }))
    }

    if (userInfo.accountStatus === USER_PASSWORD_IS_INITIAL) {
      showUpdateInitPasswordModal()
    }
  }, [userInfo])

  useEffect(() => {
    if (initDataLoading) {
      getInitData()
    }
    timeLoop()
    window.addEventListener('mousemove', () => {
      timer = 0
    })

    router.subscribe((e) => {
      setCurrentURLKey(e.location.pathname.split('exam/')[1])
    })

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('mousemove', () => {})
    }
  }, [])

  useEffect(() => {
    if (!initDataLoading) {
      if (userInfo.currentRoleTag === 'superAdmin' || userAuth.filter(item => item.type === 'page').find(item => {
        return item.path === currentURLKey
      })) {
        setContent(<Outlet/>)
      } else {
        setContent(<NetworkError error={ { code: 500, msg: '暂无页面权限，请可联系管理员开通' } }/>)
      }
    }
  }, [currentURLKey, initDataLoading])

  useEffect(() => {
    setBreadcrumbItems(getBreadcrumbItems())
  }, [location])

  const timeLoop = () => {
    if (timer >= 15 * 60 * 1000) {
      sessionStorage.setItem('access_token', '')
      clearTimeout(timeout)
      Modal.warning({
        title: '提示',
        content: '尊敬的用户，由于您长时间未进行操作，系统已检测到您的账号处于非活动状态。出于安全考虑，您的登录已超时失效。烦请您重新登录。感谢您的理解与支持！',
        okText: '重新登录',
        onOk: () => {
          logout()
        },
        onCancel: () => {
          logout()
        }
      })
      return
    }

    timeout = setTimeout(() => {
      timer = timer + cycleTime
      timeLoop()
    }, cycleTime)
  }

  const showUpdateInitPasswordModal = () => {
    updateInitPasswordModalRef.current.showModal()
  }

  const getInitData = () => {
    Promise.all([
      getAccountDetail(),
      getRoleList(),
      getRoleTagList(),
      getAuthList(),
      getRegionList({ parent: '100000' }),
      getOrgList()
    ]).then(([
               accountDetailRes,
               roleListRes,
               roleTagMapRes,
               authListRes,
               regionListRes,
               orgListRes
             ]) => {
      setInitDataLoading(false)
      if (accountDetailRes?.code !== ResponseCode.success) {
        setContent(<NetworkError error={ { code: 500, msg: '获取账号详情失败' } }/>)
        return
      }

      if (roleListRes?.code !== ResponseCode.success) {
        setContent(<NetworkError error={ { code: 500, msg: '获取角色列表失败' } }/>)
        return
      }

      if (roleTagMapRes?.code !== ResponseCode.success) {
        setContent(<NetworkError error={ { code: 500, msg: '获取角色信息失败' } }/>)
        return
      }

      if (authListRes?.code !== ResponseCode.success) {
        setContent(<NetworkError error={ { code: 500, msg: '获取用户权限列表失败' } }/>)
        return
      }

      if (regionListRes?.code !== ResponseCode.success) {
        setContent(<NetworkError error={ { code: 500, msg: '获取区域列表失败' } }/>)
        return
      }

      if (orgListRes?.code !== ResponseCode.success) {
        setContent(<NetworkError error={ { code: 500, msg: '获取机构列表失败' } }/>)
        return
      }

      dispatch(updateAuthList({ authList: authListRes.data }))
      dispatch(updateRoleList({ roleList: roleListRes.data }))
      dispatch(updateRoleTagMap({ roleTagMap: roleTagMapRes.data }))
      dispatch(updateRegionList({
        regionList: regionListRes.data.map(item => {
          return {
            ...item,
            label: item.name,
            value: item.code
          }
        })
      }))
      dispatch(updateUserInfo({ userInfo: accountDetailRes.data }))
      dispatch(updateUserAuth({
        userAuth: accountDetailRes.data.currentAuthoritySet
      }))
      dispatch(updateOrgList({ orgList: orgListRes.data }))
    }).catch(err => {
      console.error(err)
    })
  }

  const logout = () => {
    dispatch(updateAuthList({ authList: [] }))
    dispatch(updateRoleList({ roleList: [] }))
    dispatch(updateRoleTagMap({ roleTagMap: [] }))
    dispatch(updateRegionList({ regionList: [] }))
    dispatch(updateUserInfo({ userInfo: {} }))
    dispatch(updateExamList({ examList: [] }))
    dispatch(updateSelectedExam({ selectedExam: {} }))

    const siteId = sessionStorage.getItem('siteId')
    const loginPagePath = sessionStorage.getItem('login-page') || 'login'
    sessionStorage.setItem('access_token', '')

    router.navigate(`/${ siteId }/${ loginPagePath }`)
  }

  const filterMenuItem = (menuList, authList) => {
    if (userInfo.currentRoleTag === 'superAdmin') {
      return menuList
    } else {
      return menuList.map(menu => {
        if (menu.children?.length > 0) {
          return {
            ...menu,
            children: menu.children.filter(childMenu => {
              return authList?.some(auth => {
                return auth.type === 'page' && auth.path === childMenu.key
              })
            })
          }
        } else {
          return {
            ...menu,
            visible: authList?.some(auth => {
              return auth.type === 'page' && (auth.path === menu.key || menu.key === 'logo')
            }) ? 'true' : 'false'
          }
        }
      }).filter(menu => {
        return menu.visible === 'true' || menu.children?.length > 0
      })
    }
  }

  const showToggleAccountModal = () => {
    toggleAccountModalRef.current.showModal()
  }

  const showEditPasswordModal = () => {
    editUserPasswordModalRef.current.showModal()
  }

  const showLogoutModal = () => {
    Modal.confirm({
      title: '退出登录',
      content: '确定要退出吗？',
      onOk: () => {
        logout()
      }
    })
  }

  return (
    <Layout style={ { minWidth: 1320 } }>
      <Header
        style={ {
          padding: 0,
          background: colorBgContainer
        } }
      >
        <div className="px-4 d-flex align-items-center justify-content-between">
          <div className={'d-flex align-items-center'}>
            <img src={ Logo } width="24px" height="24px"/>
            <div
              className={'ml-2'}
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: '#3c9cf3'
            }}>{ envInfo.title || '中正公考人事考试服务平台' }</div>
          </div>

          <Space>
            <Button
              className={ 'pl-2 pr-0' }
              type={ 'link' }
              icon={ <UserSwitchOutlined/> }
              onClick={ showToggleAccountModal }
            >
              { currentAccount.name
                ? `${ currentAccount.name }(${ currentAccount.roleName })`
                : <LoadingOutlined/>
              }
            </Button>
            <Button
              className={ 'pl-2 pr-0' }
              type={ 'link' }
              icon={ <LockOutlined/> }
              onClick={ showEditPasswordModal }
            >修改密码</Button>
            <Button
              className={ 'pl-2 pr-0' }
              type={ 'link' }
              icon={ <PoweroffOutlined/> }
              onClick={ showLogoutModal }
            >退出</Button>
          </Space>
        </div>
      </Header>
      <Layout>
        <Sider
          trigger={ null }
          collapsible
          collapsed={ collapsed }
          style={ { background: '#e8ebf4', height: 'calc(100vh - 64px)', maxHeight: 'calc(100vh - 64px)' } }
        >
          <Menu
            className="app-side-menu"
            theme="light"
            mode="inline"
            selectedKeys={ [currentURLKey] }
            style={ { background: '#e8ebf4', color: '#555' } }
            defaultOpenKeys={ defaultActiveKeys }
            items={ filterMenuItem(menuItems, userAuth) }
          />
        </Sider>
        <Layout>
          <Breadcrumb items={ breadcrumbItems } style={ { margin: '8px 16px' } }/>
          <Content
            style={ {
              margin: '0px 16px 16px',
              padding: '12px 24px',
              minHeight: 'calc(100vh - 118px)',
              height: 'calc(100vh - 118px)',
              overflow: 'scroll',
              background: colorBgContainer
            } }
          >
            { content }
          </Content>
        </Layout>
      </Layout>

      <ToggleAccountModal
        ref={ toggleAccountModalRef }
        accountList={ accountList }
        currentAccount={ currentAccount }
      ></ToggleAccountModal>

      <EditUserPasswordModal
        ref={ editUserPasswordModalRef }
        logout={ logout }
      ></EditUserPasswordModal>

      <UpdateInitPasswordModal
        ref={ updateInitPasswordModalRef }
      ></UpdateInitPasswordModal>
    </Layout>
  )
}
export default Home
