import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, DatePicker, Form, Modal, Row, Select, Space, Spin } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import ExamineesNumberRange from './ExamineesNumberRange'
import Styles from './examinee-export.module.scss'
import ResponseCode from '../../common/response-code'
import {
  downloadExamineePhoto,
  downloadExamineeFile,
  getExamApplyTemplate,
  getExamineeUnit,
  getExamPositionList,
  getExamStatusInfoFields,
  getExamRoomInfoField
} from '../../common/request'
import { examCheckStatus } from '../../common/common'
import SelectApplyFormFieldModal from './SelectApplyFormFieldModal'
import router from '../../routers/router'

function ExamineeExport () {
  const defaultOption = { label: '全部', value: '' }
  const selectApplyFormFieldModalRef = useRef()
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const [applySiteOptions, setApplySiteOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState([])
  const [positionOptions, setPositionOptions] = useState([defaultOption])
  const [applyFormFields, setApplyFormFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [roomInfoFields, setRoomInfoFields] = useState([])
  const [examStatusInfoFields, setExamStatusInfoFields] = useState([])

  const payStatusOptions = [
    defaultOption,
    { label: '已缴费', value: 1 },
    { label: '未缴费', value: 0 }
  ]

  const saveStatusOptions = [
    defaultOption,
    ...Object.keys(examCheckStatus).reduce((prev, key) => {
      prev.push({ value: Number(key), label: examCheckStatus[key].label })
      return prev
    }, [])
  ]

  useEffect(() => {
    if (selectedExam.id) {
      form.resetFields()
      const initOptions = selectedExam.applySiteList?.map(site => {
        return {
          ...site,
          label: site.name,
          value: site.number
        }
      })

      setApplySiteOptions([defaultOption, ...(initOptions || [])])
    }
  }, [selectedExam, userInfo])

  useEffect(() => {
    if (selectedExam.id) {
      getExamineeUnitAction()
      getExamPositionListAction()
      getExamApplyTemplateAction()
      examRoomInfoFieldAction()
      examStatusInfoFieldsAction()
    }
  }, [selectedExam])

  const examRoomInfoFieldAction = () => {
    getExamRoomInfoField().then(res => {
      if (res.code === ResponseCode.success) {
        setRoomInfoFields(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取初始数据失败'
      })
    })
  }

  const examStatusInfoFieldsAction = () => {
    getExamStatusInfoFields().then(res => {
      if (res.code === ResponseCode.success) {
        setExamStatusInfoFields(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取初始数据失败'
      })
    })
  }

  const getExamineeUnitAction = () => {
    getExamineeUnit({ id: selectedExam.id }).then((res) => {
      if (res.code === ResponseCode.success) {
        let unitOption
        const initUint = res.data.map(item => {
          return {
            label: item.unitName + '_' + item.unitCode,
            value: item.unitCode
          }
        })

        unitOption = [defaultOption, ...initUint]
        if (userInfo.admin) {
          form.setFieldValue('positionId', '')
        } else {
          form.setFieldValue('positionId', initUint.length > 0 ? initUint[0].unitCode : '')
        }

        setUnitOptions(unitOption)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取初始数据失败'
      })
    })
  }

  const getExamPositionListAction = (unitCode) => {
    const params = {
      pageNum: 1,
      pageSize: 9999,
      examId: selectedExam.id,
      unitNameOrCode: unitCode
    }

    getExamPositionList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setPositionOptions([defaultOption, ...res.data.elements.map(item => {
          return {
            ...item,
            label: item.positionName + '_' + item.positionCode,
            value: item._id
          }
        })])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询职位数据失败',
        centered: true
      })
    })
  }

  const getExamApplyTemplateAction = () => {
    getExamApplyTemplate({ examId: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setApplyFormFields(res.data.fields || [])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询职位数据失败',
        centered: true
      })
    })
  }

  const getFormData = () => {
    let data = form.getFieldsValue()
    if (data.applyTimeRange.length > 0) {
      data.applyStartTime = dayjs(data.applyTimeRange[0]).format('YYYY-MM-DD HH:MM:00')
      data.applyEndTime = dayjs(data.applyTimeRange[1]).format('YYYY-MM-DD HH:MM:00')
    }

    if (data.examineesNumberRange.length > 0) {
      data.examineesNumberStart = data.examineesNumberRange[0]
      data.examineesNumberEnd = data.examineesNumberRange[1]
    }
    delete data.applyTimeRange
    delete data.examineesNumberRange

    return data
  }

  const showSelectFieldModal = () => {
    const searchData = getFormData()
    selectApplyFormFieldModalRef.current.showModal(searchData)
  }

  const showDownloadExamineeImageModal = () => {
    Modal.confirm({
      title: '操作确认',
      content: '确认要导出考生照片吗？',
      onOk: () => {
        downloadExamineeFileAction(downloadExamineePhoto)
      }
    })
  }

  const showDownloadExamineeFileModal = () => {
    Modal.confirm({
      title: '操作确认',
      content: '确认要导出考生证件资料吗？',
      onOk: () => {
        downloadExamineeFileAction(downloadExamineeFile)
      }
    })
  }

  const downloadExamineeFileAction = (request) => {
    const searchData = getFormData()
    setLoading(true)
    const params = {
      ...searchData,
      examId: selectedExam.id,
      applySiteNumbers: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : applySiteOptions.filter(site => {
          return site.value
        }).map(site => site.value)
    }

    delete params.applySiteNumber

    request(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        Modal.success({
          title: '操作成功',
          content: '下载任务已添加，是否跳转到下载中心查看',
          onOk: () => {
            const siteId = sessionStorage.getItem('siteId')
            router.navigate(`/${ siteId }/exam/download-center`)
          }
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，添加下载任务失败'
      })
    })
  }

  const onFieldsChange = (fields) => {
    if (fields[0]?.name[0] === 'unitCode') {
      getExamPositionListAction(fields[0].value)
      form.setFieldValue('positionId', '')
    }
  }

  return (
    <div>
      <SelectExam>
        <Spin spinning={ loading }>
          <Form
            className={ Styles.examineeExportForm }
            form={ form }
            initialValues={ {
              applySiteNumber: '',
              payStatus: '',
              saveStatus: '',
              unitCode: '',
              positionId: '',
              applyTimeRange: [],
              examineesNumberRange: []
            } }
            onFieldsChange={ onFieldsChange }
          >
            <Row gutter={ 16 }>
              <Col span={ 8 }>
                <Form.Item
                  label={ '报名点' }
                  name={ 'applySiteNumber' }
                >
                  <Select options={ applySiteOptions }></Select>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label={ '审核状态' }
                  name={ 'saveStatus' }
                >
                  <Select options={ saveStatusOptions }></Select>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label={ '缴费状态' }
                  name={ 'payStatus' }
                >
                  <Select options={ payStatusOptions }></Select>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label={ '报考单位' }
                  name={ 'unitCode' }
                >
                  <Select
                    options={ unitOptions }
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={ 8 }>
                <Form.Item
                  label={ '报考职位' }
                  name={ 'positionId' }
                >
                  <Select options={ positionOptions }></Select>
                </Form.Item>
              </Col>
              <Col span={ 10 }>
                <Form.Item
                  label={ '报名时间' }
                  name={ 'applyTimeRange' }
                >
                  <DatePicker.RangePicker
                    format="YYYY-MM-DD HH:00"
                    showTime={ {
                      hideDisabledOptions: true,
                      format: 'HH:00',
                      defaultValue: [dayjs('00', 'HH'), dayjs('23', 'HH')]
                    } }
                    style={ { width: '100%' } }
                  ></DatePicker.RangePicker>
                </Form.Item>
              </Col>
              <Col span={ 14 }>
                <Form.Item
                  label={ '报名序号' }
                  name={ 'examineesNumberRange' }
                  trigger={ 'onInput' }
                >
                  <ExamineesNumberRange></ExamineesNumberRange>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={ 'd-flex align-items-center justify-content-center' }>
            <Space>
              <Button type={ 'primary' } onClick={ showSelectFieldModal }>导出Excel</Button>
              <Button type={ 'primary' } onClick={ showDownloadExamineeImageModal }>导出考生照片</Button>
              <Button type={ 'primary' } onClick={ showDownloadExamineeFileModal }>导出证件资料</Button>
            </Space>
          </div>
        </Spin>
      </SelectExam>

      <SelectApplyFormFieldModal
        ref={ selectApplyFormFieldModalRef }
        applySiteOptions={ applySiteOptions }
        fieldList={ applyFormFields }
        examInfo={ selectedExam }
        roomInfoFields={ roomInfoFields }
        examStatusInfoFields={ examStatusInfoFields }
      ></SelectApplyFormFieldModal>
    </div>
  )
}

export default ExamineeExport
