import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Select, Table } from 'antd'
import { useSelector } from 'react-redux'
import { downloadExamStatisticExcel, statisticalByClassify } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadFile, throttle } from '../../plugins/utils'
import PageStatus from '../../components/PageStatus'

function ExamStatisticalByClassify () {
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const [applySiteOptions, setApplySiteOptions] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [pageStatus, setPageStatus] = useState('')
  const [pageStatusText, setPageStatusText] = useState('')

  useEffect(() => {
    if (selectedExam.id) {
      setApplySiteOptions([{
        label: '全部',
        value: ''
      }, ...(selectedExam.applySiteList?.map(site => {
        return {
          ...site,
          label: site.name,
          value: site.number
        }
      }) || [])])
    }
  }, [selectedExam, userInfo])

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageSize, pageNum])

  const columns = [
    {
      title: '报名点名称',
      key: 'siteName',
      dataIndex: 'siteName'
    },
    {
      title: '报名点代码',
      key: 'siteCode',
      dataIndex: 'siteCode'
    },
    {
      title: '考试大类',
      key: 'levelName',
      dataIndex: 'levelName'
    },
    {
      title: '考试大类代码',
      key: 'levelCode',
      dataIndex: 'levelCode'
    },
    {
      title: '考试小类',
      key: 'majorName',
      dataIndex: 'majorName'
    },
    {
      title: '考试小类代码',
      key: 'majorCode',
      dataIndex: 'majorCode'
    },
    {
      title: '报名人数',
      key: 'applyCount',
      dataIndex: 'applyCount'
    },
    {
      title: '缴费人数',
      key: 'payCount',
      dataIndex: 'payCount'
    },
    {
      title: '审核通过人数',
      key: 'checkCount',
      dataIndex: 'checkCount'
    },
    {
      title: '打印报名表',
      key: 'applyPrintCount',
      dataIndex: 'applyPrintCount'
    },
    {
      title: '打印准考证',
      key: 'certificatePrintCount',
      dataIndex: 'certificatePrintCount'
    }
  ]

  const getTableData = (allFields) => {
    const searchData = allFields || form.getFieldsValue()
    setPageStatus('loading')
    setPageStatusText('正在加载数据···')
    const params = {
      applySiteNumberList: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum: pageNum,
      pageSize: pageSize
    }

    statisticalByClassify(params).then(res => {
      if (res.code == ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询统计数据失败'
      })
    }).finally(() => {
      setPageStatus('')
      setPageStatusText('')
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const onValuesChange = (fields, allFields) => {
    getTableData(allFields)
  }

  const downloadExcel = () => {
    const searchData = form.getFieldsValue()
    const params = {
      applySiteNumberList: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      examId: selectedExam.id
    }
    setPageStatus('loading')
    setPageStatusText('正在加载数据···')

    downloadExamStatisticExcel(params).then(res => {
      downloadFile(res, 'xlsx', '按考试分类统计表').catch(err => {
        console.log(err)
        Modal.error({
          title: '错误',
          content: err
        })
      })
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载统计数据失败'
      })
    }).finally(() => {
      setPageStatus('')
      setPageStatusText('')
    })
  }

  return (<>
    <PageStatus
      pageStatus={ pageStatus }
      text={ pageStatusText }
    >
      <div className={ 'd-flex align-items-center justify-content-between' }>
        <Form
          form={ form }
          initialValues={ {
            applySiteNumber: ''
          } }
          onValuesChange={ throttle(onValuesChange, 300) }
        >
          <Form.Item
            label={ '报名点' }
            name={ 'applySiteNumber' }
          >
            <Select
              options={ applySiteOptions }
              style={ { width: 280 } }
            ></Select>
          </Form.Item>
        </Form>

        <Button
          type={ 'primary' }
          onClick={ downloadExcel }
        >导出考试分类统计</Button>
      </div>

      <Table
        loading={ pageStatus === 'loading' }
        columns={ columns }
        dataSource={ tableData.elements }
        rowKey={ (record) => {
          return `${ record.siteCode }_${ record.levelCode }_${ record.majorCode }`
        } }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
      ></Table>
    </PageStatus>
  </>)
}

export default ExamStatisticalByClassify
