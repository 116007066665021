import React from 'react'
import CommonComponent from './CommonComponent'

function LogRecordManage () {
  return (
    <div>
      <CommonComponent system={'manage'}></CommonComponent>
    </div>
  )
}

export default LogRecordManage
