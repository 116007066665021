import { useSelector } from 'react-redux'
import { Button, Cascader, Form, Input, Modal, notification, Popover, QRCode, Radio, Space } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { createOrg, updateOrg, getRegionTree } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { replaceChineseInitials } from '../../plugins/utils'
import AppUploadImg from '../../components/AppUploadImg'
import GenerateQrcodeModal from '../../components/GenerateQrcodeModal'
import TinymceFormItem from '../../components/TinymceFormItem'

const EditOrgModal = forwardRef((props, ref) => {
  const generateQrcodeModalRef = useRef(null)
  const [form] = Form.useForm()
  const provinceList = useSelector(state => {
    return state.regionList.regionList.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: false
      }
    })
  })
  const [regionOptions, setRegionOptions] = useState(provinceList)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [addressFullDetail, setAddressFullDetail] = useState(provinceList)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('创建机构')
      } else {
        setModalTitle('编辑机构')
        initRegionOptions(props.orgItem.regionIds)

        form.setFieldsValue({
          ...props.orgItem.siteConfigure,
          ...props.orgItem,
          siteName: props.orgItem.siteConfigure.name,
          orgCode: props.orgItem.code,
          showTips: props.orgItem.siteConfigure.showTips || ''
        })

        setAddressFullDetail(props.orgItem.addressFullDetail)

        form.setFieldValue('bgImage', props.orgItem.siteConfigure.bgImage)
      }

    }
  }, [modalVisible, props.modalType, props.orgItem])

  const initRegionOptions = (regionIds) => {
    getRegionTree({ parent: regionIds[0] }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        regionOptions.forEach(province => {
          if (province.value === regionIds[0]) {
            province.children = dataTransform(res.data)
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const dataTransform = (data) => {
    return data.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: !item.childList,
        children: item.childList ? dataTransform(item.childList) : undefined
      }
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onSave = () => {
    form.validateFields().then(fields => {
      setSaveLoading(true)

      if (props.modalType === 'create') {
        createOrgAction(fields)
      } else {
        updateOrgAction(fields)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const updateOrgAction = (fields) => {
    const params = {
      ...fields,
      id: props.orgItem.id,
      addressCode: fields.regionIds[fields.regionIds.length - 1],
      addressFullDetail: addressFullDetail
    }

    updateOrg(params).then(res => {
      setSaveLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `已更新机构`
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      setSaveLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新机构失败',
        centered: true
      })
    })
  }

  const createOrgAction = (fields) => {
    const params = {
      ...fields,
      addressCode: fields.regionIds[fields.regionIds.length - 1],
      addressFullDetail: addressFullDetail
    }

    createOrg(params).then(res => {
      setSaveLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `已创建机构【${ fields.name }】`
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      setSaveLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建机构失败',
        centered: true
      })
    })
  }

  const getRegionTreeAction = (targetOption) => {
    getRegionTree({ parent: targetOption.value }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        targetOption.loading = false
        targetOption.children = res.data.map(item => {
          return {
            label: item.name,
            value: item.code,
            id: item.id,
            isLeaf: !item.childList,
            childList: item.childList
          }
        })

        setRegionOptions([...regionOptions])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      targetOption.loading = false
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const loadRegionData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.childList) {
      targetOption.loading = false
      targetOption.children = targetOption.childList.map(item => {
        return {
          label: item.name,
          value: item.code,
          id: item.id,
          isLeaf: !item.childList,
          childList: item.childList
        }
      })

      setRegionOptions([...regionOptions])
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  const AppInput = ({ value }) => {
    const inputRef = useRef(null)

    const copyToClipboard = () => {
      const textarea = document.createElement('textarea')
      textarea.value = inputRef.current.input.value
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)

      notifyApi.success({
        message: '复制成功',
        description: '已复制到剪贴板'
      })
    }

    return (<Space>
      <Input ref={ inputRef } value={ value } disabled style={ { width: 350 } }></Input>
      <Button className={ 'p-0' } type={ 'link' } onClick={ copyToClipboard }>复制</Button>
    </Space>)
  }

  const getDomainName = () => {
    if (props.modalType === 'create') {
      return <Button className="p-0" type="link">提交后系统分配生产</Button>
    } else {
      return <AppInput></AppInput>
    }
  }

  const orgNameChange = (e) => {
    if (e.target.value.trim()) {
      form.setFieldValue('orgCode', `org_${ replaceChineseInitials(e.target.value) }`)
    } else {
      form.setFieldValue('orgCode', '')
    }
  }

  const selectedAddressChange = (selectedKey, selectedItem) => {
    setAddressFullDetail((selectedItem || []).map(item => item.label).join(''))
  }

  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas')
    if (canvas) {
      const url = canvas.toDataURL()
      generateQrcodeModalRef.current.showModal(url)
      hideModal()
    }
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        width={ 900 }
        style={ { top: 20 } }
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ saveLoading }
        onOk={ onSave }
        onCancel={ hideModal }
      >
        <div className="mt-3">
          <Form
            form={ form }
            labelCol={ {
              span: 5
            } }
            wrapperCol={ {
              span: 17
            } }
            initialValues={ {
              showQrCode: 0,
              showTips: ''
            } }
          >
            <Form.Item
              label="报名网址"
              name="domain"
            >
              { getDomainName() }
            </Form.Item>
            <Form.Item
              label="移动端报名网址"
              name="mobileDomain"
            >
              { getDomainName() }
            </Form.Item>
            <Form.Item
              label="机构名称"
              name="name"
              rules={ [{ required: true }] }>
              <Input
                placeholder="输入机构名称"
                style={ { width: '260px' } }
                onChange={ orgNameChange }
              ></Input>
            </Form.Item>
            <Form.Item
              label="机构代码"
              name="orgCode"
              rules={ [{ required: true, message: '机构代码不能为空' }] }>
              <Input placeholder="输入机构代码" style={ { width: '260px' } }></Input>
            </Form.Item>
            <Form.Item
              label="机构简称"
              name="shortName"
              rules={ [{ required: true }] }>
              <Input
                placeholder="输入机构名称"
                style={ { width: '260px' } }
                maxLength={ 8 }
              ></Input>
            </Form.Item>
            <Form.Item
              label="所属区域"
              name="regionIds"
              rules={ [{ required: true, message: '请选择机构所属区域' }] }>
              <Cascader
                changeOnSelect
                options={ regionOptions }
                loadData={ loadRegionData }
                placeholder="请选择机构所属区域"
                style={ { width: '260px' } }
                onChange={ selectedAddressChange }
              ></Cascader>
            </Form.Item>
            <Form.Item
              label="报名站点名称"
              name="siteName"
              rules={ [{ required: true }] }
            >
              <Input style={ { width: '260px' } }></Input>
            </Form.Item>
            <Form.Item
              label="顶部信息"
              name="showTitle"
              rules={ [{ required: true }] }
            >
              <Input style={ { width: '260px' } }></Input>
            </Form.Item>
            <Form.Item
              label="详细地址"
              name="addressDetail"
              rules={ [{ required: true, message: '详细地址不能为空' }] }>
              <Input.TextArea
                placeholder="请输入机构详细地址"
              ></Input.TextArea>
            </Form.Item>
            <Form.Item
              label="底部信息"
              name="bottomInfo"
            >
              <Input.TextArea></Input.TextArea>
            </Form.Item>
            <Form.Item
              label={ '报考须知' }
              name={ 'showTips' }
            >
              <TinymceFormItem></TinymceFormItem>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={ (prevValues) => {
                return prevValues.mobileDomain
              } }>
              { ({ getFieldValue }) => {
                return getFieldValue('mobileDomain')
                  ? <Form.Item
                    label="报名端二维码"
                    name="mobileDomain"
                  >
                    <Popover
                      trigger="click"
                      content={
                        <div id="myqrcode">
                          <Space direction={ 'vertical' } align={ 'center' }>
                            <QRCode
                              value={ getFieldValue('mobileDomain') || '-' }
                              bgColor={ '#FFFFFF' }
                            />

                            <Button type={ 'primary' } onClick={ downloadQRCode }>下载二维码</Button>
                          </Space>
                        </div>
                      }
                    >
                      <Button size={ 'small' } type={ 'primary' }>生成二维码</Button>
                    </Popover>
                  </Form.Item>
                  : <></>
              } }
            </Form.Item>

            <Form.Item
              label="报名站点背景"
              name="bgImage"
            >
              <AppUploadImg></AppUploadImg>
            </Form.Item>
            <Form.Item
              label="是否显示侧边图"
              name="showQrCode"
            >
              <Radio.Group>
                <Radio value={ 0 }>不显示</Radio>
                <Radio value={ 1 }>显示</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={ (prevValues, curValues) => {
                return prevValues.showQrCode !== curValues.showQrCode
              } }>
              { ({ getFieldValue }) => {
                return getFieldValue('showQrCode') === 1
                  ? <Form.Item
                    label="侧边图"
                    name="qrCodeImage"
                    rules={ [{ required: true, message: '请上传侧边图' }] }
                  >
                    <AppUploadImg></AppUploadImg>
                  </Form.Item>
                  : <></>
              } }
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <GenerateQrcodeModal ref={ generateQrcodeModalRef }></GenerateQrcodeModal>
    </div>
  )
})

export default EditOrgModal
