import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { notification, Modal, Form, Input, Select } from 'antd'
import { getExamSubjectList, createExamSubClass, updateExamSubClass } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const EditExamSubClassModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalType, setModalType] = useState('create')
  const [notifyApi, contextHolder] = notification.useNotification()
  const [subjectOptions, setSubjectOptions] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getSubjectOptions()

      if (modalType === 'create') {
        setModalTitle('新增考试小类')
      } else {
        setModalTitle('编辑考试小类')
      }
    }
  }, [modalVisible])

  const getSubjectOptions = () => {
    getExamSubjectList({ examId: props.examInfo.id }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setSubjectOptions(res.data.map(item => {
          return {
            label: item.subjectName,
            value: item.id
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询科目列表失败',
        centered: true
      })
    })
  }

  const showModal = (type, row) => {
    setModalVisible(true)
    setModalType(type)
    form.setFieldsValue({
      ...row,
      subjectIdList: row.subjectList?.map(item => item.id)
    })
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      let request
      if (modalType === 'create') {
        request = createSubClassAction
      } else {
        request = updateSubClassAction
      }

      request(fields).then(res => {
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess(modalType)
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        Modal.error({
          title: '网络错误',
          content: '考试分类配置失败',
          centered: true
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const createSubClassAction = (fields) => {
    const params = {
      examId: props.examInfo.id,
      ...fields
    }

    return createExamSubClass(params)
  }

  const updateSubClassAction = (fields) => {
    const params = {
      ...fields,
      examId: props.examInfo.id,
      id: fields.majorId
    }

    return updateExamSubClass(params)
  }

  const majorCodeChange = (e) => {
    form.setFieldValue('majorCode', e.target.value.replace(/\D/g, ''))
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        style={ { position: 'absolute', right: '230px' } }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <div className="mt-3">
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item noStyle name="levelId"></Form.Item>
            <Form.Item noStyle name="majorId"></Form.Item>
            <Form.Item
              label="考试小类代码"
              name="majorCode"
              rules={ [{ required: true, message: '考试小类代码不能为空' }] }
            >
              <Input
                placeholder="请输入考试小类代码"
                disabled={ modalType !== 'create' }
                onChange={ majorCodeChange }
                style={ { width: '100%' } }
              ></Input>
            </Form.Item>
            <Form.Item
              label="考试小类名称"
              name="majorName"
              rules={ [{ required: true, message: '考试小类名称不能为空' }] }
            >
              <Input placeholder="请输入考试小类名称"></Input>
            </Form.Item>
            <Form.Item
              label="考试科目"
              name="subjectIdList"
              rules={ [{ required: true, message: '请选择考试科目' }] }
            >
              <Select mode="multiple" options={ subjectOptions }></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
})
export default EditExamSubClassModal
