import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Table } from 'antd'

import { getExaminationLocationRoomList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import MergeLastRoomModal from './MergeLastRoomModal'
import ExaminationRoomExamineeTable from './ExaminationRoomExamineeTable'

function ExaminationRoomTable ({ componentType, batchInfo, locationInfo, expanded, needReloadRow }) {
  const mergeLastRoomModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})

  const columns = [
    {
      title: '考场编号',
      key: 'roomNumber',
      dataIndex: 'roomNumber',
      render: (record) => {
        return `第 ${ record } 考场`
      }
    },
    {
      title: '最大容量',
      key: 'standardNumber',
      dataIndex: 'standardNumber'
    },
    {
      title: '已分配人数',
      key: 'allocateNumber',
      dataIndex: 'allocateNumber'
    },
    {
      width: 120,
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        if (componentType !== 'view' && row.lastRoom) {
          return (<div>
            <Button
              type={ 'primary' }
              ghost
              onClick={ showMergeLastRoomModal.bind(this, row) }
            >
              合并尾考场</Button>
          </div>)
        } else {
          return <></>
        }
      }
    }
  ]

  useEffect(() => {
    if (locationInfo.locationId === needReloadRow.locationId) {
      getTableData()
    }
  }, [needReloadRow])

  useEffect(() => {
    if (expanded) {
      getTableData()
    }
  }, [expanded])

  const showMergeLastRoomModal = (row) => {
    setCurrentEditItem(row)
    mergeLastRoomModalRef.current.showModal()
  }

  const getTableData = () => {
    setLoading(true)
    const params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId,
      locationId: locationInfo.locationId
    }
    getExaminationLocationRoomList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考点考场数据失败'
      })
    })
  }

  const roomExamineeTableVisible = (visible) => {
    if (visible) {
      return {
        expandedRowRender: (record, index, indent, expanded) => {
          return <ExaminationRoomExamineeTable
            batchInfo={ batchInfo }
            locationInfo={ locationInfo }
            roomInfo={ record }
            expanded={ expanded }
          ></ExaminationRoomExamineeTable>
        }
      }
    } else {
      return false
    }
  }

  return (<>
    <Table
      loading={ loading }
      columns={ columns }
      dataSource={ tableData }
      rowKey={ record => record.roomId }
      pagination={ false }
      expandable={ roomExamineeTableVisible(componentType === 'view') }
    >
    </Table>

    <MergeLastRoomModal
      ref={ mergeLastRoomModalRef }
      batchInfo={ batchInfo }
      locationInfo={ locationInfo }
      roomInfo={ currentEditItem }
      insertSuccess={ getTableData }
    ></MergeLastRoomModal>
  </>)
}

export default ExaminationRoomTable
