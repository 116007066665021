import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Form, Input, Modal, Pagination, Spin, Tooltip } from 'antd'
import Styles from './select-exam.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getExamListByRole } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { updateExamList } from '../../store/slice/exam-info-slice'
import { ReloadOutlined } from '@ant-design/icons'
import { throttle } from '../../plugins/utils'

const SelectExamModal = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [examInfo, setExamInfo] = useState({
    elements: []
  })
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [pageStatus, setPageStatus] = useState('')
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const orgList = useSelector(state => state.org.orgList)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getExamListAction()
    }
  }, [modalVisible, pageNum])

  const getExamListAction = (searchFields = {}) => {
    setPageStatus('loading')
    const params = {
      ...searchFields,
      orgIdStrSet: orgList.map(item => item.id),
      pageNum: pageNum,
      pageSize: 10,
      orderBy: 'id',
      orderType: 'DESC'
    }

    getExamListByRole(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setExamInfo(res.data)
        dispatch(updateExamList({ examList: res.data.elements }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试列表失败'
      })
    }).finally(() => {
      setPageStatus('')
    })
  }

  const onPageChange = (num) => {
    setPageNum(num)
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const getClassName = (examItem, currentExam) => {
    if (currentExam.id === examItem.id) {
      return Styles.examItem + ' ' + Styles.is_active
    } else {
      return Styles.examItem
    }
  }

  const generateExamItem = (list, currentExam) => {
    return list?.map(examItem => {
      return (
        <Tooltip
          title={ examItem.name }
          placement={ 'topRight' }
          key={ examItem.id }
        >
          <div
            className={ getClassName(examItem, currentExam) }
            onClick={ selectExamChange.bind(this, examItem) }
          >
            { examItem.name }
          </div>
        </Tooltip>
      )
    })
  }

  const selectExamChange = (examItem) => {
    hideModal()
    props.onChange?.(examItem)
  }

  const resetSearchParams = () => {
    form.resetFields()
    getExamListAction()
  }

  const handleSearch = (fields) => {
    setPageNum(1)
    getExamListAction(fields)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 680 }
        title={ '选择考试' }
        open={ modalVisible }
        onCancel={ hideModal }
        footer={ [] }
      >
        <Spin spinning={ pageStatus === 'loading' }>
          <div className={ 'my-3 d-flex align-items-center justify-content-between' }>
            <Form
              layout={ 'inline' }
              form={ form }
              initialValues={ {
                examName: ''
              } }
              onValuesChange={ throttle(handleSearch, 500) }
            >
              <Form.Item name={ 'name' }>
                <Input
                  placeholder={ '输入考试名称搜索' }
                  style={ { width: 320 } }
                ></Input>
              </Form.Item>
            </Form>
            <Button
              type={ 'link' }
              icon={ <ReloadOutlined/> }
              onClick={ resetSearchParams }
            >刷新</Button>
          </div>
          <Spin spinning={ loading }>
            <div className={ Styles.examItemWrap }>
              { generateExamItem(examInfo.elements, selectedExam) }
            </div>
            <div className={ 'd-flex justify-content-end' }>
              <Pagination
                showTotal={ (total) => `总数：${ total }场` }
                current={ pageNum }
                total={ examInfo.totalElements }
                onChange={ onPageChange }
              />
            </div>
          </Spin>
        </Spin>
      </Modal>
    </div>
  )
})

export default SelectExamModal
