import React, { useEffect, useState } from 'react'
import { Button, Modal, notification, Space, Table } from 'antd'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import { getPoorList, poorRefund } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'

function PoorExamineeRefund () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const refundStatusMap = {
    '-1': '退款失败',
    0: '退款中',
    1: '退款成功',
    2: '退款关闭'
  }

  const columns = [
    {
      title: '考生姓名',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '身份证号',
      key: 'idCard',
      dataIndex: 'idCard'
    },
    {
      title: '所属报名点',
      key: 'applySiteName',
      dataIndex: 'applySiteName'
    },
    {
      title: '退费金额（元）',
      key: 'refundAmount',
      dataIndex: 'refundAmount'
    },
    {
      title: '审核人员',
      key: 'checkerList',
      dataIndex: 'checkerList',
      render: (record, row) => {
        return row.checkerList?.map(item => {
          return item.name
        })
      }
    },
    {
      title: '退费状态',
      key: 'refundStatus',
      dataIndex: 'refundStatus',
      render: (record) => {
        return refundStatusMap[record]
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (<>
          {
            (row.refundStatus === 0 || row.refundStatus === 1)
              ? <></>
              : <ComponentVisibleByAuth authCode={ 'button_pkstf-tf' }>
                <Space className={ 'd-flex justify-content-end' }>
                  <Button
                    type={ 'primary' }
                    ghost
                    onClick={ showPoorRefundModal.bind(this, [row.id]) }
                  >退费</Button>
                </Space>
              </ComponentVisibleByAuth>
          }

        </>)
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageSize, pageNum])

  const getTableData = () => {
    const params = {
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum,
      pageSize,
      statusList: [2]
    }

    setLoading(true)
    getPoorList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，加载贫困生数据失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const onSelectedChange = (keys) => {
    setSelectedRowKeys(keys)
  }

  const showPoorRefundModal = (idList) => {
    if (idList.length > 0) {
      Modal.confirm({
        title: '贫困生退费',
        content: '确定要退费吗？',
        onOk: () => {
          poorRefundAction(idList)
        }
      })
    } else {
      Modal.error({
        title: '错误',
        content: '未选择考生'
      })
    }
  }

  const poorRefundAction = (idList) => {
    setLoading(true)
    poorRefund({ applyIdList: idList }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        getTableData()
        notification.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，退费失败'
      })
    })
  }

  return (<>
    <SelectExam>
      <div className={ 'mb-3 d-flex justify-content-end' }>
        <ComponentVisibleByAuth authCode={ 'button_pkstf-tf' }>
          <Button
            type={ 'primary' }
            loading={ loading }
            onClick={ showPoorRefundModal.bind(this, selectedRowKeys) }
          >批量退费</Button>
        </ComponentVisibleByAuth>
      </div>
      <Table
        loading={ loading }
        columns={ columns }
        dataSource={ tableData.elements }
        rowKey={ record => record.id }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
        rowSelection={ {
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectedChange,
          getCheckboxProps: (record) => ({
            disabled: record.refundStatus === 0 || record.refundStatus === 1,
          })
        } }
      ></Table>
    </SelectExam>
  </>)
}

export default PoorExamineeRefund
