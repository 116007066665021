import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Space, Spin, Table } from 'antd'

import { deleteExamApplySite } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import EditExamApplySiteModal from './EditExamApplySiteModal'
import EditExamApplySiteWorkerModal from './EditExamApplySiteWorkerModal'
import SelectApplySiteModal from './SelectApplySiteModal'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import ComponentVisibleByAuth from '../../../../components/ComponentVisibleByAuth'
import { useSelector } from 'react-redux'
import eventBus from '../../../../plugins/events'

function SettingApplySite () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const selectApplySiteModalRef = useRef()
  const editExamApplySiteModalRef = useRef()
  const editExamApplySiteWorkerModalRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    if (selectedExam.id) {
      setTableData(selectedExam.applySiteList)
    }
  }, [selectedExam])

  const columns = [
    {
      title: '报名点名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '报名点代码',
      dataIndex: 'applySiteCode',
      key: 'applySiteCode'
    },
    {
      title: '所属区域',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '操作',
      width: '280px',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (<>
          <Space className="d-flex justify-content-end">
            <ComponentVisibleByAuth authCode={ 'button_ksxq-bmdsz-pzgzry' }>
              <Button
                type={ 'primary' }
                ghost onClick={ openModal.bind(this, row, showEditApplySiteWorkerModal) }
              >配置工作人员</Button>
            </ComponentVisibleByAuth>
            <ComponentVisibleByAuth authCode={ 'button_ksxq-bmdsz-bj' }>
              <ComponentDisabled
                disabledTip={ '报名已开始，禁止修改' }
                disabled={ selectedExam.examinationStatus > 0 }
              >
                <Button
                  type={ 'primary' }
                  ghost
                  onClick={ openModal.bind(this, row, showEditApplySiteModal) }
                >编辑报名点</Button>
                <Button
                  ghost
                  danger
                  onClick={ openModal.bind(this, row, showDeleteApplySiteModal) }
                >删除</Button>
              </ComponentDisabled>
            </ComponentVisibleByAuth>
          </Space>
        </>)
      }
    }
  ]

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showEditApplySiteWorkerModal = () => {
    editExamApplySiteWorkerModalRef.current.showModal()
  }

  const showEditApplySiteModal = () => {
    editExamApplySiteModalRef.current.showModal()
  }

  const showSelectApplySiteModal = () => {
    selectApplySiteModalRef.current.showModal()
  }

  const showDeleteApplySiteModal = (row) => {
    Modal.confirm({
      title: '删除报名点',
      content: `确定要删除报名点【${ row.name }】吗？`,
      onOk: () => {
        deleteApplySiteAction([row.id])
      }
    })
  }

  const showBatchDeleteApplySiteModal = () => {
    if (selectedRowKeys.length == 0) {
      Modal.error({
        title: '错误',
        content: '未选择报名点，不能进行批量操作'
      })
    } else {
      Modal.confirm({
        title: '删除报名点',
        content: '确定要批量删除报名点吗？',
        onOk: () => {
          deleteApplySiteAction(selectedRowKeys)
        }
      })
    }
  }

  const deleteApplySiteAction = (idList) => {
    setLoading(true)
    const params = {
      examId: selectedExam.id,
      applySiteIds: idList
    }

    deleteExamApplySite(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        applySiteListChange()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const applySiteListChange = () => {
    eventBus.emit('reloadExamDetail', selectedExam.id)
  }

  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys)
  }

  return (
    <div>
      { contextHolder }
      <Spin spinning={ loading }>
        <Space className="mb-2 d-flex justify-content-end">
          <ComponentVisibleByAuth authCode={ 'button_ksxq-bmdsz-bj' }>
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <Button
                type={ 'primary' }
                onClick={ openModal.bind(this, {}, showSelectApplySiteModal) }
              >选择报名点</Button>
              <Button
                type="primary"
                danger
                onClick={ openModal.bind(this, {}, showBatchDeleteApplySiteModal) }
              >批量删除</Button>
            </ComponentDisabled>
          </ComponentVisibleByAuth>
        </Space>
        <ConfigProvider componentSize={ 'small' }>
          <Table
            loading={ loading }
            columns={ columns }
            dataSource={ tableData }
            scroll={ {
              y: 'calc(100vh - 440px)'
            } }
            rowKey={ (record) => {
              return record.id
            } }
            rowSelection={ {
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange
            } }
            pagination={ false }
          ></Table>
        </ConfigProvider>
      </Spin>

      <SelectApplySiteModal
        ref={ selectApplySiteModalRef }
        orgId={ selectedExam.orgId }
        examInfo={ selectedExam }
        selectedSiteList={ tableData }
        insertSuccess={ applySiteListChange }
      ></SelectApplySiteModal>

      <EditExamApplySiteModal
        ref={ editExamApplySiteModalRef }
        orgId={ selectedExam.orgId }
        siteItem={ currentEditItem }
        insertSuccess={ applySiteListChange }
      ></EditExamApplySiteModal>

      <EditExamApplySiteWorkerModal
        ref={ editExamApplySiteWorkerModalRef }
        siteItem={ currentEditItem }
        examInfo={ selectedExam }
        insertSuccess={ applySiteListChange }
      ></EditExamApplySiteWorkerModal>
    </div>
  )
}

export default SettingApplySite
