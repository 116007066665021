import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table, ConfigProvider, Modal, notification, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { deleteApplySite, getApplySiteList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import EditOrgApplySiteModal from './EditOrgApplySiteModal'
import { setOrgListLoading } from '../../../store/slice/org-slice'
import AppButton from '../../../components/AppButton'

function ConfigApplySite (props) {
  const dispatch = useDispatch()
  const EditOrgApplySiteModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [modalType, setModalType] = useState('')
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [notifyApi, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.modalVisible && props.orgItem.id) {
      getTableData()
    }
  }, [props.orgItem, props.modalVisible])

  const columns = [
    {
      title: '报名点名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '报名点代码',
      dataIndex: 'applySiteCode',
      key: 'applySiteCode'
    },
    {
      title: '报名点区域',
      key: 'address',
      dataIndex: 'address'
    },
    {
      title: '操作',
      width: '180px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <Space className="d-flex justify-content-end">
            <AppButton
              authCode={ 'button_jgxq-bmd-bj' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditApplySiteModal) }
            >编辑</AppButton>
            <AppButton
              authCode={ 'button_jgxq-bmd-sc' }
              type="primary"
              danger
              ghost
              onClick={ openModal.bind(this, row, showDeleteApplySiteModal) }
            >删除</AppButton>
          </Space>
        </>)
      }
    }
  ]

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateApplySiteModal = () => {
    setModalType('create')
    EditOrgApplySiteModalRef.current.showModal()
  }

  const showEditApplySiteModal = () => {
    setModalType('edit')
    EditOrgApplySiteModalRef.current.showModal()
  }

  const showDeleteApplySiteModal = (row) => {
    Modal.confirm({
      title: '删除报名点',
      centered: true,
      content: (
        <div>确定要删除报名点【{ row.name }】吗？</div>
      ),
      onOk () {
        deleteOrgApplySiteAction(row)
      }
    })
  }

  const deleteOrgApplySiteAction = (row) => {
    setLoading(true)
    const params = {
      id: props.orgItem.id,
      applySiteId: row.id
    }

    deleteApplySite(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        updateOrgInfo()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '删除失败',
        centered: true
      })
    })
  }

  const getTableData = () => {
    const params = {
      id: props.orgItem.id
    }

    getApplySiteList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，查询报名点列表失败'
      })
    })
  }

  const updateOrgInfo = () => {
    getTableData()
    dispatch(setOrgListLoading({ loading: true }))
  }

  return (
    <div>
      { contextHolder }
      <ConfigProvider componentSize="small">
        <div className="mb-2 d-flex justify-content-end">
          <AppButton
            authCode={ 'button_jgxq-bmd-xzbmd' }
            type="primary"
            icon={ <PlusOutlined/> }
            onClick={ openModal.bind(this, {}, showCreateApplySiteModal) }
          >新增报名点</AppButton>
        </div>

        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData }
          style={ { minHeight: '230px' } }
          rowKey={ (record) => {
            return record.id
          } }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <EditOrgApplySiteModal
        ref={ EditOrgApplySiteModalRef }
        modalType={ modalType }
        orgItem={ props.orgItem }
        applySiteItem={ currentEditItem }
        insertSuccess={ updateOrgInfo }
      >
      </EditOrgApplySiteModal>
    </div>
  )
}

export default ConfigApplySite
