import { Tabs } from 'antd'

import DataDictionaryManagement from './data-dictionary/DataDictionaryManagement'
import RegionDictionaryManagement from './region-dictionary/RegionDictionaryManagement'

function DictionaryManagement () {
  const tabsItems = [
    {
      key: '1',
      label: '数据字典',
      children: <DataDictionaryManagement />,
    },
    {
      key: '2',
      label: '地域字典',
      children: <RegionDictionaryManagement />,
    }
  ]

  return (
    <div>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </div>
  )
}

export default DictionaryManagement
