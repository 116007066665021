export const phoneRegExp = /^1[3-9]\d{9}$/
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/
export const locationNumberRegExp = /(^0[1-9]$)|(^[1-9][0-9]$)/
export const startNumberRegExp = /(^00[1-9]$)|(^0[1-9][0-9]$)|(^[1-9][0-9][0-9]$)/

export const phoneValidate = (rule, value) => {
  const data = value?.trim()

  if (!data) {
    return Promise.reject('手机号不能为空')
  }

  if (!phoneRegExp.test(data)) {
    return Promise.reject('手机号格式错误')
  }
  return Promise.resolve()
}

export const passwordValidate = (rule, value) => {
  if (!value) {
    return Promise.reject('密码不能为空')
  }

  if (!passwordRegExp.test(value)) {
    return Promise.reject('密码格式错误，必须包含英文大小写、数字、特殊符号')
  }
  return Promise.resolve()
}

export const confirmPasswordValidate = (fieldName, { getFieldValue }) => {
  return {
    validator (_, value) {
      if (!value || getFieldValue(fieldName) === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('与新密码不一致，请确认'))
    }
  }
}

export const locationNumberValidate = (rule, value) => {
  if (!locationNumberRegExp.test(value)) {
    return Promise.reject('格式错误，范围01~99')
  }
  return Promise.resolve('')
}

export const startNumberValidate = (rule, value) => {
  if (!startNumberRegExp.test(value)) {
    return Promise.reject('格式错误，范围001~999')
  }
  return Promise.resolve('')
}
