import React from 'react'
import { InputNumber } from 'antd'

import Styles from './index.module.scss'

const SubjectListFormItem = (props) => {
  const subjectCostChange = (item, value) => {
    props.onChange(props.subjectList.map(subject => {
      if (subject.id === item.id) {
        subject.cost = Number(value)
      }
      return subject
    }))
  }

  return (<>
    { props.subjectList.map(item => {
      return (
        <div className="mb-3 d-flex align-items-center" key={ item.id }>
          <div className={ Styles.formLabel }>{ item.subjectName }</div>
          <InputNumber
            value={ item.cost || 0 }
            suffix="元"
            min={ 0 }
            style={ { width: '120px' } }
            onChange={ subjectCostChange.bind(this, item) }
          ></InputNumber>
        </div>
      )
    }) }
  </>)
}

export default SubjectListFormItem
