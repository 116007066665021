import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs, Drawer, Descriptions, App } from 'antd'
import ConfigWorker from './config-worker/ConfigWorker'
import ConfigApplySite from './config-apply-site/ConfigApplySite'
import ConfigReceivingAccount from './config-receiving-account/ConfigReceivingAccount'
import ConfigFinancialPersonnel from './config-financial-personnel/ConfigFinancialPersonnel'
import ConfigOperationPersonnel from './config-operation-personnel/ConfigOperationPersonnel'
import ConfigPositionFields from './config-position-fields/ConfigPositionFields'
import ConfigApplyFields from './config-apply-form-fields/ConfigApplyFormFields'
import ConfigExaminationSite from './config-examination-site/ConfigExaminationSite'
import FileTemplateFieldManagement from '../../components/FileTemplateFieldManagement'
import ExamineeAccountManagement from '../../components/examinee-account-management/ExamineeAccountManagement'
import { orgDetail } from '../../common/request'
import ResponseCode from '../../common/response-code'
import PageStatus from '../../components/PageStatus'
import { useSearchParams } from 'react-router-dom'
import ConfigAnnouncement from './config-announcement/ConfigAnnouncement'

const EditOrgConfig = forwardRef((props, ref) => {
  const { modal } = App.useApp()
  const [modalVisible, setModalVisible] = useState(false)
  const [activeTabs, setActiveTabs] = useState('')
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const userAuth = useSelector(state => state.userInfo.userAuth)
  const [orgDetailInfo, setOrgDetailInfo] = useState({})
  const [pageStatus, setPageStatus] = useState('')
  const [pageStatusText, setPageStatusText] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const tabsItems = [
    {
      key: 'applySite',
      label: '报名点',
      children: <ConfigApplySite
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigApplySite>
    },
    {
      key: 'examLocation',
      label: '考点',
      children: <ConfigExaminationSite
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigExaminationSite>
    },
    {
      key: 'applyFields',
      label: '报名字段',
      children: <ConfigApplyFields
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigApplyFields>
    },
    {
      key: 'positionFields',
      label: '职位表字段',
      children: <ConfigPositionFields
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigPositionFields>
    },
    {
      key: 'fileTemplateFields',
      label: '样表字段管理',
      children: <FileTemplateFieldManagement
        authCodePrefix={ 'button_jgxq-ybzdgl' }
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
        fieldLevel={ 'U' }
      ></FileTemplateFieldManagement>
    },
    {
      key: 'examineeAccount',
      label: '考生注册信息',
      children: <ExamineeAccountManagement
        type={ 'orgConfig' }
        authCodePrefix={ 'button_jgxq-kszcxx' }
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ExamineeAccountManagement>
    },
    {
      key: 'worker',
      label: '工作人员',
      children: <ConfigWorker
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigWorker>
    },
    {
      key: 'receivingAccount',
      label: '收款账号',
      children: <ConfigReceivingAccount
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigReceivingAccount>
    },
    {
      key: 'financialPersonnel',
      label: '财务人员',
      children: <ConfigFinancialPersonnel
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigFinancialPersonnel>
    },
    {
      key: 'operationPersonnel',
      label: '管理员',
      children: <ConfigOperationPersonnel
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigOperationPersonnel>
    },
    {
      key: 'announcement',
      label: '公告',
      children: <ConfigAnnouncement
        modalVisible={ modalVisible }
        orgItem={ orgDetailInfo }
      ></ConfigAnnouncement>
    }
  ]

  const showModal = (orgId, tabsKey) => {
    setActiveTabs(tabsKey || 'applySite')
    setModalVisible(true)
    getOrgDetailAction(orgId)
  }

  const getOrgDetailAction = (orgId) => {
    setPageStatus('loading')
    setPageStatusText('加载中...')
    orgDetail({ id: orgId }).then(res => {
      if (res.code === ResponseCode.success) {
        setOrgDetailInfo(res.data)
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      modal.error({
        title: '错误',
        content: '网络错误，获取机构详情失败'
      })
    }).finally(() => {
      setPageStatus('')
      setPageStatusText('')
    })
  }

  const hideModal = () => {
    setModalVisible(false)
    setSearchParams('')
  }

  const activeTabsChange = (tabsKey) => {
    setActiveTabs(tabsKey)
    setSearchParams(`activeKey=${tabsKey}&orgId=${orgDetailInfo.id}`)
  }

  const filterTabsByAuth = (tabs, authList) => {
    if (userInfo.currentRoleTag === 'superAdmin') {
      return tabs
    } else {
      return tabs.filter(tab => {
        return authList.some(auth => {
          return auth.type === 'menu' && `org-detail/${ tab.key }` === auth.path
        })
      })
    }
  }

  return (
    <div ref={ ref }>
      <Drawer
        title={ orgDetailInfo.name }
        contentWrapperStyle={ {
          width: '100%'
        } }
        placement="right"
        size="large"
        onClose={ hideModal }
        open={ modalVisible }
      >
        <PageStatus
          pageStatus={ pageStatus }
          text={ pageStatusText }
        >
          <div>
            <Descriptions
              size={ 'small' }
              column={ 3 }
              title="基本信息"
            >
              <Descriptions.Item label="机构简称">{ orgDetailInfo.shortName }</Descriptions.Item>
              <Descriptions.Item label="机构代码">{ orgDetailInfo.code }</Descriptions.Item>
              <Descriptions.Item label="所属区域">{ orgDetailInfo.address }</Descriptions.Item>
              <Descriptions.Item label="详细地址">{ orgDetailInfo.addressDetail }</Descriptions.Item>
              <Descriptions.Item label="报名站点名称">{ orgDetailInfo.siteConfigure?.name }</Descriptions.Item>
              <Descriptions.Item label="顶部信息">{ orgDetailInfo.siteConfigure?.showTitle }</Descriptions.Item>
              <Descriptions.Item label="报名网址">{ orgDetailInfo.siteConfigure?.domain }</Descriptions.Item>
              <Descriptions.Item label="移动端报名网址">{ orgDetailInfo.siteConfigure?.mobileDomain }</Descriptions.Item>
              <Descriptions.Item label="底部信息">{ orgDetailInfo.siteConfigure?.bottomInfo }</Descriptions.Item>
            </Descriptions>
          </div>
          <Tabs
            activeKey={ activeTabs }
            items={ filterTabsByAuth(tabsItems, userAuth) }
            onChange={ activeTabsChange }
          ></Tabs>
        </PageStatus>
      </Drawer>
    </div>
  )
})

export default EditOrgConfig
