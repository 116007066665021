import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'antd'

import eventBus from '../../../plugins/events'
import { getExaminationRoomExamineeList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

function ExaminationRoomExamineeTable (props) {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '姓名',
      key: 'examineesName',
      dataIndex: 'examineesName'
    },
    {
      title: '身份证',
      key: 'idCard',
      dataIndex: 'idCard'
    },
    {
      title: '报名点',
      key: 'applySiteName',
      dataIndex: 'applySiteName'
    },
    {
      title: '报考单位',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      title: '报考职位',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '大类',
      key: 'levelName',
      dataIndex: 'levelName'
    },
    {
      title: '小类',
      key: 'majorName',
      dataIndex: 'majorName'
    },
    {
      title: '报名序号',
      key: 'examineesNumber',
      dataIndex: 'examineesNumber'
    },
    {
      title: '座位号',
      key: 'seatNumber',
      dataIndex: 'seatNumber'
    },
    {
      title: '准考证号',
      key: 'candidateNumber',
      dataIndex: 'candidateNumber'
    }
  ]

  useEffect(() => {
    eventBus.addListener('needUpdateRoomList', eventListener)

    return () => {
      eventBus.removeListener('needUpdateRoomList', eventListener)
    }
  }, [])

  useEffect(() => {
    if (props.expanded) {
      getTableData()
    }
  }, [props.expanded])

  const eventListener = () => {
    getTableData()
  }

  const getTableData = () => {
    setLoading(true)
    const params = {
      examId: props.batchInfo.examId,
      batchId: props.batchInfo.batchId,
      locationId: props.locationInfo.locationId,
      roomId: props.roomInfo.roomId
    }

    getExaminationRoomExamineeList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考场考生信息失败'
      })
    })
  }

  return (<>
    <Table
      loading={ loading }
      columns={ columns }
      dataSource={ tableData }
      rowKey={ record => record.examineesNumber }
      pagination={ false }
    ></Table>
  </>)
}

export default ExaminationRoomExamineeTable
