import AppLoading from './AppLoading'
import { Result } from 'antd'

const PageStatus = ({ pageStatus, children, text, height }) => {

  const generatePageStatus = (status) => {
    switch (status) {
      case 'loading_only':
        return <div>
          <AppLoading
            height={ height || '100vh' }
            text={ text || '' }
          ></AppLoading>
        </div>
        break
      case 'loading':
      case '':
        return <div>
          {
            status === 'loading'
              ? <AppLoading
                spinning={ true }
                height={ height || '100vh' }
                text={ text || '' }
              ></AppLoading>
              : ''
          }
          { children }
        </div>
        break
      case 'fail':
        return <Result
          status="error"
          title="错误"
          subTitle="Please check and modify the following information before resubmitting."
        ></Result>
        break
      case 'error':
        return <Result
          status="500"
          title={ 'ERROR' }
          subTitle={ text || '网络错误，查询数据失败' }
        ></Result>
        break
      default:
        return children
    }
  }

  return (<>
    { generatePageStatus(pageStatus) }
  </>)
}

export default PageStatus
