import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Modal, Spin, Transfer } from 'antd'
import { useSelector } from 'react-redux'

import { getExaminationRoomApplySite } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const ApplySiteTransferModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [targetKeys, setTargetKeys] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const selectedExam = useSelector(state => state.examInfo.selectedExam)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getApplySiteList()
    }
  }, [modalVisible])

  const getApplySiteList = () => {
    setLoading(true)
    getExaminationRoomApplySite({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setLoading(false)
        setDataSource(res.data.map(item => {
          return {
            ...item,
            key: item.number,
            title: item.name
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询报名点列表失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    props.insertSuccess?.(targetKeys)
    hideModal()
  }

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys)
  }

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 660 }
        title={ '选择报名点' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Transfer
            listStyle={ {
              width: 300,
              minHeight: 300
            } }
            dataSource={ dataSource }
            targetKeys={ targetKeys }
            selectedKeys={ selectedKeys }
            rowKey={ (record) => {
              return record.number
            } }
            showSearch={ true }
            onChange={ onChange }
            onSelectChange={ onSelectChange }
            render={ (item) => item.title }
          >
          </Transfer>
        </Spin>
      </Modal>
    </div>
  )
})

export default ApplySiteTransferModal
