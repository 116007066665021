import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, Radio, Space, Table, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { deleteFileTemplateField, getFileTemplateFieldPage } from '../common/request'
import ResponseCode from '../common/response-code'
import EditFileTemplateFieldModal from '../views/file-template-field-management/EditFileTemplateFieldModal'
import AppButton from './AppButton'

function FileTemplateFieldManagement (props) {
  const editFileTemplateFieldModalRef = useRef()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [modalType, setModalType] = useState('')
  const [filterFieldByType, setFilterFieldByType] = useState(2)
  const [loading, setLoading] = useState(false)
  const [searchForm] = Form.useForm()

  const fieldTypeMap = {
    1: '文本',
    6: '图像',
    7: '表格'
  }

  useEffect(() => {
    if (props.modalVisible) {
      getTableData()
    }
  }, [pageNum, pageSize, filterFieldByType, props.orgItem, props.modalVisible])

  const columns = [
    {
      title: '字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        return fieldTypeMap[record]
      }
    },
    {
      title: '是否必传',
      dataIndex: 'required',
      key: 'required',
      render: (record) => {
        return record ? '必传' : '非必传'
      }
    },
    {
      title: '操作',
      width: '220px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <Space className="d-flex justify-content-end">
            <AppButton
              authCode={ `${ props.authCodePrefix }-bj` }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditApplyFormFieldModal) }
            >编辑</AppButton>
            <AppButton
              authCode={ `${ props.authCodePrefix }-sc` }
              type="primary"
              danger
              ghost
              onClick={ openModal.bind(this, row, showDeleteApplyFormFieldModal) }
            >删除</AppButton>
          </Space>
        </>)
      }
    }
  ]

  const handleSearch = () => {
    setPageNum(1)
    getTableData()
  }

  const getTableData = () => {
    const params = {
      fieldLevel: props.fieldLevel || 'S',
      orderBy: 'id',
      orderType: 'ASC',
      orgId: props.orgItem?.id,
      type: filterFieldByType,
      pageNum,
      pageSize
    }
    const { fieldName, fieldCode } = searchForm.getFieldsValue()
    fieldName && (params.fieldName = fieldName.trim())
    fieldCode && (params.fieldCode = fieldCode.trim())

    setLoading(true)
    getFileTemplateFieldPage(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch((err) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '查询文件模板字段列表失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateApplyFormFieldModal = () => {
    setModalType('create')
    editFileTemplateFieldModalRef.current.showModal()
  }

  const showEditApplyFormFieldModal = () => {
    setModalType('edit')
    editFileTemplateFieldModalRef.current.showModal()
  }

  const showDeleteApplyFormFieldModal = (row) => {
    Modal.confirm({
      title: '删除报名字段',
      centered: true,
      content: `确定要删除报名字段【${ row.fieldName }】吗？`,
      onOk: () => {
        deleteApplyFormFieldAction(row.id)
      }
    })
  }

  const deleteApplyFormFieldAction = (id) => {
    deleteFileTemplateField({ id: id }).then(res => {
      if (res.code === ResponseCode.success) {
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '删除文件模板字段失败'
      })
    })
  }

  const filterFieldByTypeChange = (e) => {
    setPageNum(1)
    setFilterFieldByType(e.target.value)
  }

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between">
        <Radio.Group
          value={ filterFieldByType }
          onChange={ filterFieldByTypeChange }
          buttonStyle="solid"
        >
          <Radio.Button value={ 2 }>笔试准考证字段</Radio.Button>
          <Radio.Button value={ 3 }>面试准考证字段</Radio.Button>
        </Radio.Group>
        <AppButton
          authCode={ `${ props.authCodePrefix }-xzzd` }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateApplyFormFieldModal) }
        >新增字段</AppButton>
      </div>
      <div className="mb-3">
        <Form
          layout="inline"
          form={ searchForm }
          initialValues={
            {
              fieldName: '',
              fieldCode: ''
            }
          }
          autoComplete="off"
        >
          <Form.Item
            label="字段名称"
            name="fieldName"
          >
            <Input
              placeholder="输入字段名称搜索"
              style={ { width: '180px' } }
            />
          </Form.Item>
          <Form.Item
            label="字段编码"
            name="fieldCode"
          >
            <Input
              placeholder="输入字段名称搜索"
              style={ { width: '180px' } }
            />
          </Form.Item>
          <Form.Item className="d-flex justify-content-center">
            <Button
              type="primary"
              onClick={ handleSearch }
            >
              查询
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditFileTemplateFieldModal
        ref={ editFileTemplateFieldModalRef }
        fieldType={ filterFieldByType }
        fieldItem={ currentEditItem }
        modalType={ modalType }
        fieldLevel={ props.fieldLevel || 'S' }
        orgItem={ props.orgItem }
        insertSuccess={ getTableData }
      ></EditFileTemplateFieldModal>
    </div>
  )
}

export default FileTemplateFieldManagement

