import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, Table } from 'antd'
import { accountTypeMap } from '../../../common/common'
import { getReceivingAccountList, setOrgPayAccount } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const EditReceivingAccountModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [tableData, setTableData] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setSelectedRowKeys(props.accountList.map(item => item.accountId))
      getTableData()
    }
  }, [modalVisible])

  const columns = [
    {
      title: '账户名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '户号',
      dataIndex: 'accountNumber',
      key: 'accountNumber'
    },
    {
      title: '收款单位',
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: '账户类型',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (data) => {
        return (<>
          { accountTypeMap[data] }
        </>)
      }
    }
  ]

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const getTableData = () => {
    const params = {
      orderBy: 'id',
      pageNum: 1,
      pageSize: 10,
      orgId: props.orgItem.id
    }

    getReceivingAccountList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询收款账户列表失败',
        centered: true
      })
    })
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const onOk = () => {
    const params = {
      id: props.orgItem.id,
      payAccountList: selectedRowKeys
    }

    setOrgPayAccount(params).then(res => {
      if (res.code === ResponseCode.success) {
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '设置收款账户失败',
        centered: true
      })
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        title="配置收款账户"
        width={ 800 }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Table
          rowSelection={ {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          } }
          rowKey={ (record) => record.accountId }
          columns={ columns }
          dataSource={ tableData.elements }
          pagination={ false }
        ></Table>
      </Modal>
    </div>
  )
})

export default EditReceivingAccountModal
