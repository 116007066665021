import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getExamDictData } from '../common/request'
import { Modal } from 'antd'
import ResponseCode from '../common/response-code'
import { updateDictInfo } from '../store/slice/dict-slice'

const GetExamDictData = ({ dictCode }) => {
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)

  useEffect(() => {
    if (dictCode) {
      getExamDictDataAction()
    }
  }, [dictCode])

  const getExamDictDataAction = () => {
    if (dictInfo[dictCode]) {
      return
    }

    getExamDictData({ dictCode: dictCode }).then(res => {
      if (res.code === ResponseCode.success) {
        dispatch(updateDictInfo({ dictKey: dictCode, dictValue: res.data }))
      } else {
        Modal.error({
          title: '获取字典数据失败',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '获取字典数据失败',
        content: `网络错误，查询字典【${ dictCode }】数据失败`
      })
    })
  }
}

export default GetExamDictData
