import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import ExamStatisticalByClassify from './ExamStatisticalByClassify'
import ExamSearchPositionByRule from './ExamSearchPositionByRule'
import ExamCollectFeeStatistical from './ExamCollectFeeStatistical'
import ExamStatisticalByPosition from './ExamStatisticalByPosition'
import SelectExam from '../../components/select-exam/SelectExam'
import { useSelector } from 'react-redux'
import NetworkError from '../network-error/NetworkError'

function ExamStatistical () {
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const userAuth = useSelector(state => state.userInfo.userAuth)
  const [tabItems, setTabItems] = React.useState([])

  const initTabItems = [
    {
      label: '按考试分类统计',
      key: 'statisticalByClassify',
      children: (
        <ExamStatisticalByClassify></ExamStatisticalByClassify>
      )
    },
    {
      label: '按职位统计',
      key: 'statisticalByPosition',
      children: (
        <ExamStatisticalByPosition></ExamStatisticalByPosition>
      )
    },
    {
      label: '收费统计',
      key: 'collectFeeStatistical',
      children: (
        <ExamCollectFeeStatistical></ExamCollectFeeStatistical>
      )
    },
    {
      label: '按条件查询职位',
      key: 'searchPositionByRule',
      children: (
        <ExamSearchPositionByRule></ExamSearchPositionByRule>
      )
    }
  ]

  useEffect(() => {
    if (userAuth.length > 0) {
      setTabItems(filterTabsByAuth(initTabItems, userAuth))
    }
  }, [userAuth])

  const filterTabsByAuth = (tabs, authList) => {
    let menu = null
    if (userInfo.currentRoleTag === 'superAdmin') {
      menu = tabs
    } else {
      menu = tabs.filter(tab => {
        return authList.some(auth => {
          return auth.type === 'menu' && `exam-statistical/${ tab.key }` === auth.path
        })
      })
    }

    return menu
  }

  return (<>
    <SelectExam>
      {
        tabItems.length > 0
          ? <Tabs items={ tabItems }></Tabs>
          : <NetworkError error={ { code: 500, msg: '暂无菜单权限，请联系管理员', hideBackBtn: true } }></NetworkError>
      }
    </SelectExam>
  </>)
}

export default ExamStatistical
