import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Table } from 'antd'
import { useSelector } from 'react-redux'

import CityRegionDictTable from './CityRegionDictTable'
import EditCityRegionModal from './EditCityRegionModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'

function RegionDictionaryManagement () {
  const tableRef = useRef()
  const editCityRegionModalRef = useRef()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const regionList = useSelector((state) => state.regionList.regionList)
  const [tableData, setTableData] = useState([])
  const [editRegionLevel, setEditRegionLevel] = useState('')
  const [needReloadRow, setNeedReloadRow] = useState({})

  useEffect(() => {
    getTableData()
  }, [regionList])

  const columns = [
    {
      title: '省级区域名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '省级区域编码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '操作',
      width: '200px',
      dataIndex: '',
      key: 'operation',
      render: (data, row) => {
        return (<div className="d-flex justify-content-end">
          <ComponentVisibleByAuth authCode={'button_zdgl-dyzd-bj'}>
            <Button
              type="primary"
              ghost
              onClick={ showEditCityRegionModal.bind(this, row) }
            >添加市级行政区</Button>
          </ComponentVisibleByAuth>
        </div>)
      }
    }
  ]

  const showEditCityRegionModal = (row) => {
    setCurrentEditItem(row)
    setEditRegionLevel('city')
    editCityRegionModalRef.current.showModal(row.code)
  }

  const getTableData = () => {
    setTableData(regionList)
  }

  const updateExpandedTable = () => {
    setNeedReloadRow(currentEditItem)
  }

  return (
    <div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          ref={ tableRef }
          columns={ columns }
          dataSource={ tableData }
          rowKey={ (record) => record.id }
          scroll={ {
            y: 'calc(100vh - 240px)'
          } }
          expandable={ {
            expandedRowRender: (record, index, indent, expanded) => {
              return (
                <CityRegionDictTable
                  parentRegion={ record }
                  rowKey={ record.id }
                  needReloadRow={ needReloadRow }
                  expanded={ expanded }
                ></CityRegionDictTable>
              )
            }
          } }
          pagination={ false }>
        </Table>
      </ConfigProvider>

      <EditCityRegionModal
        modalType={ 'create' }
        ref={ editCityRegionModalRef }
        regionItem={ currentEditItem }
        regionLevel={ editRegionLevel }
        insertSuccess={ updateExpandedTable }
      ></EditCityRegionModal>
    </div>
  )
}

export default RegionDictionaryManagement
