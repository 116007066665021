import { createSlice } from '@reduxjs/toolkit'

export const roleSlice = createSlice({
  name: 'role-list',
  initialState: {
    roleList: [],
    roleTagMap: []
  },
  reducers: {
    updateRoleList: (state, { payload }) => {
      state.roleList = payload.roleList
    },
    updateRoleTagMap: (state, { payload }) => {
      state.roleTagMap = payload.roleTagMap
    }
  }
})

export const { updateRoleList, updateRoleTagMap } = roleSlice.actions

export default roleSlice.reducer
