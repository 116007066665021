import React, { useEffect, useState } from 'react'
import { Button, InputNumber, Modal, notification, Radio, Spin } from 'antd'

import Styles from './index.module.scss'
import { examApplyCostMap } from '../../../../common/common'
import { getExamCost, updateExamCost } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import SubjectListFormItem from './SubjectListFormItem'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import { useSelector } from 'react-redux'

function SettingExamApplyCost () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [costType, setCostType] = useState(1)
  const [costStandard, setCostStandard] = useState(0)
  const [subjectList, setSubjectList] = useState([])
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (selectedExam.id) {
      getInitData()
    }
  }, [selectedExam])

  const getInitData = () => {
    const params = {
      id: selectedExam.id
    }

    setLoading(true)
    getExamCost(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        if (res.data.subjectList) {
          setSubjectList(res.data.subjectList)
        }

        if (res.data.costType) {
          setCostType(res.data.costType)
        }

        if (res.data.costStandard) {
          setCostStandard(res.data.costStandard)
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询报名费用设置失败',
        centered: true
      })
    })
  }

  const costTypeChange = (e) => {
    setCostType(e.target.value)
  }

  const costStandardChange = (value) => {
    setCostStandard(Number(value))
  }

  const subjectsCostChange = (list) => {
    setSubjectList(list)
  }

  const getParams = () => {
    let params = {
      costType: costType,
      id: selectedExam.id
    }

    switch (costType) {
      case examApplyCostMap.uniformCharge.value:
        params.costStandard = costStandard
        break
      case examApplyCostMap.chargeBySubject.value:
        params.subjectList = subjectList.map(item => {
          return {
            subjectId: item.id,
            cost: item.cost
          }
        })
        break
    }

    return params
  }

  const updateExamCostAction = () => {
    const params = getParams()
    setLoading(true)
    updateExamCost(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '设置报名费用失败',
        centered: true
      })
    })
  }

  const getFormContent = () => {
    if (costType === examApplyCostMap.uniformCharge.value) {
      return (
        <div className="d-flex align-items-center">
          <div className={ Styles.formLabel }>考试费用:</div>
          <ComponentDisabled
            disabledTip={ '报名已开始，禁止修改' }
            disabled={ selectedExam.examinationStatus > 0 }
          >
            <InputNumber
              value={ costStandard }
              min={ 0 }
              suffix={ '元' }
              style={ { width: '120px' } }
              onChange={ costStandardChange }
            ></InputNumber>
          </ComponentDisabled>

        </div>
      )
    } else {
      return (<>
        <div className="d-flex">
          <div className={ Styles.formLabel }>科目列表:</div>
          <div>
            <SubjectListFormItem
              subjectList={ subjectList }
              onChange={ subjectsCostChange }
            ></SubjectListFormItem>
          </div>
        </div>
      </>)
    }
  }

  return (
    <div>
      { contextHolder }
      <Spin spinning={ loading }>
        <div className="mb-3 d-flex align-items-center">
          <div>缴费方式：</div>
          <ComponentDisabled
            disabledTip={ '报名已开始，禁止修改' }
            disabled={ selectedExam.examinationStatus > 0 }
          >
            <Radio.Group value={ costType } onChange={ costTypeChange }>
              {
                Object.values(examApplyCostMap).map(item => {
                  return <Radio value={ item.value } key={ item.value }>{ item.label }</Radio>
                })
              }
            </Radio.Group>
          </ComponentDisabled>
          <div className="text-danger">(缴费方式只能选择唯一)</div>
        </div>

        <div style={ { height: '260px' } }>
          { getFormContent(costType) }
        </div>

        <div>
          <ComponentDisabled
            disabledTip={ '报名已开始，禁止修改' }
            disabled={ selectedExam.examinationStatus > 0 }
          >
            <Button type={ 'primary' } onClick={ updateExamCostAction }>保存</Button>
          </ComponentDisabled>
        </div>
      </Spin>
    </div>
  )
}

export default SettingExamApplyCost
