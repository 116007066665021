import React, { useEffect, useState, useRef } from 'react'
import { Button, Modal, notification, Space, Table } from 'antd'

import { deleteRegionDict, getRegionList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import EditCityRegionModal from './EditCityRegionModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'

function CountyRegionDictTable (props) {
  const editCityRegionModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [editRegionLevel, setEditRegionLevel] = useState('')
  const [modalType, setModalType] = useState('')

  useEffect(() => {
    getTableData()
  }, [props.expanded])

  useEffect(() => {
    if (props.rowKey === props.needReloadRow.id) {
      getTableData()
    }
  }, [props.needReloadRow])

  const columns = [
    {
      title: '县级区域名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '县级区域编码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '操作',
      width: '240px',
      dataIndex: '',
      key: 'operation',
      render: (data, row) => {
        return (
          <ComponentVisibleByAuth authCode={ 'button_zdgl-dyzd-bj' }>
            <Space className="d-flex justify-content-end">
              <Button
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditCountyRegionModal) }
              >编辑</Button>
              <Button
                type="primary"
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteCountyModal) }
              >删除</Button>
            </Space>
          </ComponentVisibleByAuth>
        )
      }
    }
  ]

  const getTableData = () => {
    const params = { parent: props.parentRegion.code }

    getRegionList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询区域数据失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showEditCountyRegionModal = (row) => {
    setModalType('edit')
    setEditRegionLevel('county')
    editCityRegionModalRef.current.showModal(props.parentRegion.code)
  }

  const showDeleteCountyModal = (row) => {
    Modal.confirm({
      title: '删除县级区域',
      centered: true,
      content: (
        <div>确定要删除县级区域【{ row.name }】吗？</div>
      ),
      onOk () {
        deleteCountyRegionAction(row)
      }
    })
  }

  const deleteCountyRegionAction = (row) => {
    setLoading(true)
    deleteRegionDict({ id: row.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '删除区域数据失败',
        centered: true
      })
    })
  }

  return (<>
    { contextHolder }
    <Table
      sticky
      loading={ loading }
      columns={ columns }
      dataSource={ tableData }
      rowKey={ (record) => record.id }
      pagination={ false }
    ></Table>

    <EditCityRegionModal
      modalType={ modalType }
      ref={ editCityRegionModalRef }
      regionItem={ currentEditItem }
      regionLevel={ editRegionLevel }
      insertSuccess={ getTableData }
    ></EditCityRegionModal>
  </>)
}

export default CountyRegionDictTable
