import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Spin } from 'antd'
import { updateExamBasicInfo, updateExamName } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

const EditExamNameModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldValue('name', props.examInfo.name)
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)

      const params = {
        id: props.examInfo.id,
        ...fields
      }

      updateExamName(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          hideModal()
          notification.success({
            message: '操作成功'
          })
          props.insertSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，修改考试名称失败'
        })
      })
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '修改考试名称' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item
              label={ '考试名称' }
              name={ 'name' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入考试名称' }></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamNameModal
