import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, ConfigProvider, Form, Modal, Space, Table } from 'antd'
import { getDictList } from '../common/request'
import ResponseCode from '../common/response-code'
import { getUid } from '../plugins/utils'
import EditTableHeaderForm from './EditTableHeaderForm'

const EditFieldTypeTableHeadModal = forwardRef((props, ref) => {
  const editTableHeadFormModalRef = useRef()
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectOptions, setSelectOptions] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getSelectOptions()
    }
  }, [modalVisible])

  const columns = [
    {
      title: '表头名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '字段类型',
      dataIndex: 'type',
      key: 'type',
      render: (record) => {
        return typeOptions.find(item => {
          return item.value === record
        })?.label
      }
    },
    {
      title: '关联数据',
      dataIndex: 'content',
      key: 'content',
      render: (record) => {
        return selectOptions.find(item => {
          return item.value === record
        })?.label
      }
    },
    {
      title: '字段描述',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: '120px',
      render: (record, row) => {
        return (<Space>
          <Button
            type={ 'primary' }
            ghost
            onClick={ showEditTableHeadFormModal.bind(this, row) }
          >编辑</Button>
          <Button
            type={ 'primary' }
            ghost
            danger
            onClick={ deleteTableHead.bind(this, row) }
          >删除</Button>
        </Space>)
      }
    }
  ]

  const typeOptions = [
    {
      label: '单行文本框',
      value: 'a'
    },
    {
      label: '下拉列表',
      value: 's'
    },
    {
      label: '日期选择器',
      value: 't'
    }
  ]

  const deleteTableHead = (row) => {
    setTableData([].concat(tableData.filter(item => {
      return item.id !== row.id
    })))
  }

  const getSelectOptions = () => {
    const params = {
      orderBy: 'id',
      pageNum: 1,
      pageSize: 9999
    }

    getDictList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setSelectOptions(res.data.elements.map(item => {
          return {
            label: item.dictName,
            value: item.dictCode
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '查询字段类型字典失败'
      })
    })
  }

  const showModal = (data) => {
    if (data.length > 0) {
      setTableData(Object.values(data[0]).map(item => {
        return {
          ...item,
          id: getUid()
        }
      }))
    }

    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setTableData([])
  }

  const onOk = () => {
    hideModal()
    form.resetFields()
    props.insertSuccess && props.insertSuccess(tableData)
  }

  const showEditTableHeadFormModal = (row) => {
    editTableHeadFormModalRef.current.showModal(row)
  }

  const saveTableHeadField = (headFieldInfo) => {
    if (headFieldInfo.id) {
      setTableData([].concat(tableData.map(item => {
        if (item.id === headFieldInfo.id) {
          return {
            ...item,
            ...headFieldInfo
          }
        } else {
          return item
        }
      })))
    } else {
      setTableData([].concat(tableData, {
        ...headFieldInfo,
        id: getUid()
      }))
    }
  }

  return (
    <div>
      <Modal
        title={ '配置表头字段' }
        open={ modalVisible }
        width={ 920 }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Space
          className={ 'w-100 py-2' }
          direction={ 'vertical' }
          align={ 'end' }
        >
          <Button
            type={ 'primary' }
            onClick={ showEditTableHeadFormModal.bind(this, null) }
          >添加表头字段</Button>
        </Space>
        <ConfigProvider componentSize={ 'small' }>
          <Table
            columns={ columns }
            dataSource={ tableData }
            rowKey={ (record) => record.id }
            scroll={ {
              y: 300
            } }
            pagination={ false }
          ></Table>
        </ConfigProvider>
      </Modal>

      <EditTableHeaderForm
        ref={ editTableHeadFormModalRef }
        selectOptions={ selectOptions }
        saveTableHeadField={ saveTableHeadField }
      ></EditTableHeaderForm>
    </div>
  )
})

export default EditFieldTypeTableHeadModal
