import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, Radio, notification, Spin } from 'antd'

import { createDict, updateDict } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { replaceChineseInitials } from '../../../plugins/utils'

const EditDictModal = forwardRef((props, ref) => {
  const [editDictForm] = Form.useForm()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('新增字典')
      } else {
        setModalTitle('编辑字典')
        editDictForm.setFieldsValue({
          dictName: props.dictItem.dictName,
          dictCode: props.dictItem.dictCode,
          state: props.dictItem.state
        })
      }
    }

  }, [modalVisible])

  useImperativeHandle(ref, () => {
    return {
      showModal: showCreatDictModal
    }
  })

  const showCreatDictModal = () => {
    setModalVisible(true)
  }

  const hideEditDictModal = () => {
    setModalVisible(false)
    editDictForm.resetFields()
  }

  const createDictAction = (fields) => {
    const params = {
      ...fields,
      dictType: 0
    }

    setLoading(true)
    createDict(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditDictModal()
        notifyApi.success({
          message: '操作成功',
          description: `已创建字典【${ params.dictName }】`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建字典失败',
        centered: true
      })
    })
  }

  const updateDictAction = (fields) => {
    const params = {
      ...fields,
      id: props.dictItem.id,
      dictType: 0
    }

    setLoading(true)
    updateDict(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditDictModal()
        notifyApi.success({
          message: '操作成功',
          description: `更新字典成功`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新字典失败',
        centered: true
      })
    })
  }

  const onOK = () => {
    editDictForm.validateFields().then(fields => {
      if (props.modalType === 'create') {
        createDictAction(fields)
      } else {
        updateDictAction(fields)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const dictNameChange = (e) => {
    if (e.target.value.trim()) {
      editDictForm.setFieldValue('dictCode', `sys_${ replaceChineseInitials(e.target.value) }`)
    }
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        open={ modalVisible }
        title={ modalTitle }
        confirmLoading={ loading }
        onOk={ onOK }
        onCancel={ hideEditDictModal }>
        <Spin spinning={ loading }>
          <Form
            form={ editDictForm }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            initialValues={
              {
                dictName: '',
                dictCode: '',
                state: 1
              }
            }
          >

            <Form.Item label="字典名称" required name="dictName" rules={ [{ required: true, message: '字典名称不能为空' }] }>
              <Input onChange={ dictNameChange }></Input>
            </Form.Item>
            <Form.Item label="字典编码" required name="dictCode" rules={ [{ required: true, message: '字典值不能为空' }] }>
              <Input></Input>
            </Form.Item>
            <Form.Item label="字典状态" required name="state">
              <Radio.Group>
                <Radio value={ 1 }> 启用 </Radio>
                <Radio value={ 0 }> 禁用 </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditDictModal
