import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Image, Modal } from 'antd'
import { useSelector } from 'react-redux'

const ViewExamineeFileModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [fileItem, setFileItem] = useState({})
  const [isPdf, setIsPdf] = useState(false)
  const envInfo = useSelector(state => state.env.envInfo)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    setFileItem(props.fileItem)
    setIsPdf(props.fileItem?.filePath?.includes('.pdf'))
  }, [props.fileItem])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ fileItem.fileName }
        width={ isPdf ? 1000 : 600 }
        open={ modalVisible }
        onCancel={ hideModal }
        footer={ [] }
      >
        <div className={ 'd-flex justify-content-center' }>
          {
            isPdf ?
              <iframe
                src={ `${ envInfo.picDomain }/${ fileItem.filePath }` }
                style={ { width: '100%', height: '700px', borderWidth: 0 } }
              ></iframe> :
              <Image src={ `${ envInfo.picDomain }/${ fileItem.filePath }` }></Image>
          }
        </div>
      </Modal>

    </div>
  )
})

export default ViewExamineeFileModal
