import { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, Space, Table, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { deletePositionFields, getPositionList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import EditPositionFieldsModal from './EditPositionFieldsModal'
import AppButton from '../../components/AppButton'

function PositionFieldManagement () {
  const editPositionFieldsModalRef = useRef()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [modalType, setModalType] = useState('create')
  const [searchForm] = Form.useForm()

  useEffect(() => {
    getTableData()
  }, [pageNum, pageSize])

  const columns = [
    {
      title: '排序',
      dataIndex: 'fieldSort',
      key: 'fieldSort'
    },
    {
      title: '职位字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '职位字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        if (String(record) === '1') {
          return '文本'
        } else if (String(record) === '2') {
          return '下拉选项'
        }
      }
    },
    {
      title: '是否启用',
      dataIndex: 'state',
      key: 'state',
      render: (record) => {
        return (record && Number(record) === 1) ? '启用' : '禁用'
      }
    },
    {
      title: '操作',
      width: '220px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <Space className="d-flex justify-content-end">
            <AppButton
              authCode={ 'button_zwzdgl-bj' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditPositionFieldsModal) }
            >编辑</AppButton>
            <AppButton
              authCode={ 'button_zwzdgl-sc' }
              type="primary"
              danger
              ghost
              onClick={ openModal.bind(this, row, showDeletePositionFieldsModal) }
            >删除</AppButton>
          </Space>
        </>)
      }
    }
  ]

  const handleSearch = () => {
    setPageNum(1)
    getTableData()
  }

  const getTableData = () => {
    const params = {
      fieldLevel: 'S',
      orderBy: 'fieldSort',
      orderType: 'ASC',
      pageNum,
      pageSize
    }
    const { fieldName, fieldCode } = searchForm.getFieldsValue()
    fieldName && (params.fieldName = fieldName.trim())
    fieldCode && (params.fieldCode = fieldCode.trim())

    getPositionList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '查询职位字段列表失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreatePositionModal = () => {
    setModalType('create')
    editPositionFieldsModalRef.current.showModal()
  }

  const showEditPositionFieldsModal = () => {
    setModalType('edit')
    editPositionFieldsModalRef.current.showModal()
  }

  const showDeletePositionFieldsModal = (row) => {
    Modal.confirm({
      title: '删除职位字段',
      centered: true,
      content: `确定要删除职位字段【${ row.fieldName }】吗？`,
      onOk: () => {
        deletePositionFieldsAction([row.id])
      }
    })
  }

  const deletePositionFieldsAction = (idList) => {
    deletePositionFields({ ids: idList }).then(res => {
      if (res.code === ResponseCode.success) {
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '网络错误',
        centered: true,
        content: '删除职位字段失败'
      })
    })
  }

  return (
    <div>
      <div className="mb-2 d-flex justify-content-between">
        <div>
          <Form
            layout="inline"
            form={ searchForm }
            initialValues={
              {
                fieldName: '',
                fieldCode: ''
              }
            }
            autoComplete="off">
            <Form.Item
              label="字段名称"
              name="fieldName">
              <Input placeholder="输入字段名称搜索" style={ { width: '180px' } }/>
            </Form.Item>
            <Form.Item
              label="字段编码"
              name="fieldCode">
              <Input placeholder="输入字段名称搜索" style={ { width: '180px' } }/>
            </Form.Item>
            <Form.Item className="d-flex justify-content-center">
              <Button type="primary" onClick={ handleSearch }>
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
        <AppButton
          authCode={ 'button_zwzdgl-xzzwzd' }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreatePositionModal) }
        >新增职位字段</AppButton>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditPositionFieldsModal
        ref={ editPositionFieldsModalRef }
        modalType={ modalType }
        fieldItem={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditPositionFieldsModal>
    </div>
  )
}

export default PositionFieldManagement
