import React from 'react'
import CommonComponent from './CommonComponent'

function LogRecordChecker () {
  return (
    <div>
      <CommonComponent system={ 'check' }></CommonComponent>
    </div>
  )
}

export default LogRecordChecker
