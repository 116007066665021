import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { notification, Modal, Form, Input } from 'antd'
import { createExamClass, updateExamClass } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const CreateExamClassModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState('create')
  const [notifyApi, contextHolder] = notification.useNotification()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (type = 'create', row = {}) => {
    setModalType(type)
    form.setFieldsValue({
      ...row
    })
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const createLevelAction = (fields) => {
    const params = {
      examId: props.examInfo.id,
      ...fields
    }

    return createExamClass(params)
  }

  const updateLevelAction = (fields) => {
    const params = {
      ...fields,
      examId: props.examInfo.id,
      id: fields.levelId
    }

    return updateExamClass(params)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      let request

      if (modalType === 'create') {
        request = createLevelAction
      } else {
        request = updateLevelAction
      }
      request(fields).then(res => {
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess(modalType)
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        Modal.error({
          title: '网络错误',
          content: '创建考试分类失败',
          centered: true
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const levelCodeChange = (e) => {
    form.setFieldValue('levelCode', e.target.value.replace(/\D/g, ''))
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ `${modalType === 'create' ? '新增' : '编辑'}考试大类` }
        open={ modalVisible }
        style={ { position: 'absolute', right: '230px' } }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <div className="mt-3">
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item noStyle name="levelId">
            </Form.Item>
            <Form.Item
              label="考试大类代码"
              name="levelCode"
              rules={ [{ required: true, message: '考试大类代码不能为空' }] }
            >
              <Input
                placeholder="请输入考试大类代码"
                style={ { width: '100%' } }
                disabled={ modalType !== 'create' }
                onChange={ levelCodeChange }
              ></Input>
            </Form.Item>
            <Form.Item
              label="考试大类名称"
              name="levelName"
              rules={ [{ required: true, message: '考试大类名称不能为空' }] }
            >
              <Input placeholder="请输入考试大类名称"></Input>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
})
export default CreateExamClassModal
