import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Modal, notification, Select, Space, Table, Upload, Image } from 'antd'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import {
  clearManualAllocationData,
  downloadManualAllocationTemplate,
  findExamCertificateTemplate, generateSeatTable, getExaminationAllocationProcess,
  getManualAllocationExamineeList, getManualAllocationStatus, importExamineePrintStatus, updateManualAllocateStatus,
  uploadExaminee,
  uploadExamineeImage,
} from '../../common/request'
import ResponseCode from '../../common/response-code'
import router from '../../routers/router'
import ValidateAllocateStatus from '../../components/ValidateAllocateStatus'
import eventBus from '../../plugins/events'
import ViewExamineeInfoModal from './ViewExamineeInfoModal'
import EditExamineeInfoModal from './EditExamineeInfoModal'
import { downloadFile, throttle } from '../../plugins/utils'
import PageStatus from '../../components/PageStatus'
import DeIdentification from '../../components/DeIdentification'

function ExaminationManualAllocation () {
  const viewExamineeInfoModalRef = useRef()
  const editExamineeInfoModalRef = useRef()
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const envInfo = useSelector(state => state.env.envInfo)
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [columns, setColumns] = useState([])
  const [allocationStatus, setAllocationStatus] = useState(0)
  const [allocateProgress, setAllocateProgress] = useState()
  const [pageStatus, setPageStatus] = useState({ text: '', status: '' })

  const selectOptions = [
    { label: '全部', value: '' },
    { label: '无', value: 0 },
    { label: '有', value: 1 }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getManualAllocationStatusAction()
      getTableData()
      getColumnList()
    }
  }, [selectedExam, pageSize, pageNum])

  useEffect(() => {
    if (selectedExam.id) {
      getExaminationAllocationProcessAction()
    }
  }, [selectedExam])

  const getExaminationAllocationProcessAction = () => {
    setPageStatus({ text: '正在查询考试分配进度', status: 'loading' })
    getExaminationAllocationProcess({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        setAllocateProgress(res.data.allocateProgress)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setPageStatus({ text: '', status: '' })
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试分配进度失败'
      })
    })
  }

  const getManualAllocationStatusAction = () => {
    setPageStatus({ text: '正在查询考试分配状态', status: 'loading' })
    getManualAllocationStatus({ id: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        setAllocationStatus(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试分配状态错误'
      })
    })
  }

  const getColumnList = () => {
    const params = {
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum: 1,
      pageSize: 9999
    }
    findExamCertificateTemplate(params).then(res => {
      if (res.code === ResponseCode.success) {
        let columnsData = res.data?.fieldSet || []
        columnsData = columnsData.toSorted((a, b) => {
          return a.fieldSort - b.fieldSort
        })

        setColumns([...columnsData?.map(item => {
          return {
            ...item,
            title: item.fieldName,
            key: item.fieldCode,
            dataIndex: item.fieldCode,
            render: (record, row) => {
              return item.fieldType !== '6'
                ? (<DeIdentification value={ row[item.fieldCode] } type={ item.fieldCode }></DeIdentification>)
                : (row[item.fieldCode] ? (<Image src={ `${ envInfo.picDomain }/${ (row[item.fieldCode]) }` }></Image>) : '暂无数据')
            }
          }
        }),
          {
            title: '打印状态',
            key: 'printStatus',
            dataIndex: 'printStatus',
            width: 100,
            render: (record, row) => {
              return !!row.printCertificate ? '已打印' : '未打印'
            }
          },
          {
          title: '操作',
          key: 'operation',
          width: 180,
          dataIndex: 'operation',
          fixed: 'right',
          render: (record, row) => {
            return (<Space className={ 'd-flex justify-content-center' }>
              <Button
                type={ 'primary' }
                ghost
                size={ 'small' }
                onClick={ showViewExamineeInfoModal.bind(this, row) }
              >查看</Button>
              <Button
                type={ 'primary' }
                ghost
                size={ 'small' }
                onClick={ showEditExamineeInfoModal.bind(this, row) }
              >修改</Button>
            </Space>)
          }
        }])
      }
    })
  }

  const getTableData = () => {
    const searchData = form.getFieldsValue()
    setPageStatus({ text: '正在查询考生列表', status: 'loading' })
    const params = {
      ...searchData,
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum,
      pageSize
    }

    getManualAllocationExamineeList(params).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生列表失败'
      })
    })
  }

  const isJsonObject = (obj) => {
    try {
      JSON.parse(obj)
      return true
    } catch (error) {
      return false
    }
  }

  const downloadManualAllocationTemplateAction = () => {
    setPageStatus({ text: '正在下载模板', status: 'loading' })
    downloadManualAllocationTemplate({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (!isJsonObject(e.target.result)) {
            resolve(res)
          } else {
            reject(JSON.parse(e.target.result).msg)
          }
        }
        reader.readAsText(new Blob([res], {
          type: 'application/json'
        }))
      }).then((data) => {
        downloadFile(data, 'xlsx', '考场安排模板').catch(err => {
          console.log(err)
          Modal.error({
            title: '错误',
            content: err,
          })
        })
      }).catch(err => {
        Modal.error({
          title: '错误',
          content: err
        })
      })
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，下载模板失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const showViewExamineeInfoModal = (row) => {
    viewExamineeInfoModalRef.current.showModal(row)
  }

  const showEditExamineeInfoModal = (row) => {
    editExamineeInfoModalRef.current.showModal(row)
  }

  const beforeUpload = () => {
    setPageStatus({ text: '正在导入数据', status: 'loading' })
  }

  const uploadPersonImageChange = ({ file }) => {
    const res = file.response
    if (res) {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        getTableData()
        getManualAllocationStatusAction()
        Modal.success({
          title: '操作成功',
          content: res.msg
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  const clearImportAllocationData = () => {
    Modal.confirm({
      title: '清空数据',
      content: '确定要清除数据吗？',
      onOk: () => {
        clearAllocationData()
      }
    })
  }

  const clearAllocationData = () => {
    setPageStatus({ text: '正在清空数据', status: 'loading' })
    clearManualAllocationData({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        getTableData()
        notification.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，清除数据失败'
      })
    })
  }

  const generateSeatTableAction = () => {
    setPageStatus({ text: '正在生成座次表', status: 'loading' })
    generateSeatTable({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        getTableData()
        Modal.confirm({
          title: '操作成功',
          content: '下载任务已添加，是否跳转到下载中心查看',
          onOk: () => {
            const siteId = sessionStorage.getItem('siteId')
            router.navigate(`/${ siteId }/exam/download-center`)
          }
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，座次表生成失败'
      })
    })
  }

  const allocateFinish = () => {
    const params = {
      examId: selectedExam.id,
      type: 1
    }

    setPageStatus({ text: '正在保存考场安排', status: 'loading' })
    updateManualAllocateStatus(params).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        getManualAllocationStatus()
        eventBus.emit('reloadExamDetail', selectedExam.id)
        notification.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setPageStatus({ text: '', status: '' })
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考场安排失败'
      })
    })
  }

  const resetAllocation = () => {
    setPageStatus({ text: '正在重置考场安排', status: 'loading' })
    Promise.all([
      updateManualAllocateStatus({
        examId: selectedExam.id,
        type: -1
      }),
      clearManualAllocationData({ examId: selectedExam.id })
    ]).then(([res1, res2]) => {
      setPageStatus({ text: '', status: '' })
      if (res1.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: res1.msg
        })
        return
      }

      if (res2.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: res2.msg
        })
        return
      }

      notification.success({
        message: '操作成功',
        description: '已重置导入状态并清除笔试数据'
      })

      getManualAllocationStatusAction()
      eventBus.emit('reloadExamDetail', selectedExam.id)
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，清除数据失败'
      })
    })
  }

  const onSearchParamsChange = () => {
    setPageNum(1)
    getTableData()
  }

  const importPrintStatus = () => {
    setPageStatus({ text: '正在导出打印状态', status: 'loading' })
    importExamineePrintStatus({
      examId: selectedExam.id,
      printCertificate: form.getFieldValue('printCertificate')
    }).then(res => {
      setPageStatus({ text: '', status: '' })
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (!isJsonObject(e.target.result)) {
            resolve(res)
          } else {
            reject(JSON.parse(e.target.result).msg)
          }
        }
        reader.readAsText(new Blob([res], {
          type: 'application/json'
        }))
      }).then((data) => {
        downloadFile(res, 'xlsx', `考生打印状态-${ selectedExam.id }`).catch(err => {
          console.log(err)
          Modal.error({
            title: '错误',
            content: err,
          })
        })
      }).catch(err => {
        Modal.error({
          title: '错误',
          content: err
        })
      })
    }).catch(err => {
      setPageStatus({ text: '', status: '' })
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，导出考生打印状态失败'
      })
    })
  }

  return (<>
    <SelectExam>
      <PageStatus text={ pageStatus.text } pageStatus={ pageStatus.status }>
        <ValidateAllocateStatus
          allocateType={ 'import' }
          allocateProcess={ allocateProgress }
        >
          <div className={ 'my-3 d-flex align-items-center justify-content-between' }>
            <Space>
              {
                allocationStatus === 1 || selectedExam.examinationStatus >= 2
                  ? <></>
                  : (<>
                    <Button
                      type={ 'primary' }
                      ghost
                      onClick={ downloadManualAllocationTemplateAction }
                    >下载模板</Button>
                    <Upload
                      name={ 'file' }
                      action={ `${ envInfo.picDomain }/${ uploadExaminee }` }
                      headers={ {
                        authorization: window.sessionStorage.getItem('access_token')
                      } }
                      disabled={ pageStatus.status === 'loading' }
                      maxCount={ 1 }
                      data={ {
                        examId: selectedExam.id
                      } }
                      showUploadList={ false }
                      beforeUpload={ beforeUpload }
                      onChange={ uploadPersonImageChange }
                    >
                      <Button
                        type={ 'primary' }
                        ghost
                      >导入考生</Button>
                    </Upload>
                    <Upload
                      name={ 'file' }
                      action={ `${ envInfo.picDomain }/${ uploadExamineeImage }` }
                      headers={ {
                        authorization: window.sessionStorage.getItem('access_token')
                      } }
                      disabled={ pageStatus.status === 'loading' }
                      maxCount={ 1 }
                      data={ {
                        examId: selectedExam.id
                      } }
                      showUploadList={ false }
                      beforeUpload={ beforeUpload }
                      onChange={ uploadPersonImageChange }
                    >
                      <Button
                        type={ 'primary' }
                        ghost
                      >导入照片</Button>
                    </Upload>
                    <Button
                      type={ 'primary' }
                      ghost
                      danger
                      onClick={ clearImportAllocationData }
                    >清除数据</Button>
                  </>)
              }

              <Button
                type={ 'primary' }
                ghost
                onClick={ generateSeatTableAction }
              >座次表生成</Button>

            </Space>

            <Space>
              <Button
                type={ 'primary' }
                onClick={ importPrintStatus }
                ghost
              >导出考生打印状态</Button>
              {
                allocationStatus === 1
                  ? <Button
                    disabled={ (selectedExam.examinationStatus >= 2) }
                    type={ 'primary' }
                    onClick={ resetAllocation }
                  >重新导入</Button>
                  : <Button
                    type={ 'primary' }
                    disabled={ tableData.elements?.length === 0 }
                    onClick={ allocateFinish }
                  >完成导入</Button>
              }
            </Space>

          </div>
          <Form
            className={ 'mb-3' }
            layout={ 'inline' }
            form={ form }
            initialValues={ {
              name: '',
              idCard: '',
              examineesNumber: '',
              associate: '',
              printCertificate: ''
            } }
            onFieldsChange={ throttle(onSearchParamsChange, 300) }
          >
            <Space wrap size={ 8 }>
              <Form.Item
                label={ '考生姓名' }
                name={ 'name' }
              >
                <Input placeholder={ '输入考生姓名搜索' }></Input>
              </Form.Item>
              <Form.Item
                label={ '身份证号' }
                name={ 'idCard' }
              >
                <Input placeholder={ '输入身份证号搜索' }></Input>
              </Form.Item>
              <Form.Item
                label={ '报名序号' }
                name={ 'examineesNumber' }
              >
                <Input placeholder={ '输入报名序号搜索' }></Input>
              </Form.Item>
              <Form.Item
                label={ '个人照片' }
                name={ 'associate' }
              >
                <Select options={ selectOptions } style={ { width: 120 } }></Select>
              </Form.Item>
              <Form.Item
                label={ '打印状态' }
                name={ 'printCertificate' }
              >
                <Select options={ [
                  { label: '全部', value: '' },
                  { label: '未打印', value: 0 },
                  { label: '已打印', value: 1 }
                ] } style={ { width: 120 } }></Select>
              </Form.Item>
            </Space>
          </Form>

          <Table
            loading={ pageStatus.status === 'loading' }
            columns={ columns }
            dataSource={ tableData.elements }
            rowKey={ record => record.id }
            scroll={ {
              x: 2060
            } }
            pagination={ {
              showTotal: (total) => {
                return `共 ${ total } 条`
              },
              size: 'normal',
              showSizeChanger: true,
              total: tableData.totalElements,
              pageSize: pageSize,
              current: pageNum,
              onChange: paginationChange
            } }
          ></Table>
        </ValidateAllocateStatus>
      </PageStatus>

      <ViewExamineeInfoModal
        ref={ viewExamineeInfoModalRef }
        columns={ columns }
        ignoreCheck={ true }
      ></ViewExamineeInfoModal>
      <EditExamineeInfoModal
        ref={ editExamineeInfoModalRef }
        columns={ columns }
        updateExamineeSuccess={ getTableData }
      ></EditExamineeInfoModal>
    </SelectExam>
  </>)
}

export default ExaminationManualAllocation
