import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Empty, Modal, notification, Space, Spin } from 'antd'

import { clearManualAllocationData, examinationAllocationReset, updateManualAllocateStatus } from '../common/request'
import ResponseCode from '../common/response-code'
import eventBus from '../plugins/events'

function ValidateAllocateStatus (props) {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)

  const allocationAgain = () => {
    setLoading(true)
    examinationAllocationReset({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        props.updateAllocateProgress?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考试分配信息失败'
      })
    })
  }

  const clearAllocationData = () => {
    setLoading(true)
    Promise.all([
      updateManualAllocateStatus({
        examId: selectedExam.id,
        type: -1
      }),
      clearManualAllocationData({ examId: selectedExam.id })
    ]).then(([res1, res2]) => {
      setLoading(false)
      if (res1.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: res1.msg
        })
        return
      }

      if (res2.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: res2.msg
        })
        return
      }

      notification.success({
        message: '操作成功'
      })

      props.updateAllocateProgress?.()
      eventBus.emit('reloadExamDetail', selectedExam.id)
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，清除数据失败'
      })
    })
  }

  const resetConfirmModal = (fn) => {
    Modal.confirm({
      title: '重新分配',
      content: '确定要重新分配吗？',
      onOk: () => {
        fn?.()
      }
    })
  }

  const validateAllocateStatusContent = (allocateType, process, examInfo) => {
    if (allocateType === 'auto') {
      if (examInfo.roomCandidateImport !== 1) {
        return props.children
      } else {
        return (
          <div
            className={ 'd-flex align-items-center justify-content-center' }
            style={ { height: 300 } }
          >
            <Space
              size={ 16 }
              direction={ 'vertical' }
              align={ 'center' }
            >
              <Empty description={ '考试已手动导入考场安排数据，如需自动分配请点击重新分配' }></Empty>
              <Button
                disabled={ selectedExam.examinationStatus >= 2 }
                type={ 'primary' }
                ghost
                danger
                onClick={ resetConfirmModal.bind(this, clearAllocationData) }
              >重新分配</Button>
            </Space>
          </div>
        )
      }
    }

    if (allocateType === 'import') {
      if (process !== 6) {
        return props.children
      } else {
        return (
          <div
            className={ 'd-flex align-items-center justify-content-center' }
            style={ { height: 300 } }
          >
            <Space
              size={ 16 }
              direction={ 'vertical' }
              align={ 'center' }
            >
              <Empty description={ '考试已完成自动考场安排，如需手动导入分配请点击重新分配' }></Empty>
              <Button
                disabled={ selectedExam.examinationStatus >= 2 }
                type={ 'primary' }
                ghost
                danger
                onClick={ resetConfirmModal.bind(this, allocationAgain) }
              >重新分配</Button>
            </Space>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <Spin spinning={ loading }>
        { validateAllocateStatusContent(props.allocateType, props.allocateProcess, selectedExam) }
      </Spin>
    </div>
  )
}

export default ValidateAllocateStatus
