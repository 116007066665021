import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Space, Table } from 'antd'
import { deleteDownloadCenterFile, downloadFile, getDownloadFileList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'
import { downloadFile as generateFileAndDownload } from '../../plugins/utils'

function DownloadCenter (props) {
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '文件名',
      key: 'fileName',
      dataIndex: 'fileName'
    },
    {
      title: '文件类型',
      key: 'suffix',
      dataIndex: 'suffix'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        if (record === 0) {
          return <div className={ 'text-primary' }>正在生成</div>
        } else if (record === 1) {
          return <div className={ 'text-success' }>已生成</div>
        } else {
          return <div className={ 'text-danger' }>生成失败</div>
        }
      }
    },
    {
      title: '生成时间',
      key: 'createTime',
      dataIndex: 'createTime'
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (
          <Space className={ 'd-flex justify-content-end' }>
            { row.status === 1
              ? <Button
                type={ 'primary' }
                ghost
                onClick={ downloadFileAction.bind(this, row) }
              >下载</Button>
              : '' }
            <ComponentVisibleByAuth authCode={ 'button_xzzx-sc' }>
              <Button
                type={ 'primary' }
                ghost
                danger
                onClick={ deleteFile.bind(this, row) }
              >删除</Button>
            </ComponentVisibleByAuth>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    loopTableData()
  }, [])

  useEffect(() => {
    getTableData()
  }, [pageSize, pageNum])

  const loopTableData = () => {
    setTimeout(() => {
      getTableData()
    }, 10 * 1000)
  }

  const getTableData = () => {
    const params = {
      orderBy: 'id',
      orderType: 'DESC',
      pageNum,
      pageSize
    }

    setLoading(true)
    getDownloadFileList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询文件列表失败'
      })
    })
  }

  const downloadFileAction = (row) => {
    downloadFile({ fileUrl: row.filePath }).then(res => {
      generateFileAndDownload(res, row.suffix, row.fileName)
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载文件失败'
      })
    })
  }

  const deleteFile = (row) => {
    Modal.confirm({
      title: '删除文件',
      content: `确定要删除文件【${ row.fileName }】吗？`,
      onOk: () => {
        deleteFileAction(row)
      }
    })
  }

  const deleteFileAction = (row) => {
    setLoading(true)
    deleteDownloadCenterFile({ id: row.id }).then(res => {
      setLoading(false)
      if (res.code == ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，删除文件失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  return (
    <div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ record => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>
    </div>
  )
}

export default DownloadCenter
