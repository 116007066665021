import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, ConfigProvider, Form, Input, Modal, Table, notification, Spin, Select } from 'antd'

import { phoneValidate } from '../../../../plugins/validates'
import { getApplySiteRoleList, getApplySiteUser, updateApplySiteWorkerList } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { getUid } from '../../../../plugins/utils'

const EditExamApplySiteWorkerModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contentHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [roleOptions, setRoleOptions] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getRoleOptions()
      getTableData()
    }
  }, [modalVisible])

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '角色',
      dataIndex: 'roleTag',
      key: 'roleTag',
      render: (record) => {
        return roleOptions.find(item => {
          return item.roleTag === record
        })?.label
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: '80px',
      key: 'operation',
      render: (record, row) => {
        return (
          <div className={ 'd-flex align-items-center justify-content-end' }>
            <Button
              ghost
              danger
              type={ 'primary' }
              onClick={ deleteCheckPerson.bind(this, row) }
            >删除</Button>
          </div>
        )
      }
    }
  ]

  const getTableData = () => {
    setModalLoading(true)
    getApplySiteUser({ applySiteId: props.siteItem.id }).then(res => {
      setModalLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data.map((item => ({ ...item, id: item.accountId }))))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setModalLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询报名点工作人员失败'
      })
    })
  }

  const getRoleOptions = () => {
    getApplySiteRoleList().then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setRoleOptions(res.data.map(item => {
          return {
            label: item.name,
            value: item.id,
            roleTag: item.roleTag
          }
        }))
      }
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setTableData([])
  }

  const deleteCheckPerson = (row) => {
    setTableData(tableData.filter(item => {
      return row.id !== item.id
    }))
  }

  const addCheckPerson = () => {
    form.validateFields().then(fields => {
      if (tableData.find(item => {
        return item.phone === fields.phone && item.roleId === fields.roleId
      })) {
        Modal.error({
          title: '错误',
          content: '工作人员已存在，请勿重复添加'
        })
        return
      }
      setTableData([].concat(tableData, [{
        ...fields,
        id: getUid()
      }]))
    }).catch(err => {
      console.error(err)
    })
  }

  const onOk = () => {
    setModalLoading(true)

    const params = {
      applySiteId: props.siteItem.id,
      applySiteUsers: tableData
    }

    updateApplySiteWorkerList(params).then(res => {
      setModalLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setModalLoading(false)
      Modal.error({
        title: '网络错误',
        content: '配置报名点工作人员失败',
        centered: true
      })
    })
  }

  const handleReset = () => {
    if (props.positionItemList.length > 1) {
      setTableData([])
    } else {
      setTableData(props.positionItemList[0].checkPersonList)
    }
  }

  const roleIdChange = (key, item) => {
    form.setFieldValue('roleTag', item.roleTag)
  }

  return (
    <div ref={ ref }>
      { contentHolder }

      <Modal
        title={ '配置报名点工作人员' }
        width={ '800px' }
        open={ modalVisible }
        onOk={ onOk }
        okText={ '保存' }
        onCancel={ hideModal }
        footer={ [
          <Button key="back" onClick={ hideModal }>
            取消
          </Button>,
          <Button key="reset" type="primary" ghost onClick={ handleReset }>
            重置
          </Button>,
          <Button key="save" type="primary" loading={ modalLoading } onClick={ onOk }>
            保存
          </Button>
        ] }
      >
        <Spin spinning={ modalLoading }>
          <ConfigProvider componentSize={ 'small' }>
            <div className={ 'my-3 d-flex' }>
              <Form
                form={ form }
                layout={ 'inline' }
              >
                <Form.Item
                  name={ 'name' }
                  label={ '姓名' }
                  rules={ [{ required: true, message: '姓名不能为空' }] }
                >
                  <Input placeholder={ '请输入姓名' }></Input>
                </Form.Item>
                <Form.Item
                  name={ 'phone' }
                  label={ '手机号' }
                  required
                  rules={ [{ validator: phoneValidate }] }
                >
                  <Input placeholder={ '请输入手机号' } style={{ width: '140px' }}></Input>
                </Form.Item>
                <Form.Item
                  name={ 'roleId' }
                  label={ '角色' }
                  required
                  rules={ [{ required: true }] }
                >
                  <Select
                    options={ roleOptions }
                    style={{width: '140px'}}
                    onChange={ roleIdChange }
                  ></Select>
                </Form.Item>
                <Form.Item
                  name={ 'roleTag' }
                  label={ '角色类型' }
                  hidden
                >
                  <Input></Input>
                </Form.Item>
              </Form>

              <Button
                type={ 'primary' }
                onClick={ addCheckPerson }
              >添加</Button>
            </div>

            <Table
              columns={ columns }
              dataSource={ tableData }
              rowKey={ (record) => record.id }
              pagination={ false }
            ></Table>
          </ConfigProvider>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamApplySiteWorkerModal
