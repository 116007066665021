import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Empty, Modal, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Styles from '../ExaminationAllocation.module.scss'
import ExamBatchItem from './ExamBatchItem'
import EditExamBatchModal from './EditExamBatchModal'
import { completeExaminationBatchConfig, getExaminationBatchList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'

function ExaminationBatchList (props) {
  const editExamBatchModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)
  const [examBatchList, setExamBatchList] = useState([])

  useEffect(() => {
    if (selectedExam.id) {
      getExamBatchList()
    }
  }, [selectedExam])

  const showCreateExamBatchModal = () => {
    editExamBatchModalRef.current.showModal()
  }

  const getExamBatchList = () => {
    setLoading(true)
    getExaminationBatchList({ id: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setExamBatchList(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试批次列表失败'
      })
    })
  }

  const generateExamBatchContent = (batchList) => {
    if (batchList.length > 0) {
      return batchList.map(batch => {
        return <ExamBatchItem
          batchInfo={ batch }
          key={ batch.id }
          batchListChange={ getExamBatchList }></ExamBatchItem>
      })
    } else {
      return <div
        className={ 'mb-3 d-flex align-items-center justify-content-center' }
        style={ {
          height: 300,
          background: '#fafafa',
          border: '1px solid #dfdfdf'
        } }
      >
        <Empty description={ '暂无考试批次' }/>
      </div>
    }
  }

  const nextStep = () => {
    if (examBatchList.length === 0) {
      Modal.error({
        title: '错误',
        centered: true,
        content: '未创建考试批次'
      })

      return
    }
    setLoading(true)
    completeExaminationBatchConfig({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        props.nextStep?.()
      } else {
        Modal.error({
          title: '错误',
          centered: true,
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，设置批次数据失败'
      })
    })
  }

  return (
    <div>
      <Spin spinning={ loading }>
        <div className={ Styles.pageTitle }>创建考试批次</div>
        <div className={ 'my-3 d-flex justify-content-end' }>
          <Button
            type={ 'primary' }
            icon={ <PlusOutlined/> }
            onClick={ showCreateExamBatchModal }
          >新建考试批次</Button>
        </div>
        <Spin spinning={ loading }>
          { generateExamBatchContent(examBatchList) }
        </Spin>

        <div className={ 'd-flex justify-content-center' }>
          <Button
            loading={ loading }
            type={ 'primary' }
            onClick={ nextStep }
          >下一步</Button>
        </div>
      </Spin>

      <EditExamBatchModal
        ref={ editExamBatchModalRef }
        batchList={ examBatchList }
        examInfo={ selectedExam }
        insertSuccess={ getExamBatchList }
      ></EditExamBatchModal>
    </div>
  )
}

export default ExaminationBatchList
