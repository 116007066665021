import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Empty, Modal, notification, Space, Tree } from 'antd'
import { useSelector } from 'react-redux'
import { createExamApplySiteBatch, getApplySiteList, getRegionTree } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const SelectApplySiteModal = forwardRef((props, ref) => {
  const provinceList = useSelector(state => {
    return state.regionList.regionList.map(item => {
      return {
        title: item.name,
        value: item.code,
        id: item.id,
        key: item.code,
        checkable: false,
        isLeaf: false
      }
    })
  })
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [treeData, setTreeData] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [applySiteList, setApplySiteList] = useState([])
  const [treeExpandedKeys, setTreeExpandedKeys] = useState([])
  const selectedExam = useSelector(state => state.examInfo.selectedExam)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setCheckedKeys(props.selectedSiteList?.map(item => {
        return `${item.id}_${item.regionIds.join('_')}`
      }))
    }
  }, [modalVisible])

  useEffect(() => {
    setTreeExpandedKeys([])
    setTreeData(treeData.map(item => {
      return {
        ...item,
        children: []
      }
    }))
    getApplySiteListAction()
  }, [selectedExam])

  useEffect(() => {
    if (applySiteList.length > 0) {
      setTreeData(provinceList.filter(item => {
        return applySiteList.map(site => site.regionIds[0]).includes(item.value)
      }))
    }
  }, [applySiteList])

  const getApplySiteListAction = () => {
    const params = { id: props.orgId }

    getApplySiteList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setApplySiteList(res.data.map(item => {
          return {
            ...item,
            key: item.regionIds?.join('_')
          }
        }))
      }
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setCheckedKeys([])
    setModalVisible(false)
  }

  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children
        }
      }

      return node
    })
  }

  const generateTreeData = (list, parentCode, regionPath) => {
    if (list) {
      const regionPathKey = regionPath.map(region => region.code).join('_')
      const regionData = list.filter(item => {
        return applySiteList.some(site => {
          return site.key.indexOf(`${ parentCode }_${ item.code }`) > -1
        })
      }).map(item => {
        const path = [].concat(regionPath, [item])
        const key = `${ parentCode }_${ item.code }`

        return {
          title: item.name,
          code: item.code,
          id: item.id,
          checkable: false,
          key: key,
          isLeaf: false,
          path: path,
          children: generateTreeData(item.childList, key, path)
        }
      })

      const regionApplySite = applySiteList.filter(site => {
        return site.key === regionPathKey
      }).map(site => {
        return {
          ...site,
          applySiteId: site.id,
          applySiteNumber: site.number,
          title: site.name,
          code: site.code,
          id: site.id,
          key: `${site.id}_${site.key}`,
          isLeaf: true
        }
      })


      return regionApplySite.length> 0 ? [...regionApplySite, ...regionData] : regionData
    }
    return []
  }

  const loadRegionData = ({ key, value, title, children }) => {
    return new Promise((resolve) => {
      if (children) {
        resolve()
        return
      }

      getRegionTree({ parent: value }).then(res => {
        if (res.code === ResponseCode.success && res.data) {
          setTreeData((origin) => {
              return updateTreeData(origin, key, generateTreeData(res.data, value, [{
                name: title,
                value: value,
                code: value
              }]))
            }
          )
          resolve()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          content: '查询区域失败',
          centered: true
        })
      })
    })
  }

  const onOk = () => {
    setLoading(true)
    const params = {
      id: selectedExam.id,
      applySites: checkedItems.map(item => {
        return {
          name: item.title,
          applySiteId: item.applySiteId,
          applySiteNumber: item.applySiteNumber,
          examId: selectedExam.id
        }
      })
    }

    createExamApplySiteBatch(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '批量创建报名点失败',
        centered: true
      })
    })
  }

  const onCheckTreeData = (keys, { checkedNodes }) => {
    setCheckedKeys(keys)
    setCheckedItems(checkedNodes)
  }

  const treeExpandChange = (keys) => {
    setTreeExpandedKeys(keys)
  }

  const generateTreeContent = (list) => {
    if (list.length > 0) {
      return (
        <Tree
          height={ 500 }
          selectable={ false }
          treeExpandedKeys={treeExpandedKeys}
          checkable
          checkedKeys={ checkedKeys }
          treeData={ treeData }
          loadData={ loadRegionData }
          onCheck={ onCheckTreeData }
          onTreeExpand={treeExpandChange}
        ></Tree>
      )
    } else {
      return (
        <Space direction={'vertical'} align={'center'} style={{ width: '100%' }}>
          <Empty description={'机构未配置报名点'}></Empty>
        </Space>
      )
    }
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ '选择报名点' }
        destroyOnClose
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <ConfigProvider componentSize={ 'small' }>
          { generateTreeContent(applySiteList) }
        </ConfigProvider>
      </Modal>
    </div>
  )
})

export default SelectApplySiteModal
