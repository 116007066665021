import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, Space, Table } from 'antd'
import { useSelector } from 'react-redux'

import { downloadPositionQuery, getStatisticalInfo, statisticalByPosition } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadFile, throttle } from '../../plugins/utils'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'

function ExamStatisticalByPosition () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [applySiteOptions, setApplySiteOptions] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [statisticalInfo, setStatisticalInfo] = useState({})

  useEffect(() => {
    if (selectedExam.id) {
      setApplySiteOptions([{
        label: '全部',
        value: ''
      }, ...(selectedExam.applySiteList?.map(site => {
        return {
          ...site,
          label: site.name,
          value: site.number
        }
      }) || [])])
    }
  }, [selectedExam])

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
      getStatisticalInfoAction()
    }
  }, [selectedExam, pageSize, pageNum])

  const columns = [
    {
      title: '报考单位',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode'
    },
    {
      title: '报考职位',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode'
    },
    {
      title: '报名人数',
      key: 'applyCount',
      dataIndex: 'applyCount'
    },
    {
      title: '待审核',
      key: 'treatCheckCount',
      dataIndex: 'treatCheckCount'
    },
    {
      title: '审核通过',
      key: 'checkCount',
      dataIndex: 'checkCount'

    },
    {
      title: '审核未通过',
      key: 'notPassCheckCount',
      dataIndex: 'notPassCheckCount'
    },
    {
      title: '已缴费',
      key: 'payCount',
      dataIndex: 'payCount'
    },
    {
      title: '笔试确认',
      key: 'confirmCount',
      dataIndex: 'confirmCount'
    },
    {
      title: '未提交',
      key: 'unSubmitCount',
      dataIndex: 'unSubmitCount'
    },
    {
      title: '计划招考',
      key: 'p_zprs',
      dataIndex: 'p_zprs'
    }
  ]

  const getStatisticalInfoAction = (allFields) => {
    const searchData = allFields || form.getFieldsValue()
    getStatisticalInfo({
      applySiteNumber: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      examId: selectedExam.id,
      siteNumber: searchData.applySiteNumber,
      unitName: searchData.unitName
    }).then(res => {
      if (res.code === ResponseCode.success) {
        setStatisticalInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试统计数据失败'
      })
    })
  }

  const getTableData = (allFields) => {
    const searchData = allFields || form.getFieldsValue()
    setLoading(true)
    const params = {
      applySiteNumber: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      unitName: searchData.unitName,
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum: pageNum,
      pageSize: pageSize
    }

    statisticalByPosition(params).then(res => {
      setLoading(false)
      if (res.code == ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询统计数据失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const exportTableData = () => {
    setLoading(true)
    const searchData = form.getFieldsValue()

    downloadPositionQuery({
      examId: selectedExam.id,
      applySiteNumber: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      unitName: searchData.unitName
    }).then(res => {
      setLoading(false)
      downloadFile(res, 'xlsx', '职位统计').catch(err => {
        console.log(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，导出职位统计数据失败'
      })
    })
  }

  const onValuesChange = (fields, allFields) => {
    setPageNum(1)
    setPageSize(10)
    getTableData(allFields)
    getStatisticalInfoAction(allFields)
  }

  const filterColumnsByExamConfig = (columnList) => {
    if (selectedExam.payType !== 1) {
      columnList = columnList.filter(column => {
        return column.key !== 'payCount'
      })
    }

    if (selectedExam.takeExamCheck !== 1) {
      columnList = columnList.filter(column => {
        return column.key !== 'confirmCount'
      })
    }
    return columnList
  }

  return (<>
    <div className={ 'd-flex align-items-center' }>
      <Form
        className={ 'mb-3' }
        layout={ 'inline' }
        form={ form }
        initialValues={ {
          applySiteNumber: ''
        } }
        onValuesChange={ throttle(onValuesChange, 300) }
      >
        <Form.Item label={ '报名点' } name={ 'applySiteNumber' }>
          <Select
            options={ applySiteOptions }
            style={ { width: 220 } }
          ></Select>
        </Form.Item>
        <Form.Item label={ '报考单位' } name={ 'unitName' }>
          <Input placeholder={ '输入报考单位' }></Input>
        </Form.Item>
      </Form>
    </div>

    <div className={ 'd-flex align-items-center justify-content-end' }>
      <ComponentVisibleByAuth authCode={ 'button_cxtj-àzwtj-dc' }>
        <Button
          type={ 'primary' }
          loading={ loading }
          onClick={ exportTableData }
        >导出</Button>
      </ComponentVisibleByAuth>
    </div>

    <Space className={ 'mb-1' } size={ 16 }>
      <div>
        <span>计划招考合计：</span>
        <span>{ statisticalInfo.p_zprs } 人 </span>
      </div>
      <div>
        <span>报考合计：</span>
        <span>{ statisticalInfo.applyCount } 人 </span>
      </div>
      <div>
        <span>审核通过合计：</span>
        <span>{ statisticalInfo.checkCount } 人 </span>
      </div>
      {
        selectedExam.payType === 1
          ? <div>
            <span>已缴费合计：</span>
            <span>{ statisticalInfo.payCount } 人 </span>
          </div>
          : <></>
      }
      {
        selectedExam.takeExamCheck === 1
          ? <div>
            <span>笔试确认合计：</span>
            <span>{ statisticalInfo.confirmCount } 人 </span>
          </div>
          : <></>
      }
      <div>
        <span>未提交合计：</span>
        <span>{ statisticalInfo.unSubmitCount } 人 </span>
      </div>
    </Space>

    <Table
      loading={ loading }
      columns={ filterColumnsByExamConfig(columns) }
      dataSource={ tableData.elements }
      rowKey={ (record) => {
        return `${ record.id }_${ record.unitCode }_${ record.positionCode }`
      } }
      pagination={ {
        showTotal: (total) => {
          return `共 ${ total } 条`
        },
        size: 'normal',
        showSizeChanger: true,
        total: tableData.totalElements,
        pageSize: pageSize,
        current: pageNum,
        onChange: paginationChange
      } }
    ></Table>
  </>)
}

export default ExamStatisticalByPosition
