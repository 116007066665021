import React, { useState, useRef, useEffect } from 'react'
import { Modal, Space, Table } from 'antd'
import { useSelector } from 'react-redux'

import EditSampleTableModal from './EditSampleTableModal'
import { sampleTableMap } from '../../../../common/common'
import { convertPdfAndDownload, getSampleTableData } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { downloadPdf } from '../../../../plugins/utils'
import eventBus from '../../../../plugins/events'
import AppButton from '../../../../components/AppButton'
import SelectFieldsModal from './SelectFieldsModal'

function SettingSampleTable () {
  const selectFieldModalRef = useRef(null)
  const editSampleTableModalRef = useRef(null)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam])

  const columns = [
    {
      title: '文件类型',
      dataIndex: 'fileName',
      key: 'fileName'
    },
    {
      title: '状态',
      dataIndex: 'type',
      key: 'type',
      render: (record) => {
        return (<>
          { record ? <div className="text-primary">已配置</div> : <div>未配置</div> }
        </>)
      }
    },
    {
      title: '操作',
      width: '100px',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (
          <Space className={ 'd-flex align-items-center justify-content-end' }>

            {
              row.fileType !== 'applyForm'
                ? (<>
                  <AppButton
                    size={ 'small' }
                    disabled={ getFileEditBtnDisabled(row) }
                    authCode={ 'button_ksxq-ybsz-pzzd/px' }
                    ghost
                    type={ 'primary' }
                    onClick={ showSelectFieldModal.bind(this, row) }
                  >配置字段/排序</AppButton>
                </>)
                : <></>
            }
            {
              row.type
                ? (<>
                  <AppButton
                    size={ 'small' }
                    authCode={ 'button_ksxq-mbsz-xz' }
                    type={ 'primary' }
                    ghost
                    onClick={ downloadFile.bind(this, row) }
                  >下载</AppButton>
                </>)
                : <></>
            }
            <AppButton
              className={ 'mr-2' }
              size={ 'small' }
              authCode={ 'button_ksxq-mbsz-pz' }
              ghost
              type={ 'primary' }
              onClick={ showEditSampleTableModal.bind(this, row, getFileEditBtnDisabled(row)) }
            >{ getFileEditBtnDisabled(row) ? '查看' : '配置' }</AppButton>
          </Space>
        )
      }
    }
  ]

  const getFileEditBtnDisabled = (row) => {
    switch (row.fileType) {
      case 'applyForm':
        return selectedExam.examinationStatus >= 1
        break
      case 'examCertificate':
        return selectedExam.examinationStatus >= 2
        break
      case 'interviewNotice':
        return selectedExam.examinationStatus >= 4
        break
    }
  }

  const showSelectFieldModal = (row) => {
    setCurrentEditItem(row)
    selectFieldModalRef.current.showModal()
  }

  const getFileContent = (content) => {
    return `<style>
          p {
            margin: 6px;
            color: #222!important;
            font-size: 9.9pt;
          }
          table {
            margin: 0 auto!important;
          }
          td, tr {
            word-break: break-all!important;
          }         
        </style>
        ${ content }`
  }

  const downloadFile = (row) => {
    let fileName = ''
    const params = {
      content: getFileContent(row.content)
    }
    switch (row.fileType) {
      case sampleTableMap.apply.key:
        params.fileType = 'APPLY'
        fileName = '报名表模板'
        break
      case sampleTableMap.examinationRoom.key:
        params.fileType = 'CERTIFICATE'
        fileName = '笔试准考证模板'
        break
      case sampleTableMap.interviewNotice.key:
        params.fileType = 'INTERVIEW'
        fileName = '面试准考证模板'
    }
    setLoading(true)

    convertPdfAndDownload(params).then(res => {
      setLoading(false)
      downloadPdf(res, fileName)
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载失败'
      })
    })
  }

  const getTableData = () => {
    getSampleTableData({ examId: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(selectedExam.process.filter(item => {
          return item !== 'publicResult'
        })?.map(item => {
          return {
            fileName: sampleTableMap[item].label,
            fileType: sampleTableMap[item].key,
            content: res.data[sampleTableMap[item].key],
            type: !!res.data[sampleTableMap[item].key],
            fields: res.data[sampleTableMap[item].fieldKey]
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询样表文件失败',
        centered: true
      })
    })
  }

  const showEditSampleTableModal = (row, editDisabled) => {
    setCurrentEditItem(row)
    editSampleTableModalRef.current.showModal(editDisabled)
  }

  const fileChanged = () => {
    eventBus.emit('updateExamMonitor')
    getTableData()
  }

  return (
    <div>
      <Table
        loading={ loading }
        columns={ columns }
        dataSource={ tableData }
        rowKey={ record => record.fileType }
        pagination={ false }
      ></Table>

      <EditSampleTableModal
        ref={ editSampleTableModalRef }
        fileItem={ currentEditItem }
        examInfo={ selectedExam }
        insertSuccess={ fileChanged }
      ></EditSampleTableModal>

      <SelectFieldsModal
        ref={ selectFieldModalRef }
        fileItem={ currentEditItem }
        examInfo={ selectedExam }
        insertSuccess={ fileChanged }
      ></SelectFieldsModal>
    </div>
  )
}

export default SettingSampleTable
