import React, { useEffect, useState } from 'react'
import { Cascader, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getTreeDict } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { updateDictInfo } from '../../store/slice/dict-slice'

function TreeDataFormField ({ value, fieldJoinSelect, onChange }) {
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [treeData, setTreeData] = useState([])

  useEffect(() => {
    getTreeDictInfo(fieldJoinSelect)
  }, [])

  useEffect(() => {
    if (value) {
      setTreeData(dataTransform(value))
    }
  }, [value])

  const dataTransform = (data) => {
    return data.map(item => {
      return item.split(',')[0]
    })
  }

  const getTreeDictInfo = () => {
    if (!dictInfo[fieldJoinSelect]) {
      getTreeDict({ dictCode: fieldJoinSelect }).then(res => {
        if (res.code === ResponseCode.success) {
          dispatch(updateDictInfo({ dictKey: fieldJoinSelect, dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const treeDictTransform = (list) => {
    return list?.map(item => {
      return {
        label: item.dictLabel,
        value: item.dictValue,
        children: item.subDictList?.length > 0
          ? treeDictTransform(item.subDictList)
          : []
      }
    })
  }

  const treeDataChange = (key, items) => {
    onChange(items.map(item => {
      return `${item.value},${item.label}`
    }))
  }

  return (
    <Cascader
      value={ treeData }
      changeOnSelect
      options={ treeDictTransform(dictInfo[fieldJoinSelect]) }
      placeholder="请选择"
      style={ { width: '300px' } }
      onChange={ treeDataChange }
    ></Cascader>
  )
}

export default TreeDataFormField
