import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Table, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import EditTableFormFieldModal from './EditTableFormFieldModal'
import { useRef } from 'react'
import { getUid } from '../../plugins/utils'

function TableFormField ({ value = [], columnsData, dataSource, fieldItem, onChange }) {
  const editTableFormFieldModalRef = useRef()
  const [columns, setColumns] = useState([])
  const [currentEditTableFormField, setCurrentEditTableFormField] = useState({})
  const [modalType, setModalType] = useState('')

  useEffect(() => {
    setColumns(columnsData)
  }, [columnsData])

  const editTableItem = (row) => {
    setModalType('edit')
    setCurrentEditTableFormField({
      columns: columnsData,
      data: row,
      field: fieldItem,
    })
    editTableFormFieldModalRef.current.showModal()
  }

  const deleteTableItem = (row) => {
    onChange(value.filter(item => {
      return item.id !== row.id
    }))
  }

  const showEditTableFormFieldModal = (columnData) => {
    setModalType('create')
    setCurrentEditTableFormField({
      columns: columnData,
      data: dataSource,
      field: fieldItem,
    })

    editTableFormFieldModalRef.current.showModal()
  }

  const createTableFormData = (data) => {
    onChange([].concat(value, {
      ...data.value,
      id: getUid()
    }))
  }

  const editTableFormData = (data) => {
    const newData = { ...currentEditTableFormField.data, ...data.value }
    onChange(value.map(item => item.id === newData.id ? newData : item))
  }

  return (
    <>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          scroll={{
            x: 700
          }}
          dataSource={ value }
          columns={ [].concat(columns, [
            {
              width: 130,
              title: '操作',
              key: 'operation',
              dataIndex: 'opeartion',
              fixed: 'right',
              render: (record, row) => {
                return (
                  <Space>
                    <Button ghost type={ 'primary' } onClick={ () => editTableItem(row) }>修改</Button>
                    <Button ghost danger onClick={ deleteTableItem.bind(this, row) }>删除</Button>
                  </Space>
                )
              }
            }
          ]) }
          rowKey={ (record) => {
            return record.id
          } }
          pagination={ false }
        >
        </Table>
        <div
          className={ 'd-flex justify-content-center' }
          style={ { borderBottom: '1px solid #f5f5f5' } }
          onClick={ showEditTableFormFieldModal.bind(this, columnsData) }
        >
          <Button type={ 'link' } icon={ <PlusOutlined/> }>新增</Button>
        </div>
      </ConfigProvider>
      <EditTableFormFieldModal
        ref={ editTableFormFieldModalRef }
        editItem={ currentEditTableFormField }
        modalType={ modalType }
        insertSuccess={ (data) => { modalType === 'create' ? createTableFormData(data) : editTableFormData(data) } }
      ></EditTableFormFieldModal>
    </>
  )
}

export default TableFormField
