import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Modal, notification, Spin } from 'antd'

import { createExam } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import BasicForm from './BasicForm'
import ApplyProcessForm from './ApplyProcessForm'

const CreateExamModal = forwardRef((props, ref) => {
  const basicFormRef = useRef()
  const applyProcessFormRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [applyProcessVisible, setApplyProcessVisible] = useState(false)
  const [currentOrgId, setCurrentOrgId] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const applyProcessChange = (examProcess) => {
    if (examProcess.includes('apply')) {
      setApplyProcessVisible(true)
    } else {
      setApplyProcessVisible(false)
    }
  }

  const getApplyProcessForm = (visible) => {
    if (visible) {
      return (<>
        <ApplyProcessForm ref={ applyProcessFormRef } orgId={ currentOrgId }></ApplyProcessForm>
      </>)
    } else {
      return <></>
    }
  }

  const orgChange = (orgId) => {
    setCurrentOrgId(orgId)
  }

  const onOk = async () => {
    let basicFormData = await basicFormRef.current.getFormValue()
    let applyProcessFormData = {}
    let params = {
      isCheck: 0,
      saveStatus: 1,
      applyPositionChange: 0,
      payType: 0,
      takeExamCheck: 0,
    }

    if (!basicFormData.success) {
      return
    }

    Object.assign(params, basicFormData.data)

    if (applyProcessVisible) {
      applyProcessFormData = applyProcessFormRef.current.getFormValue()

      Object.assign(params, applyProcessFormData)
    }
    setConfirmLoading(true)

    createExam(params).then(res => {
      setConfirmLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `已创建考试${ params.name }`
        })
        hideModal()

        props.insertSuccess && props.insertSuccess(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setConfirmLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建考试失败',
        centered: true
      })
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title="创建考试"
        width="560px"
        style={ { top: 20 } }
        open={ modalVisible }
        confirmLoading={ confirmLoading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ confirmLoading }>
          <div className="mt-3">
            <BasicForm
              ref={ basicFormRef }
              modalVisible={ modalVisible }
              applyProcessChange={ applyProcessChange }
              orgChange={ orgChange }
            ></BasicForm>
            { getApplyProcessForm(applyProcessVisible) }
          </div>
        </Spin>
      </Modal>
    </div>
  )
})

export default CreateExamModal
