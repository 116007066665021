import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Input, Modal, notification, Row, Select, Space, Switch, Table, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditAccountModal from './EditAccountModal'
import ResponseCode from '../../common/response-code'
import { getAccountList, deleteAccount, resetPassword, getExamPublicityData } from '../../common/request'
import AppButton from '../../components/AppButton'
import { useSelector } from 'react-redux'
import { throttle } from '../../plugins/utils'
import DeIdentification from '../../components/DeIdentification'

function AccountManagement () {
  const editAccountRef = useRef()
  const [searchForm] = Form.useForm()
  const orgList = useSelector(state => state.org.orgList)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [editAccountModalType, setEditAccountModalType] = useState('')
  const [orgOptions, setOrgOptions] = useState([])
  const roleList = useSelector(state => state.role.roleList)
  const [roleTagOptions, setRoleTagOptions] = useState([])

  const columns = [
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'phone' }></DeIdentification>)
      }
    },
    {
      title: '账户名',
      dataIndex: 'name',
      key: 'name',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
      render: (roleName) => {
        return (<>
          <Tag color="blue">{ roleName }</Tag>
        </>)
      }
    },
    {
      title: '信息脱敏',
      dataIndex: 'deIdentification',
      key: 'deIdentification',
      render: (record, row) => {
        return (<>
          <Switch
            checked={ !!record }
            checkedChildren="开启"
            unCheckedChildren="关闭"
            onChange={ deIdentificationChange.bind(this, !record, row) }
          ></Switch>
        </>)
      }
    },
    {
      title: '操作',
      dataIndex: '',
      width: '220px',
      key: '',
      render: (data, row) => {
        return (
          <Space className="d-flex justify-content-end">
            <AppButton
              size={ 'small' }
              authCode={ 'button_zhgl-zzmm' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showResetPasswordModal) }
            >重置密码</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ 'button_zhgl-bj' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditAccountModal) }
            >编辑</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ 'button_zhgl-sc' }
              type="primary"
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteAccountModal) }
            >删除</AppButton>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    initOrgOptions(orgList)
    setRoleTagOptions(roleList.map(item => {
      return {
        label: item.name,
        value: item.roleTag
      }
    }))
  }, [])

  useEffect(() => {
    if (orgOptions?.length > 0) {
      getTableData()
    }
  }, [orgOptions, pageNum, pageSize])

  const initOrgOptions = (optionData) => {
    setOrgOptions([
      { label: '全部', value: '' },
      ...optionData?.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    ])
  }

  const getTableData = (allFields) => {
    setTableDataLoading(true)
    const searchData = allFields || searchForm.getFieldsValue()
    const params = {
      ...searchData,
      organizationIds: searchData.organizationId
        ? [searchData.organizationId]
        : undefined,
      pageSize,
      pageNum,
      orderType: 'DESC',
      orderBy: 'id'
    }
    delete params.organizationId

    getAccountList(params).then(res => {
      setTableDataLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setTableDataLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询权限失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateAccountModal = () => {
    setEditAccountModalType('create')
    editAccountRef.current.showModal()
  }

  const showEditAccountModal = () => {
    setEditAccountModalType('edit')
    editAccountRef.current.showModal()
  }

  const showResetPasswordModal = (authItem) => {
    Modal.confirm({
      title: '重置密码',
      content: (
        <div>确定要重置账号【{ authItem.name }】的密码吗？</div>
      ),
      onOk () {
        resetPasswordAction(authItem)
      }
    })
  }

  const showDeleteAccountModal = (authItem) => {
    Modal.confirm({
      title: '删除账号',
      content: (
        <div>确定要删除账号【{ authItem.name }】吗？</div>
      ),
      onOk () {
        deleteAccountAction(authItem)
      }
    })
  }

  const resetPasswordAction = (authItem) => {
    const params = {
      id: authItem.userId
    }

    resetPassword(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '重置密码失败',
        centered: true
      })
    })
  }

  const deleteAccountAction = (authItem) => {
    const params = {
      accountId: authItem.accountId
    }

    deleteAccount(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除账号失败',
        centered: true
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const onValuesChange = (fields, allFields) => {
    setPageNum(1)
    setPageSize(10)
    getTableData(allFields)
  }

  const deIdentificationChange = (checked, row) => {
    Modal.confirm({
      title: `${checked ? '开启' : '关闭'}信息脱敏`,
      content: `确定${checked ? '开启' : '关闭'}信息脱敏吗？`,
      onOk () {
        const params = {
          accountId: row.accountId,
          deIdentification: Number(checked)
        }

        getExamPublicityData(params).then(res => {
          if (res.code === ResponseCode.success) {
            notifyApi.success({
              message: '操作成功'
            })
            getTableData()
          } else {
            Modal.error({
              title: '错误',
              content: res.msg
            })
          }
        }).catch(err => {
          console.error(err)
          Modal.error({
            title: '网络错误',
            content: `${checked ? '开启' : '关闭'}信息脱敏失败`
          })
        })
      }
    })
  }

  return (
    <div>
      { contextHolder }
      <div>
        <Form
          form={ searchForm }
          initialValues={ {
            name: '',
            phone: '',
            organizationId: ''
          } }
          autoComplete="off"
          onValuesChange={ throttle(onValuesChange, 500) }
        >

          <Row gutter={ 16 }>
            <Col span={ 8 }>
              <Form.Item
                label="所属机构"
                name="organizationId">
                <Select options={ orgOptions }/>
              </Form.Item>
            </Col>
            <Col span={ 12 }>
              <Form.Item
                label="角色类型"
                name="roleTags">
                <Select
                  mode="multiple"
                  allowClear
                  options={ roleTagOptions }
                  placeholder="选择角色类型"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
              label="账号名称"
              name="name">
              <Input
                allowClear
                placeholder="输入账号名称"/>
            </Form.Item>
            </Col>
            <Col span={ 6 }>
              <Form.Item
                label="手机号"
                name="phone">
                <Input
                  allowClear
                  placeholder="输入手机号"/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Space className={'w-100'} direction={'vertical'} align={'end'}>
        <AppButton
          authCode={ 'button_zhgl-cjzh' }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateAccountModal) }
        >创建账号</AppButton>
      </Space>

      <Table
        columns={ columns }
        loading={ tableDataLoading }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
        rowKey={ (record) => record.accountId }
        dataSource={ tableData.elements }></Table>

      <EditAccountModal
        ref={ editAccountRef }
        modalType={ editAccountModalType }
        authItem={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditAccountModal>
    </div>
  )
}

export default AccountManagement
