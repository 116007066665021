import { configureStore } from '@reduxjs/toolkit'

import roleReducer from './slice/role-slice'
import authListReducer from './slice/auth-list-slice'
import userInfoReducer from './slice/user-info-slice'
import regionListReducer from './slice/region-list-slice'
import orgListReducer from './slice/org-slice'
import positionDictReducer from './slice/position-dict-slice'
import dictReducer from './slice/dict-slice'
import examInfoReducer from './slice/exam-info-slice'
import envReducer from './slice/env-slice'

export default configureStore({
  reducer: {
    env: envReducer,
    role: roleReducer,
    authList: authListReducer,
    userInfo: userInfoReducer,
    regionList: regionListReducer,
    org: orgListReducer,
    positionDict: positionDictReducer,
    dict: dictReducer,
    examInfo: examInfoReducer
  }
})
