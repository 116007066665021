import React, { useEffect, useRef, useState } from 'react'
import { ConfigProvider, Modal, notification, Space, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import EditPositionFieldModal from './EditPositionFieldModal'
import { deletePositionFields, getPositionList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import AppButton from '../../../components/AppButton'

function ConfigPositionFields (props) {
  const editPositionFieldModalRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [modalType, setModalType] = useState('')
  const [currentEditItem, setCurrentEditItem] = useState({})

  useEffect(() => {
    if (props.modalVisible && props.orgItem.id) {
      getTableData()
    }
  }, [pageNum, pageSize, props.orgItem, props.modalVisible])

  const columns = [
    {
      title: '职位字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '职位字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        if (String(record) === '1') {
          return '文本'
        } else if (String(record) === '2') {
          return '下拉选项'
        }
      }
    },
    {
      title: '是否启用',
      dataIndex: 'state',
      key: 'state',
      render: (state) => {
        return (<div>
          { state === 1 ? '启用' : '禁用' }
        </div>)
      }
    },
    {
      title: '操作',
      width: '220px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <Space className="d-flex justify-content-end">
            <AppButton
              authCode={ 'button_jgxq-zwbzd-bj' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditPositionModal) }
            >编辑</AppButton>
            <AppButton
              authCode={ 'button_jgxq-zwbzd-sc' }
              type="primary"
              danger
              ghost
              onClick={ openModal.bind(this, row, showDeletePositionModal) }
            >删除</AppButton>
          </Space>
        </>)
      }
    }
  ]

  const getTableData = () => {
    const params = {
      fieldLevel: 'U',
      orderBy: 'fieldSort',
      orderType: 'ASC',
      orgId: props.orgItem.id,
      pageNum,
      pageSize
    }

    getPositionList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      }
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreatePositionModal = () => {
    setModalType('create')
    editPositionFieldModalRef.current.showModal()
  }

  const showEditPositionModal = () => {
    setModalType('edit')
    editPositionFieldModalRef.current.showModal()
  }

  const showDeletePositionModal = (row) => {
    Modal.confirm({
      title: '删除职位字段',
      centered: true,
      content: (
        <div>确定要删除职位字段【{ row.fieldName }】吗？</div>
      ),
      onOk () {
        deletePositionFieldAction(row)
      }
    })
  }

  const deletePositionFieldAction = (row) => {
    const params = {
      ids: [row.id]
    }

    deletePositionFields(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `删除职位字段成功`
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除职位字段失败',
        centered: true
      })
    })
  }

  return (
    <div>
      { contextHolder }

      <div className="mb-2 d-flex justify-content-end">
        <AppButton
          authCode={ 'button_jgxq-zwbzd-xzzwzd' }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreatePositionModal) }
        >新增职位字段</AppButton>
      </div>

      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditPositionFieldModal
        ref={ editPositionFieldModalRef }
        modalType={ modalType }
        fieldItem={ currentEditItem }
        orgItem={ props.orgItem }
        insertSuccess={ getTableData }
      ></EditPositionFieldModal>
    </div>
  )
}

export default ConfigPositionFields
