import React, { useEffect, useState } from 'react'
import { Form, Modal, Select, Table } from 'antd'
import { useSelector } from 'react-redux'

import { collectFeeStatistical, downloadPayQuery } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadFile, throttle } from '../../plugins/utils'
import AppButton from '../../components/AppButton'

function ExamCollectFeeStatistical () {
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)
  const [applySiteOptions, setApplySiteOptions] = useState([])
  const [selectedApplyNumber, setSelectedApplyNumber] = useState(null)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (selectedExam.id) {
      setApplySiteOptions([{
        label: '全部',
        value: ''
      }, ...(selectedExam.applySiteList?.map(site => {
        return {
          ...site,
          label: site.name,
          value: site.number
        }
      }) || [])])

      form.setFieldValue('applySiteNumber', '')
      setSelectedApplyNumber('')
    }
  }, [selectedExam])

  useEffect(() => {
    if (selectedApplyNumber !== null) {
      getTableData()
    }
  }, [selectedApplyNumber])

  const columns = [
    {
      title: '报名点名称',
      key: 'siteName',
      dataIndex: 'siteName'
    },
    {
      title: '报名点代码',
      key: 'siteCode',
      dataIndex: 'siteCode'
    },
    {
      title: '缴费成功人数',
      key: 'successCount',
      dataIndex: 'successCount'
    },
    {
      title: '贫困生免费人数',
      key: 'freeCount',
      dataIndex: 'freeCount'
    },
    {
      title: '总金额',
      key: 'totalAmount',
      dataIndex: 'totalAmount'
    }
  ]

  const getTableData = (allFields) => {
    const searchData = allFields || form.getFieldsValue()

    setLoading(true)
    const params = {
      applySiteNumberList: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      examId: selectedExam.id
    }

    collectFeeStatistical(params).then(res => {
      setLoading(false)
      if (res.code == ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询统计数据失败'
      })
    })
  }

  const exportTableData = () => {
    setLoading(true)
    const searchData = form.getFieldsValue()

    downloadPayQuery({
      examId: selectedExam.id,
      applySiteNumberList: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        })
    }).then(res => {
      setLoading(false)
      downloadFile(res, 'xlsx', '收费统计').catch(err => {
        console.log(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，导出收费统计数据失败'
      })
    })
  }

  const onValuesChange = (fields, allFields) => {
    getTableData(allFields)
  }

  return (<>
    <div className={ 'd-flex justify-content-between' }>
      <Form
        form={ form }
        initialValues={ {
          applySiteNumber: ''
        } }
        onValuesChange={ throttle(onValuesChange, 300) }
      >
        <Form.Item label={ '报名点' } name={ 'applySiteNumber' }>
          <Select
            options={ applySiteOptions }
            style={ { width: 180 } }
          ></Select>
        </Form.Item>
      </Form>
      <AppButton
        authCode={ 'button_cxtj-sftj-dc' }
        type={ 'primary' }
        loading={ loading }
        onClick={ exportTableData }
      >导出</AppButton>
    </div>

    <Table
      loading={ loading }
      columns={ columns }
      dataSource={ tableData }
      rowKey={ (record) => record.siteNumber }
      pagination={ false }
      summary={ (pageData) => {
        let totalSuccessCount = 0
        let totalFreeCount = 0
        let total = 0
        pageData.forEach(({ successCount, freeCount, totalAmount }) => {
          totalSuccessCount += successCount
          totalFreeCount += freeCount
          total += (totalAmount * 1000)
        })
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 2 }>合计</Table.Summary.Cell>
              <Table.Summary.Cell index={ 3 }>{ totalSuccessCount }</Table.Summary.Cell>
              <Table.Summary.Cell index={ 4 }>{ totalFreeCount }</Table.Summary.Cell>
              <Table.Summary.Cell index={ 5 }>{ total / 1000 }</Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      } }
    ></Table>
  </>)
}


export default ExamCollectFeeStatistical
