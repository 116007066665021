import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, Table } from 'antd'

import ResponseCode from '../../../../common/response-code'
import { getExamPositionTemplate } from '../../../../common/request'
import ConfigExamPositionFieldsModal from './ConfigExamPositionFieldsModal'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import FieldSortModal from './FieldSortModal'
import { SwapOutlined } from '@ant-design/icons'
import ComponentVisibleByAuth from '../../../../components/ComponentVisibleByAuth'
import { useSelector } from 'react-redux'
import eventBus from '../../../../plugins/events'

function SettingPositionTable () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const fieldSortModalRef = useRef()
  const configExamPositionFieldsModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '职位字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '职位字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        if (String(record) === '1') {
          return '文本'
        } else if (String(record) === '2') {
          return '下拉选项'
        }
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam])

  const getTableData = () => {
    const params = {
      id: selectedExam.id
    }

    setLoading(true)
    getExamPositionTemplate(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        if (res.data.indexTemplateSet) {
          setTableData(res.data.indexTemplateSet)
        }

      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询考试职位表失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    fn && fn(row)
  }

  const showFieldsSortModal = () => {
    fieldSortModalRef.current.showModal()
  }

  const showConfigExamPositionFieldsModal = () => {
    configExamPositionFieldsModalRef.current.showModal()
  }

  const insertSuccess = () => {
    eventBus.emit('updateExamMonitor')
    getTableData()
  }

  return (
    <div>
      <div className="mb-2 d-flex align-items-center justify-content-end">
        <ComponentVisibleByAuth authCode={ 'button_ksxq-zwzd-bj' }>
          <ComponentDisabled
            disabledTip={ '报名已开始，禁止修改' }
            disabled={ selectedExam.examinationStatus > 0 }
          >
            <Button
              type="primary"
              onClick={ showConfigExamPositionFieldsModal }
            >配置职位字段</Button>
            <Button
              type="primary"
              icon={ <SwapOutlined/> }
              onClick={ openModal.bind(this, {}, showFieldsSortModal) }
            >字段排序</Button>
          </ComponentDisabled>
        </ComponentVisibleByAuth>
      </div>

      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData }
          rowKey={ (record) => record.id }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <FieldSortModal
        ref={ fieldSortModalRef }
        fieldList={ tableData }
        examInfo={ selectedExam }
        insertSuccess={ getTableData }
      ></FieldSortModal>

      <ConfigExamPositionFieldsModal
        ref={ configExamPositionFieldsModalRef }
        examInfo={ selectedExam }
        selectedItems={ tableData }
        insertSuccess={ insertSuccess }
      ></ConfigExamPositionFieldsModal>
    </div>
  )
}

export default SettingPositionTable
