import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Modal, notification, Space, Spin, Tabs } from 'antd'

import ExamineeApplyFormTable from './ExamineeApplyFormTable'
import ExamineeInfo from './ExamineeInfo'
import ExamineePublicResult from './ExamineePublicResult'
import { getExamApplyTemplate, getExamineeApplyInfo, updateExamineeCheckStatus } from '../../common/request'
import ResponseCode from '../../common/response-code'
import RefuseCheckModal from './RefuseCheckModal'
import ExamineeAdmissionTicket from './ExamineeAdmissionTicket'
import ExamineeInterviewAdmission from './ExamineeInterviewAdmission'
import { useSelector } from 'react-redux'
import eventBus from '../../plugins/events'

const ExamineeInfoModal = forwardRef((props, ref) => {
  const refuseCheckModalRef = useRef()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [applyInfo, setApplyInfo] = useState({})
  const [templateField, setTemplateField] = useState()
  const [examineeTabsItems, setExamineeTabsItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('')
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (applyInfo && templateField) {
      setExamineeTabsItems(props.activeTabs?.map(key => {
        return tabsMap[key]
      }))
    }
  }, [templateField, applyInfo])

  useEffect(() => {
    if (modalVisible && props.examineeItem.examId) {
      getExamineeApplyInfoAction()
      if (props.modalType === 'view') {
        setModalTitle('考生信息')
      } else {
        setModalTitle('考生报名信息详情')
      }
    }

    if (!modalVisible) {
      setActiveKey('examineeInfo')
    }

    if (modalVisible && (props.examineeItem?.id || props.examineeItem?.applyInfoId)) {
      eventBus.emit('reloadOperatingRecord', props.examineeItem.id || props.examineeItem.applyInfoId)
    }
  }, [modalVisible, props.examineeItem])

  const tabsMap = {
    examineeInfo: {
      key: 'examineeInfo',
      label: '考生信息',
      children: (
        <ExamineeInfo
          activeKey={ activeKey }
          applyInfo={ applyInfo }
          templateField={ templateField }
          examineeItem={ props.examineeItem }
          modalVisible={ modalVisible }
        ></ExamineeInfo>
      )
    },
    applyFormTable: {
      key: 'applyFormTable',
      label: '报名表',
      forceRender: false,
      children: (
        <ExamineeApplyFormTable
          activeKey={ activeKey }
          applyInfo={ applyInfo }
          examineeItem={ props.examineeItem }
          modalVisible={ modalVisible }
        ></ExamineeApplyFormTable>
      )
    },
    examCertificate: {
      key: 'examCertificate',
      label: '笔试准考证',
      forceRender: false,
      children: (<ExamineeAdmissionTicket
        activeKey={ activeKey }
        applyInfo={ applyInfo }
        examineeItem={ props.examineeItem }
        modalVisible={ modalVisible }
      ></ExamineeAdmissionTicket>)
    },
    interviewNotice: {
      key: 'interviewNotice',
      label: '面试准考证',
      forceRender: false,
      children: (<ExamineeInterviewAdmission
        activeKey={ activeKey }
        examineeItem={ props.examineeItem }
        modalVisible={ modalVisible }
      ></ExamineeInterviewAdmission>)
    },
    publicResult: {
      key: 'publicResult',
      label: '成绩查询',
      forceRender: false,
      children: (
        <ExamineePublicResult
          activeKey={ activeKey }
          applyInfo={ applyInfo }
          templateField={ templateField }
          examineeItem={ props.examineeItem }
          modalVisible={ modalVisible }
        ></ExamineePublicResult>
      )
    }
  }

  const getExamineeApplyInfoAction = () => {
    setLoading(true)
    const params = {
      applyInfoId: props.examineeItem.applyInfoId || props.examineeItem.id,
      examId: props.examineeItem.examId,
      positionNumber: props.examineeItem.positionNumber,
      userId: props.examineeItem.userId
    }

    Promise.all([
      getExamineeApplyInfo(params),
      getExamApplyTemplate({ examId: props.examineeItem.examId })
    ]).then(([applyInfoRes, templateFieldRes]) => {
      setLoading(false)
      if (applyInfoRes.code !== ResponseCode.success || templateFieldRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '查询考生报名信息失败'
        })
        return
      }

      setTemplateField(templateFieldRes.data)
      setApplyInfo(applyInfoRes.data)
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生报名信息失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const checkPass = () => {
    Modal.confirm({
      title: '信息确认',
      content: '确认该考生通过审核吗？',
      onOk: () => {
        const params = {
          applyInfoId: props.examineeItem.applyInfoId || props.examineeItem.id,
          auditStatus: 2,
          optLock: applyInfo.optLock
        }

        setLoading(true)
        updateExamineeCheckStatus(params, userInfo.currentRoleTag).then(res => {
          setLoading(false)
          if (res.code === ResponseCode.success) {
            notification.success({
              message: '操作成功'
            })
            needUploadList()
          } else {
            Modal.error({
              title: '错误',
              content: res.msg
            })
          }
        }).catch(err => {
          console.error(err)
          setLoading(false)
          Modal.error({
            title: '错误',
            content: '网络错误，修改审核状态失败'
          })
        })
      }
    })
  }

  const showNotPassModal = () => {
    refuseCheckModalRef.current.showModal()
  }

  const examineeCheckControlBarVisible = (visible, info) => {
    const currentTime = new Date().getTime()
    if (props.pageType === 'check' && (userInfo.currentRoleTag === 'superAdmin' || (visible && selectedExam.checkEndTime > currentTime))) {
      return (
        <div className={ 'w-100 mt-3 d-flex align-items-center justify-content-center' }>
          <Space size={ 50 }>
            {
              info?.saveStatus !== 2
                ? <Button
                  type={ 'primary' }
                  onClick={ checkPass }
                >审核通过</Button>
                : <></>
            }
            {
              info?.saveStatus !== -1 && info?.saveStatus !== 2
                ? <Button
                  type={ 'primary' }
                  danger
                  onClick={ showNotPassModal }
                >审核不通过</Button>
                : <></>
            }
          </Space>
        </div>
      )
    } else {
      return <></>
    }
  }

  const activeKeyChange = (key) => {
    setActiveKey(key)
  }

  const needUploadList = () => {
    hideModal()
    props.uploadList?.()
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 1020 }
        title={ modalTitle }
        open={ modalVisible }
        footer={ [
          <Button
            key={ 'close' }
            type={ 'primary' }
            onClick={ hideModal }
          >关闭</Button>
        ] }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Tabs destroyInactiveTabPane activeKey={ activeKey } items={ examineeTabsItems } onChange={ activeKeyChange }></Tabs>

          { examineeCheckControlBarVisible(props.examineeCheckable, applyInfo) }
        </Spin>
      </Modal>

      <RefuseCheckModal
        ref={ refuseCheckModalRef }
        examineeItem={ props.examineeItem }
        applyInfo={ applyInfo }
        insertSuccess={ needUploadList }
      ></RefuseCheckModal>
    </div>
  )
})

export default ExamineeInfoModal
