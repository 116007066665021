import React, { useEffect } from 'react'
import { useRouteLoaderData, useNavigate } from 'react-router-dom'
import { Tabs, ConfigProvider, Image } from 'antd'

import Styles from './Login.module.scss'
import LoginPassword from './LoginPassword'
import LoginPhone from './LoginPhone'
import LoginTitleImg from '../../assets/images/logo.png'
import LoginBgLogoImg from '../../assets/images/checker-login-bg-logo.png'
import { useSelector } from 'react-redux'

const tabsItem = [
  {
    key: '1',
    label: '账号密码登录',
    children: <LoginPassword/>
  },
  {
    key: '2',
    label: '手机验证码登录',
    children: <LoginPhone/>
  }
]

const Login = () => {
  const navigate = useNavigate()
  const rootData = useRouteLoaderData('root')
  const envInfo = useSelector(state => state.env.envInfo)

  useEffect(() => {
    if (rootData === 500) {
      navigate('/network-error', { replace: true })
    }

    sessionStorage.setItem('login-page', 'login-checker')
  }, [])

  return (
    <ConfigProvider componentSize="large">
      <div className={ Styles.loginPage }>
        <div className={ Styles.logonWrap }>
          <div className={ 'mr-3 d-flex align-items-center flex-column' }>
            <div className={ Styles.loginTitle }>
              <Image preview={ false } src={ LoginTitleImg } width={ 32 } height={ 32 }></Image>
              <div className={ 'ml-3' }>{ envInfo.title || '中正公考人事考试服务平台' }</div>
            </div>
            <div className={ Styles.loginSubTitle }>审核管理系统</div>
            <div>
              <Image preview={ false } src={ LoginBgLogoImg } width={ 390 }></Image>
            </div>
          </div>
          <div className={ 'ml-5' }>
            <div className={ Styles.loginContainer }>
              <Tabs centered items={ tabsItem }></Tabs>
              <div className={ Styles.loginTip }>注意：其他人员没有账号请联系机构管理员。</div>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default Login
