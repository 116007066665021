import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Form, Button, Table, ConfigProvider, Modal, InputNumber, Spin, Space } from 'antd'

import Styles from '../ExaminationAllocation.module.scss'
import SelectExaminationLocationModal from './SelectExaminationLocationModal'
import EditSelectedExamLocationModal from './EditSelectedExamLocationModal'
import { findForecastRoomList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { throttle } from '../../../plugins/utils'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'

const ConfigExamBatchLocation = forwardRef((props, ref) => {
  const selectExaminationLocationModalRef = useRef()
  const editSelectedExamLocationModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [roomExamineeNum, setRoomExamineeNum] = useState('30')
  const [selectedRoomNum, setSelectedRoomNum] = useState(0)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [forecastInfo, setForecastInfo] = useState([])
  const [loading, setLoading] = useState(false)

  const classColumns = [
    {
      title: '大类名称',
      key: 'levelName',
      dataIndex: 'levelName'
    },
    {
      title: '大类代码',
      key: 'levelCode',
      dataIndex: 'levelCode'
    },
    {
      title: '小类名称',
      key: 'majorName',
      dataIndex: 'majorName'
    },
    {
      title: '小类代码',
      key: 'majorCode',
      dataIndex: 'majorCode'
    },
    {
      title: '报考人数',
      key: 'applyCount',
      dataIndex: 'applyCount'
    },
    {
      title: '预计考场数',
      key: 'forecastRoomNumber',
      dataIndex: 'forecastRoomNumber'
    },
    {
      title: '尾考场人数',
      key: 'lastRoomNumber',
      dataIndex: 'lastRoomNumber'
    }
  ]

  const columns = [
    {
      title: '考点名称',
      key: 'locationName',
      dataIndex: 'locationName'
    },
    {
      title: '考点编号',
      key: 'locationNumber',
      dataIndex: 'locationNumber'
    },
    {
      width: 300,
      title: '考点地址',
      key: 'address',
      dataIndex: 'address'
    },
    {
      title: '最大考场数',
      key: 'maxRoomNumber',
      dataIndex: 'maxRoomNumber'
    },
    {
      title: '已选考场数',
      key: 'canRoomNumber',
      dataIndex: 'canRoomNumber'
    },
    {
      title: '可容纳考生数',
      key: 'maxExamineesNumber',
      dataIndex: 'maxExamineesNumber',
      render: (record, row) => {
        return roomExamineeNum * row.canRoomNumber
      }
    },
    {
      title: '考场起始编号',
      key: 'startNumber',
      dataIndex: 'startNumber'
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (
          <Button
            type={ 'primary' }
            ghost
            onClick={ showEditExaminationRoomConfig.bind(this, row) }
          >修改</Button>
        )
      }
    }
  ]

  useEffect(() => {
    setSelectedRoomNum(tableData.reduce((pre, current) => {
      return pre + Number(current.canRoomNumber)
    }, 0))

    props.updateBatchExamRoomInfo?.(tableData.map(item => {
      return {
        ...item,
        roomExamineeNum: roomExamineeNum,
        maxExamineesNumber: item.canRoomNumber * roomExamineeNum,
        needRoomNumber: getMinRoomNum(forecastInfo)
      }
    }))
  }, [tableData])

  useEffect(() => {
    if (props.batchInfo?.locationList?.length > 0) {
      setTableData(props.batchInfo.locationList.toSorted((a, b) => {
        return a.locationNumber - b.locationNumber
      }))
      setRoomExamineeNum(props.batchInfo.locationList[0].roomExamineeNum)
    } else {
      setTableData([])
      setRoomExamineeNum('30')
    }
  }, [props.batchInfo])

  useEffect(() => {
    if (props.batchInfo?.batchId) {
      findForecastRoomListAction()
    }
  }, [props.batchInfo, roomExamineeNum])

  const findForecastRoomListAction = () => {
    const params = {
      examId: selectedExam.id,
      batchId: props.batchInfo.batchId,
      standardRoomNumber: roomExamineeNum
    }
    setLoading(true)

    findForecastRoomList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setForecastInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
    })
  }

  const showEditExaminationRoomConfig = (row) => {
    setCurrentEditItem(row)
    editSelectedExamLocationModalRef.current.showModal()
  }

  const showSelectExaminationLocationModal = () => {
    selectExaminationLocationModalRef.current.showModal()
  }

  const roomExamineeNumChange = debounce((value) => {
    setRoomExamineeNum(value.replace(/\D/g, '') || '1')
  }, 600)

  const getMinRoomNum = (arr) => {
    return arr.reduce((pre, cur) => {
      return pre + cur.forecastRoomNumber
    }, 0)
  }

  const getRoomMaxExamineeNum = (limit, list) => {
    return limit * list.reduce((pre, current) => {
      return pre + current.canRoomNumber
    }, 0)
  }

  const getSelectedRoomStatus = (limit, roomNum) => {
    if (roomNum >= limit) {
      return [Styles.dataDashboardItemNumber, 'text-success'].join(' ')
    } else {
      return [Styles.dataDashboardItemNumber, 'text-danger'].join(' ')
    }
  }

  const saveSelectedExaminationLocation = (list) => {
    setTableData(list.toSorted((a, b) => {
      return a.locationNumber - b.locationNumber
    }))
  }

  const updateLocationItem = (locationItem) => {
    setTableData(tableData.map(item => {
      if (item.locationId === locationItem.locationId) {
        return locationItem
      } else {
        return item
      }
    }).toSorted((a, b) => {
      return a.locationNumber - b.locationNumber
    }))
  }

  const preStep = () => {
    props.preStep?.()
  }

  const saveExamLocationInfoAction = () => {
    props.saveExamLocationInfoAction?.()
  }

  return (
    <div className={ 'pt-3' } ref={ ref }>
      {/*<div className={ 'mb-2 font-weight-600' }>*/}
      {/*  <span>本批次批次参考考生共</span>*/}
      {/*  <span className={ 'mx-2 text-primary' }>{ props.batchInfo?.applyCount }</span>*/}
      {/*  <span>人</span>*/}
      {/*</div>*/}

      <div className={ 'mt-3' }>
        <Form>
          <Form.Item label={ '设置标准考场人数' } required>
            <InputNumber
              value={ roomExamineeNum }
              style={ { width: 150 } }
              min={ 1 }
              suffix={ '人' }
              onInput={ roomExamineeNumChange }
            ></InputNumber>
          </Form.Item>

          <ConfigProvider componentSize={'small'}>
            <Table
              className={'mb-3'}
              scroll={{
                y: 500
              }}
              columns={ classColumns }
              dataSource={ forecastInfo }
              rowKey={ record => record.majorCode }
              pagination={ false }
            ></Table>
          </ConfigProvider>

          <Form.Item>
            <Button
              type={ 'primary' }
              onClick={ showSelectExaminationLocationModal }
            >选择考点</Button>
          </Form.Item>
        </Form>
      </div>

      <Spin spinning={ loading }>
        <div className={ Styles.dataDashboard }>
          <div className={ Styles.dataDashboardItem }>
            <div className={ Styles.dataDashboardItemNumber }>{ props.batchInfo?.applyCount }</div>
            <div>报考人数</div>
          </div>
          <div className={ Styles.dataDashboardItem }>
            <div className={ Styles.dataDashboardItemNumber }>
              { getMinRoomNum(forecastInfo) }
            </div>
            <div>最少考场数</div>
          </div>
          <div className={ Styles.dataDashboardItem }>
            <div className={ getSelectedRoomStatus(getMinRoomNum(forecastInfo), selectedRoomNum) }
            >{ selectedRoomNum }</div>
            <div>已选考场数</div>
          </div>
          <div className={ Styles.dataDashboardItem }>
            <div
              className={ getSelectedRoomStatus(getMinRoomNum(forecastInfo), selectedRoomNum) }
            >{ getRoomMaxExamineeNum(roomExamineeNum, tableData) }</div>
            <div>已选考场最大容量</div>
          </div>
        </div>
      </Spin>

      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ columns }
          dataSource={ tableData }
          rowKey={ record => record.locationId }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <Space className={ 'mt-3 d-flex justify-content-center' }>
        <Button
          type={ 'primary' }
          onClick={ preStep }
        >上一步</Button>
        <Button
          type={ 'primary' }
          onClick={ saveExamLocationInfoAction }
          loading={ loading }
        >保存考点分配</Button>
      </Space>

      <SelectExaminationLocationModal
        ref={ selectExaminationLocationModalRef }
        saveSelectedItem={ saveSelectedExaminationLocation }
      ></SelectExaminationLocationModal>

      <EditSelectedExamLocationModal
        ref={ editSelectedExamLocationModalRef }
        locationInfo={ currentEditItem }
        saveLocationItem={ updateLocationItem }
      ></EditSelectedExamLocationModal>
    </div>
  )
})

export default ConfigExamBatchLocation
