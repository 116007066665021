import { ConfigProvider, Modal, Spin, Table } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { MenuOutlined } from '@ant-design/icons'

const SortableItem = SortableElement((props) => <tr { ...props } />)
const SortableBody = SortableContainer((props) => <tbody { ...props } />)

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={ {
      cursor: 'grab',
      color: '#999'
    } }
  />
))

const UpdateAnnouncementSortModal = forwardRef(({ columns, dataList, handleOnOk }, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tableList, setTableList] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal,
      hideModal,
      updateModalLoadingStatus
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setTableList(dataList)
    }
  }, [modalVisible])

  const updateModalLoadingStatus = (status) => {
    setLoading(status)
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const setActiveTableData = (data) => {
    return setTableList(data)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(tableList.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )

      setActiveTableData(newData)
    }
  }

  const DraggableContainer = (props) => {
    return <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  }

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = tableList.findIndex((x) => {
      return x.id === restProps['data-row-key']
    })
    return <SortableItem index={ index } { ...restProps } />
  }

  const onOk = () => {
    handleOnOk?.(tableList)
  }

  return (<Modal
    width={ 700 }
    title={ '配置排序' }
    open={ modalVisible }
    confirmLoading={ loading }
    onOk={ onOk }
    onCancel={ hideModal }
  >
    <Spin spinning={ loading }>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          columns={ [
            {
              title: '',
              dataIndex: 'sort',
              width: 60,
              className: 'drag-visible',
              render: () => <DragHandle/>
            },
            ...columns
          ] }
          dataSource={ tableList }
          rowKey="id"
          pagination={ false }
          scroll={ {
            y: 500
          } }
          components={ {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          } }
        ></Table>
      </ConfigProvider>
    </Spin>
  </Modal>)
})

export default UpdateAnnouncementSortModal
