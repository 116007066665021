import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, Select } from 'antd'

const typeOptions = [
  {
    label: '单行文本框',
    value: 'a'
  },
  {
    label: '下拉列表',
    value: 's'
  },
  {
    label: '日期选择器',
    value: 't'
  }
]

const EditTableHeaderForm = forwardRef(({ selectOptions, saveTableHeadField }, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (row) => {
    setModalVisible(true)
    if (row) {
      form.setFieldsValue({
        name: row.name,
        type: row.type,
        content: row.content,
        description: row.description,
        id: row.id
      })
    }
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then((fields) => {
      saveTableHeadField(fields)
      hideModal()
    }).catch(err => {
      console.error(err)
    })
  }

  return (<Modal
    title="编辑表头"
    open={ modalVisible }
    onCancel={ hideModal }
    onOk={ onOk }
  >
    <Form
      className={ 'my-3' }
      form={ form }
      labelCol={ { span: 8 } }
      wrapperCol={ { span: 12 } }
      initialValues={ {
        name: '',
        content: '',
        description: ''
      } }
    >
      <Form.Item
        noStyle
        name={ 'id' }
      ></Form.Item>
      <Form.Item
        label={ '表头名称' }
        name={ 'name' }
        rules={ [{ required: true, message: '表头名称不能为空' }] }
      >
        <Input
          placeholder={ '例：工作单位' }
        ></Input>
      </Form.Item>
      <Form.Item
        label={ '字段类型' }
        name={ 'type' }
        rules={ [{ required: true, message: '请选择字段类型' }] }
      >
        <Select
          className={ 'mr-2' }
          options={ typeOptions }
          placeholder={ '选择字段类型' }
        ></Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={ (prevValues, curValues) => prevValues.type !== curValues.type }
      >
        { ({ getFieldValue }) => {
          return getFieldValue('type') === 's'
            ? <Form.Item
              label={ '关联数据' }
              name={ 'content' }
              rules={ [{ required: true, message: '请选择字段类型' }] }
            >
              <Select options={ selectOptions }></Select>
            </Form.Item>
            : <></>
        } }
      </Form.Item>
      <Form.Item
        label={ '字段说明' }
        name={ 'description' }
      >
        <Input.TextArea placeholder={ '输入字段说明' }></Input.TextArea>
      </Form.Item>
    </Form>
  </Modal>)
})

export default EditTableHeaderForm
