import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Modal, notification, Select, Space, Table, Upload } from 'antd'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import {
  clearInterviewExaminees,
  downloadInterviewTemplate,
  examineeInterviewList,
  generateInterviewTable, getInterviewPrintStatusFile,
  updateExamineesInterview,
  uploadExamineesInterviewPhoto
} from '../../common/request'
import ResponseCode from '../../common/response-code'
import router from '../../routers/router'
import ExamineeInterviewAdmissionModal from './ExamineeInterviewAdmissionModal'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'
import { downloadFile, throttle } from '../../plugins/utils'
import AppButton from '../../components/AppButton'
import PageStatus from '../../components/PageStatus'
import DeIdentification from '../../components/DeIdentification'

function ImportExamInterview () {
  const examineeInterviewAdmissionModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const envInfo = useSelector(state => state.env.envInfo)
  const [form] = Form.useForm()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [pageStatus, setPageStatus] = useState({ text: '', status: '' })

  const printInterviewOptions = [
    { label: '全部', value: '' },
    { label: '未打印', value: 0 },
    { label: '已打印', value: 1 }
  ]

  const columns = [
    {
      title: '考生编号',
      key: 'candidateNumber',
      dataIndex: 'candidateNumber'
    },
    {
      width: 100,
      title: '姓名',
      key: 'name',
      dataIndex: 'name',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '身份证号',
      key: 'idCard',
      dataIndex: 'idCard',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'idCard' }></DeIdentification>)
      }
    },
    {
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode'
    },
    {
      title: '报考单位',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      title: '报考职位',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode'
    },
    {
      title: '打印状态',
      key: 'interviewPrintNumber',
      dataIndex: 'interviewPrintNumber',
      render: (record) => {
        return record && record > 0 ? '已打印' : '未打印'
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (
          <div className={ 'd-flex justify-content-end' }>
            <Button
              type={ 'primary' }
              ghost
              onClick={ showExamineeInterviewTableModal.bind(this, row) }
            >查看详情</Button>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageNum, pageSize])

  const getTableData = (searchData) => {
    const params = {
      ...searchData,
      examId: selectedExam.id,
      orderBy: '_id',
      orderType: 'DESC',
      pageSize,
      pageNum
    }

    setPageStatus({ text: '加载中...', status: 'loading' })

    examineeInterviewList(params).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setPageStatus({ text: '', status: '' })
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询面试通知失败'
      })
    })
  }

  const showExamineeInterviewTableModal = (row) => {
    setCurrentEditItem(row)
    examineeInterviewAdmissionModalRef.current.showModal()
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const handleSearch = (values) => {
    setPageNum(1)
    getTableData(values)
  }

  const beforeUpload = () => {
    setPageStatus({ text: '正在导入', status: 'loading' })
  }

  const uploadPersonImageChange = ({ file }) => {
    const res = file.response
    if (res) {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        getTableData()
        Modal.success({
          title: '操作成功',
          content: res.msg
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  const downloadInterviewTemplateAction = () => {
    setPageStatus({ text: '正在上传', status: 'loading' })
    downloadInterviewTemplate({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      let blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/octet-stream'
      })
      let objectUrl = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = objectUrl
      a.download = '面试准考证模板'
      a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
      window.URL.revokeObjectURL(blob)
    }).catch(err => {
      setPageStatus({ text: '', status: '' })
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载面试准考证模板失败'
      })
    })
  }

  const showClearInterviewDataModal = () => {
    Modal.confirm({
      title: '清除数据',
      content: '确定要清除面试通知所有考生数据吗？',
      onOk: () => {
        clearInterviewExamineesAction()
      }
    })
  }

  const clearInterviewExamineesAction = () => {
    clearInterviewExaminees({ examId: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，清除面试通知考生数据失败'
      })
    })
  }

  const generateInterviewTableAction = () => {
    setPageStatus({ text: '正在生成', status: 'loading' })
    generateInterviewTable({ examId: selectedExam.id }).then(res => {
      setPageStatus({ text: '', status: '' })
      if (res.code === ResponseCode.success) {
        Modal.confirm({
          title: '操作成功',
          content: '下载任务已添加，是否跳转到下载中心查看',
          onOk: () => {
            const siteId = sessionStorage.getItem('siteId')
            router.navigate(`/${ siteId }/exam/download-center`)
          }
        })
      }
    }).catch(err => {
      console.error(err)
      setPageStatus({ text: '', status: '' })
      Modal.error({
        title: '错误',
        content: '网络错误，生成座次表失败'
      })
    })
  }

  const downloadPrintStatusFile = () => {
    const searchData = form.getFieldsValue()

    getInterviewPrintStatusFile({
      examId: selectedExam.id,
      ...searchData
    }).then(res => {
      downloadFile(res, 'xlsx', '考生面试成绩打印状态表').catch(err => {
        console.error(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，导出打印状态表失败'
      })
    })
  }

  return (<>
    <SelectExam>
      <PageStatus pageStatus={ pageStatus.status } text={ pageStatus.text }>
        <Form
          layout={ 'inline' }
          form={ form }
          initialValues={ {
            name: '',
            idCard: '',
            printInterview: ''
          } }
          onValuesChange={ throttle(handleSearch, 500) }
        >
          <Form.Item
            label={ '考生姓名' }
            name={ 'name' }
            trigger={ 'onInput' }
          >
            <Input placeholder={ '输入考生姓名查询' }></Input>
          </Form.Item>
          <Form.Item
            label={ '身份证号' }
            name={ 'idCard' }
            trigger={ 'onInput' }
          >
            <Input placeholder={ '输入考生身份证号查询' }></Input>
          </Form.Item>
          <Form.Item
            label={ '打印状态' }
            name={ 'printInterview' }
          >
            <Select options={ printInterviewOptions } style={ { width: 120 } }></Select>
          </Form.Item>
          {/*<Form.Item*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    type={ 'primary' }*/}
          {/*    onClick={ handleSearch }*/}
          {/*  >查询</Button>*/}
          {/*</Form.Item>*/}
        </Form>
        <div className={ 'my-3 d-flex align-items-center justify-content-between' }>
          {
            selectedExam.examinationStatus < 4
              ? (
                <Space>
                  <AppButton
                    authCode={ 'button_mssjdr-xzmb' }
                    type={ 'primary' }
                    onClick={ downloadInterviewTemplateAction }
                  >下载模板</AppButton>
                  <ComponentVisibleByAuth authCode={ 'button_mssjdr-drks' }>
                    <Upload
                      name={ 'file' }
                      action={ `${ envInfo.picDomain }/${ updateExamineesInterview }` }
                      headers={ {
                        authorization: window.sessionStorage.getItem('access_token')
                      } }
                      data={ {
                        examId: selectedExam.id
                      } }
                      disabled={ pageStatus.status === 'loading' }
                      maxCount={ 1 }
                      showUploadList={ false }
                      beforeUpload={ beforeUpload }
                      onChange={ uploadPersonImageChange }
                    >
                      <Button type={ 'primary' }>导入考生</Button>
                    </Upload>
                  </ComponentVisibleByAuth>
                  <ComponentVisibleByAuth authCode={ 'button_mssjdr-drzp' }>
                    <Upload
                      name={ 'file' }
                      action={ `${ envInfo.picDomain }/${ uploadExamineesInterviewPhoto }` }
                      headers={ {
                        authorization: window.sessionStorage.getItem('access_token')
                      } }
                      data={ {
                        examId: selectedExam.id
                      } }
                      disabled={ pageStatus.status === 'loading' }
                      maxCount={ 1 }
                      showUploadList={ false }
                      beforeUpload={ beforeUpload }
                      onChange={ uploadPersonImageChange }
                    >
                      <Button type={ 'primary' }>导入照片</Button>
                    </Upload>
                  </ComponentVisibleByAuth>
                  <AppButton
                    authCode={ 'button_mssjdr-qcsj' }
                    type={ 'primary' }
                    ghost
                    danger
                    onClick={ showClearInterviewDataModal }
                  >清除数据</AppButton>
                </Space>
              )
              : <div></div>
          }


          <Space>
            <AppButton
              authCode={ 'button_mssjdr-dcdyztb' }
              type={ 'primary' }
              onClick={ downloadPrintStatusFile }
            >导出打印状态表</AppButton>
            <AppButton
              authCode={ 'button_mssjdr-sczcb' }
              type={ 'primary' }
              onClick={ generateInterviewTableAction }
            >生成座次表</AppButton>
          </Space>
        </div>

        <Table
          loading={ pageStatus.status === 'loading' }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ record => record._id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </PageStatus>
    </SelectExam>

    <ExamineeInterviewAdmissionModal
      ref={ examineeInterviewAdmissionModalRef }
      examineeItem={ currentEditItem }
    ></ExamineeInterviewAdmissionModal>
  </>)
}

export default ImportExamInterview

