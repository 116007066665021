import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import { RedoOutlined } from '@ant-design/icons'

import './PersonVerification.scss'

class PersonVerification extends Component {
  state = {
    modalVisible: false,
    tips: [],
    fontArr: [],
    pointer: []
  }
  accuracy = 10
  key = '赵钱孙李周吴郑王朱秦尤许何吕施张孔曹严华金魏陶姜戚谢邹喻柏水窦章云苏潘葛奚范彭郎鲁韦昌马苗凤花方俞任袁柳酆鲍史唐'

  initCanvas = () => {
    this.canvas = document.getElementById('person-verification')
    this.canvas.width = this.canvas.offsetWidth
    this.canvas.height = this.canvas.offsetHeight
    this.ctx = this.canvas.getContext('2d')

    this.getImg()
  }

  getImg = () => {
    this.bgImg = new Image()
    const reqSvgs = require.context('../../assets/images/check/', true, /\.png$/)
    const allSvgFilepaths = reqSvgs.keys()
    let imagePath = []
    const randomNum = parseInt(Math.random() * allSvgFilepaths.length, 10) + 1
    for (let i in allSvgFilepaths) {
      if (allSvgFilepaths[i] === './' + randomNum + '.png') {
        imagePath = allSvgFilepaths[i]
      }
    }

    this.bgImg.src = reqSvgs(imagePath)
    this.bgImg.onload = () => {
      this.drawCanvas()
    }
  }

  randomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  getRandomCharacter = () => {
    const fontStrLen = this.key.length
    const randomIndex = Math.floor(Math.random() * fontStrLen)
    const character = this.key.charAt(randomIndex)

    const isSome = this.state.fontArr.some(item => {
      return item.character === character
    })
    if (isSome) {
      return this.getRandomCharacter()
    } else {
      return character
    }
  }

  randomColor = (min, max) => {
    let r = this.randomNum(min, max)
    let g = this.randomNum(min, max)
    let b = this.randomNum(min, max)
    return 'rgb(' + r + ',' + g + ',' + b + ')'
  }

  drawCanvas = () => {
    this.ctx.drawImage(this.bgImg, 0, 0, this.canvas.width, this.canvas.height)


    for (let i = 0; i < 6; i++) {
      const character = this.getRandomCharacter()
      const fontSize = this.randomNum(20, this.canvas.height * 1 / 4)
      const fontWeight = Math.random() > 0.5 ? 'bold' : 'normal'
      const fontStyle = Math.random() > 0.5 ? 'italic' : 'normal'
      const fontFamily = Math.random() > 0.5 ? 'sans-serif' : 'serif'
      const x = this.canvas.width / 6 * i + 10
      const y = Math.random() * (this.canvas.height - fontSize)

      this.ctx.fillStyle = this.randomColor(0, 255)
      this.ctx.font = `${ fontStyle } ${ fontWeight } ${ fontSize }px ${ fontFamily }`
      this.ctx.textBaseline = 'top'
      this.ctx.fillText(character, x, y)

      this.state.fontArr.push({
        character,
        x,
        y,
        fontSize
      })
      this.setState((state) => {
        return {
          fontArr: state.fontArr
        }
      })
    }

    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * this.state.fontArr.length)
      const character = this.state.fontArr.splice(randomIndex, 1)[0]
      this.state.tips.push(character)
      this.setState((state) => {
        return {
          tips: state.tips
        }
      })
    }
  }

  openModal = () => {
    this.setState({ modalVisible: true }, () => {
      if (!this.canvas && this.state.modalVisible) {
        this.initCanvas()
      } else {
        this.reset()
      }
    })
  }

  drawCircle = (x, y) => {
    this.ctx.beginPath()
    this.ctx.lineWidth = 3
    this.ctx.arc(x, y, 15, 0, 2 * Math.PI)
    this.ctx.strokeStyle = '#ffffff'
    this.ctx.fillStyle = '#7cb305'
    this.ctx.fill()
    this.ctx.closePath()
    this.ctx.fillStyle = '#ffffff'
    this.ctx.font = 'normal normal 26px auto'
    this.ctx.textBaseline = 'top'
    this.ctx.fillText(this.state.pointer.length + 1 + '', (x - 7.5), (y - 10))
    this.ctx.stroke()
  }

  createPointer = (e) => {
    if (this.state.pointer.length === 3) {
      return
    }
    const rect = this.canvas.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    if (this.state.pointer.length < this.state.tips.length) {
      this.drawCircle(x, y)

      this.state.pointer.push({ x, y })
      this.setState((state) => {
        return {
          pointer: state.pointer
        }
      })
    }
  }

  verify = () => {
    const result = this.state.pointer.every((item, index) => {
      const _left = item.x > this.state.tips[index].x - this.accuracy
      const _top = item.y > this.state.tips[index].y - this.accuracy
      const _right = item.x < this.state.tips[index].x + this.state.tips[index].fontSize + this.accuracy
      const _bottom = item.y < this.state.tips[index].y + this.state.tips[index].fontSize + this.accuracy
      return _left && _right && _top && _bottom
    })

    return result
  }

  reset = () => {
    this.setState({
      fontArr: [],
      tips: [],
      pointer: []
    })
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.getImg()
  }

  getModelTitle = () => {
    return this.state.tips.map(item => {
      return `【${ item.character }】`
    }).join('、')
  }

  checkPointerNum = () => {
    return !(this.state.pointer.length === this.state.tips.length)
  }

  handleValidate = () => {
    if (this.state.pointer.length === this.state.tips.length) {
      const isPass = this.verify()
      if (isPass) {
        this.props.checkSuccess && this.props.checkSuccess()
        this.setState({ modalVisible: false })
      } else {
        Modal.error({
          title: '错误',
          content: '请按顺序依次选择文字',
          centered: true,
          onOk: () => {
            this.reset()
          }
        })
      }
    }
  }

  handleCancel = () => {
    this.setState({ modalVisible: false })
  }

  render () {
    return (
      <div>
        <Modal
          centered
          title="图形验证"
          closable={ false }
          cancelText="取消"
          okText="确定"
          footer={ null }
          open={ this.state.modalVisible }
        >
          <div className="mb-1 d-flex align-items-center justify-content-between">
            <div>请按顺序选择{ this.getModelTitle() }</div>
            <Button
              size="mini"
              type="link"
              primary="true"
              icon={ <RedoOutlined/> }
              onClick={ this.reset }
            >刷新</Button>
          </div>
          <canvas
            id="person-verification"
            onClick={ this.createPointer }
          ></canvas>
          <div className="mt-2 d-flex align-items-center justify-content-end">
            <Button
              className="mr-2"
              size="normal"
              onClick={ this.handleCancel }
            >取消</Button>
            <Button
              disabled={ this.checkPointerNum() }
              size="normal"
              type="primary"
              onClick={ this.handleValidate }
            >确定</Button>
          </div>
        </Modal>

      </div>
    )
  }
}

export default PersonVerification
