import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Empty, Form, Input, Modal, Spin } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'

import { getExamDetail, getExamInfoById } from '../../common/request'
import ResponseCode from '../../common/response-code'
import SelectExamModal from './SelectExamModal'
import { updateSelectedExam } from '../../store/slice/exam-info-slice'
import eventBus from '../../plugins/events'

function SelectExam (props) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const selectExamModalRef = useRef()
  const selectedExamItem = useSelector(state => state.examInfo.selectedExam)
  const examList = useSelector(state => state.examInfo.examList)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useSearchParams()
  const queryExamId = search.get('examId')

  useEffect(() => {
    if (selectedExamItem?.id) {
      setSearch(`examId=${ selectedExamItem.id }`)
      form.setFieldValue('applySiteName', selectedExamItem.name)
    } else {
      queryExamId && getExamInfoByIdAction(queryExamId)
    }
    eventBus.addListener('reloadExamDetail', eventListener)

    return () => {
      eventBus.removeListener('reloadExamDetail', eventListener)
    }
  }, [])

  const eventListener = (id) => {
    getExamInfoByIdAction(id, 'reload')
  }

  const getExamInfoByIdAction = (id) => {
    setLoading(true)
    Promise.all([
      getExamInfoById({ id: id }),
      getExamDetail({ id: id })
    ]).then(([examInfoRes, examDetailRes]) => {
      setLoading(false)
      if (examDetailRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: examDetailRes.msg,
          centered: true
        })
        return
      }
      if (examInfoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: examInfoRes.msg,
          centered: true
        })
        return
      }

      form.setFieldValue('applySiteName', examDetailRes.data.name)

      dispatch(updateSelectedExam({
        selectedExam: {
          ...examInfoRes.data,
          ...examDetailRes.data
        }
      }))
    })
  }

  const showSelectExamModal = () => {
    selectExamModalRef.current.showModal()
  }

  const selectedExamChange = (examItem) => {
    setSearch(`examId=${ examItem.id }`)
    getExamInfoByIdAction(examItem.id)
  }

  const getContent = (examItem) => {
    if (examItem.id) {
      return (<>
        <Spin spinning={ loading }>
          { props.children }
        </Spin>
      </>)
    } else {
      return (<>
        <div className={ 'd-flex align-items-center justify-content-center' } style={ { height: 300 } }>
          <div>
            <Empty description={ false }></Empty>
            <div className={ 'd-flex justify-content-center' }>
              <Button type={ 'primary' } ghost onClick={ showSelectExamModal }>选择考试</Button>
            </div>
          </div>
        </div>
      </>)
    }
  }

  return (
    <div>
      <Spin spinning={ loading } tip={ '加载考试列表' }>
        <Form
          form={ form }
        >
          <Form.Item label={ '考试名称' } name={ 'applySiteName' } rules={ [{ required: true }] }>
            <Input
              readOnly
              placeholder={ '请选择考试' }
              style={ { width: 450 } }
              onClick={ showSelectExamModal }
              suffix={ <DownOutlined style={ { fontSize: 14, color: '#d9d9d9' } }/> }
            ></Input>
          </Form.Item>
        </Form>
        { getContent(selectedExamItem) }
      </Spin>

      <SelectExamModal
        ref={ selectExamModalRef }
        examList={ examList }
        loading={ loading }
        onChange={ selectedExamChange }
      ></SelectExamModal>
    </div>
  )
}

export default SelectExam
