import React, { useState, useRef } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import router from '../../routers/router'
import { checkerLogin } from '../../common/request'
import ResponseCode from '../../common/response-code'
import FindPasswordModal from '../login/FindPasswordModal'
import { useSelector } from 'react-redux'

function LoginPassword () {
  const FindPasswordModalRef = useRef()
  const navigate = useNavigate()
  const { siteId } = useParams()
  const envInfo = useSelector(state => state.env.envInfo)
  const [form] = Form.useForm()

  const [formData] = useState({
    userName: '',
    password: ''
  })

  const getEmptyRule = (message) => {
    return [
      {
        required: true,
        message: message
      }
    ]
  }

  const onFinish = () => {
    form.validateFields().then((params) => {
      checkerLogin({
        userName: params.userName,
        pwd: params.password,
        area: envInfo.name
      }).then(res => {
        if (res.code === ResponseCode.success && res.data) {
          sessionStorage.setItem('access_token', res.data.token)
          sessionStorage.setItem('token_header', res.data.tokenHead)

          if (router.state.location.search.indexOf('redirect') > -1) {
            navigate(window.decodeURIComponent(router.state.location.search.split('redirect=')[1]))
          } else {
            navigate(`/${ siteId }/exam/dashboard`)
          }
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      })
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: err.errorFields[0].errors[0]
      })
    })
  }

  const onKeyDown = (event) => {
    const formData = form.getFieldsValue()
    if (event.keyCode === 13 && formData.userName && formData.password) {
      onFinish()
    }
  }

  return (
    <div style={{ marginTop: 39 }}>
      <Form
        name="basic"
        form={ form }
        autoComplete="off">
        <Form.Item
          label=""
          name="userName"
          rules={ getEmptyRule('手机号不能为空') }
          style={{ marginBottom: 34 }}
        >
          <Input
            value={ formData.userName }
            placeholder="请输入手机号"
            prefix={ <UserOutlined style={ { color: '#516FEB' } }/> }
            onKeyDown={ onKeyDown }
          />
        </Form.Item>
        <Form.Item
          label=""
          name="password"
          rules={ getEmptyRule('密码不能为空') }
          style={{ marginBottom: 34 }}
        >
          <Input.Password
            value={ formData.password }
            placeholder="请输入密码"
            prefix={ <LockOutlined style={ { color: '#516FEB' } }/> }
            onKeyDown={ onKeyDown }
          />
        </Form.Item>
      </Form>
      <div>
        <Button className="w-100" type="primary" onClick={ onFinish }>登录</Button>
      </div>
      <div className="d-flex justify-content-center" style={{ marginTop: 22 }}>
        <Button type="link" size="small" onClick={ () => FindPasswordModalRef.current.showModal() }>
          忘记密码
        </Button>
      </div>

      <FindPasswordModal
        ref={ FindPasswordModalRef }
      ></FindPasswordModal>
    </div>
  )
}

export default LoginPassword
