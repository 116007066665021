import { Spin, Empty } from 'antd'

import Styles from './ExamineeInfoModal.module.scss'

function ExamineePublicResult ({candidateScore, status}) {

  const getExamineeResultItems= (list = [], loading = false) => {
    if (loading) {
      return <Spin spinning={ loading }>
        <div style={ { height: 300 } }></div>
      </Spin>
    } else if(list.length === 0) {
      return <Empty description={ '暂无该考生成绩' }></Empty>
    } else {
      return list.map((item, index) => {
        return (
          <div className={ Styles.infoItem } key={ index }>
            <div className={ Styles.infoLabel } >{ item.label }</div>
            <div>{ item.value }</div>
          </div>
        )
      })
    }
  }

  return (
    <div className={ 'd-flex justify-content-between' }>
      <div className={ Styles.sampleTable }>
        <div>{ getExamineeResultItems(candidateScore, status) }</div>
      </div>
    </div>
  )
}

export default ExamineePublicResult
