import React, { useEffect, useState } from 'react'
import { Descriptions, Tabs } from 'antd'

import { getExamProcessTime } from '../../common/request'
import SettingTime from './exam-detail-tabs/setting-time/SettingTime'
import SettingSubject from './exam-detail-tabs/setting-subject/SettingSubject'
import SettingClass from './exam-detail-tabs/setting-class/SettingClass'
import SettingApplySite from './exam-detail-tabs/setting-apply-site/SettingApplySite'
import SettingPositionTable from './exam-detail-tabs/setting-position-table/SettingPositionTable'
import SettingPosition from './exam-detail-tabs/setting-position/SettingPosition'
import SettingApplyFields from './exam-detail-tabs/setting-apply-fields/SettingApplyFields'
import SettingExamFile from './exam-detail-tabs/setting-exam-file/SettingExamFile'
import SettingSampleTable from './exam-detail-tabs/setting-sample-table/SettingSampleTable'
import SettingExamApplyCost from './exam-detail-tabs/setting-exam-apply-cost/SettingExamApplyCost'
import ExamDashboard from './exam-detail-tabs/exam-dashboard/ExamDashboard'
import { useSelector } from 'react-redux'
import { examProcessTabsMap } from '../../common/common'
import SelectExam from '../../components/select-exam/SelectExam'
import eventBus from '../../plugins/events'
import { useSearchParams } from 'react-router-dom'
import SettingApplyPublicity from './exam-detail-tabs/setting-apply-publicity/SettingApplyPublicity'

const ExamDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryActiveTabKey = searchParams.get('activeTabKey')
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const userAuth = useSelector(state => state.userInfo.userAuth)
  const [tabItems, setTabItems] = useState([])
  const [activeTabs, setActiveTabs] = useState('')
  const [examVisibleTabs, setExamVisibleTabs] = useState([])

  const examProcessMap = {
    apply: '报名',
    examinationRoom: '考场安排',
    publicResult: '成绩公布',
    interviewNotice: '面试'
  }

  useEffect(() => {
    if (userAuth.length > 0 && examVisibleTabs.length > 0) {
      setTabItems(filterTabsByAuth(initTabsItems, userAuth).filter(item => {
        if (item.key === 'examApplyCost') {
          return examVisibleTabs.includes(item.key) && selectedExam.payType === 1
        } else {
          return examVisibleTabs.includes(item.key)
        }
      }))
    }
  }, [userAuth, examVisibleTabs])

  useEffect(() => {
    if (tabItems.length > 0) {
      if (tabItems.map(item => item.key).includes(queryActiveTabKey)) {
        setActiveTabs(queryActiveTabKey)
      } else {
        setActiveTabs(tabItems[0].key)
      }
    }
  }, [tabItems])

  useEffect(() => {
    if (selectedExam.id) {
      setExamVisibleTabs([...new Set(selectedExam.process.map(key => {
        return examProcessTabsMap[key]
      }).flat())])
    }
  }, [selectedExam])

  useEffect(() => {
    if (examVisibleTabs.length > 0 && !queryActiveTabKey) {
      if (!examVisibleTabs.includes(activeTabs)) {
        setActiveTabs(examVisibleTabs[0])
      }
    }
  }, [examVisibleTabs])

  const getExamDetailAction = () => {
    eventBus.emit('reloadExamDetail', selectedExam.id)
  }

  const activeTabsChange = (activeKey) => {
    setActiveTabs(activeKey)
    setSearchParams({
      examId: searchParams.get('examId'),
      activeTabKey: activeKey
    })
  }

  const updateExamStatusAndData = () => {
    getExamDetailAction()
    getExamProcessTime()
  }

  const initTabsItems = [
    {
      key: 'examDashboard',
      label: '配置进度',
      children: (
        <ExamDashboard
          examInfo={ selectedExam }
          selectActiveTabs={ activeTabsChange }
          uploadExamDetail={ updateExamStatusAndData }
        ></ExamDashboard>
      )
    },
    {
      key: 'examFile',
      label: '考试文件',
      children: (
        <SettingExamFile
          examInfo={ selectedExam }
        ></SettingExamFile>
      )
    },
    {
      key: 'timeConfig',
      label: '时间安排',
      children: (
        <SettingTime
          examInfo={ selectedExam }
        ></SettingTime>
      )
    },
    {
      key: 'applySite',
      label: '报名点',
      children: (
        <SettingApplySite
          updateExamDetail={ getExamDetailAction }
        ></SettingApplySite>
      )
    },
    {
      key: 'applyField',
      label: '报名字段',
      children: (
        <SettingApplyFields
          examInfo={ selectedExam }
          updateExamDetail={ getExamDetailAction }
        ></SettingApplyFields>
      )
    },
    {
      key: 'subjectConfig',
      label: '考试科目',
      children: (
        <SettingSubject
          examInfo={ selectedExam }
        ></SettingSubject>
      )
    },
    {
      key: 'classification',
      label: '考试分类',
      children: (
        <SettingClass
          examInfo={ selectedExam }
        ></SettingClass>
      )
    },
    {
      key: 'examApplyCost',
      label: '报名费用',
      children: (
        <SettingExamApplyCost
          examInfo={ selectedExam }
        ></SettingExamApplyCost>
      )
    },

    {
      key: 'positionField',
      label: '职位字段',
      children: (
        <SettingPositionTable
          examInfo={ selectedExam }
          updateExamDetail={ getExamDetailAction }
        ></SettingPositionTable>
      )
    },
    {
      key: 'position',
      label: '职位管理',
      children: (
        <SettingPosition
          examInfo={ selectedExam }
        ></SettingPosition>
      )
    },
    {
      key: 'sampleFile',
      label: '样表设置',
      children: (
        <SettingSampleTable
          examInfo={ selectedExam }
        ></SettingSampleTable>
      )
    },
    {
      key: 'applyPublicity',
      label: '报名公示',
      children: (
        <SettingApplyPublicity
          examInfo={ selectedExam }
        ></SettingApplyPublicity>
      )
    }
  ]

  const filterTabsByAuth = (tabs, authList) => {
    let menu = null
    if (userInfo.currentRoleTag === 'superAdmin') {
      menu = tabs
    } else {
      menu = tabs.filter(tab => {
        return authList.some(auth => {
          return auth.type === 'menu' && `exam-detail/${ tab.key }` === auth.path
        })
      })
    }

    return menu
  }

  const getExamProcessLabel = (list) => {
    return list?.map(item => {
      return examProcessMap[item]
    }).join('、')
  }

  return (
    <div>
      <SelectExam>
        <div>
          <Descriptions title="基本信息">
            <Descriptions.Item label="组织机构">{ selectedExam.orgDetail?.name }</Descriptions.Item>
            <Descriptions.Item label="考试流程">{ getExamProcessLabel(selectedExam.process) }</Descriptions.Item>
            <Descriptions.Item label="考试类型">公务员/事业单位招考</Descriptions.Item>
            <Descriptions.Item label="是否缴费">
              { String(selectedExam.payType) === '1' ? '开启' : '关闭' }
            </Descriptions.Item>
            <Descriptions.Item label="是否笔试确认">
              { String(selectedExam.takeExamCheck) === '1' ? '开启' : '关闭' }
            </Descriptions.Item>
            <Descriptions.Item label="是否审核">
              { String(selectedExam.isCheck) === '1' ? '开启' : '关闭' }
            </Descriptions.Item>
            <Descriptions.Item label="自动审核">
              { String(selectedExam.checkType) === '2' ? '开启' : '关闭' }
            </Descriptions.Item>
            <Descriptions.Item label="人工复审">
              { String(selectedExam.artificialCheck) === '1' ? '开启' : '关闭' }
            </Descriptions.Item>
            <Descriptions.Item label="报名表预打印">
              { String(selectedExam.printAhead) === '1' ? '开启' : '关闭' }
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div style={ { borderTop: '1px solid #d1d1d1' } }>
          <Tabs
            activeKey={ activeTabs }
            items={ tabItems }
            onChange={ activeTabsChange }
          ></Tabs>
        </div>
      </SelectExam>
    </div>
  )
}

export default ExamDetail
