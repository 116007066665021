import {
  AppstoreOutlined,
  DownloadOutlined,
  FileProtectOutlined, HistoryOutlined,
  IdcardOutlined,
  MonitorOutlined,
  SettingOutlined,
  SisternodeOutlined,
  SnippetsOutlined,
  TeamOutlined, UsergroupAddOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

export const menuItems = [
  {
    key: 'dashboard',
    icon: <AppstoreOutlined/>,
    label: <Link to="dashboard">首页</Link>
  },
  {
    key: 'exam-management',
    icon: <SnippetsOutlined/>,
    label: '考试管理',
    children: [
      {
        key: 'exam-management-list',
        label: <Link to="exam-management-list">考试创建</Link>
      },
      {
        key: 'exam-detail',
        label: <Link to="exam-detail">考试配置</Link>
      },
      {
        key: 'examination-publish',
        label: <Link to="examination-publish">考试发布</Link>
      },
      {
        key: 'import-exam-score',
        label: <Link to="import-exam-score">笔试成绩导入</Link>
      },
      {
        key: 'import-exam-interview',
        label: <Link to="import-exam-interview">面试数据导入</Link>
      }
    ]
  },
  {
    key: 'examination-room-allocation',
    icon: <SisternodeOutlined/>,
    label: '考场安排',
    children: [
      {
        key: 'examination-auto-allocation',
        label: <Link to="examination-auto-allocation">考场系统分配</Link>
      },
      {
        key: 'examination-manual-allocation',
        label: <Link to="examination-manual-allocation">考场数据导入</Link>
      }
    ]
  },
  {
    key: 'exam-check',
    icon: <FileProtectOutlined/>,
    label: '审核管理',
    children: [
      {
        key: 'exam-check-management',
        label: <Link to="exam-check-management">资格审核</Link>
      },
      {
        key: 'exam-check-account-management',
        label: <Link to="exam-check-account-management">审核账号维护</Link>
      }
    ]
  },
  {
    key: 'examinee-management-root',
    icon: <TeamOutlined/>,
    label: '考生管理',
    children: [
      {
        key: 'examinee-management',
        label: <Link to="examinee-management">考生信息维护</Link>
      },
      {
        key: 'examinee-export',
        label: <Link to="examinee-export">考生数据导出</Link>
      },
      {
        key: 'examinee-print',
        label: <Link to="examinee-print">考生表下载</Link>
      }
    ]
  },
  {
    key: 'poor-examinee-management',
    icon: <TeamOutlined/>,
    label: '免收费管理',
    children: [
      {
        key: 'poor-examinee-check',
        label: <Link to="poor-examinee-check">免收费考生审核</Link>
      },
      {
        key: 'poor-examinee-refund',
        label: <Link to="poor-examinee-refund">免收费考生退费</Link>
      }
    ]
  },
  {
    key: 'exam-statistical',
    icon: <MonitorOutlined/>,
    label: <Link to="exam-statistical">查询统计</Link>
  },
  {
    key: 'download-center',
    icon: <DownloadOutlined/>,
    label: <Link to="download-center">下载中心</Link>
  },
  {
    key: 'examinee-account-management',
    icon: <IdcardOutlined/>,
    label: <Link to="examinee-account-management">考生注册信息</Link>
  },
  {
    key: 'basic',
    icon: <SettingOutlined/>,
    label: '基础设施',
    children: [
      {
        key: 'dictionary-management',
        label: <Link to="dictionary-management">字典管理</Link>
      },
      {
        key: 'apply-field-management',
        label: <Link to="apply-field-management">报名字段管理</Link>
      },
      {
        key: 'position-field-management',
        label: <Link to="position-field-management">职位字段管理</Link>
      },
      {
        key: 'file-template-field-management',
        label: <Link to="file-template-field-management">样表字段管理</Link>
      },
      {
        key: 'organization-management',
        label: <Link to="organization-management">机构管理</Link>
      },
      {
        key: 'receiving-account-management',
        label: <Link to="receiving-account-management">收款账户管理</Link>
      }
    ]
  },
  {
    key: 'system-auth-management',
    icon: <UsergroupAddOutlined/>,
    label: '运维管理',
    children: [
      {
        key: 'account-management',
        label: <Link to="account-management">账号管理</Link>
      },
      {
        key: 'authority-management',
        label: <Link to="authority-management">权限管理</Link>
      },
      {
        key: 'role-management',
        label: <Link to="role-management">角色管理</Link>
      },
      {
        key: 'monitoring-center',
        label: <Link to="monitoring-center">异常监控</Link>
      }
    ]
  },
  {
    key: 'log-record',
    icon: <HistoryOutlined/>,
    label: '操作日志',
    children: [
      {
        key: 'log-record-manage',
        label: <Link to="log-record-manage">管理端</Link>
      },
      {
        key: 'log-record-checker',
        label: <Link to="log-record-checker">审核端</Link>
      },
      {
        key: 'log-record-apply',
        label: <Link to="log-record-apply">报名端</Link>
      },
      {
        key: 'log-record-finance',
        label: <Link to="log-record-finance">对账端</Link>
      }
    ]
  }
]
