import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, Input, Spin, notification } from 'antd'
import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons'

import Styles from './edit-sample-table.module.scss'
import {
  updateApplyForm,
  updateExamCertificate,
  updateInterviewNotice
} from '../../../../common/request'
import { sampleTableMap } from '../../../../common/common'
import ResponseCode from '../../../../common/response-code'

import AppEditor from '../../../../components/AppEditor'

const EditSampleTableModal = forwardRef((props, ref) => {
  const [editorInstance, setEditorInstance] = useState(null)
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [fields, setFields] = useState([])
  const [fieldOptions, setFieldOptions] = useState([])
  const [selectedFieldsCodeMap, setSelectedFieldsCodeMap] = useState(new Set())
  const [editorDisabled, setEditorDisabled] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible && editorInstance) {
      setModalTitle(`配置${ props.fileItem.fileName }`)
      editorInstance.setContent(props.fileItem.content || '')
      editorContentChange()
      getFieldList()
    }
  }, [modalVisible, editorInstance])

  const getFieldList = () => {
    setFields(props.fileItem.fields || [])
    setFieldOptions(props.fileItem.fields || [])
  }

  const showModal = (editorDisabled) => {
    setModalVisible(true)
    setEditorDisabled(!!editorDisabled)
  }

  const hideModal = () => {
    setModalVisible(false)
    editorInstance.setContent('')
    setSelectedFieldsCodeMap(new Set())
    removeTinyMceCache()
  }

  const removeTinyMceCache = () => {
    sessionStorage.removeItem(`tinymce-autosave-${ window.location.pathname }${ window.location.search }-${ editorInstance.id }-draft`)
    sessionStorage.removeItem(`tinymce-autosave-${ window.location.pathname }${ window.location.search }-${ editorInstance.id }-time`)
  }

  const onOk = () => {
    if (editorDisabled) {
      hideModal()
      return
    }

    setModalLoading(true)
    let request
    const editorContent = editorInstance.getContent()
    const params = {
      examId: props.examInfo.id,
      template: editorContent,
      fieldsList: props.fileItem.fields
    }

    switch (props.fileItem.fileType) {
      case sampleTableMap.apply.key:
        request = updateApplyForm(params)
        break
      case sampleTableMap.examinationRoom.key:
        request = updateExamCertificate(params)
        break
      case sampleTableMap.interviewNotice.key:
        request = updateInterviewNotice(params)
    }

    request.then(res => {
      setModalLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setModalLoading(false)
      Modal.error({
        title: '网络错误',
        content: `保存样表文件【${ props.fileItem.fileName }】失败`,
        centered: true
      })
    })
  }

  const generateFieldList = (fieldList, selectedFieldsCodeMap) => {
    return fieldList?.map(field => {
      return (
        <div
          className={ Styles.fieldItem }
          key={ field.fieldCode }
          onClick={ insertField.bind(this, field) }>
          { field.fieldName }
          <div className={ Styles.isSelected }>
            { selectedFieldsCodeMap.has(`{{${ field.fieldCode }}}`)
              ? <CheckCircleOutlined style={ { fontSize: '12px', color: '#52c41a' } }/>
              : <></>
            }
          </div>
        </div>)
    })
  }

  const insertField = (field) => {
    editorInstance.execCommand('insertText', false, `{{${ field.fieldCode }}}`)
  }

  const onSearchChange = (e) => {
    const searchKey = e.target.value

    if (searchKey) {
      setFieldOptions(fields.filter(field => {
        return field.fieldName.indexOf(searchKey) > -1 || field.fieldCode.indexOf(searchKey) > -1
      }))
    } else {
      setFieldOptions(fields)
    }
  }

  const onInstanceReady = (e, editor) => {
    setEditorInstance(editor)
  }

  const editorContentChange = () => {
    const editorContent = editorInstance.getContent()
    const rgx = /\{{2}\w*\}{2}/g
    setSelectedFieldsCodeMap(new Set(editorContent.match(rgx)))
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ modalLoading }
        maskClosable={ false }
        onOk={ onOk }
        onCancel={ hideModal }
        width={ 1200 }
      >
        <Spin spinning={ modalLoading }>
          <div className={ Styles.editorContent }>
            <div className={ Styles.editorWrap }>
              <AppEditor
                disabled={ editorDisabled }
                onChange={ editorContentChange }
                onInit={ onInstanceReady }/>
            </div>
            <div className={ Styles.editorControl }>
              <div className={ Styles.controlTitle }>可选字段</div>
              <div className={ Styles.controlSearchBar }>
                <Input
                  size={ 'small' }
                  placeholder={ '输入文字搜索' }
                  style={ { width: '100%' } }
                  suffix={ <SearchOutlined/> }
                  onChange={ onSearchChange }
                ></Input>
              </div>
              <div className={ Styles.controlBody }>
                { generateFieldList(fieldOptions, selectedFieldsCodeMap) }
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditSampleTableModal
