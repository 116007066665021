import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Descriptions, Form, Input, InputNumber, Modal } from 'antd'

import { locationNumberValidate, startNumberValidate } from '../../../plugins/validates'

const EditSelectedExamLocationModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        canRoomNumber: props.locationInfo.canRoomNumber,
        startNumber: props.locationInfo.startNumber,
        locationNumber: props.locationInfo.locationNumber
      })
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      props.saveLocationItem?.({
        ...props.locationInfo,
        ...fields
      })
      hideModal()
    }).catch(err => {
      console.error(err)
    })
  }

  const canRoomNumberChange = (value) => {
    if (value > props.locationInfo.maxRoomNumber) {
      form.setFieldValue('canRoomNumber', props.locationInfo.maxRoomNumber)
    } else {
      form.setFieldValue('canRoomNumber', Number(value))
    }
  }

  const locationNumberChange = (e) => {
    form.setFieldValue('locationNumber', e.target.value)
  }

  const startNumberChange = (e) => {
    form.setFieldValue('startNumber', e.target.value)
  }


  return (
    <div ref={ ref }>
      <Modal
        title={ '修改考点设置' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <div className={ 'my-4 ml-5 pl-4' }>
          <Descriptions
            column={ 1 }
            labelStyle={ {
              width: 106,
              color: '#222',
              justifyContent: 'end'
            } }
            contentStyle={ {
              paddingLeft: 8
            } }
          >
            <Descriptions.Item label="考点名称">
              { props.locationInfo.locationName }
            </Descriptions.Item>
            <Descriptions.Item label="最大考场数量">
              { props.locationInfo.maxRoomNumber }
            </Descriptions.Item>
          </Descriptions>
          <Form
            form={ form }
            labelCol={ { span: 7 } }
            wrapperCol={ { span: 12 } }
          >
            <Form.Item
              label={ '考点编号' }
              name={ 'locationNumber' }
              rules={ [
                { required: true },
                { validator: locationNumberValidate }
              ] }
            >
              <Input
                onChange={ locationNumberChange }
              ></Input>
            </Form.Item>
            <Form.Item
              label={ '分配考场数量' }
              name={ 'canRoomNumber' }
              rules={ [{ required: true }] }
            >
              <InputNumber
                min={ 1 }
                max={ props.locationInfo.maxRoomNumber }
                onChange={ canRoomNumberChange }
              >
              </InputNumber>
            </Form.Item>
            <Form.Item
              label={ '考场起始编号' }
              name={ 'startNumber' }
              rules={ [
                { required: true },
                { validator: startNumberValidate }
              ] }
            >
              <Input
                onChange={ startNumberChange }
              ></Input>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
})

export default EditSelectedExamLocationModal
