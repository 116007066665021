import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Modal, Input, Spin, notification } from 'antd'
import UploadImageFormItem from '../../components/UploadImageFormItem'
import { updateImportExamineeApplyInfo } from '../../common/request'
import { useSelector } from 'react-redux'

const EditExamineeInfoModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [examineeIInfo, setExamineeInfo] = useState({})

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (examineeItem) => {
    setModalVisible(true)
    form.setFieldsValue(examineeItem)
    setExamineeInfo(examineeItem)
  }

  const handleOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      updateImportExamineeApplyInfo({
        examId: selectedExam.id,
        id: examineeIInfo.id,
        ...fields
      }).then(res => {
        setLoading(false)
        if (res.code === 200) {
          setModalVisible(false)
          notification.success({
            message: '操作成功'
          })

          props.updateExamineeSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        Modal.error({
          title: '错误',
          content: '网络错误，保存考生信息失败'
        })
      })
    })
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const generateFormItem = (columns) => {
    return columns.filter(column => {
      return column.fieldCode
    }).map(column => {
      return (<div key={column.fieldCode}>
        {
          column.fieldCode === 'personalPhoto'
            ? (
              <Form.Item
                key={ column.fieldCode }
                label={ column.fieldName }
                name={ column.fieldCode }
                rules={ [{ required: true }] }
              >
                <UploadImageFormItem
                  key={ column.fieldCode }
                ></UploadImageFormItem>
              </Form.Item>
            )
            : (
              <Form.Item
                key={ column.fieldCode }
                label={ column.fieldName }
                name={ column.fieldCode }
                rules={ [{ required: true }] }
              >
                <Input></Input>
              </Form.Item>
            )
        }
      </div>)
    })
  }

  return (
    <Modal
      title="修改考生信息"
      open={ modalVisible }
      onOk={ handleOk }
      confirmLoading={ loading }
      onCancel={ handleCancel }
    >
      <Spin spinning={ loading }>
        <Form
          form={ form }
          labelCol={ { span: 8 } }
          wrapperCol={ { span: 12 } }
        >
          { generateFormItem(props.columns) }
        </Form>
      </Spin>
    </Modal>
  )
})

export default EditExamineeInfoModal
