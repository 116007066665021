import { App, Button, Space, Switch, Table } from 'antd'
import { useEffect, useRef, useState } from 'react'
import ResponseCode from '../../../common/response-code'
import { deleteAnnouncementItem, getAnnouncementList, updateAnnouncementSort, updateAnnouncementStatus } from '../../../common/request'
import CreateAnnouncementModal from './CreateAnnouncementModal'
import UpdateAnnouncementSortModal from './UpdateAnnouncementSortModal'

function ConfigAnnouncement ({ orgItem, modalVisible }) {
  const updateAnnouncementSortModalRef = useRef()
  const { modal, notification } = App.useApp()
  const createAnnouncementModalRef = useRef()
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({ elements: [], totalElements: [] })
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      width: 60,
      title: '排序',
      key: 'sort',
      align: 'center',
      dataIndex: 'sort'
    },
    {
      title: '公告标题',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: '发布时间',
      key: 'createTime',
      dataIndex: 'createTime'
    },
    {
      title: '启用',
      key: 'status',
      dataIndex: 'status',
      render: (record, row) => {
        return <Switch
          checked={ !!record }
          checkedChildren="开启"
          unCheckedChildren="关闭"
          onChange={ announcementStatusToggle.bind(this, row) }
        />
      }
    },
    {
      width: 150,
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (<Space align={ 'end' }>
          <Button
            type={ 'primary' }
            ghost
            onClick={ showCreateAnnouncementModal.bind(this, 'edit', row) }
          >编辑</Button>
          <Button
            type={ 'primary' }
            ghost
            danger
            onClick={ deleteAnnouncementItemAction.bind(this, [row.id]) }
          >删除</Button>
        </Space>)
      }
    }
  ]

  const sortModal = [
    {
      width: 60,
      title: '排序',
      key: 'sort',
      dataIndex: 'sort'
    },
    {
      title: '公告标题',
      key: 'title',
      dataIndex: 'title'
    }
  ]

  useEffect(() => {
    if (orgItem.id && modalVisible) {
      getAnnouncementListAction()
    }
  }, [orgItem, modalVisible, pageNum, pageSize])

  const getAnnouncementListAction = () => {
    const params = {
      pageNum: pageNum,
      pageSize: pageSize,
      orgId: orgItem.id
    }
    setLoading(true)

    getAnnouncementList(params).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      modal.error({
        title: '错误',
        content: '网络错误，查询公告信息列表失败'
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const showCreateAnnouncementModal = (type, row) => {
    createAnnouncementModalRef.current.showModal(type, row)
  }

  const insertSuccess = () => {
    getAnnouncementListAction()
  }

  const deleteAnnouncementItemAction = (ids) => {
    modal.confirm({
      title: '确认删除',
      content: '确认删除该公告吗？',
      onOk: () => {
        setLoading(true)
        deleteAnnouncementItem({ ids: ids }).then(res => {
          if (res.code === ResponseCode.success) {
            notification.success({
              message: '成功',
              description: '公告删除成功'
            })

            getAnnouncementListAction()
          } else {
            modal.error({
              title: '错误',
              content: res.msg
            })
          }
        }).catch(err => {
          console.error(err)
          modal.error({
            title: '错误',
            content: '网络错误，删除公告失败'
          })
        }).finally(() => {
          setLoading(false)
        })
      }
    })
  }

  const showSortModal = () => {
    updateAnnouncementSortModalRef.current.showModal()
  }

  const updateAnnouncementSortAction = (list) => {
    updateAnnouncementSortModalRef.current.updateModalLoadingStatus(true)
    const params = {
      sortList: list.map((item, index) => {
        return {
          id: item.id,
          sort: index + 1
        }
      })
    }
    setLoading(true)
    updateAnnouncementSort(params).then(res => {
      if (res.code === ResponseCode.success) {
        getAnnouncementListAction()
        updateAnnouncementSortModalRef.current.hideModal()
        notification.success({
          message: '成功',
          description: '更新成功'
        })
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      modal.error({
        title: '错误',
        content: '网络错误，更新公告排序失败'
      })
    }).finally(() => {
      setLoading(false)
      updateAnnouncementSortModalRef.current.updateModalLoadingStatus(false)
    })
  }

  const announcementStatusToggle = (row) => {
    setLoading(true)
    updateAnnouncementStatus({
      id: row.id,
      status: row.status ? 0 : 1
    }).then(res => {
      if (res.code === ResponseCode.success) {
        getAnnouncementListAction()
        notification.success({
          message: '成功',
          description: '公告状态更新成功'
        })
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      modal.error({
        title: '错误',
        content: '网络错误，更新公告状态失败'
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (<div>
    <Space className={ 'd-flex justify-content-end' }>
      <Button
        type={ 'primary' }
        onClick={ showSortModal }
      >
        配置排序
      </Button>
      <Button
        type={ 'primary' }
        onClick={ showCreateAnnouncementModal.bind(this, 'create') }
      >新增公告</Button>
    </Space>
    <Table
      columns={ columns }
      loading={ loading }
      dataSource={ tableData.elements }
      rowKey={ (record) => record.id }
      pagination={ {
        showTotal: (total) => {
          return `共 ${ total } 条`
        },
        size: 'normal',
        showSizeChanger: true,
        total: tableData.totalElements,
        pageSize: pageSize,
        current: pageNum,
        onChange: paginationChange
      } }
    ></Table>

    <CreateAnnouncementModal
      ref={ createAnnouncementModalRef }
      orgItem={ orgItem }
      insertSuccess={ insertSuccess }
    ></CreateAnnouncementModal>

    <UpdateAnnouncementSortModal
      ref={ updateAnnouncementSortModalRef }
      columns={ sortModal }
      dataList={ tableData.elements }
      handleOnOk={ updateAnnouncementSortAction }
    ></UpdateAnnouncementSortModal>
  </div>)
}

export default ConfigAnnouncement
