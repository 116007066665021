import React, { useEffect, useRef, useState } from 'react'
import { Modal, notification, Table, Tag, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { deleteExamClass, deleteSubClass, getExamClass, syncPositionInfo } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import CreateExamClassModal from './CreateExamClassModal'
import EditExamSubClassModal from './EditExamSubClassModal'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import eventBus from '../../../../plugins/events'
import AppButton from '../../../../components/AppButton'

function SettingClass () {
  const createExamClassModalRef = useRef()
  const EditExamSubClassModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '考试大类',
      dataIndex: 'levelName',
      key: 'levelName'
    },
    {
      title: '考试大类代码',
      dataIndex: 'levelCode',
      key: 'levelCode'
    },
    {
      title: '考试小类数量',
      dataIndex: 'majorNum',
      key: 'majorNum',
      render: (record, row) => {
        return row.majorList ? row.majorList.length : 0
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (
          <Space className="d-flex align-items-center justify-content-end">
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }>
              <AppButton
                size={ 'small' }
                authCode={ 'button_ksxq-ksfl-xzksxl' }
                type="primary"
                ghost
                onClick={ showConfigExamSubClassModal.bind(this, 'create', { levelId: row.levelId }) }
              >新增考试小类</AppButton>
              <AppButton
                size={ 'small' }
                authCode={ 'button_ksxq-ksfl-bjdl' }
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditExamClassModal) }
              >编辑大类</AppButton>
              <AppButton
                size={ 'small' }
                authCode={ 'button_ksxq-ksfl-scdl' }
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteClassModal) }
              >删除大类</AppButton>
            </ComponentDisabled>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam])

  const openModal = (row, fn) => {
    fn && fn(row)
  }

  const showCreateExamClassModal = () => {
    createExamClassModalRef.current.showModal()
  }

  const showEditExamClassModal = (row) => {
    createExamClassModalRef.current.showModal('edit', row)
  }

  const showConfigExamSubClassModal = (type, row) => {
    EditExamSubClassModalRef.current.showModal(type, row)
  }

  const showDeleteClassModal = (row) => {
    Modal.confirm({
      title: '删除考试大类',
      style: { position: 'absolute', top: '280px', right: '290px' },
      content: (
        <div>确定要删除考试大类【{ row.levelName }】吗？</div>
      ),
      onOk () {
        deleteExamClassAction(row)
      }
    })
  }

  const deleteExamClassAction = (row) => {
    const params = {
      examId: selectedExam.id,
      id: row.levelId
    }

    deleteExamClass(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
        eventBus.emit('updateExamMonitor')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除考试大类失败',
        centered: true
      })
    })
  }

  const showDeleteExamSubClassModal = (row) => {
    Modal.confirm({
      title: '删除考试小类',
      style: { position: 'absolute', top: '280px', right: '290px' },
      content: (
        <div>确定要删除考试小类【{ row.majorName }】吗？</div>
      ),
      onOk () {
        deleteSubClassAction(row)
      }
    })
  }

  const deleteSubClassAction = (row) => {
    const params = {
      examId: selectedExam.id,
      id: row.majorId
    }

    deleteSubClass(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        getTableData()
        eventBus.emit('updateExamMonitor')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除考试小类失败',
        centered: true
      })
    })
  }

  const getTableData = () => {
    setLoading(true)
    getExamClass({ examId: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg || '',
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询考试分类失败',
        centered: true
      })
    })
  }

  const expandedRowRender = (data) => {
    const columns = [
      {
        title: '考试小类',
        dataIndex: 'majorName',
        key: 'majorName'
      },
      {
        title: '考试小类代码',
        dataIndex: 'majorCode',
        key: 'majorCode'
      },
      {
        title: '科目(科目代码)',
        dataIndex: 'majorAndCode',
        key: 'majorAndCode',
        render: (record, row) => {
          return row.subjectList.map((item, index) => {
            return (<Tag color="blue" key={ index }>{ `${ item.subjectName } (${ item.subjectCode })` }</Tag>)
          })
        }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (record, row) => {
          return (<>
            <Space className="d-flex justify-content-end">
              <ComponentDisabled
                disabledTip={ '报名已开始，禁止修改' }
                disabled={ selectedExam.examinationStatus > 0 }>
                <AppButton
                  size={ 'small' }
                  authCode={ 'button_ksxq-ksfl-bjxl' }
                  type="primary"
                  ghost
                  onClick={ showConfigExamSubClassModal.bind(this, 'edit', row) }
                >编辑小类</AppButton>
                <AppButton
                  size={ 'small' }
                  authCode={ 'button_ksxq-ksfl-scxl' }
                  danger
                  ghost
                  onClick={ openModal.bind(this, row, showDeleteExamSubClassModal) }
                >删除小类</AppButton>
              </ComponentDisabled>
            </Space>
          </>)
        }
      }
    ]

    return (
      <Table
        columns={ columns }
        dataSource={ data.majorList.map(item => {
          return {
            ...item,
            levelId: data.levelId
          }
        }) }
        rowKey={ (recordItem) => recordItem.majorId }
        pagination={ false }
      ></Table>
    )
  }

  const insertSuccess = (type) => {
    getTableData()
    eventBus.emit('updateExamMonitor')

    if (type === 'edit') {
      syncPositionInfoAction()
    }
  }

  const syncPositionInfoAction = () => {
    syncPositionInfo({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        eventBus.emit('reloadTableData', selectedExam.id)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(err => {
      Modal.error({
        title: '网络错误',
        content: '同步职位信息失败',
        centered: true
      })
    })
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-2 d-flex align-items-center justify-content-end">
        <ComponentDisabled
          disabledTip={ '报名已开始，禁止修改' }
          disabled={ selectedExam.examinationStatus > 0 }>
          <AppButton
            authCode={ 'button_ksxq-ksfl-bj' }
            type="primary"
            icon={ <PlusOutlined/> }
            onClick={ openModal.bind(this, {}, showCreateExamClassModal) }
          >新增考试大类</AppButton>
        </ComponentDisabled>
      </div>
      <Table
        loading={ loading }
        columns={ columns }
        dataSource={ tableData }
        rowKey={ (record) => record.levelId }
        expandable={ {
          expandedRowRender,
          expandRowByClick: true
        } }
        pagination={ false }
      ></Table>

      <CreateExamClassModal
        ref={ createExamClassModalRef }
        examInfo={ selectedExam }
        insertSuccess={ insertSuccess }
      ></CreateExamClassModal>

      <EditExamSubClassModal
        ref={ EditExamSubClassModalRef }
        examInfo={ selectedExam }
        insertSuccess={ insertSuccess }
      ></EditExamSubClassModal>
    </div>
  )
}

export default SettingClass
