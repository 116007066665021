import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from 'antd'
import ExamineeInterviewAdmission from '../../components/examinee/ExamineeInterviewAdmission'

const ExamineeInterviewAdmissionModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 1100 }
        title={ '面试准考证' }
        open={ modalVisible }
        onCancel={ hideModal }
        footer={ [] }
      >
        <ExamineeInterviewAdmission
          examineeItem={ props.examineeItem }
          modalVisible={ modalVisible }
        ></ExamineeInterviewAdmission>
      </Modal>
    </div>
  )
})

export default ExamineeInterviewAdmissionModal
