import React, { useEffect, useState } from 'react'
import { deleteErrorInfoItem, getErrorData, getErrorGroupByExam, getErrorTypeList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { App, Button, ConfigProvider, Form, Popconfirm, Select, Space, Table } from 'antd'
import { examCheckStatus, payStatusMap, takeExamCheckMap } from '../../common/common'
import { throttle } from '../../plugins/utils'

function MonitorCenter () {
  const { modal } = App.useApp()
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [errorTypeMap, setErrorTypeMap] = useState({})
  const [errorTypeOption, setErrorTypeOption] = useState([])
  const [pageStatus, setPageStatus] = useState('')
  const [tableData, setTableData] = useState({})
  const [examList, setExamList] = useState([])
  const [selectedExamErrorInfoIds, setSelectedExamErrorInfoIds] = useState([])

  const columns = [
    {
      title: 'UserID',
      key: 'userId',
      dataIndex: 'userId'
    },
    {
      title: '考生名称',
      key: 'examineesName',
      dataIndex: 'examineesName'
    },
    {
      title: '身份证号',
      key: 'idCard',
      dataIndex: 'idCard'
    },
    {
      title: '报名时间',
      key: 'applyDate',
      dataIndex: 'applyDate'
    },
    {
      title: 'ApplyID',
      key: 'applyInfoId',
      dataIndex: 'applyInfoId'
    },
    {
      title: '审核类型',
      key: 'checkStatus',
      dataIndex: 'checkStatus',
      render: (record) => {
        const { label, className } = examCheckStatus[record]
        return <div className={ className }>{ label }</div>
      }
    },
    {
      title: '错误类型',
      key: 'checkType',
      dataIndex: 'checkType',
      render: (record) => {
        return <div className={ 'text-warning' }>{ errorTypeMap[record]?.name }</div>
      }
    },
    {
      title: '支付状态',
      key: 'payStatus',
      dataIndex: 'payStatus',
      render: (record) => {
        const { label, className } = payStatusMap[record]
        return <div className={ className }>{ label }</div>
      }
    },
    {
      title: '笔试确认',
      key: 'takeExamCheck',
      dataIndex: 'takeExamCheck',
      render: (record) => {
        const { label, className } = takeExamCheckMap[record]
        return <div className={ className }>{ label }</div>
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (<Space align={ 'end' }>
          <Popconfirm
            title="删除错误信息"
            description="确定要删除错误信息吗？"
            onConfirm={ handleDelete.bind(this, row) }
            okText="确定"
            cancelText="取消"
          >
            <Button
              type={ 'primary' }
              danger
              ghost
            >删除</Button>
          </Popconfirm>
        </Space>)
      }
    }
  ]
  useEffect(() => {
    getErrorTypeMap()
    getMonitorData()
    getErrorGroupByExamAction()
  }, [])

  const getErrorTypeMap = () => {
    getErrorTypeList().then(res => {
      if (res.code === 200 && ResponseCode.success) {
        res.data.forEach(item => {
          setErrorTypeMap(pre => {
            pre[item.type] = item
            return pre
          })
          setErrorTypeOption([
            { label: '全部', value: '' },
            ...res.data.map(item => {
              return {
                label: item.name,
                value: item.type
              }
            })
          ])
        })
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.log(err)
      modal.error({
        title: '错误',
        content: '网络错误，获取Error Map失败'
      })
    })
  }

  const getErrorGroupByExamAction = () => {
    getErrorGroupByExam().then(res => {
      if (res.code === ResponseCode.success) {
        setExamList([
          { label: '全部', value: '' },
          ...res.data.map(item => {
            return {
              label: item.name,
              value: item.id
            }
          })
        ])
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      modal.error({
        title: '错误',
        content: '网络错误，获取考试数据失败'
      })
    })
  }

  const getMonitorData = (fields = {}) => {
    setPageStatus('loading')
    const params = {
      pageNum,
      pageSize,
      ...fields
    }
    getErrorData(params).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.log(err)
      modal.error({
        title: '错误',
        content: '网络错误，获取数据失败'
      })
    }).finally(err => {
      setPageStatus('')
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const handleDelete = (row) => {
    setPageStatus('loading')
    deleteErrorInfoItem({ ids: [row.applyInfoId] }).then(res => {
      if (res.code === ResponseCode.success) {
        getMonitorData()
      } else {
        modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.log(err)
      modal.error({
        title: '错误',
        content: '网络错误，获取数据失败'
      })
    }).finally(err => {
      setPageStatus('')
    })
  }

  const handleSearch = (fields, allFields) => {
    setPageNum(1)
    getMonitorData(allFields)
  }

  const deleteExamErrorInfoBatchAction = () => {
    if (selectedExamErrorInfoIds.length === 0) {
      modal.error({
        title: '错误',
        content: '请选择要删除的错误信息'
      })
      return
    }
    modal.confirm({
      title: '错误',
      content: '确定要删除选中的错误信息吗？',
      onOk: () => {
        deleteErrorInfoItem({ ids: selectedExamErrorInfoIds }).then(res => {
          if (res.code === ResponseCode.success) {
            getMonitorData()
          } else {
            modal.error({
              title: '错误',
              content: res.msg
            })
          }
        }).catch(err => {
          console.error(err)
          modal.error({
            title: '错误',
            content: '网络错误，删除错误信息失败'
          })
        })
      }
    })
  }

  return (<>
    <div className={'d-flex justify-content-between'}>
      <Form
        layout={'inline'}
        initialValues={{
          id: '',
          type: ''
        }}
        onValuesChange={ throttle(handleSearch, 500) }
      >
        <Form.Item label={'考试名称'} name={'id'}>
          <Select options={ examList } style={{ width: 360 }}></Select>
        </Form.Item>
        <Form.Item label={'错误类型'} name={'type'}>
          <Select options={ errorTypeOption } style={{ width: 230 }}></Select>
        </Form.Item>
      </Form>
      <Button type={'primary'} onClick={ deleteExamErrorInfoBatchAction }>批量删除</Button>
    </div>

    <ConfigProvider componentSize={ 'small' }>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            setSelectedExamErrorInfoIds(selectedRowKeys)
          }
        }}
        columns={ columns }
        loading={ pageStatus.status === 'loading' }
        dataSource={ tableData.elements }
        scroll={ {
          x: 1360
        } }
        rowKey={ (record) => record.userId }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
      ></Table>
    </ConfigProvider>
  </>)
}

export default MonitorCenter
