import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { DatePicker, Form, Input, Modal, notification, Spin, TimePicker } from 'antd'
import dayjs from 'dayjs'

import { createSubject, updateSubject } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const EditExamSubjectModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('添加科目')
      } else {
        form.setFieldsValue({
          subjectName: props.subjectItem.subjectName,
          subjectCode: props.subjectItem.subjectCode,
          examDate: props.subjectItem.examDate ? dayjs(props.subjectItem.examDate) : null,
          examTime: props.subjectItem.startTime ? [dayjs(replenishTime(props.subjectItem.startTime)), dayjs(replenishTime(props.subjectItem.endTime))] : []
        })
        setModalTitle('编辑科目')
      }
    }
  }, [modalVisible])

  const replenishTime = (time) => {
    return props.subjectItem.examDate + ' ' + time
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const createSubjectAction = (subjectItem) => {
    const params = {
      ...subjectItem,
      examId: props.examInfo.id
    }

    setLoading(true)
    createSubject(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '添加科目失败',
        centered: true
      })
    })
  }

  const updateSubjectAction = (subjectItem) => {
    const params = {
      examId: props.examInfo.id,
      subjectConfigList: [
        {
          subjectId: props.subjectItem.id,
          ...subjectItem
        }
      ]
    }

    delete params.examTime

    setLoading(true)
    updateSubject(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '添加科目失败',
        centered: true
      })
    })
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      const subjectItem = {
        ...fields,
        examDate: fields.examDate ? dayjs(fields.examDate).format('YYYY-MM-DD').toString() : '',
        startTime: fields.examTime?.length > 0 ? dayjs(fields.examTime[0]).format('HH:mm').toString() : '',
        endTime: fields.examTime?.length > 0 ? dayjs(fields.examTime[1]).format('HH:mm').toString() : ''
      }

      delete subjectItem.examTime

      if (props.modalType === 'create') {
        createSubjectAction(subjectItem)
      } else {
        updateSubjectAction(subjectItem)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const subjectCodeChange = (e) => {
    form.setFieldValue('subjectCode', e.target.value.replace(/\D/g, ''))
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item
              label="科目代码"
              name="subjectCode"
              rules={ [{ required: true, message: '科目代码不能为空' }] }
            >
              <Input
                placeholder="请输入科目代码"
                style={ { width: '100%' } }
                disabled={ props.examInfo.examinationStatus > 0 }
                onChange={ subjectCodeChange }
              ></Input>
            </Form.Item>
            <Form.Item
              label="科目名称"
              name="subjectName"
              rules={ [{ required: true, message: '科目名称不能为空' }] }
            >
              <Input placeholder="请输入科目名称" disabled={ props.examInfo.examinationStatus > 0 }></Input>
            </Form.Item>
            <Form.Item
              label="考试日期"
              name="examDate"
            >
              <DatePicker/>
            </Form.Item>
            <Form.Item
              label="考试时段"
              name="examTime"
            >
              <TimePicker.RangePicker format="HH:mm"/>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamSubjectModal
