import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function ComponentVisibleByAuth (props) {
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const [authList, setAuthList] = useState([])

  useEffect(() => {
    getUserAuthList()
  }, [])

  const getUserAuthList = () => {
    setAuthList(userInfo.currentAuthoritySet.filter(item => {
      return item.type === 'button'
    }))
  }

  const componentVisibleByAuth = (authCode) => {
    if (userInfo.currentRoleTag === 'superAdmin') {
      return props.children
    } else {
      if (authList.find(item => {
        return item.code === authCode
      })) {
        return props.children
      }
    }
  }

  return (<>
    { componentVisibleByAuth(props.authCode) }
  </>)
}

export default ComponentVisibleByAuth
