import React, { useEffect, useRef, useState } from 'react'
import { Table, Form, Button, Input, Select, Modal, notification, ConfigProvider, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getDictList, deleteDict } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import EditChildDictionaryModal from './EditChildDictionaryModal'
import EditDictModal from './EditDictModal'
import DataDictExpandedTable from './DataDictExpandedTable'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'

function DataDictionaryManagement () {
  const [notifyApi, contextHolder] = notification.useNotification()
  const editChildDictModalRef = useRef()
  const editDictModalRef = useRef()
  const dictRef = useRef()
  const [searchForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [editDictModalType, setEditDictModalType] = useState('create')
  const [currentEditDict, setCurrentEditDict] = useState({})
  const [needReloadRow, setNeedReloadRow] = useState({})

  const options = [
    {
      value: '',
      label: '全部'
    },
    {
      value: 1,
      label: '启用'
    },
    {
      value: 0,
      label: '停用'
    }
  ]

  const columns = [
    {
      title: '字典名称',
      dataIndex: 'dictName',
      key: 'dictName'
    },
    {
      title: '字典编码',
      dataIndex: 'dictCode',
      key: 'dictCode'
    },
    {
      title: '字典状态',
      dataIndex: 'state',
      key: 'state',
      render: (state) => {
        return (<div>
          { state === 1 ? '启用' : '禁用' }
        </div>)
      }
    },
    {
      title: '操作',
      dataIndex: '',
      width: '220px',
      key: '',
      render: (data, row) => {
        return (
          <ComponentVisibleByAuth authCode={ 'button_zdgl-sjzd-bj' }>
            <Space className="d-flex justify-content-end">
              <Button
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showAddDictChildModal) }
              >添加子类</Button>
              <Button
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditDictModal) }
              >编辑</Button>
              <Button
                type="primary"
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteDictModal) }
              >删除</Button>
            </Space>
          </ComponentVisibleByAuth>
        )
      }
    }
  ]

  useEffect(() => {
    getTableData()
  }, [pageNum, pageSize])


  const openModal = (row, fn) => {
    setCurrentEditDict(row)
    fn(row)
  }

  const showCreateDictModal = () => {
    setEditDictModalType('create')
    editDictModalRef.current.showModal()
  }

  const showAddDictChildModal = () => {
    editChildDictModalRef.current.showModal()
  }

  const showEditDictModal = () => {
    setEditDictModalType('edit')
    editDictModalRef.current.showModal()
  }

  const showDeleteDictModal = (dictItem) => {
    Modal.confirm({
      title: '删除字典',
      content: (
        <div>确定要删除字典【{ dictItem.dictName }】吗？</div>
      ),
      onOk () {
        deleteDictAction(dictItem)
      }
    })
  }

  const deleteDictAction = (dictItem) => {
    const params = {
      id: dictItem.id
    }

    deleteDict(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `删除字典成功`
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除字典失败',
        centered: true
      })
    })
  }

  const handleSearch = () => {
    getTableData(searchForm.getFieldsValue())
  }

  const getTableData = (searchItem = {}) => {
    setLoading(true)
    const params = {
      orderBy: 'id',
      pageNum: pageNum,
      pageSize: pageSize,
      ...searchItem
    }
    getDictList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询字典数据失败',
        centered: true
      })
    })
  }

  const updateExpandedTable = () => {
    setNeedReloadRow(currentEditDict)
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <div>
          <Form
            layout="inline"
            form={ searchForm }
            initialValues={
              {
                dictName: '',
                state: ''
              }
            }
            autoComplete="off">
            <Form.Item
              label="字典名称"
              name="dictName">
              <Input placeholder="输入字典名称搜索" style={ { width: '180px' } }/>
            </Form.Item>
            <Form.Item
              label="字典状态"
              name="state">
              <Select placeholder="输入字段状态搜索" options={ options } style={ { width: '180px' } }/>
            </Form.Item>
            <Form.Item className="d-flex justify-content-center">
              <Button type="primary" onClick={ handleSearch }>
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
        <ComponentVisibleByAuth authCode={ 'button_zdgl-sjzd-bj' }>
          <Button
            type="primary"
            icon={ <PlusOutlined/> }
            onClick={ openModal.bind(this, {}, showCreateDictModal) }
          >新增字典</Button>
        </ComponentVisibleByAuth>

      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          ref={ dictRef }
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          expandable={ {
            expandedRowRender: (record, index, indent, expanded) => {
              return <DataDictExpandedTable
                dictCode={ record.dictCode }
                rowKey={ record.id }
                needReloadRow={ needReloadRow }
                expanded={ expanded }
              ></DataDictExpandedTable>
            }
          } }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }>
        </Table>
      </ConfigProvider>

      <EditChildDictionaryModal
        ref={ editChildDictModalRef }
        modalType="create"
        dictItem={ currentEditDict }
        insertSuccess={ updateExpandedTable }>
      </EditChildDictionaryModal>
      <EditDictModal
        ref={ editDictModalRef }
        modalType={ editDictModalType }
        dictItem={ currentEditDict }
        insertSuccess={ getTableData }>
      </EditDictModal>
    </div>
  )
}

export default DataDictionaryManagement
