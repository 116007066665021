import React, { useEffect, useState } from 'react'
import { Button, Empty, Modal, Spin } from 'antd'

import Styles from './ExamineeInfoModal.module.scss'
import { convertPdfAndDownload, examinationProgress, findExamCertificateInfo, getExamTemplateFieldList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadPdf } from '../../plugins/utils'
import { useSelector } from 'react-redux'
import { generateAdmissionTicket } from './plugins/GenerateAdmissionTicket'

function ExamineeAdmissionTicket (props) {
  const envInfo = useSelector(state => state.env.envInfo)
  const [admissionTicketContent, setAdmissionTicketContent] = useState('')
  const [examMonitorStatus, setExamMonitorStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  const [initDataLoading, setInitDataLoading] = useState(true)
  const [printDisabled, setPrintDisabled] = useState(false)

  useEffect(() => {
    setInitDataLoading(true)
    if (!props.modalVisible) {
      setAdmissionTicketContent('')
    }
    if (props.modalVisible && props.examineeItem?.examId) {
      getInitData()
    }
  }, [props.examineeItem, props.modalVisible])

  useEffect(() => {
    if (examMonitorStatus) {
      getExamAdmissionTicketContent()
    }
  }, [examMonitorStatus])

  const getInitData = () => {
    examinationProgress({ id: props.examineeItem?.examId }).then(res => {
      if (res.code === ResponseCode.success) {
        setExamMonitorStatus(res.data)
        const { room, template } = res.data
        const printDisabled = !room || !template
        setPrintDisabled(printDisabled)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试进度失败'
      })
    })
  }

  const getExamAdmissionTicketContent = () => {
    const params = {
      examId: props.examineeItem.examId,
      idCard: props.examineeItem.idCard
    }

    Promise.all([
      getExamTemplateFieldList({ examId: props.examineeItem.examId }),
      findExamCertificateInfo(params)
    ]).then(([templateRes, infoRes]) => {
      setInitDataLoading(false)
      if (templateRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: templateRes.msg
        })
        return
      }

      if (infoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: infoRes.msg
        })
        return
      }

      setAdmissionTicketContent(generateAdmissionTicket(templateRes.data.examCertificate, templateRes.data.examCertificateFields, infoRes.data, envInfo.picDomain))
    }).catch(err => {
      console.error(err)
      setInitDataLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，初始化数据失败'
      })
    })
  }

  const generateExamineeAdmissionTicket = (status, loading) => {
    if (loading) {
      return (<Spin spinning={ initDataLoading }>
        <div style={ { height: 300 } }></div>
      </Spin>)
    }

    if (!props.ignoreCheck && status.check && props.examineeItem.checkStatus !== 2) {
      return (<div style={ { height: 300 } }>
        <Empty description={ '考生未通过审核' }></Empty>
      </div>)
    }

    if (!status.room) {
      return (<div style={ { height: 300 } }>
        <Empty description={ '未分配考场' }></Empty>
      </div>)
    }

    if (!status.template) {
      return (<div style={ { height: 300 } }>
        <Empty description={ '未配置笔试准考证' }></Empty>
      </div>)
    }

    return <div className={ Styles.sampleTable } dangerouslySetInnerHTML={ { __html: admissionTicketContent } }></div>
  }

  const downloadFile = () => {
    setLoading(true)
    const params = {
      content: admissionTicketContent.replaceAll('strong', 'span'),
      fileType: 'CERTIFICATE'
    }

    convertPdfAndDownload(params).then(res => {
      setLoading(false)
      downloadPdf(res, `${ props.examineeItem.examineesName || props.examineeItem.name }-笔试准考证`)
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载失败'
      })
    })
  }

  return (
    <>
      <Spin spinning={ loading }>
        <div className={ 'my-3 d-flex justify-content-end' }>
          <Button
            type={ 'primary' }
            loading={ loading }
            disabled={ printDisabled }
            onClick={ downloadFile }
          >下载</Button>
        </div>
        { generateExamineeAdmissionTicket(examMonitorStatus, initDataLoading) }
      </Spin>
    </>
  )
}

export default ExamineeAdmissionTicket
