import React from 'react'
import CommonComponent from './CommonComponent'

function LogRecordFinance () {
  return (
    <div>
      <CommonComponent system={'finance'}></CommonComponent>
    </div>
  )
}

export default LogRecordFinance
