import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { getApplyUnitList } from '../common/request'
import ResponseCode from '../common/response-code'
import { Modal } from 'antd'

const ExamApplyUnitList = forwardRef((props, ref) => {
  const [unitList, setUnitList] = useState([])

  useImperativeHandle(ref, () => {
    return {
      getUnitList
    }
  })

  useEffect(() => {
    if (props.examId) {
      getApplyUnitListAction()
    }
  }, [props.examId])

  useEffect(() => {
    props.loadSuccess?.(unitList)
  }, [unitList])

  const getApplyUnitListAction = () => {
    getApplyUnitList({ id: props.examId }).then(res => {
      if (res.code === ResponseCode.success) {
        setUnitList(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询报考单位列表失败'
      })
    })
  }

  const getUnitList = () => {
    return unitList
  }

  return (
    <div ref={ref}></div>
  )
})

export default ExamApplyUnitList
