import { Button, Modal, notification, Space, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import ResponseCode from '../../../common/response-code'
import { deleteDictChild, getDictChildList } from '../../../common/request'
import EditChildDictionaryModal from './EditChildDictionaryModal'
import ComponentVisibleByAuth from '../../../components/ComponentVisibleByAuth'

const DataDictExpandedTable = (props) => {
  const editChildDictModalRef = useRef()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentEditDict, setCurrentEditDict] = useState({})
  const [notifyApi, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (props.expanded) {
      getTableData()
    }
  }, [props.expanded])

  useEffect(() => {
    if (props.rowKey === props.needReloadRow.id) {
      getTableData()
    }
  }, [props.needReloadRow])

  const columns = [
    {
      title: '字典标签',
      dataIndex: 'dictLabel',
      key: 'dictLabel'
    },
    {
      title: '字典值',
      dataIndex: 'dictValue',
      key: 'dictValue'
    },
    {
      title: '排序',
      dataIndex: 'dictSort',
      key: 'dictSort'
    },
    {
      title: '字典状态',
      dataIndex: 'state',
      key: 'state',
      render: (state) => {
        return (<div>
          { state === 1 ? '启用' : '禁用' }
        </div>)
      }
    },
    {
      title: '操作',
      dataIndex: '',
      width: '220px',
      key: '',
      render: (data, row) => {
        return (
        <ComponentVisibleByAuth authCode={'button_zdgl-sjzd-bj'}>
          <Space className="d-flex justify-content-end">
            <Button
              className="mr-1"
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditDictModal) }
            >编辑</Button>
            <Button
              type="primary"
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteDictModal) }
            >删除</Button>
          </Space>
        </ComponentVisibleByAuth>
        )
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    const params = {
      dictCode: props.dictCode,
      orderBy: 'dictSort',
      orderType: 'ASC',
      pageNum: 1,
      pageSize: 999
    }

    getDictChildList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询字典小类失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditDict(row)
    fn(row)
  }

  const showEditDictModal = () => {
    editChildDictModalRef.current.showModal()
  }

  const showDeleteDictModal = (dictItem) => {
    Modal.confirm({
      title: '删除字典',
      content: (
        <div>确定要删除字典小类【{ dictItem.dictLabel }】吗？</div>
      ),
      onOk () {
        deleteDictAction(dictItem)
      }
    })
  }

  const deleteDictAction = (dictItem) => {
    setLoading(true)
    const params = {
      id: dictItem.id
    }

    deleteDictChild(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `删除字典小类成功`
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除字典小类失败',
        centered: true
      })
    })
  }

  return (<>
    <Table
      loading={ loading }
      columns={ columns }
      dataSource={ tableData.elements }
      rowKey={ (record) => record.id }
      pagination={ false }
    ></Table>
    <EditChildDictionaryModal
      ref={ editChildDictModalRef }
      modalType="edit"
      dictItem={ currentEditDict }
      insertSuccess={ getTableData }>
    </EditChildDictionaryModal>
    { contextHolder }
  </>)
}

export default DataDictExpandedTable
