import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Checkbox, Form, Input, Modal, Radio, Select } from 'antd'
import { getOrgListByAccount } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import Styles from './index.module.scss'

const BasicForm = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [orgOptions, setOrgOptions] = useState([])

  useImperativeHandle(ref, () => {
    return {
      getFormValue
    }
  })

  useEffect(() => {
    if (props.modalVisible) {
      getOrgList()
    }
  }, [props.modalVisible])

  const getFormValue = () => {
    return new Promise((resolve) => {
      form.validateFields().then(fields => {
        resolve({
          success: true,
          data: fields
        })
      }).catch(err => {
        resolve({
          success: false,
          data: err
        })
      })
    })
  }

  const getOrgList = () => {
    getOrgListByAccount().then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setOrgOptions(res.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        }))

        if (res.data.length > 0) {
          form.setFieldValue('orgId', res.data[0].id)
          props.orgChange(res.data[0].id)
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询机构列表失败'
      })
    })
  }

  const examProcessChange = (examProcess) => {
    props.applyProcessChange && props.applyProcessChange(examProcess)
  }

  const orgChange = (orgId) => {
    props.orgChange(orgId)
  }

  return (
    <div>
      <div className={ Styles.formTitle }>基础设置</div>
      <div className="pt-2">
        <Form
          form={ form }
          initialValues={ {
            examinationType: 1
          } }
        >
          <Form.Item
            label="组织机构"
            name="orgId"
            rules={ [{ required: true, message: '请选择组织机构' }] }
          >
            <Select options={ orgOptions } placeholder="选择组织机构" onChange={ orgChange }></Select>
          </Form.Item>
          <Form.Item
            label="考试名称"
            name="name"
            rules={ [{ required: true, message: '请输入考试名称' }] }
          >
            <Input placeholder="请输入考试名称"></Input>
          </Form.Item>
          <Form.Item
            label="考试类型"
            name="examinationType"
            rules={ [{ required: true, message: '请选择考试类型' }] }
          >
            <Radio.Group>
              <Radio value={ 1 }>公务员/事业单位招考</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="考试流程"
            name="process"
            rules={ [{ required: true, message: '请选择考试流程' }] }
          >
            <Checkbox.Group onChange={ examProcessChange }>
              <Checkbox value="apply">报名</Checkbox>
              <Checkbox value="examinationRoom">考场安排</Checkbox>
              <Checkbox value="publicResult">成绩公布</Checkbox>
              <Checkbox value="interviewNotice">面试</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
})

export default BasicForm
