import { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Descriptions, Modal } from 'antd'
import GetExamDictData from '../GetExamDictData'
import { useSelector } from 'react-redux'

const ExamineeAccountDetailModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const dictList = ['sys_xl', 'sys_apply_kslx', 'sys_apply_aqwty', 'sys_apply_aqwte']
  const dictInfo = useSelector(state => state.dict.dictInfo)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const examineeInfoMap = {
    name: {
      label: '姓名'
    },
    id: {
      label: '账户ID'
    },
    sex: {
      label: '性别'
    },
    birth: {
      label: '出生日期'
    },
    idCard: {
      label: '身份证'
    },
    education: {
      label: '最高学历',
      render: (record) => {
        return getDictLabel(record, 'sys_xl')
      }
    },
    phone: {
      label: '手机号'
    },
    candidateType: {
      label: '考生类型',
      render: (record) => {
        return getDictLabel(record, 'sys_apply_kslx')
      }
    },
    email: {
      label: '邮箱'
    },
    wechatBind: {
      label: '绑定微信',
      render: (record) => {
        return record ? '已绑定' : '未绑定'
      }
    },
    registerTime: {
      label: '注册时间'
    },
    lastLoginTime: {
      label: '最后登录时间'
    },
    securityIssueOne: {
      label: '安全问题一',
      render: (record) => {
        return getDictLabel(record, 'sys_apply_aqwty')
      }
    },
    securityIssueTwo: {
      label: '安全问题二',
      render: (record) => {
        return getDictLabel(record, 'sys_apply_aqwte')
      }
    },
    securityAnswersOne: {
      label: '安全问题答案一'
    },
    securityAnswersTwo: {
      label: '安全问题答案二'
    }
  }

  const getDictLabel = (value, dictType) => {
    return dictInfo[dictType]?.find(dictItem => dictItem.dictValue === value)?.dictLabel
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const generateExamineeDetailDescription = (info) => {
    return Object.keys(examineeInfoMap).map((key) => {
      return <Descriptions.Item
        label={ examineeInfoMap[key].label }
        key={ key }>
        {
          examineeInfoMap[key].render
            ? examineeInfoMap[key].render(info[key])
            : info[key]
        }
      </Descriptions.Item>
    })
  }

  const getDictInfo = () => {
    return dictList.map(dictCode => {
      return <GetExamDictData dictCode={ dictCode } key={ dictCode }></GetExamDictData>
    })
  }

  return (<Modal
    width={ 800 }
    title={ '考生账号详情' }
    open={ modalVisible }
    onCancel={ hideModal }
    footer={ [
      <Button
        key={ 'close' }
        type={ 'primary' }
        onClick={ hideModal }
      >关闭</Button>
    ] }
  >
    { getDictInfo() }
    <Descriptions column={ 2 }>
      { generateExamineeDetailDescription(props.examineeInfo) }
    </Descriptions>

  </Modal>)
})

export default ExamineeAccountDetailModal
