const generateImage = (path, height) => {
  return `<div class="img-wrap" style="width: 100%;${ height }">
      <div class="img" style="background: url('${ path }') no-repeat"></div>
    </div>`
}

const getDictLabel = (fieldInfo, field, applyInfo) => {
  return applyInfo.applyInfo[field.fieldCode]
    ? fieldInfo.find(item => {
      return item.dictValue === applyInfo.applyInfo[field.fieldCode]
    })?.dictLabel
    : ''
}

const generateTable = (field, applyInfo) => {
  const thContent = field.selectContent.split('|').map(item => {
    return item.split('-')
  })

  return `<table 
        border="0"
        class="inner-table"
        cellpadding="0"
        cellspacing="0"
        style="width: 100%;">
      <tbody>
        <tr>${
    thContent 
      ? (
        thContent.map(([key]) => {
          return `<td style="color: #222;text-align: center;">${ key }</td>`
        }).join('')
      )
      : ''
  }</tr>
    ${
    applyInfo.applyInfo[field.fieldCode]?.length > 0
      ? (
        applyInfo.applyInfo[field.fieldCode].map(row => {
          return `<tr>${
            thContent?.map(([key, type]) => {
              return `<td style="width: ${ type !== 'a' ? '100px' : 'auto' }; color: #222; text-align: center;">
                <div style="">${ row[key] }</div>
              </td>`
            }).join('')
          }</tr>`
        }).join('')
      )
      : (
        `<tr>
        ${
          thContent?.map(() => {
            return '<td style="height: 20px;color: #222;text-align: center;"></td>'
          }).join('')
          
        }
      </tr>`
      )
  }</tbody>
    </table>`
}

const getTreeDataLabel = (field, applyInfo) => {
  return applyInfo.applyInfo[field.fieldCode] ?
    applyInfo.applyInfo[field.fieldCode].map(item => {
      return item.split(',')[1]
    }).join('-')
    : ''
}

const getRegionDataLabel = (field, applyInfo) => {
  const regionData = applyInfo.applyInfo[field.fieldCode]
  let label = ''
  if (regionData?.province) {
    label += regionData?.province.name
  }
  if (regionData?.city) {
    label += regionData?.city.name
  }
  if (regionData?.district) {
    label += regionData?.district.name
  }
  return label
}

const getRegExp = (value) => {
  return `{{${ value }}}`
}

const baseStyle = `<style>
  p {
    height: auto;
    margin: 0;
  }
  table {
    margin: 0 auto;
  }
  td, tr {
    word-break: break-all!important;
    padding: 0!important;
  }
  .inner-table {
    width: 100%;
    font-family: inherit;
    font-size: inherit;
  }
  .inner-table thead {
    width: 100%
  }
  .inner-table thead tr > th {
    border-bottom: solid windowtext 1.0pt;
    font-size: inherit;
  }
  .inner-table td {
    font-size: inherit;
    padding: 4px 6px!important;
  }
  .inner-table tr:not(:last-child) > td {
    border-bottom: solid windowtext 1.0pt;
  }
  .inner-table tr > th:not(:last-child),
  .inner-table tr > td:not(:last-child) {
    border-right: solid windowtext 1.0pt;
  }
  .img-wrap {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .img-wrap .img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: contain !important;
    background-position: center !important;
  }
  .table-wrap {
    display: flex;
  }
</style>`

export const generateApplyFormTable = (template, fieldList, applyInfo, dictInfo, fileDomain) => {
  let html = `${ baseStyle }${ template || '' }`

  fieldList?.forEach(field => {
    if (field.fieldFromType === '1') {
      html = html.replaceAll(getRegExp(field.fieldCode), applyInfo[field.fieldCode] || applyInfo.applyInfo[field.fieldCode] || '')
    }

    if (field.fieldFromType === '2' && field.fieldFilling === '2') {
      html = html.replaceAll(getRegExp(field.fieldCode), applyInfo[field.fieldAuto] || applyInfo.applyInfo[field.fieldCode] || '')
    }

    if (field.fieldFromType === '2' && field.fieldFilling === '1') {
      switch (field.fieldType) {
        case '1':
          html = html.replaceAll(getRegExp(field.fieldCode), applyInfo.examineesNumber) || ''
          break
        case '4':
          html = html.replaceAll(getRegExp(field.fieldCode), getDictLabel(dictInfo[field.fieldJoinSelect], field, applyInfo))
          break
        case '6':
          html = html.replaceAll(getRegExp(field.fieldCode), '{{img_@_placeholder}}')
          html.match(/<td(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?<p(.|\n|\r)[^>]*?><span(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?{{img_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?<\/span><\/p>/g)?.forEach(item => {
            const rowspanNumber = item.match(/rowspan="(\d*)"/)?.[1] || 1
            const matchResult = item.match(/height:(.[^(<|>)]*)(\d*)(pt|cm|px);/)
            const heightNumber = matchResult?.[1].trim()
            const heightStr = matchResult[0]?.replace(heightNumber, Number(heightNumber) * Number(rowspanNumber))
            html = html.replaceAll(/(?<=(>))((.|\n|\r)[^(<|>)]*?)?{{img_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?(?=(<))/g, generateImage(`${ fileDomain }/${ applyInfo.applyInfo[field.fieldCode] }`, heightStr, rowspanNumber))
          })
          break
        case '7':
          html = html.replaceAll(getRegExp(field.fieldCode), '{{table_@_placeholder}}')
          html.match(/(<span(.|\n|\r)[^>]*?>)?((.|\n|\r)[^(<|>)]*?)?{{table_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?(<\/span>)?/g)?.forEach(item => {
            let divStr = item.replace('<span', '<div class="table-wrap"')
            divStr = divStr.replace('</span', '</div')
            divStr = divStr.replace(/(?<=(>))((.|\n|\r)[^(<|>)]*?)?{{table_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?(?=(<))/g, generateTable(field, applyInfo))
            html = html.replaceAll(item, divStr)
          })
          break
        case '9':
          html = html.replaceAll(getRegExp(field.fieldCode), getTreeDataLabel(field, applyInfo))
          break
        case '12':
          html = html.replaceAll(getRegExp(field.fieldCode), getRegionDataLabel(field, applyInfo))
          break
        default:
          html = html.replaceAll(getRegExp(field.fieldCode), applyInfo.applyInfo[field.fieldCode] || '')
      }
    }
  })

  html = html.replaceAll(/padding: 0cm 5.4pt 0cm 5.4pt;/g, '')
  html = html.replaceAll(/<strong/g, '<b')
  html = html.replaceAll(/<\/strong/g, '</b')

  return html
}
