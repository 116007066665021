import React from 'react'
import { Space, Spin } from 'antd'

import Styles from './index.module.scss'

function Index ({ spinning, height, text, fullscreen, isFixed = true, children }) {
  const getClassName = () => {
    if (isFixed) {
      return Styles.app_loading
    } else {
      return [Styles.app_loading, Styles.no_fixed].join(' ')
    }
  }

  return (<>
      {
        fullscreen
          ? (<>
            <Spin
              spinning={spinning}
              tip={ text }
              fullscreen
            ></Spin>
            { children || '' }
            </>)
          : <div
            className={ getClassName() }
            style={ {
              width: '100%',
              height: height,
              overflow: spinning ? 'auto' : 'hidden'
            } }
          >
            <Space
              size={ 'small' }
              direction={ 'vertical' }
              align={ 'center' }
            >
              <Spin spinning={spinning} height={ height }></Spin>
              <div className={ Styles.tip }>{ text }</div>
            </Space>

          </div>
      }
    </>
  )
}

export default Index
