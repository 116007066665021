import { createSlice } from '@reduxjs/toolkit'

export const authListSlice = createSlice({
  name: 'auth-list',
  initialState: {
    authList: []
  },
  reducers: {
    updateAuthList: (state, { payload }) => {
      state.authList = payload.authList
    }
  }
})

export const { updateAuthList } = authListSlice.actions

export default authListSlice.reducer
