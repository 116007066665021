import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import { Form, Input, Modal, Radio, notification } from 'antd'

import ResponseCode from '../../common/response-code'
import { createAuth, editAuth } from '../../common/request'
import { authTypeMap } from '../../common/common'
import { replaceChineseInitials } from '../../plugins/utils'


const EditAuthModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('create')
  const [insertAuthLoading, setInsertAuthLoading] = useState(false)

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('创建权限')
      } else {
        setModalTitle('编辑权限')
        form.setFieldsValue({
          ...props.authItem
        })
      }

    }
  }, [modalVisible, props.modalType])

  useImperativeHandle(ref, () => {
    return {
      showModal: showEditAuthModal
    }
  })

  const onOk = () => {
    form.validateFields().then(fields => {
      if (props.modalType === 'create') {
        createAuthAction(fields)
      } else {
        editAuthAction(fields)
      }
    }).catch(err => {
      console.error(err)
    })

  }

  const showEditAuthModal = () => {
    setModalVisible(true)
  }

  const hideEditAuthModal = () => {
    setModalVisible(false)
    // form.resetFields()
  }

  const createAuthAction = (fields) => {
    setInsertAuthLoading(true)
    const params = {
      ...fields
    }

    createAuth(params).then(res => {
      setInsertAuthLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditAuthModal()
        notifyApi.success({
          message: '操作成功',
          description: `创建权限成功`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setInsertAuthLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建权限失败',
        centered: true
      })
    })
  }

  const editAuthAction = (fields) => {
    setInsertAuthLoading(true)
    const params = {
      ...fields,
      id: props.authItem.id
    }

    editAuth(params).then(res => {
      setInsertAuthLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditAuthModal()
        notifyApi.success({
          message: '操作成功',
          description: `更新权限成功`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setInsertAuthLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新权限失败',
        centered: true
      })
    })
  }

  const getAuthTypeList = () => {
    return authTypeMap.map((item, index) => {
      return <Radio value={item.value} key={`radio_${index}`}>{ item.label }</Radio>
    })
  }

  const authNameChange = (e) => {
    const authType = form.getFieldValue('type')
    const authName = form.getFieldValue('name')

    if (authName.trim()) {
      form.setFieldValue('code', `${authType}_${replaceChineseInitials(authName)}`)
    }
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ insertAuthLoading }
        onOk={ onOk }
        onCancel={ hideEditAuthModal }
      >
        <Form
          rootClassName="mt-3"
          form={ form }
          labelCol={ {
            span: 6
          } }
          wrapperCol={ {
            span: 16
          } }
          initialValues={ {
            name: '',
            type: 'button',
            code: '',
            path: ''
          } }
        >
          <Form.Item label="权限名称" name="name" rules={ [{ required: true, message: '权限名称不能为空' }] }>
            <Input onChange={authNameChange}></Input>
          </Form.Item>
          <Form.Item label="权限类型" name="type" required>
            <Radio.Group  onChange={authNameChange}>
              { getAuthTypeList() }
            </Radio.Group>
          </Form.Item>
          <Form.Item label="权限编码" name="code" rules={ [{ required: true, message: '权限编码不能为空' }] }>
            <Input></Input>
          </Form.Item>
          <Form.Item label="权限路径" name="path" rules={ [{ required: true, message: '权限路径不能为空' }] }>
            <Input></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
})

export default EditAuthModal
