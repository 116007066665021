import React, { useEffect, useState } from 'react'
import { Button, Modal, notification, Spin } from 'antd'

import Styles from './index.module.scss'
import { examMonitor, examPublish, findParamByExam, getExamProcessTime } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { createExamMonitorMap } from '../../../../common/common'
import { useSelector } from 'react-redux'
import eventBus from '../../../../plugins/events'
import AppButton from '../../../../components/AppButton'

function ExamDashboard (props) {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [examStatus, setExamStatus] = useState({})

  useEffect(() => {
    if (selectedExam.id) {
      getExamMonitorData()
      getExamProcessTimeAction()
    }
  }, [selectedExam])

  useEffect(() => {
    eventBus.addListener('updateExamMonitor', eventListener)

    return () => {
      eventBus.removeListener('updateExamMonitor', eventListener)
    }
  }, [])

  const eventListener = () => {
    getExamMonitorData()
  }


  const getExamMonitorData = () => {
    setLoading(true)
    examMonitor({ id: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(Object.keys(createExamMonitorMap).map(key => {
          return {
            label: createExamMonitorMap[key].label,
            tabsKey: createExamMonitorMap[key].tabsKey,
            value: key,
            finished: res.data[key]
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新考试监控数据失败',
        centered: true
      })
    })
  }

  const generateMonitorDashboard = (list) => {
    return list.map(item => {
      return (
        <div className={ Styles.monitorItem } key={ item.value }>
          <div className={ Styles.monitorLabel }>{ item.label }</div>
          <div className="d-flex align-items-center">
            {
              item.finished
                ? <span className="text-success">已配置</span>
                : <span className="text-danger">未配置</span>
            }
            <Button
              className={ 'ml-4' }
              type={ 'primary' }
              size={ 'small' }
              ghost
              onClick={ changeParentActiveTabs.bind(this, item) }
            >查看</Button>
          </div>
        </div>
      )
    })
  }

  const changeParentActiveTabs = (item) => {
    props.selectActiveTabs && props.selectActiveTabs(item.tabsKey)
  }

  const disabledPublishExam = (list) => {
    return list.some(item => {
      return !item.finished
    })
  }

  const examPublishAction = () => {
    Modal.confirm({
      title: `${ examStatus.examinationStatus === 0 ? '发布' : '暂停' }考试`,
      content: `确定要${ examStatus.examinationStatus === 0 ? '发布' : '暂停' }考试吗？`,
      centered: true,
      onOk: () => {
        examPublish({ id: selectedExam.id }).then(res => {
          if (res.code === ResponseCode.success) {
            notification.success({
              message: '操作成功'
            })
            getExamProcessTimeAction()
            eventBus.emit('reloadExamDetail', selectedExam.id)
          } else {
            Modal.error({
              title: '错误',
              content: res.msg,
              centered: true
            })
          }
        }).catch(() => {
          Modal.error({
            title: '网络错误',
            content: `${ examStatus.examinationStatus === 0 ? '发布' : '暂停' }考试失败`,
            centered: true
          })
        })
      }
    })
  }

  const testApplyExam = () => {
    findParamByExam({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        window.open(`${ res.data.testDomain }-${ selectedExam.id }`, '_blank')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询考试详情失败',
        centered: true
      })
    })
  }

  const showTestApplyExamBtn = (examStatus) => {
    if (examStatus.examinationStatus === 0) {
      return <Button
        className={ 'ml-4' }
        type={ 'primary' }
        size={ 'small' }
        ghost
        onClick={ testApplyExam }
      >测试</Button>
    } else {
      return <></>
    }
  }

  const getExamProcessTimeAction = () => {
    getExamProcessTime({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setExamStatus(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误查询考试状态失败',
        centered: true
      })
    })
  }

  return (<>
    <Spin spinning={ loading }>
      <div>
        <div className={ Styles.monitorTitle }>考试准备阶段</div>
        <div className="">
          { generateMonitorDashboard(tableData) }
          <div className={ Styles.monitorItem }>
            <div className={ Styles.monitorLabel }>报名测试</div>
            <div className="d-flex align-items-center">
              {
                disabledPublishExam(tableData)
                  ? <span className="text-danger">未配置</span>
                  : <span className="text-success">已配置</span>
              }
              { showTestApplyExamBtn(examStatus) }
            </div>
          </div>
        </div>

        <div className={ 'mt-3' }>
          <AppButton
            authCode={ 'button_ksxq-ksjk-fbks' }
            disabled={ disabledPublishExam(tableData) }
            type={ 'primary' }
            danger={ examStatus.examinationStatus === 1 }
            onClick={ examPublishAction }
          >{
            examStatus.examinationStatus === 0
              ? '发布考试'
              : '停止考试'
          }</AppButton>
        </div>
      </div>
    </Spin>
  </>)
}

export default ExamDashboard
