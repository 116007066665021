import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Form, Input, InputNumber, Modal, notification, Radio, Spin } from 'antd'
import { createDictChild, updateDictChild } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { replaceChineseInitials } from '../../../plugins/utils'

const EditChildDictionaryModal = forwardRef((props, ref) => {
  const [editDictChildForm] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)
  const [editDictChildModalVisible, setDictChildModalVisible] = useState(false)

  useEffect(() => {
    if (editDictChildModalVisible) {
      if (props.modalType === 'create') {
        editDictChildForm.setFieldValue('dictCode', props.dictItem.dictCode)
      } else {
        editDictChildForm.setFieldsValue({
          ...props.dictItem
        })
      }
    }
  }, [editDictChildModalVisible])

  const onOk = () => {
    editDictChildForm.validateFields().then(fields => {
      if (props.modalType === 'create') {
        createDictChildAction(fields)
      } else {
        editDictChildAction(fields)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const createDictChildAction = (fields) => {
    const params = {
      ...fields,
      isDefault: 0
    }

    setLoading(true)
    createDictChild(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditDictChildModal()
        notifyApi.success({
          message: '操作成功',
          description: `已创建字典小类【${ params.dictLabel }】`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '创建字典小类失败',
        centered: true
      })
    })
  }

  const editDictChildAction = (fields) => {
    const params = {
      ...fields,
      isDefault: 0,
      id: props.dictItem.id
    }

    setLoading(true)
    updateDictChild(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideEditDictChildModal()
        notifyApi.success({
          message: '操作成功',
          description: `已更新字典小类`
        })
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新字典小类失败',
        centered: true
      })
    })
  }

  useImperativeHandle(ref, () => {
    return {
      showModal: showEditDictChildModal
    }
  })

  const showEditDictChildModal = () => {
    setDictChildModalVisible(true)
  }

  const hideEditDictChildModal = () => {
    setDictChildModalVisible(false)
    editDictChildForm.resetFields()
  }

  const dictValueChange = (e) => {
    if (e.target.value.trim()) {
      editDictChildForm.setFieldValue('dictValue', `${ props.dictItem.dictCode }_${ replaceChineseInitials(e.target.value) }`)
    }
  }

  return (
    <div ref={ ref }>
      <Modal
        open={ editDictChildModalVisible }
        title="添加子类"
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideEditDictChildModal }>
        <Spin spinning={ loading }>
          <Form
            form={ editDictChildForm }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            initialValues={
              {
                dictLabel: '',
                dictValue: '',
                dictSort: 0,
                state: 1
              }
            }
          >

            <Form.Item label="字典大类编码" name="dictCode">
              <Input disabled></Input>
            </Form.Item>
            <Form.Item label="字典小类标签" required name="dictLabel" rules={ [{ required: true, message: '字典标签不能为空' }] }>
              <Input onChange={ dictValueChange }></Input>
            </Form.Item>
            <Form.Item label="字典小类值" required name="dictValue" rules={ [{ required: true, message: '字典值不能为空' }] }>
              <Input></Input>
            </Form.Item>
            <Form.Item label="排序值" required name="dictSort">
              <InputNumber></InputNumber>
            </Form.Item>
            <Form.Item label="字典状态" required name="state">
              <Radio.Group>
                <Radio value={ 1 }> 启用 </Radio>
                <Radio value={ 0 }> 禁用 </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      { contextHolder }
    </div>
  )
})

export default EditChildDictionaryModal
