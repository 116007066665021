import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, Radio, Select, notification, Spin } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import { createPosition, getDictList, updatePosition } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { updateDictList } from '../../../store/slice/dict-slice'
import { replaceChineseInitials } from '../../../plugins/utils'

const EditPositionFieldModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictList)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [dictList, setDictList] = useState([])
  const [notifyApi, contextHolder] = notification.useNotification()
  const [fieldJoinSelectFormVisible, setFieldJoinSelectFormVisible] = useState(false)

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle('新增职位字段')
      } else {
        setModalTitle('编辑职位字段')

        if (props.fieldItem.fieldJoinSelect) {
          setFieldJoinSelectFormVisible(true)
        } else {
          setFieldJoinSelectFormVisible(false)
        }

        form.setFieldsValue({
          ...props.fieldItem
        })
      }

      getDictListAction()
    }
  }, [modalVisible])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const getDictListAction = () => {
    if (dictInfo.length > 0) {
      setDictList(dictInfo)
    } else {
      const params = {
        orderBy: 'id',
        pageNum: 1,
        pageSize: 9999
      }
      getDictList(params).then(res => {
        if (res.code === ResponseCode.success && res.data) {
          setDictList(res.data.elements)
          dispatch(updateDictList({ dictList: res.data.elements }))
        }
      })
    }
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setFieldJoinSelectFormVisible(false)
    form.resetFields()
  }

  const getRequest = (fields) => {
    let request
    const params = {
      ...fields,
      orgId: props.orgItem.id,
      requiredSystem: 0,
      fieldLevel: 'U',
      fieldSort: 999
    }

    if (props.modalType === 'create') {
      request = createPosition(params)
    } else {
      params.id = props.fieldItem.id
      request = updatePosition(params)
    }

    return request
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      getRequest(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        setLoading(false)
        Modal.error({
          title: '网络错误',
          content: '保存职位字段失败',
          centered: true
        })
      })
    })
  }

  const getFieldJoinSelectForm = (visible) => {
    const options = dictList.map(item => {
      return {
        label: item.dictName,
        value: item.dictCode
      }
    })

    if (visible) {
      return (
        <Form.Item
          label="关联数据"
          name="fieldJoinSelect"
          rules={ [{ required: true, message: '职位字段名称不能为空' }] }
        >
          <Select options={ options } placeholder="请选择关联数据项"></Select>
        </Form.Item>
      )
    } else {
      return <></>
    }
  }

  const fieldTypeChange = (e) => {
    if (e.target.value === '1') {
      setFieldJoinSelectFormVisible(false)
    } else {
      setFieldJoinSelectFormVisible(true)
    }
  }

  const fieldNameChange = (e) => {
    if (e.target.value.trim()) {
      form.setFieldValue('fieldCode', `p_${ replaceChineseInitials(e.target.value) }`)
    }
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        centered
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            className="mt-4"
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 14
            } }
            initialValues={ {
              fieldType: '1',
              fieldIsNotBlank: 1,
              state: 1
            } }
          >
            <Form.Item
              label="职位字段名称"
              name="fieldName"
              rules={ [{ required: true, message: '职位字段名称不能为空' }] }
            >
              <Input placeholder="请输入职位字段名称" onChange={ fieldNameChange }></Input>
            </Form.Item>
            <Form.Item
              label="职位字段编码"
              name="fieldCode"
              rules={ [{ required: true, message: '职位字段编码不能为空' }] }
            >
              <Input disabled placeholder="请输入职位字段名称"></Input>
            </Form.Item>
            <Form.Item
              label="字段类型"
              name="fieldType"
            >
              <Radio.Group onChange={ fieldTypeChange }>
                <Radio value="1">文本</Radio>
                <Radio value="2">下拉选项</Radio>
              </Radio.Group>
            </Form.Item>
            { getFieldJoinSelectForm(fieldJoinSelectFormVisible) }

            <Form.Item
              label="是否必填"
              name="fieldIsNotBlank"
            >
              <Radio.Group>
                <Radio value={ 1 }>是</Radio>
                <Radio value={ 0 }>否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="是否启用"
              name="state"
            >
              <Radio.Group>
                <Radio value={ 1 }>是</Radio>
                <Radio value={ 0 }>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditPositionFieldModal
