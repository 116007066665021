import { Outlet, useParams } from 'react-router-dom'
import { getAppEnv, getSiteData } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useEffect, useRef, useState } from 'react'
import PageStatus from '../../components/PageStatus'
import { useDispatch } from 'react-redux'
import { updateEnvInfo } from '../../store/slice/env-slice'

const Layout = () => {
  const dispatch = useDispatch()
  const { siteId } = useParams()
  const [pageStatus, setPageStatus] = useState('loading_only')
  const [pageStatusText, setPageStatusText] = useState('获取站点数据...')

  useEffect(() => {
    getPreloadInfo()
  }, [])

  const getPreloadInfo = () => {
    setPageStatus('loading_only')
    Promise.all([
      getAppEnv(),
      getSiteData({ code: siteId })
    ]).then(([envRes, siteRes]) => {
      sessionStorage.setItem('siteId', siteId)
      if (siteRes?.code !== ResponseCode.success) {
        setPageStatus('error')
        setPageStatusText(`获取站点数据失败，${ siteRes.msg }`)

        return
      }

      if (envRes?.code !== ResponseCode.success) {
        setPageStatus('error')
        setPageStatusText(`获取站点环境数据失败，${ envRes.msg }`)

        return
      }

      dispatch(updateEnvInfo({
        envInfo: {
          ...envRes.data,
          ...siteRes.data
        }
      }))
      setPageStatus('')
    }).catch(() => {
      setPageStatus('error')
      setPageStatusText('网络错误，获取站点数据失败')
    })
  }

  return (<PageStatus pageStatus={ pageStatus } text={ pageStatusText }>
    <Outlet/>
  </PageStatus>)
}

export default Layout
