import AppEditor from './AppEditor'
import { useEffect, useState } from 'react'

function RichEditorForm ({ disabled, value, onChange }) {
  const [editorInstance, setEditorInstance] = useState(null)

  useEffect(() => {
    if (editorInstance) {
      editorInstance.setContent(value)
    }
  }, [value, editorInstance])

  const editorContentChange = () => {
    onChange?.(editorInstance.getContent())
  }

  const onInstanceReady = (e, editor) => {
    setEditorInstance(editor)
  }

  return (<>
    <AppEditor
      disabled={ disabled }
      onChange={ editorContentChange }
      onInit={ onInstanceReady }
    ></AppEditor>
  </>)
}

export default RichEditorForm
