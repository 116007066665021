export const USER_PASSWORD_IS_INITIAL = -2

export const authTypeMap = [
  {
    label: '按钮',
    value: 'button',
    color: 'green'
  },
  {
    label: '菜单',
    value: 'menu',
    color: 'gold'
  },
  {
    label: '页面',
    value: 'page',
    color: 'blue'
  }
]

export const accountTypeMap = {
  1: '微信',
  2: '支付宝',
  4: '民生银行',
  5: '招商银行'
}

export const FieldTypeMap = {
  input: '2',
  textarea: '3',
  select: '4',
  dateRangePicker: '5',
  table: '7',
  treeData: '9',
  cascader: '12'
}

export const RuleLogicMap = {
  include: 1,
  exclude: 2,
  equal: 3
}

export const RelationTypeMap = {
  and: 1,
  or: 2
}

export const sampleTableMap = {
  apply: {
    label: '报名表',
    key: 'applyForm',
    fieldKey: 'applyFormFields'
  },
  examinationRoom: {
    label: '笔试准考证',
    key: 'examCertificate',
    fieldKey: 'examCertificateFields'
  },
  interviewNotice: {
    label: '面试准考证',
    key: 'interviewNotice',
    fieldKey: 'interviewNoticeFields'
  }
}

export const examApplyCostMap = {
  uniformCharge: {
    label: '统一收费',
    value: 1
  },
  chargeBySubject: {
    label: '按科目收费',
    value: 2
  }
}

export const createExamMonitorMap = {
  applyShouldKnow: {
    label: '考试文件',
    tabsKey: 'examFile'
  },
  timeConfig: {
    label: '时间安排',
    tabsKey: 'timeConfig'
  },
  applySiteConfig: {
    label: '报名点',
    tabsKey: 'applySite'
  },
  examFieldConfig: {
    label: '报名字段',
    tabsKey: 'applyField'
  },
  subjectConfig: {
    label: '考试科目',
    tabsKey: 'subjectConfig'
  },
  levelConfig: {
    label: '考试分类',
    tabsKey: 'classification'
  },
  positionTemplateConfig: {
    label: '职位字段',
    tabsKey: 'positionField'
  },
  positionConfig: {
    label: '职位管理',
    tabsKey: 'position'
  },
  applyForm: {
    label: '样表设置',
    tabsKey: 'sampleFile'
  }
}

export const examinationAllocationMonitorMap = {
  check: {
    label: '资格审核'
  },
  room: {
    label: '考场安排'
  },
  template: {
    label: '准考证模板'
  }
}

export const examinationInterviewMonitorMap = {
  template: {
    label: '准考证模板'
  },
  imp: {
    label: '面试数据导入'
  }
}

export const examinationStatusMap = {
  0: '创建考试',
  1: '报名中',
  2: '考场安排',
  3: '成绩发布',
  4: '面试通知',
  5: '考试结束'
}

export const payStatusMap = {
  '-1': {
    label: '申请退款成功',
    className: 'text-primary'
  },
  0: {
    label: '未缴费',
    className: 'text-danger'
  },
  1: {
    label: '已缴费',
    className: 'text-success'
  },
  2: {
    label: '不需要缴费',
    className: 'text-primary'
  },
  3: {
    label: '贫困生免费',
    className: 'text-warning'
  }
}

export const takeExamCheckMap = {
  0: {
    label: '未确认',
    className: 'text-danger'
  },
  1: {
    label: '已确认',
    className: 'text-success'
  }
}

export const examCheckStatus = {
  '-1': {
    label: '审核不通过',
    className: 'text-danger'
  },
  0: {
    label: '未提交',
    className: 'text-danger'
  },
  1: {
    label: '待审核',
    className: 'text-primary'
  },
  2: {
    label: '审核通过',
    className: 'text-success'
  }
}

export const examBatchMap = {
  1: '标准考场',
  2: '非标准考场'
}

export const examProcessTabsMap = {
  apply: ['examDashboard', 'applySite', 'examFile', 'timeConfig', 'subjectConfig', 'classification', 'examApplyCost', 'applyField', 'positionField', 'position', 'sampleFile', 'applyPublicity'],
  examinationRoom: ['examFile', 'timeConfig', 'sampleFile'],
  publicResult: ['examFile', 'timeConfig'],
  interviewNotice: ['examFile', 'timeConfig', 'sampleFile'],
}
