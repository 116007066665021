import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Space, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditOrgModal from './EditOrgModal'
import EditOrgConfig from './EditOrgConfig'
import ResponseCode from '../../common/response-code'
import { getOrgList, deleteOrg } from '../../common/request'
import AppButton from '../../components/AppButton'
import { useSearchParams } from 'react-router-dom'

function OrgManagement () {
  const editOrgRef = useRef()
  const editOrgConfigRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [editOrgModalType, setEditOrgModalType] = useState('')
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const activeKey = searchParams.get('activeKey')
  const orgId = searchParams.get('orgId')

  useEffect(() => {
    getTableData()

    if (activeKey) {
      showOrgConfigDetail(orgId, activeKey)
    }
  }, [])


  const columns = [
    {
      title: '机构名称',
      width: '220px',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'
    },
    {
      title: '机构代码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '报名点',
      dataIndex: 'applySites',
      key: 'applySites',
      render: (record, row) => {
        return <Button
          type={ 'link' }
          onClick={ showOrgConfigDetail.bind(this, row.id, 'applySite') }
        >{ `${ record.length }个报名点` }</Button>
      }
    },

    {
      title: '地址',
      width: '240px',
      dataIndex: 'addressFullDetail',
      key: 'addressFullDetail',
      ellipsis: {
        showTitle: true
      },
      render: (record, row) => {
        return `${ row.addressFullDetail }${ row.addressDetail }`
      }
    },
    {
      title: '操作',
      dataIndex: '',
      width: '220px',
      key: '',
      fixed: 'right',
      render: (data, row) => {
        return (
          <Space className="d-flex justify-content-end">
            <AppButton
              authCode={ 'button_jggl-jgpz' }
              type="primary"
              ghost
              onClick={ showOrgConfigDetail.bind(this, row.id, 'applySite') }
            >机构配置</AppButton>
            <AppButton
              authCode={ 'button_jggl-bj' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditOrgModal) }
            >编辑</AppButton>
            <AppButton
              authCode={ 'button_jggl-sc' }
              type="primary"
              ghost
              danger
              onClick={ openModal.bind(this, row, showDeleteOrgModal) }
            >删除</AppButton>
          </Space>
        )
      }
    }
  ]

  const getTableData = () => {
    setLoading(true)
    getOrgList().then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
        setLoading(false)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询机构列表失败',
        centered: true
      })
    })
  }

  const showOrgConfigDetail = (orgId, tabsKey = 'applySite') => {
    editOrgConfigRef.current.showModal(orgId, tabsKey)
    setSearchParams(`activeKey=${tabsKey}&orgId=${orgId}`)
  }

  const openModal = (row, fn, tabsKey) => {
    setCurrentEditItem(row)
    fn && fn(row, tabsKey)
  }

  const showCreateOrgModal = () => {
    setEditOrgModalType('create')
    editOrgRef.current.showModal()
  }

  const showEditOrgModal = () => {
    setEditOrgModalType('edit')
    editOrgRef.current.showModal()
  }

  const showDeleteOrgModal = (orgItem) => {
    Modal.confirm({
      title: '删除权限',
      content: (
        <div>确定要删除机构【{ orgItem.name }】吗？</div>
      ),
      onOk () {
        deleteOrgAction(orgItem)
      }
    })
  }

  const deleteOrgAction = (orgItem) => {
    const params = {
      id: orgItem.id
    }

    deleteOrg(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: `删除机构成功`
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除机构失败',
        centered: true
      })
    })
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-3 d-flex align-items-center justify-content-end">
        <AppButton
          authCode={ 'button_jggl-cjjg' }
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateOrgModal) }
        >创建机构</AppButton>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          scroll={ {
            x: 1000
          } }
          columns={ columns }
          rowKey={ (record) => record.id }
          dataSource={ tableData }
          pagination={ false }
        ></Table>
      </ConfigProvider>

      <EditOrgModal
        ref={ editOrgRef }
        modalType={ editOrgModalType }
        orgItem={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditOrgModal>

      <EditOrgConfig
        ref={ editOrgConfigRef }
        modalType={ editOrgModalType }
        orgItem={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditOrgConfig>
    </div>
  )
}

export default OrgManagement

