import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Checkbox, Col, ConfigProvider, Modal, Row, Space, Spin, Tooltip } from 'antd'

import { downloadExamineeExcel } from '../../common/request'
import { downloadFile } from '../../plugins/utils'
import ResponseCode from '../../common/response-code'
import router from '../../routers/router'

const SelectApplyFormFieldModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [applyFormFieldOptions, setApplyFormFieldOptions] = useState([])
  const [searchData, setSearchData] = useState({})
  const [checkedList, setCheckedList] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [allFields, setAllFields] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    setAllFields([...applyFormFieldOptions, ...props.roomInfoFields, ...props.examStatusInfoFields])
  }, [applyFormFieldOptions, props.roomInfoFields, props.examStatusInfoFields])

  useEffect(() => {
    setApplyFormFieldOptions(props.fieldList.map(field => {
      return {
        ...field,
        label: field.fieldName,
        value: field.fieldCode
      }
    }))
  }, [props.fieldList])

  useEffect(() => {
    setCheckAll(checkedList.length === allFields.length && checkedList.length > 0)
  }, [checkedList])

  const showModal = (data) => {
    setSearchData(data)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    if (checkedList.length === 0) {
      Modal.warning({
        title: '错误',
        content: '未选择考生数据字段'
      })

      return
    }

    setLoading(true)

    const params = {
      ...searchData,
      applySiteNumbers: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : props.applySiteOptions.filter(site => {
          return site.value
        }).map(site => site.value),
      examId: props.examInfo.id,
      fieldsList: checkedList.map(item => {
        return {
          fieldName: item.fieldName,
          fieldCode: item.fieldCode,
          fieldJoinSelect: item.fieldJoinSelect
        }
      })
    }

    delete params.applySiteNumber

    downloadExamineeExcel(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        Modal.success({
          title: '操作成功',
          content: '下载任务已添加，是否跳转到下载中心查看',
          onOk: () => {
            const siteId = sessionStorage.getItem('siteId')
            router.navigate(`/${ siteId }/exam/download-center`)
          }
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，导出考生数据失败'
      })
    })
  }

  const fieldItem = (field) => {
    return <Col key={ field.fieldCode } span={ 6 }>
      <Checkbox
        value={ field }
      >
        <Tooltip title={field.fieldName } autoAdjustOverflow mouseEnterDelay={ 0.3 }>
          <div className={'text-overflow-1'} style={ { width: 150 } }>{ field.fieldName }</div>
        </Tooltip>
      </Checkbox>
    </Col>
  }

  const generateCheckbox = (applyFields, roomInfoFields, examStatusInfoFields) => {
    return (<>
      <Col span={24}>
        <div className={'form-title'}>报名字段</div>
      </Col>
      { applyFields.map(field => {
        return fieldItem(field)
      }) }

      <Col span={24}>
        <div className={'form-title'}>考场信息字段</div>
      </Col>
      { roomInfoFields.filter(field => {
        return field.fieldType !== '6'
      }).map(field => {
        return fieldItem(field)
      }) }

      <Col span={24}>
        <div className={'form-title'}>考生信息字段</div>
      </Col>
      { examStatusInfoFields.filter(field => {
        return field.fieldType !== '6'
      }).map(field => {
        return fieldItem(field)
      }) }
    </>)
  }

  const onChange = (list) => {
    setCheckedList(list)
  }

  const onCheckAll = () => {
    if (checkedList.length < allFields.length) {
      setCheckedList(allFields)
    } else {
      setCheckedList([])
    }
  }

  const onCheckInvert = () => {
    setCheckedList(allFields.filter(item => {
      return !checkedList.find(checkItem => {
        return item.fieldCode === checkItem.fieldCode
      })
    }))
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 800 }
        title={ '选择Excel导出字段' }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <ConfigProvider componentSize={ 'small' }>
            <Space className={ 'mt-3' }>
              <Button
                type={ 'primary' }
                onClick={ onCheckAll }
              >
                { checkAll ? '取消全选' : '全选' }
              </Button>
              <Button
                type={ 'primary' }
                onClick={ onCheckInvert }
              >反选</Button>
            </Space>
          </ConfigProvider>
          <Checkbox.Group value={ checkedList } onChange={ onChange }>
            <Row gutter={ [16, 8] }>
              { generateCheckbox(applyFormFieldOptions, props.roomInfoFields, props.examStatusInfoFields) }
            </Row>
          </Checkbox.Group>
        </Spin>
      </Modal>
    </div>
  )
})

export default SelectApplyFormFieldModal
