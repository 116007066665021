import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Space, Spin } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import Styles from '../ExaminationAllocation.module.scss'
import { examBatchDetail, saveExamLocationInfo } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { useSelector } from 'react-redux'
import ConfigExamBatchLocation from './ConfigExamBatchLocation'
import ConfigExamNonstandardBatchLocation from './ConfigExamNonstandardBatchLocation'

function ExaminationLocationAllocation (props) {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [activeTab, setActiveTab] = useState({})
  const [batchDetail, setBatchDetail] = useState({})
  const [batchList, setBatchList] = useState([])
  const [loading, setLoading] = useState(false)

  const saveExamLocationInfoAction = () => {
    const validate = batchList.every(item => {
      const canRoomNumber = item.locationList?.reduce((pre, cur) => {
        return pre + cur.canRoomNumber
      }, 0)

      if (!item.locationList || item.locationList.length === 0) {
        Modal.error({
          title: item.batchName,
          content: `【${ item.batchName }】未配置考点`
        })
        return false
      }

      if (canRoomNumber < item.locationList[0].needRoomNumber) {
        Modal.error({
          title: '错误',
          content: '已分配考场数量低于最少考场数'
        })

        return false
      }

      return true
    })

    if (!validate) {
      return
    }

    setLoading(true)
    const params = {
      chooseList: batchList.map(item => {
        return {
          examId: item.examId,
          batchId: item.batchId,
          locationList: item.locationList.map(item => {
            return {
              ...item,
              startNumber: Number(item.startNumber)
            }
          }),
          roomNumber: item.locationList.reduce((pre, current) => {
            return pre + Number(current.canRoomNumber)
          }, 0),
          standardRoomNumber: item.locationList[0].roomExamineeNum,
          needRoomNumber: item.locationList[0].needRoomNumber,
          canAllocateNumber: item.locationList.reduce((pre, current) => {
            return pre + (Number(current.canRoomNumber) * current.roomExamineeNum)
          }, 0)
        }
      })
    }

    saveExamLocationInfo(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        props.nextStep?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考试批次考点分配失败'
      })
    })
  }

  useEffect(() => {
    getBatchDetail()
  }, [])

  useEffect(() => {
    if (batchDetail.examRoomAllocateInfoVOList) {
      setBatchList(batchDetail.examRoomAllocateInfoVOList)
    }
  }, [batchDetail])

  const getBatchDetail = () => {
    setLoading(true)
    examBatchDetail({ id: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setBatchDetail(res.data)
        setActiveTab(res.data.examRoomAllocateInfoVOList[0])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试批次详情失败'
      })
    })
  }

  const getBatchItemClassName = (batch) => {
    if (activeTab.batchId === batch.batchId) {
      return [Styles.batchTabItem, Styles.isActive].join(' ')
    } else {
      return Styles.batchTabItem
    }
  }

  const getBatchStatusIcon = (batch) => {
    if (batch?.locationList?.length > 0) {
      const result = batch.locationList.every(item => {
        return item.canRoomNumber > Number(batch?.applyCount / item.roomExamineeNum)
      })

      if (result) {
        return <CheckCircleOutlined className={ 'text-success' }/>
      } else {
        return <ExclamationCircleOutlined className={ 'text-danger' }/>
      }
    } else {
      return <ExclamationCircleOutlined className={ 'text-danger' }/>
    }
  }

  const toggleActiveTab = (batch) => {
    setActiveTab(batch)
  }

  const updateBatchExamRoomInfo = (list) => {
    batchList.forEach(item => {
      if (item.batchId === activeTab.batchId) {
        item.locationList = list
      }
    })

    setBatchList([].concat(batchList))
  }

  const getTabBarItem = (list) => {
    return list.map(batch => {
      return (
        <Space
          className={ getBatchItemClassName(batch) }
          key={ batch.batchId }
          onClick={ toggleActiveTab.bind(this, batch) }
        >
          <div>{ batch.batchName }</div>
          { getBatchStatusIcon(batch, activeTab) }
        </Space>
      )
    })
  }

  const preStep = () => {
    props.preStep?.()
  }

  const nextStep = () => {
    props.nextStep?.()
  }

  const stepTo = (data) => {
    props.stepTo?.(data)
  }

  return (
    <div>
      <Spin spinning={ loading }>
        <div className={ Styles.pageTitle }>考场分配</div>
        <div className={ Styles.batchTabBar }>
          <div className={ 'd-flex align-items-center' }>
            { getTabBarItem(batchList) }
          </div>
          <Space>
            <Space>
              <CheckCircleOutlined className={ 'text-success' }/>
              <div>配置完成</div>
            </Space>
            <Space>
              <ExclamationCircleOutlined className={ 'text-danger' }/>
              <div>未分配</div>
            </Space>
          </Space>
        </div>

        {
          batchList.map(batch => {
            if (batch === activeTab) {
              if (batch.batchType === 1) {
                return <ConfigExamBatchLocation
                  key={ batch.batchId }
                  batchInfo={ activeTab }
                  updateBatchExamRoomInfo={ updateBatchExamRoomInfo }
                  preStep={ preStep }
                  saveExamLocationInfoAction={ saveExamLocationInfoAction }
                ></ConfigExamBatchLocation>
              } else {
                return <ConfigExamNonstandardBatchLocation
                  key={ batch.batchId }
                  batchInfo={ activeTab }
                  updateBatchExamRoomInfo={ updateBatchExamRoomInfo }
                  preStep={ preStep }
                  nextStep={ nextStep }
                  stepTo={ stepTo }
                ></ConfigExamNonstandardBatchLocation>
              }
            }
          })
        }
      </Spin>
    </div>
  )
}

export default ExaminationLocationAllocation
