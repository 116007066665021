import { Table, Button } from 'antd'
import { getRegistrationSite } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'

function RegistrationSiteManagement () {
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    getTableData()
  }, [])

  const columns = [
    {
      title: '报名点名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '报名点地址',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '是否启用',
      dataIndex: 'status',
      key: 'status',
      render: (data) => {
        return (<>{ data === 1 ? '启用' : '停用' }</>)
      }
    },
    {
      title: '操作',
      width: '280px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<div className="d-flex justify-content-end">
          { getOperation(data, row) }
          <Button className="mr-2" type="primary" ghost>编辑</Button>
          <Button type="primary" danger ghost>删除</Button>
        </div>)
      }
    }
  ]

  const getOperation = (data, row) => {
    if (row.status) {
      return <Button className="mr-2" type="primary" ghost>启用</Button>
    } else {
      return <Button className="mr-2" type="primary" ghost>停用</Button>
    }
  }

  const getTableData = () => {
    const params = {
      pageNum,
      pageSize
    }

    getRegistrationSite(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      }
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const openModal = () => {}

  const showCreateRegistrationSiteModal = () => {}

  return (
    <div>
      <div className="mb-2 d-flex justify-content-end">
        <Button type="primary" icon={ <PlusOutlined/> } onClick={ openModal.bind(this, {}, showCreateRegistrationSiteModal) }>创建报名点</Button>
      </div>
      <Table
        columns={ columns }
        dataSource={ tableData.elements }
        rowKey={(record) => record.id}
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
      ></Table>
    </div>
  )
}

export default RegistrationSiteManagement
