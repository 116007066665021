import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Radio, Spin } from 'antd'

import { createFileTemplateField, updateFileTemplateField } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { replaceChineseInitials } from '../../plugins/utils'

const EditFileTemplateFieldModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldValue('type', props.fieldType)
      if (props.modalType === 'edit') {
        form.setFieldsValue({
          ...props.fieldItem
        })
        setModalTitle(`编辑${ props.fieldType === 2 ? '笔试准考证' : '面试准考证' }字段`)
      } else {
        setModalTitle(`创建${ props.fieldType === 2 ? '笔试准考证' : '面试准考证' }字段`)
      }
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      const params = {
        ...fields,
        fieldLevel: props.fieldLevel
      }
      let request

      if (props.fieldLevel === 'U') {
        params.orgId = props.orgItem.id
      }

      if (props.modalType === 'create') {
        request = createFileTemplateField
      } else {
        params.id = props.fieldItem.id
        request = updateFileTemplateField
      }

      setLoading(true)
      request(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '保存字段失败'
        })
      })
    })
  }

  const dictNameChange = (e) => {
    if (e.target.value.trim()) {
      form.setFieldValue('fieldCode', `file_field_${ replaceChineseInitials(e.target.value) }`)
    }
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
        confirmLoading={ loading }
      >
        <Spin spinning={ loading }>
          <Form
            className={ 'my-3' }
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            initialValues={ {
              fieldName: '',
              fieldCode: '',
              fieldType: '1',
              type: 2,
              required: false
            } }
          >
            <Form.Item
              label={ '字段名称' }
              name={ 'fieldName' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入字段名称' } onChange={ dictNameChange }></Input>
            </Form.Item>
            <Form.Item
              label={ '字段代码' }
              name={ 'fieldCode' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入字段代码' }></Input>
            </Form.Item>
            <Form.Item
              label={ '字段类型' }
              name={ 'fieldType' }
              rules={ [{ required: true }] }
            >
              <Radio.Group>
                <Radio value={ '1' }>文本</Radio>
                <Radio value={ '6' }>图像</Radio>
                <Radio value={ '7' }>表格</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={ '字段归属' }
              name={ 'type' }
              rules={ [{ required: true }] }
            >
              <Radio.Group disabled>
                <Radio value={ 2 }>笔试准考证</Radio>
                <Radio value={ 3 }>面试准考证</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={ '是否必传' }
              name={ 'required' }
              rules={ [{ required: true }] }
            >
              <Radio.Group>
                <Radio value={ false }>非必传</Radio>
                <Radio value={ true }>必传</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditFileTemplateFieldModal
