import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Row, Button, notification, Modal } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { getExamTime, updateExamTime } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { isNumber } from 'lodash/lang'
import eventBus from '../../../../plugins/events'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const validateTime = (getFieldValue, key, isEndTime = false) => {
  const msg = isEndTime
    ? '结束时间不能小于开始时间'
    : '开始时间不能大于结束时间'
  return {
    validator (_, value) {
      const flag = isEndTime
        ? getFieldValue(key) <= value
        : getFieldValue(key) >= value
      if (flag) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(msg))
    }
  }
}

dayjs.extend(customParseFormat)

const SettingTime = () => {
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [applyStageFormItems, setApplyStageFormItems] = useState([])
  const [otherStageFormItems, setOtherStageFormItems] = useState([])

  const applyStageBasicFormItems = [
    {
      label: '报名开始时间',
      name: 'applyStartTime',
      rules: [{ required: true, message: '报名开始时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'applyEndTime')],
      type: 'datePicker',
      dependencies: ['applyEndTime'],
      visible: true
    },
    {
      label: '报名结束时间',
      name: 'applyEndTime',
      rules: [{ required: true, message: '报名结束时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'applyStartTime', true)],
      type: 'datePicker',
      dependencies: ['applyStartTime'],
      visible: true
    },
    {
      label: '查询开始时间',
      name: 'queryStartTime',
      rules: [{ required: true, message: '查询开始时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'queryEndTime')],
      type: 'datePicker',
      dependencies: ['queryEndTime'],
      visible: true
    },
    {
      label: '查询结束时间',
      name: 'queryEndTime',
      rules: [{ required: true, message: '查询结束时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'queryStartTime', true)],
      type: 'datePicker',
      dependencies: ['queryStartTime'],
      visible: true
    },
    {
      label: '修改开始时间',
      name: 'updateStartTime',
      rules: [{ required: true, message: '修改开始时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'updateEndTime')],
      type: 'datePicker',
      dependencies: ['updateEndTime'],
      visible: true
    },
    {
      label: '修改结束时间',
      name: 'updateEndTime',
      rules: [{ required: true, message: '修改结束时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'updateStartTime', true)],
      type: 'datePicker',
      dependencies: ['updateStartTime'],
      visible: true
    },
    {
      label: '审核开始时间',
      name: 'checkStartTime',
      rules: [{ required: true, message: '审核开始时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'checkEndTime')],
      type: 'datePicker',
      dependencies: ['checkEndTime'],
      visible: selectedExam.isCheck === 1
    },
    {
      label: '审核结束时间',
      name: 'checkEndTime',
      rules: [{ required: true, message: '审核结束时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'checkStartTime', true)],
      type: 'datePicker',
      dependencies: ['checkStartTime'],
      visible: selectedExam.isCheck === 1
    },
    {
      label: '缴费开始时间',
      name: 'costStartTime',
      rules: [{ required: true, message: '缴费开始时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'costEndTime')],
      type: 'datePicker',
      dependencies: ['costEndTime'],
      visible: selectedExam.payType === 1
    },
    {
      label: '缴费结束时间',
      name: 'costEndTime',
      rules: [{ required: true, message: '缴费结束时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'costStartTime', true)],
      type: 'datePicker',
      dependencies: ['costStartTime'],
      visible: selectedExam.payType === 1
    },
    {
      label: '笔试确认开始时间',
      name: 'takeExamStartTime',
      rules: [{ required: true, message: '笔试确认开始时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'takeExamEndTime')],
      type: 'datePicker',
      dependencies: ['takeExamEndTime'],
      visible: selectedExam.takeExamCheck === 1
    },
    {
      label: '笔试确认结束时间',
      name: 'takeExamEndTime',
      rules: [{ required: true, message: '笔试确认结束时间不能为空' }, ({ getFieldValue }) => validateTime(getFieldValue, 'takeExamStartTime', true)],
      type: 'datePicker',
      dependencies: ['takeExamStartTime'],
      visible: selectedExam.takeExamCheck === 1
    },
    {
      label: '审核查看截止时间',
      name: 'checkViewEndTime',
      rules: [{ required: true, message: '账号权限回收时间不能为空' }],
      type: 'datePicker',
      col: 12,
      visible: selectedExam.isCheck === 1
    },
    {
      label: '职位修改截止时间',
      name: 'positionChangeEndTime',
      rules: [{ required: true, message: '修改职位结束时间不能为空' }],
      type: 'datePicker',
      col: 12,
      visible: true
    }
  ]

  const poorFormItems = [
    {
      label: '贫困生申请开始时间',
      name: 'poorApplyStartTime',
      type: 'datePicker',
      dependencies: ['poorApplyEndTime'],
      rules: [({ getFieldValue }) => validateTime(getFieldValue, 'poorApplyEndTime')],
      visible: selectedExam.payType === 1
    },
    {
      label: '贫困生申请结束时间',
      name: 'poorApplyEndTime',
      type: 'datePicker',
      dependencies: ['poorApplyStartTime'],
      rules: [({ getFieldValue }) => validateTime(getFieldValue, 'poorApplyStartTime', true)],
      visible: selectedExam.payType === 1
    },
    {
      label: '贫困生审核开始时间',
      name: 'poorCheckStartTime',
      type: 'datePicker',
      dependencies: ['poorCheckEndTime'],
      rules: [({ getFieldValue }) => validateTime(getFieldValue, 'poorCheckEndTime')],
      visible: selectedExam.payType === 1
    },
    {
      label: '贫困生审核结束时间',
      name: 'poorCheckEndTime',
      type: 'datePicker',
      dependencies: ['poorCheckStartTime'],
      rules: [({ getFieldValue }) => validateTime(getFieldValue, 'poorCheckStartTime', true)],
      visible: selectedExam.payType === 1
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      generateFormItem()
      getExamTimeDetail()
    }
  }, [selectedExam])

  const generateFormItem = () => {
    let applyForm = []
    let otherForm = []

    if (selectedExam.process.includes('apply')) {
      applyForm = applyForm.concat(applyStageBasicFormItems)
      // if (selectedExam.isCheck === 1) {
      //   applyForm = applyForm.concat(applyCheckStageFormItems)
      // }
      //
      // applyForm = applyForm.concat([])
      // if (selectedExam.payType === 1) {
      //   applyForm = applyForm.concat(applyPayStageFormItems)
      // }
      if (selectedExam.payType === 1) {
        otherForm = otherForm.concat(poorFormItems)
      }
      //
      // if (selectedExam.takeExamCheck === 1) {
      //   applyForm = applyForm.concat(applyTakeExamStageFormItems)
      // }
    }

    if (selectedExam.process.includes('examinationRoom')) {
      otherForm = otherForm.concat([{
        label: '笔试准考证打印开始时间',
        name: 'examCertificateStartTime',
        type: 'datePicker',
        dependencies: ['examCertificateEndTime'],
        rules: [({ getFieldValue }) => validateTime(getFieldValue, 'examCertificateEndTime')]
      }, {
        label: '笔试准考证打印结束时间',
        name: 'examCertificateEndTime',
        type: 'datePicker',
        dependencies: ['examCertificateStartTime'],
        rules: [({ getFieldValue }) => validateTime(getFieldValue, 'examCertificateStartTime', true)]
      }])
    }

    if (selectedExam.process.includes('publicResult')) {
      otherForm = otherForm.concat([{
        label: '笔试成绩查询开始时间',
        name: 'resultQueryStartTime',
        type: 'datePicker',
        dependencies: ['resultQueryEndTime'],
        rules: [({ getFieldValue }) => validateTime(getFieldValue, 'resultQueryEndTime')]
      }, {
        label: '笔试成绩查询结束时间',
        name: 'resultQueryEndTime',
        type: 'datePicker',
        dependencies: ['resultQueryStartTime'],
        rules: [({ getFieldValue }) => validateTime(getFieldValue, 'resultQueryStartTime', true)]
      }])
    }

    if (selectedExam.process.includes('interviewNotice')) {
      otherForm = otherForm.concat([{
        label: '面试准考证打印开始时间',
        name: 'interviewStartTime',
        type: 'datePicker',
        dependencies: ['interviewEndTime'],
        rules: [({ getFieldValue }) => validateTime(getFieldValue, 'interviewEndTime')]
      }, {
        label: '面试准考证打印结束时间',
        name: 'interviewEndTime',
        type: 'datePicker',
        dependencies: ['interviewStartTime'],
        rules: [({ getFieldValue }) => validateTime(getFieldValue, 'interviewStartTime', true)]
      }])
    }

    setApplyStageFormItems(applyForm)
    setOtherStageFormItems(otherForm)
  }

  const strTimeTransform = (strTime) => {
    return strTime ? dayjs(strTime) : ''
  }

  const getExamTimeDetail = () => {
    getExamTime({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        if (selectedExam.process.includes('apply')) {
          form.setFieldsValue({
            applyStartTime: strTimeTransform(res.data.applyStartTime),
            applyEndTime: strTimeTransform(res.data.applyEndTime),
            queryStartTime: strTimeTransform(res.data.queryStartTime),
            queryEndTime: strTimeTransform(res.data.queryEndTime),
            updateStartTime: strTimeTransform(res.data.updateStartTime),
            updateEndTime: strTimeTransform(res.data.updateEndTime),
            positionChangeEndTime: strTimeTransform(res.data.positionChangeEndTime)
          })
          if (selectedExam.isCheck === 1) {
            form.setFieldsValue({
              checkStartTime: strTimeTransform(res.data.checkStartTime),
              checkEndTime: strTimeTransform(res.data.checkEndTime),
              checkViewEndTime: strTimeTransform(res.data.checkViewEndTime)
            })
          }

          if (selectedExam.payType === 1) {
            form.setFieldsValue({
              costStartTime: strTimeTransform(res.data.costStartTime),
              costEndTime: strTimeTransform(res.data.costEndTime),
              poorApplyStartTime: strTimeTransform(res.data.poorApplyStartTime),
              poorApplyEndTime: strTimeTransform(res.data.poorApplyEndTime),
              poorCheckStartTime: strTimeTransform(res.data.poorCheckStartTime),
              poorCheckEndTime: strTimeTransform(res.data.poorCheckEndTime)
            })
          }

          if (selectedExam.takeExamCheck === 1) {
            form.setFieldsValue({
              takeExamStartTime: strTimeTransform(res.data.takeExamStartTime),
              takeExamEndTime: strTimeTransform(res.data.takeExamEndTime)
            })
          }
        }

        if (selectedExam.process.includes('examinationRoom')) {
          form.setFieldsValue({
            examCertificateStartTime: strTimeTransform(res.data.examCertificateStartTime),
            examCertificateEndTime: strTimeTransform(res.data.examCertificateEndTime)
          })
        }

        if (selectedExam.process.includes('publicResult')) {
          form.setFieldsValue({
            resultQueryStartTime: strTimeTransform(res.data.resultQueryStartTime),
            resultQueryEndTime: strTimeTransform(res.data.resultQueryEndTime)
          })
        }

        if (selectedExam.process.includes('interviewNotice')) {
          form.setFieldsValue({
            interviewStartTime: strTimeTransform(res.data.interviewStartTime),
            interviewEndTime: strTimeTransform(res.data.interviewEndTime)
          })
        }
      }
    })
  }

  const getDayjsFormatTime = (field, arrayIndex) => {
    if (isNumber(arrayIndex)) {
      return (field && field[arrayIndex]) ? dayjs(field[arrayIndex]).format('YYYY-MM-DD HH:mm:ss').toString() : ''
    } else {
      return field ? dayjs(field).format('YYYY-MM-DD HH:mm:ss').toString() : ''
    }
  }

  const updateExamTimeAction = () => {
    form.validateFields().then(fields => {
      const params = {
        id: selectedExam.id
      }

      if (selectedExam.process.includes('apply')) {
        Object.assign(params, {
          applyStartTime: getDayjsFormatTime(fields.applyStartTime),
          applyEndTime: getDayjsFormatTime(fields.applyEndTime),
          queryStartTime: getDayjsFormatTime(fields.queryStartTime),
          queryEndTime: getDayjsFormatTime(fields.queryEndTime),
          updateStartTime: getDayjsFormatTime(fields.updateStartTime),
          updateEndTime: getDayjsFormatTime(fields.updateEndTime),
          positionChangeEndTime: getDayjsFormatTime(fields.positionChangeEndTime)
        })
        if (selectedExam.isCheck === 1) {
          Object.assign(params, {
            checkStartTime: getDayjsFormatTime(fields.checkStartTime),
            checkEndTime: getDayjsFormatTime(fields.checkEndTime),
            checkViewEndTime: getDayjsFormatTime(fields.checkViewEndTime)
          })
        }

        if (selectedExam.payType === 1) {
          Object.assign(params, {
            costStartTime: getDayjsFormatTime(fields.costStartTime),
            costEndTime: getDayjsFormatTime(fields.costEndTime),
            poorApplyStartTime: getDayjsFormatTime(fields.poorApplyStartTime),
            poorApplyEndTime: getDayjsFormatTime(fields.poorApplyEndTime),
            poorCheckStartTime: getDayjsFormatTime(fields.poorCheckStartTime),
            poorCheckEndTime: getDayjsFormatTime(fields.poorCheckEndTime)
          })
        }

        if (selectedExam.takeExamCheck === 1) {
          Object.assign(params, {
            takeExamStartTime: getDayjsFormatTime(fields.takeExamStartTime),
            takeExamEndTime: getDayjsFormatTime(fields.takeExamEndTime)
          })
        }
      }

      if (selectedExam.process.includes('examinationRoom')) {
        Object.assign(params, {
          examCertificateStartTime: getDayjsFormatTime(fields.examCertificateStartTime),
          examCertificateEndTime: getDayjsFormatTime(fields.examCertificateEndTime)
        })
      }

      if (selectedExam.process.includes('publicResult')) {
        Object.assign(params, {
          resultQueryStartTime: getDayjsFormatTime(fields.resultQueryStartTime),
          resultQueryEndTime: getDayjsFormatTime(fields.resultQueryEndTime)
        })
      }

      if (selectedExam.process.includes('interviewNotice')) {
        Object.assign(params, {
          interviewStartTime: getDayjsFormatTime(fields.interviewStartTime),
          interviewEndTime: getDayjsFormatTime(fields.interviewEndTime)
        })
      }

      updateExamTime(params).then(res => {
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功',
            description: '已更新考试时间'
          })
          eventBus.emit('updateExamMonitor')
          if (searchParams.get('examId')) {
            eventBus.emit('reloadExamDetail', selectedExam.id)
          }
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        Modal.error({
          title: '网络错误',
          content: '更新考试时间失败',
          centered: true
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const getFormContent = (item) => {
    if (item.type === 'rangePicker') {
      return (
        <DatePicker.RangePicker
          format="YYYY-MM-DD HH:mm"
          style={ { width: item.width } }
          changeOnBlur
          showTime={ {
            hideDisabledOptions: true,
            defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:59:59', 'HH:mm')]
          } }
        ></DatePicker.RangePicker>
      )
    } else if (item.type === 'datePicker') {
      return (
        <DatePicker
          format="YYYY-MM-DD HH:mm"
          style={ { width: item.width } }
          showTime={ {
            defaultValue: dayjs('00:00:00', 'HH:mm')
          } }
        />
      )
    }
  }

  const getApplyStageForm = () => {
    if (applyStageFormItems.length > 0) {
      return (<>
        <div className={ 'form-title' }>报名缴费阶段</div>
        <div className="pt-3">
          <Row gutter={ 20 }>
            { applyStageFormItems.map(item => {
              return item.visible
                ? (<Col span={ item.col || 12 } key={ item.name }>
                  <Form.Item
                    label={ item.label }
                    name={ item.name }
                    rules={ item.rules }
                    dependencies={ item.dependencies }
                  >
                    { getFormContent(item) }
                  </Form.Item>
                </Col>)
                : <></>
            }) }
          </Row>
        </div>
      </>)
    } else {
      return (<></>)
    }
  }

  const getOtherStageForm = () => {
    if (otherStageFormItems.length > 0) {
      return (<>
        <div className={ 'form-title' }>其他</div>
        <div className="pt-3">
          <Row gutter={ 20 }>
            { otherStageFormItems.map(item => {
              return (
                <Col span={ item.col || 12 } key={ item.name }>
                  <Form.Item
                    label={ item.label }
                    name={ item.name }
                    rules={ item.rules }
                    dependencies={ item.dependencies }
                  >
                    { getFormContent(item) }
                  </Form.Item>
                </Col>
              )
            }) }
          </Row>
        </div>
      </>)
    } else {
      return (<></>)
    }
  }

  return (<>
    <div>
      { contextHolder }
      <Form
        form={ form }
        labelCol={{
          span: 8
        }}
      >
        { getApplyStageForm() }
        { getOtherStageForm() }
      </Form>
      <div className="d-flex justify-content-center">
        <Button type="primary" onClick={ updateExamTimeAction }>保存</Button>
      </div>
    </div>
  </>)
}

export default SettingTime
