import { createSlice } from '@reduxjs/toolkit'

export const orgSlice = createSlice({
  name: 'org-list',
  initialState: {
    orgList: [],
    orgListLoading: true,
    workerList: [],
    workerListLoading: true,
    workerListNeedUpdate: false,
  },
  reducers: {
    updateOrgList: (state, { payload }) => {
      state.orgList = payload.orgList
    },
    setOrgListLoading: (state, { payload }) => {
      state.orgListLoading = payload.loading
    }
  }
})

export const {
  updateOrgList,
  setOrgListLoading
} = orgSlice.actions

export default orgSlice.reducer
