import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Styles from './index.module.scss'
import { Col, Form, Row, Switch } from 'antd'

const ApplyProcessForm = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [takeExamCheckDisabled, setTakeExamCheckDisabled] = useState(false)
  const [approveAbleChecked, setApproveAbleChecked] = useState(false)
  const [checkTypeChecked, setCheckTypeChecked] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      getFormValue
    }
  })

  const getFormValue = () => {
    const formData = form.getFieldsValue()

    return {
      payType: formData.payType ? 1 : 0,
      artificialCheck: formData.artificialCheck ? 1 : 0,
      isCheck: formData.isCheck ? 1 : 0,
      checkType: formData.checkType ? 2 : 1,
      takeExamCheck: formData.takeExamCheck ? 1 : 0,
      printAhead: formData.printAhead ? 1 : 0
    }
  }

  const payTypeChange = (checked) => {
    setTakeExamCheckDisabled(checked)

    if (checked) {
      form.setFieldValue('takeExamCheck', false)
    }
  }

  const approveAbleChange = (checked) => {
    setApproveAbleChecked(checked)
    if (!checked) {
      form.setFieldValue('checkType', false)
      form.setFieldValue('artificialCheck', false)
    }
  }

  const checkTypeChange = (checked) => {
    setCheckTypeChecked(checked)

    if (!checked) {
      form.setFieldValue('artificialCheck', false)
    }
  }

  return (
    <div ref={ ref }>
      <div className={ Styles.formTitle }>报名流程设置</div>
      <div className="">
        <Form
          form={ form }
          layout="inline"
          initialValues={
            {
              artificialCheck: false,
              checkType: false,
              isCheck: false,
              payType: false,
              takeExamCheck: false
            }
          }
        >
          <Row gutter={ [0, 4] }>
            <Col span={ 8 }>
              <Form.Item
                label="缴费功能"
                name="payType"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="开启"
                  unCheckedChildren="关闭"
                  onChange={ payTypeChange }/>
              </Form.Item>
            </Col>
            <Col span={ 16 }>
              <Form.Item
                label="笔试确认"
                name="takeExamCheck"
                valuePropName="checked"
              >
                <Switch
                  disabled={ takeExamCheckDisabled }
                  checkedChildren="开启"
                  unCheckedChildren="关闭"/>
              </Form.Item>
            </Col>
            <Col span={ 8 }>
              <Form.Item
                label="审核功能"
                name="isCheck"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="开启"
                  unCheckedChildren="关闭"
                  onChange={ approveAbleChange }
                />
              </Form.Item>
            </Col>
            <Col span={ 8 }>
              <Form.Item
                label="自动审核"
                name="checkType"
                valuePropName="checked"
              >
                <Switch
                  disabled={ !approveAbleChecked }
                  checkedChildren="开启"
                  unCheckedChildren="关闭"
                  onChange={ checkTypeChange }
                />
              </Form.Item>
            </Col>
            <Col span={ 8 }>
              <Form.Item
                label="人工复审"
                name="artificialCheck"
                valuePropName="checked"
              >
                <Switch
                  disabled={ !(approveAbleChecked && checkTypeChecked) }
                  checkedChildren="开启"
                  unCheckedChildren="关闭"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="报名表预打印"
                name="printAhead"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="开启"
                  unCheckedChildren="关闭"/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
})

export default ApplyProcessForm
