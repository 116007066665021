import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Result, Button } from 'antd'


function NetworkError (props) {
  const navigate = useNavigate()
  const error = props.error || { code: 500, msg: '网络错误' }

  const goBack = () => {
    navigate(-1)
  }
  const getExtraContent = () => {
    if (error.hideBackBtn) {
      return <></>
    } else {
      return <Button type="primary" onClick={ goBack }>返回首页</Button>
    }

  }

  return (
    <div>
      <Result
        status={error.code}
        title={error.code}
        subTitle={error.msg}
        extra={getExtraContent()}
      />
    </div>
  )
}

export default NetworkError
