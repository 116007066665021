import React, { useEffect, useState } from 'react'
import { Button, Form, InputNumber, Modal, Radio, Select, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { downloadPositionQuery, searchPositionByRule } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadFile } from '../../plugins/utils'
import AppButton from '../../components/AppButton'

function ExamSearchPositionByRule () {
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [applySiteOptions, setApplySiteOptions] = useState([])

  const ruleOptions = [
    {
      label: '报考人数',
      value: 1
    },
    {
      label: '审核通过人数',
      value: 2
    },
    {
      label: '缴费人数',
      value: 3
    },
    {
      label: '报考人数(招考比例)',
      value: 4
    },
    {
      label: '审核通过(招考比例)',
      value: 5
    },
    {
      label: '缴费人数(招考比例)',
      value: 6
    }
  ]

  const columns = [
    {
      title: '报考单位',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode'
    },
    {
      title: '报考职位',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode'
    },
    {
      title: '报名人数',
      key: 'applyCount',
      dataIndex: 'applyCount'
    },
    {
      title: '待审核人数',
      key: 'treatCheckCount',
      dataIndex: 'treatCheckCount'
    },
    {
      title: '审核通过人数',
      key: 'checkCount',
      dataIndex: 'checkCount'

    },
    {
      title: '审核未通过人数',
      key: 'notPassCheckCount',
      dataIndex: 'notPassCheckCount'
    },
    {
      title: '缴费人数',
      key: 'payCount',
      dataIndex: 'payCount'
    },
    {
      title: '计划招考人数',
      key: 'p_zprs',
      dataIndex: 'p_zprs'
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      setApplySiteOptions([{
        label: '全部',
        value: ''
      }, ...(selectedExam.applySiteList?.map(site => {
        return {
          ...site,
          label: site.name,
          value: site.number
        }
      }) || [])])
    }
  }, [selectedExam])

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageSize, pageNum])

  const getTableData = (allFields) => {
    const searchData = allFields || form.getFieldsValue()

    if (!searchData.queryValue && searchData.queryValue !== 0) {
      return
    }
    setLoading(true)

    const params = {
      ...searchData,
      applySiteNumber: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum: pageNum,
      pageSize: pageSize
    }

    searchPositionByRule(params).then(res => {
      setLoading(false)
      if (res.code == ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询统计数据失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const handleSearch = () => {
    form.validateFields().then((fields) => {
      getTableData(fields)
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '请输入查询条件'
      })
    })
  }

  const exportTableData = () => {
    const searchData = form.getFieldsValue()
    setLoading(true)

    downloadPositionQuery({
      ...searchData,
      examId: selectedExam.id,
      applySiteNumber: searchData.applySiteNumber
        ? [searchData.applySiteNumber]
        : selectedExam.applySiteList?.map(site => {
          return site.number
        }),
    }).then(res => {
      setLoading(false)
      downloadFile(res, 'xlsx', '考试职位查询统计').catch(err => {
        console.log(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，导出职位统计数据失败'
      })
    })
  }

  return (<>
    <div className={ 'd-flex align-items-center' }>
      <Form
        className={ 'mb-3' }
        layout={ 'inline' }
        form={ form }
        initialValues={ {
          applySiteNumber: '',
          queryType: 1,
          operator: 'gt',
          queryValue: ''
        } }
      >
        <Form.Item label={ '报名点' } name={ 'applySiteNumber' }>
          <Select
            options={ applySiteOptions }
            style={ { width: 180 } }
          ></Select>
        </Form.Item>
        <Form.Item label={ '查询条件' } name={ 'queryType' }>
          <Select
            options={ ruleOptions }
            style={ { width: 200 } }
          ></Select>
        </Form.Item>
        <Form.Item label={ '' } name={ 'operator' }>
          <Radio.Group>
            <Radio value={ 'gt' }>大于</Radio>
            <Radio value={ 'eq' }>等于</Radio>
            <Radio value={ 'lt' }>小于</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={ '' } name={ 'queryValue' } rules={ [{ required: true }] }>
          <InputNumber style={ { width: 90 } } min={ 0 }></InputNumber>
        </Form.Item>
        <Form.Item label={ '' }>
          <Button
            type={ 'primary' }
            icon={ <SearchOutlined/> }
            onClick={ handleSearch }
          >查询</Button>
        </Form.Item>
      </Form>
    </div>

    <div className={ 'mb-3 d-flex justify-content-end' }>
      <AppButton
        authCode={ 'button_cxtj-àtjcxtj-dc' }
        type={ 'primary' }
        loading={ loading }
        onClick={ exportTableData }
      >导出</AppButton>
    </div>

    <Table
      loading={ loading }
      columns={ columns }
      dataSource={ tableData.elements }
      rowKey={ (record) => record.id }
      pagination={ {
        showTotal: (total) => {
          return `共 ${ total } 条`
        },
        size: 'normal',
        showSizeChanger: true,
        total: tableData.totalElements,
        pageSize: pageSize,
        current: pageNum,
        onChange: paginationChange
      } }
    ></Table>
  </>)
}

export default ExamSearchPositionByRule
