import React from 'react'
import Styles from './ProcessCard.module.scss'
import { Button, Space } from 'antd'

function ProcessCard (props) {
  const handleClick = () => {
    props.onOk?.()
  }

  return (
    <div className={ Styles.processCard }>
      <div className={[
        Styles.processCardHeard,
        'd-flex align-items-center justify-content-between'
      ].join(' ')}>
        <div className={ Styles.processCardTitle }>{ props.title }</div>
        <Space>{ props.headerBtnList }</Space>
      </div>
      <div className={Styles.processCardBody}>{ props.children }</div>
    </div>
  )
}

export default ProcessCard
