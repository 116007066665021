import React, { useEffect, useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import { useSelector } from 'react-redux'

import Styles from './ExamineeInfoModal.module.scss'
import { convertPdfAndDownload, getExamApplyFormTableInfo } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadPdf } from '../../plugins/utils'
import { generateApplyFormTable } from './plugins/GenerateApplyFormTable'

function ExamineeApplyFormTable (props) {
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const envInfo = useSelector(state => state.env.envInfo)
  const [templateInfo, setTemplateInfo] = useState({})
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTemplateInfo('')
    if (props.modalVisible && props.examineeItem?.examId) {
      getExamApplyFormTableInfoAction()
    }
  }, [props.examineeItem, props.modalVisible])

  useEffect(() => {
    if (templateInfo.template) {
      setContent(generateApplyFormTable(templateInfo.template, templateInfo.fieldSet, props.applyInfo, dictInfo, envInfo.picDomain))
    }
  }, [templateInfo])

  const getExamApplyFormTableInfoAction = () => {
    setLoading(true)
    getExamApplyFormTableInfo({ examId: props.examineeItem.examId }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTemplateInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询报名表模板失败'
      })
    })
  }

  const generateExamineeAdmissionTicket = (status, loading) => {
    if (loading) {
      return (<Spin spinning={ loading }>
        <div style={ { height: 300 } }></div>
      </Spin>)
    }

    return <div className={ Styles.sampleTable } dangerouslySetInnerHTML={ { __html: content } }></div>
  }

  const downloadFile = () => {
    setLoading(true)
    const params = {
      content: content,
      fileType: 'APPLY'
    }

    convertPdfAndDownload(params).then(res => {
      setLoading(false)
      try {
        if (res.code && res.code !== ResponseCode.success) {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        } else {
          throw new Error('未接收到异常')
        }
      } catch (e) {
        downloadPdf(res, `${ props.examineeItem.examineesName || props.examineeItem.name }-报名表`)
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载失败'
      })
    })
  }

  return (<>
    <Spin spinning={ loading }>
      <div className={ 'my-3 d-flex justify-content-end' }>
        <Button
          type={ 'primary' }
          loading={ loading }
          onClick={ downloadFile }
        >下载</Button>
      </div>
      { generateExamineeAdmissionTicket(loading) }
    </Spin>
  </>)
}

export default ExamineeApplyFormTable
