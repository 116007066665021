import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Cascader, Modal } from 'antd'

import { getRegionTree } from '../../common/request'
import ResponseCode from '../../common/response-code'

function RegionFormField ({ value, onChange }) {
  const provinceList = useSelector(state => {
    return state.regionList.regionList.map(item => {
      return {
        label: item.name,
        value: item.code,
        id: item.id,
        isLeaf: false
      }
    })
  })
  const [regionIds, setRegionIds] = useState([])
  const [regionOptions, setRegionOptions] = useState(provinceList)
  const [initDataLoaded, setInitDataLoaded] = useState(false)

  useEffect(() => {
    if (value) {
      let ids = getRegionId(value)
      initRegionOptions(ids)
      setRegionIds(ids)
    }
  }, [value])

  const dataTransform = (data) => {
    return data?.map(item => {
      return {
        label: item.name,
        value: item.code,
        isLeaf: item.level === 'district' || !item.childList,
        children: !(item.level === 'district' || !item.childList)
          ? dataTransform(item.childList) : []
      }
    })
  }

  const getRegionId = (regionData) => {
    if (!regionData) {
      return
    }

    let regionIds = []
    if (regionData.province) {
      regionIds.push(regionData.province.code)
    }

    if (regionData.city) {
      regionIds.push(regionData.city.code)
    }

    if (regionData.district) {
      regionIds.push(regionData.district.code)
    }

    return regionIds
  }

  const handleRequest = (params, fn) => {
    getRegionTree(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        fn?.(res)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const initRegionOptions = (regionIds) => {
    if (initDataLoaded) {
      return
    }

    const params = { parent: regionIds[0] }

    handleRequest(params, (res) => {
      regionOptions.forEach(province => {
        if (province.value === regionIds[0]) {
          province.loading = false
          province.children = dataTransform(res.data)
        }
      })

      setRegionOptions([...regionOptions])
      setInitDataLoaded(true)
    })
  }

  const getRegionTreeAction = (targetOption) => {
    const params = { parent: targetOption.value }

    handleRequest(params, (res) => {
      targetOption.loading = false
      targetOption.children = dataTransform(res.data)

      setRegionOptions([...regionOptions])
    })
  }

  const loadRegionData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.children) {
      targetOption.loading = false
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  const selectedAddressChange = (selectedKey, selectedItem) => {
    if (selectedItem?.length > 0) {
      onChange(generateRegionData(selectedItem))
    }
  }

  const generateRegionData = (regions) => {
    let regionData = {}
    const data = ['province', 'city', 'district']

    regions.forEach((item, index) => {
      regionData[data[index]] = {
        name: item.label,
        code: item.value
      }
    })

    return regionData
  }

  return (
    <Cascader
      value={regionIds}
      changeOnSelect
      options={ regionOptions }
      loadData={ loadRegionData }
      style={ { width: '560px' } }
      onChange={ selectedAddressChange }
    ></Cascader>
  )
}

export default RegionFormField
