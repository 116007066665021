import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Select, Table } from 'antd'
import { useSelector } from 'react-redux'
import { assignChecker, getApplySiteUser } from '../../common/request'
import ResponseCode from '../../common/response-code'

const PoorExamineeCheckPersonConfigModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [applySiteOptions, setApplySiteOptions] = useState([])
  const [selectedApplySite, setSelectedApplySite] = useState('')
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchName, setSearchName] = useState('')
  const [idList, setIdList] = useState([])

  const columns = [
    {
      title: '工作人员姓名',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '手机号',
      key: 'phone',
      dataIndex: 'phone'
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {

      setApplySiteOptions(selectedExam.applySiteList.map((item, index) => {
        if (index === 0) {
          form.setFieldValue('organizationId', item.id)
          setSelectedApplySite(item.id)
        }

        return {
          label: item.name,
          value: item.id
        }
      }))
    }
  }, [modalVisible])

  useEffect(() => {
    getWorkerListAction()
  }, [selectedApplySite])

  const getWorkerListAction = () => {
    getApplySiteUser({ applySiteId: selectedApplySite }).then(res => {
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      }
    })
  }

  const showModal = (idList) => {
    setIdList(idList)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setSearchName('')
  }

  const onOk = () => {
    setLoading(true)

    const params = {
      checkerList: selectedRowKeys,
      idList: idList
    }

    assignChecker(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.success({
          message: '操作成功'
        })
        props.insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，设置审核人员失败'
      })
    })
  }

  const selectedSiteChange = (key) => {
    setSelectedApplySite(key)
  }

  const onSelectedChange = (keys) => {
    setSelectedRowKeys(keys)
  }

  const searchNameChange = (e) => {
    setSearchName(e.target.value)
  }

  const filterTableDataByName = (list, name) => {
    return list.filter(item => {
      return item.name.indexOf(name) > -1
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 700 }
        title={ `${ idList.length > 1 ? '批量' : '' }设置审核人员` }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Form
          layout={ 'inline' }
          form={ form }
        >
          <Form.Item
            label={ '报名点' }
            name={ 'organizationId' }
          >
            <Select
              options={ applySiteOptions }
              style={ { width: 120 } }
              onChange={ selectedSiteChange }
            ></Select>
          </Form.Item>
          <Form.Item
            label={ '工作人员名称' }
            name={ 'name' }
          >
            <Input onInput={ searchNameChange }></Input>
          </Form.Item>
        </Form>

        <Table
          className={ 'mt-3' }
          loading={ loading }
          columns={ columns }
          dataSource={ filterTableDataByName(tableData, searchName) }
          rowKey={ record => record.accountId }
          rowSelection={ {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedChange
          } }
        ></Table>
      </Modal>
    </div>
  )
})

export default PoorExamineeCheckPersonConfigModal
