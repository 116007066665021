import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Form, Input, Modal, notification, Row, Space, Spin } from 'antd'

import { confirmPasswordValidate, passwordValidate } from '../../plugins/validates'
import PersonVerification from '../../components/person-verification/PersonVerification'
import { getSmsCode, updatePwd } from '../../common/request'
import ResponseCode from '../../common/response-code'

const EditUserPasswordModal = forwardRef((props, ref) => {
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const personVerificationRef = useRef()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [smsCodeLoading, setSmsCodeLoading] = useState('default')
  const [smsCodeBtnContent, setSmsCodeBtnContent] = useState('获取验证码')

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
    setSmsCodeLoading('default')
    setSmsCodeBtnContent('获取验证码')
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      updatePwd(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功',
            description: '修改密码成功'
          })
          hideModal()
          props.logout?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，修改密码失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const openPersonVerification = () => {
    personVerificationRef.current.openModal()
  }

  const personVerificationSuccess = () => {
    setSmsCodeLoading('loading')
    getSmsCode({ phone: userInfo.username }).then(res => {
      if (res.code === ResponseCode.success) {
        setSmsCodeLoading('disabled')
        countdownTimer()
      } else {
        setSmsCodeLoading('default')
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setSmsCodeLoading('default')
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取验证码失败'
      })
    })
  }

  const countdownTimer = (second) => {
    second = second || 60
    setSmsCodeBtnContent(`${ second }s`)

    setTimeout(() => {
      if (second > 1) {
        second--
        countdownTimer(second)
      } else {
        setSmsCodeBtnContent('获取验证码')
        setSmsCodeLoading('default')
      }
    }, 1000)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 600 }
        title={ '修改密码' }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={loading}>
          <Form
            className={ 'my-4' }
            form={ form }

            initialValues={ {
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
              smsCode: ''
            } }
          >
            <Form.Item
              label={ '原密码' }
              name={ 'oldPassword' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [{ required: true }] }
            >
              <Input type={ 'password' } placeholder={ '输入旧密码' }></Input>
            </Form.Item>
            <Form.Item
              label={ '新密码' }
              name={ 'newPassword' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [
                {
                  required: true,
                  message: '请输入新密码'
                },
                { validator: passwordValidate }
              ] }
            >
              <Input type={ 'password' } placeholder={ '输入新密码' }></Input>
            </Form.Item>
            <Form.Item
              label={ '确认密码' }
              name={ 'confirmPassword' }
              labelCol={ {
                span: 8
              } }
              wrapperCol={ {
                span: 12
              } }
              rules={ [
                {
                  required: true,
                  message: '请再次输入新密码'
                },
                confirmPasswordValidate.bind(this, 'newPassword')
              ] }
            >
              <Input type={ 'password' } placeholder={ '再次输入新密码' }></Input>
            </Form.Item>

            <Row>
              <Col span={ 8 }></Col>
              <Col span={ 12 }>
                <Form.Item
                  name="smsCode"
                  rules={ [{ required: true, message: '验证码不能为空' }] }
                >
                  <Space.Compact style={ { width: '100%' } }>
                    <Input placeholder="输入验证码"/>
                    <Button
                      type="primary"
                      loading={ smsCodeLoading === 'loading' }
                      disabled={ smsCodeLoading === 'disabled' }
                      onClick={ openPersonVerification }>
                      <span style={ { width: '90px' } }>{ smsCodeBtnContent }</span>
                    </Button>
                  </Space.Compact>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>

      <PersonVerification
        ref={ personVerificationRef }
        checkSuccess={ personVerificationSuccess }
      ></PersonVerification>
    </div>
  )
})

export default EditUserPasswordModal
