import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getExamLocationList } from '../../common/request'
import ResponseCode from '../../common/response-code'

function ExamLocationManagement () {
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    getTableData()
  }, [])

  const columns = [
    {
      title: '考点名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '考点地点',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '考点数量',
      dataIndex: 'addressCode',
      key: 'addressCode'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (record) => {
        return (<>
          <div className="d-flex justify-content-end">
            <Button className="mr-2" type="primary" ghost>编辑</Button>
            <Button type="primary" ghost danger>删除</Button>
          </div>
        </>)
      }
    },
  ]

  const getTableData = () => {
    const params = {}

    getExamLocationList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      }
    })
  }

  const openModal = () => {}

  const showCreateExamLocationModal = () => {}

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  return (
    <div>
      <div className="mb-2 d-flex justify-content-end">
        <Button
          type="primary"
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateExamLocationModal) }
        >新增考点</Button>
      </div>
      <Table
        columns={ columns }
        dataSource={ tableData.elements }
        rowKey={ (record) => record.id }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
      ></Table>
    </div>
  )
}

export default ExamLocationManagement
