import React, { useRef, useState } from 'react'
import { Button, Form, Input, Modal, Space, Spin } from 'antd'
import { PhoneOutlined } from '@ant-design/icons'
import PersonVerification from '../../components/person-verification/PersonVerification'
import { phoneValidate } from '../../plugins/validates'
import { getLoginSmsCode, loginBySmsCode } from '../../common/request'
import ResponseCode from '../../common/response-code'
import router from '../../routers/router'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'


const LoginPhone = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { siteId } = useParams()
  const envInfo = useSelector(state => state.env.envInfo)
  const [verificationCodeBtnMsg, setVerificationCodeBtnMsg] = useState('获取验证码')
  const [verificationCodeBtnStatus, setVerificationCodeBtnStatus] = useState('active')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const cRef = useRef()

  const getVerificationCode = () => {
    setVerificationCodeBtnStatus('loading')
    getLoginSmsCode({ phone: phoneNumber }).then(res => {
      if (res.code === ResponseCode.success) {
        setVerificationCodeBtnStatus('disabled')
        countdownTimer()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setVerificationCodeBtnStatus('default')
      Modal.error({
        title: '错误',
        content: '网络错误，获取验证码失败'
      })
    })
  }

  const openPersonVerification = () => {
    cRef.current.openModal()
  }

  const countdownTimer = (second) => {
    second = second || 60
    setVerificationCodeBtnMsg(`${ second }s`)

    setTimeout(() => {
      if (second > 1) {
        second--
        countdownTimer(second)
      } else {
        setVerificationCodeBtnStatus('default')
        setVerificationCodeBtnMsg('获取验证码')
      }
    }, 1000)
  }

  const personVerificationSuccess = () => {
    getVerificationCode()
  }

  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const smsCodeBtnDisabled = (phoneNumber, status) => {
    const reg = /^1[3-9]\d{9}$/

    return !reg.test(phoneNumber) || status === 'disabled'
  }

  const loginBySmsCodeAction = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      loginBySmsCode({
        ...fields,
        area: envInfo.name
      }).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          sessionStorage.setItem('access_token', res.data.token)
          sessionStorage.setItem('token_header', res.data.tokenHead)

          if (router.state.location.search.indexOf('redirect') > -1) {
            navigate(window.decodeURIComponent(router.state.location.search.split('redirect=')[1]))
          } else {
            navigate(`/${ siteId }/exam/dashboard`)
          }
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，登录失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <div style={{ marginTop: 39 }}>
      <Spin spinning={ loading }>
        <Form
          form={ form }
          name="basic"
          autoComplete="off">
          <Form.Item
            label=""
            name="phone"
            rules={ [
              { validator: phoneValidate }
            ] }
            style={{ marginBottom: 34 }}
          >
            <Input
              value={ phoneNumber }
              placeholder="请输入手机号"
              prefix={ <PhoneOutlined style={ { color: '#516FEB' } }/> }
              onInput={ phoneNumberChange }
            />
          </Form.Item>
          <Form.Item
            label=""
            name="smsCode"
            rules={ [{ required: true, message: '密码不能为空' }] }
            style={{ marginBottom: 34 }}
          >
            <Space.Compact style={ { width: '100%', height: 40 } }>
              <Input placeholder="输入验证码"/>
              <Button
                type="primary"
                size={'large'}
                loading={ verificationCodeBtnStatus === 'loading' }
                disabled={ smsCodeBtnDisabled(phoneNumber, verificationCodeBtnStatus) }
                onClick={ openPersonVerification }
                style={{ height: 40 }}
              >
                <span style={ { width: '90px' } }>{ verificationCodeBtnMsg }</span>
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>

        <Button
          className="w-100"
          type="primary"
          loading={ loading }
          onClick={ loginBySmsCodeAction }
        >登录</Button>
      </Spin>

      <PersonVerification ref={ cRef } checkSuccess={ personVerificationSuccess }></PersonVerification>
    </div>
  )
}

export default LoginPhone
