import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Space, Table } from 'antd'

import { deleteExamFile, getExamDictData, getExamFile } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import EditExamFileModal from './EditExamFileModal'
import { updateDictInfo } from '../../../../store/slice/dict-slice'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import eventBus from '../../../../plugins/events'
import AppButton from '../../../../components/AppButton'

function SettingExamFile () {
  const dispatch = useDispatch()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const editExamFileModalRef = useRef()
  const [tableData, setTableData] = useState()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [loading, setLoading] = useState(false)
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [modalType, setModalType] = useState('')

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam])

  const columns = [
    {
      title: '文件名称',
      dataIndex: 'fileName',
      key: 'fileName'
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (record) => {
        return dictInfo['sys_fileKind'].find(item => {
          return item.dictValue === record
        })?.dictLabel
      }
    },
    {
      title: '操作',
      width: '140px',
      dataIndex: 'operation',
      key: 'operation',
      render: (record, row) => {
        return (
          <Space className={ 'd-flex align-items-center justify-content-end' }>
            <Button
              size={ 'small' }
              ghost
              type={ 'primary' }
              onClick={ openModal.bind(this, row, showViewExamFileModal) }
            >查看</Button>
            <AppButton
              size={ 'small' }
              authCode={ 'button_ksxq-kswj-bj' }
              ghost
              type={ 'primary' }
              onClick={ openModal.bind(this, row, showEditExamFileModal) }
            >修改</AppButton>
            <ComponentDisabled
              disabledTip={ '考试报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <AppButton
                size={ 'small' }
                authCode={ 'button_ksxq-kswj-bj' }
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeleteExamFileModal) }
              >删除</AppButton>
            </ComponentDisabled>
          </Space>
        )
      }
    }
  ]

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn?.(row)
  }

  const showCreateExamFileModal = () => {
    setModalType('create')
    editExamFileModalRef.current.showModal()
  }

  const showEditExamFileModal = () => {
    setModalType('edit')
    editExamFileModalRef.current.showModal()
  }

  const showViewExamFileModal = () => {
    setModalType('view')
    editExamFileModalRef.current.showModal()
  }

  const showDeleteExamFileModal = (row) => {
    Modal.confirm({
      title: `删除考试文件`,
      centered: true,
      content: (
        <div>确定要删除考试文件【{ row.fileName }】吗？</div>
      ),
      onOk () {
        deleteExamFileAction(row)
      }
    })
  }

  const deleteExamFileAction = (row) => {
    setLoading(true)
    deleteExamFile({ id: row.id }).then(res => {
      if (res.code === ResponseCode.success) {
        getTableData()
        eventBus.emit('updateExamMonitor')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '删除考试文件失败',
        centered: true
      })
    })
  }

  const getTableData = () => {
    setLoading(true)
    const params = {
      examId: selectedExam.id,
      orderBy: 'id',
      orderType: 'DESC',
      pageNum: 1,
      pageSize: 100
    }

    Promise.all([
      getExamDictData({ dictCode: 'sys_fileKind' }),
      getExamFile(params)
    ]).then(([dictRes, examFileRes]) => {
      setLoading(false)
      if (dictRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '查询字典失败'
        })
        return
      }

      if (examFileRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '考试文件数据失败'
        })
        return
      }

      dispatch(updateDictInfo({ dictKey: 'sys_fileKind', dictValue: dictRes.data }))
      setTableData(examFileRes.data.elements)
    }).catch(err => {
      setLoading(false)
      console.error(err)
    })
  }

  const insertSuccess = () => {
    eventBus.emit('updateExamMonitor')
    getTableData()
  }

  return (
    <div>
      <div className={ 'mb-3 d-flex justify-content-end' }>
        <AppButton
          authCode={ 'button_ksxq-kswj-bj' }
          type={ 'primary' }
          icon={ <PlusOutlined/> }
          onClick={ openModal.bind(this, {}, showCreateExamFileModal) }
        >新增考试文件</AppButton>
      </div>

      <Table
        loading={ loading }
        columns={ columns }
        dataSource={ tableData }
        rowKey={ record => record.fileType }
        pagination={ false }
      ></Table>

      <EditExamFileModal
        ref={ editExamFileModalRef }
        examInfo={ selectedExam }
        fileItem={ currentEditItem }
        modalType={ modalType }
        fileList={ tableData }
        insertSuccess={ insertSuccess }
      ></EditExamFileModal>
    </div>
  )
}

export default SettingExamFile
