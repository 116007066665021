import { createBrowserRouter, Navigate } from 'react-router-dom'

import Login from '../views/login/Login'
import LoginChecker from '../views/login-checker/Login'
import Home from '../views/home/Home'
import Dashboard from '../views/dashboard/Dashboard'
import ExamManagement from '../views/exam-management/ExamManagement'
import ExamineeManagement from '../views/examinee-management/ExamineeManagement'
import DictionaryManagement from '../views/dictionary-management/DictionaryManagement'
import PositionFieldManagement from '../views/position-field-management/PositionFieldManagement'
import ApplyFieldManagement from '../views/apply-field-management/ApplyFieldManagement'
import OrgManagement from '../views/organization-management/OrgManagement'
import RegistrationSiteManagement from '../views/registration-site-management/RegistrationSiteManagement'
import AuthorityManagement from '../views/authority-management/AuthorityManagement'
import RoleManagement from '../views/role-management/RoleManagement'
import AccountManagement from '../views/account-management/AccountManagement'
import ExamLocationManagement from '../views/exam-location-management/ExamLocationManagement'
import NetworkError from '../views/network-error/NetworkError'
import ReceivingAccountManagement from '../views/receiving-account-management/ReceivingAccountManagement'
import ExamStatistical from '../views/exam-statistical/ExamStatistical'
import ExamineeExport from '../views/examinee-export/ExamineeExport'
import ExamineePrint from '../views/examinee-print/ExamineePrint'
import ExamCheckManagement from '../views/exam-check-management/ExamCheckManagement'
import ExaminationAllocationWrap from '../views/examination-room-allocation/ExaminationAllocationWrap'
import ExamineeAccountManagement from '../components/examinee-account-management/ExamineeAccountManagement'
import DownloadCenter from '../views/download-center/DownloadCenter'
import ImportExamScore from '../views/import-exam-score/ImportExamScore'
import ImportExamInterview from '../views/import-exam-interview/ImportExamInterview'
import ExaminationManualAllocation from '../views/examination-manual-allocation/ExaminationManualAllocation'
import ExamCheckAccountManagement from '../views/exam-check-account-management/ExamCheckAccountManagement'
import PoorExamineeCheck from '../views/poor-examinee-check/PoorExamineeCheck'
import PoorExamineeRefund from '../views/poor-examinee-refund/PoorExamineeRefund'
import ExamPublish from '../views/exam-publish/ExamPublish'
import FileTemplateFieldManagement from '../components/FileTemplateFieldManagement'
import LogRecordManage from '../views/log-record/LogRecordManage'
import LogRecordChecker from '../views/log-record/LogRecordChecker'
import LogRecordApply from '../views/log-record/LogRecordApply'
import LogRecordFinance from '../views/log-record/LogRecordFinance'
import ExamDetail from '../views/exam-detail/ExamDetail'
import Layout from '../views/layout/Layout'
import MonitorCenter from '../views/monitoring-center/MonitorCenter'

export const homeRouters = [
  {
    path: 'dashboard',
    breadcrumb: ['首页'],
    element: <Dashboard/>
  },
  {
    path: 'exam-management-list',
    breadcrumb: ['考试管理', '考试列表'],
    element: <ExamManagement/>
  },
  {
    path: 'exam-detail',
    breadcrumb: ['考试管理', '考试详情'],
    element: <ExamDetail/>
  },
  {
    path: 'examination-publish',
    breadcrumb: ['考试管理', '考试发布'],
    element: <ExamPublish/>
  },
  {
    path: 'import-exam-score',
    breadcrumb: ['考试管理', '成绩导入'],
    element: <ImportExamScore/>
  },
  {
    path: 'import-exam-interview',
    breadcrumb: ['考试管理', '面试导入'],
    element: <ImportExamInterview/>
  },
  {
    path: 'examinee-management',
    breadcrumb: ['考生管理', '考生信息维护'],
    element: <ExamineeManagement/>
  },
  {
    path: 'examinee-export',
    breadcrumb: ['考生管理', '考生数据导出'],
    element: <ExamineeExport/>
  },
  {
    path: 'examinee-print',
    breadcrumb: ['考生管理', '考生表打印'],
    element: <ExamineePrint/>
  },
  {
    path: 'exam-check-management',
    breadcrumb: ['审核管理', '考生列表'],
    element: <ExamCheckManagement/>
  },
  {
    path: 'exam-check-account-management',
    breadcrumb: ['审核管理', '审核账号列表'],
    element: <ExamCheckAccountManagement/>
  },
  {
    path: 'exam-statistical',
    breadcrumb: ['查询统计'],
    element: <ExamStatistical/>
  },
  {
    path: 'examination-auto-allocation',
    breadcrumb: ['考场分配', '自动分配'],
    element: <ExaminationAllocationWrap/>
  },
  {
    path: 'examination-manual-allocation',
    breadcrumb: ['考场分配', '人工分配'],
    element: <ExaminationManualAllocation/>
  },
  {
    path: 'examinee-account-management',
    breadcrumb: ['考生账号管理'],
    element: <ExamineeAccountManagement/>
  },
  {
    path: 'poor-examinee-check',
    breadcrumb: ['免收费管理', '免收费考生审核'],
    element: <PoorExamineeCheck/>
  },
  {
    path: 'poor-examinee-refund',
    breadcrumb: ['免收费管理', '免收费考生退费'],
    element: <PoorExamineeRefund/>
  },
  {
    path: 'download-center',
    breadcrumb: ['下载中心'],
    element: <DownloadCenter/>
  },
  {
    path: 'dashboard',
    element: <Dashboard/>,
    breadcrumb: ['控制台']
  },
  {
    path: 'dictionary-management',
    element: <DictionaryManagement/>,
    breadcrumb: ['基础设施', '字典管理']
  },
  {
    path: 'organization-management',
    element: <OrgManagement/>,
    breadcrumb: ['基础设施', '机构管理']
  },
  {
    path: 'apply-field-management',
    element: <ApplyFieldManagement/>,
    breadcrumb: ['基础设施', '报名字段管理']
  },
  {
    path: 'position-field-management',
    element: <PositionFieldManagement/>,
    breadcrumb: ['基础设施', '职位字段管理']
  },
  {
    path: 'file-template-field-management',
    element: <FileTemplateFieldManagement
      modalVisible={ true }
      authCodePrefix={ 'button_ybzdgl' }
    />,
    breadcrumb: ['基础设施', '样表字段管理']
  },
  {
    path: 'registration-site-management',
    element: <RegistrationSiteManagement/>,
    breadcrumb: ['基础设施', '报名点管理']
  },
  {
    path: 'exam-location-management',
    element: <ExamLocationManagement/>,
    breadcrumb: ['基础设施', '考点管理']
  },
  {
    path: 'receiving-account-management',
    element: <ReceivingAccountManagement/>,
    breadcrumb: ['基础设施', '收款账户管理']
  },
  {
    path: 'account-management',
    element: <AccountManagement/>,
    breadcrumb: ['运维管理', '账号管理']
  },
  {
    path: 'authority-management',
    element: <AuthorityManagement/>,
    breadcrumb: ['运维管理', '权限管理']
  },
  {
    path: 'role-management',
    element: <RoleManagement/>,
    breadcrumb: ['运维管理', '角色管理']
  },
  {
    path: 'monitoring-center',
    element: <MonitorCenter/>,
    breadcrumb: ['运维管理', '异常监控']
  },
  {
    path: 'log-record-manage',
    element: <LogRecordManage/>,
    breadcrumb: ['操作日志', '管理端']
  },
  {
    path: 'log-record-checker',
    element: <LogRecordChecker/>,
    breadcrumb: ['操作日志', '审核端']
  },
  {
    path: 'log-record-apply',
    element: <LogRecordApply/>,
    breadcrumb: ['操作日志', '报名端']
  },
  {
    path: 'log-record-finance',
    element: <LogRecordFinance/>,
    breadcrumb: ['操作日志', '对账端']
  },
  {
    path: 'network-error',
    element: <NetworkError/>,
    breadcrumb: []
  }
]

const router = createBrowserRouter([
  {
    path: '/:siteId/',
    element: <Layout/>,
    errorElement: <Navigate to="/network-error"/>,
    id: 'root',
    children: [
      {
        path: 'exam',
        element: <Home/>,
        children: homeRouters
      },
      {
        path: 'login',
        element: <Login/>
      },
      {
        path: 'login-checker',
        element: <LoginChecker/>
      },
      {
        path: 'network-error',
        element: <NetworkError/>
      }
    ]
  },
  {
    path: '/network-error',
    element: <NetworkError/>
  }
])

export default router
