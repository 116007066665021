import React, { useRef } from 'react'
import { Button, ConfigProvider, Space } from 'antd'

import ApplySiteTransferModal from '../transfer-modal/ApplySiteTransferModal'
import ApplySubjectTransferModal from '../transfer-modal/ApplySubjectTransferModal'
import ApplyUnitTransferModal from '../transfer-modal/ApplyUnitTransferModal'

function ConfigBatchFormContent ({ value, type, onChange }) {
  const applySiteTransferModalRef = useRef()
  const applyUnitTransferModalRef = useRef()
  const applySubjectTransferModalRef = useRef()

  const getModalRef = () => {
    let currentRef = null
    switch (type) {
      case 'applySite':
        currentRef = applySiteTransferModalRef
        break
      case 'applyUnit':
        currentRef = applyUnitTransferModalRef
        break
      case 'applySubject':
        currentRef = applySubjectTransferModalRef
        break
    }

    return currentRef
  }

  const showApplySiteTransferModal = () => {
    getModalRef().current.showModal()
  }

  const insertSuccess = (data) => {
    onChange?.(data)
  }

  return (<>
    <ConfigProvider componentSize={ 'small' }>
      <Space>
        <div className={ 'text-primary' }>已选{ value.length }个</div>
        <Space>
          <Button type={ 'primary' } ghost onClick={ showApplySiteTransferModal }>选择</Button>
        </Space>
      </Space>
    </ConfigProvider>

    <ApplySiteTransferModal
      ref={ applySiteTransferModalRef }
      selectedKeys={ value }
      insertSuccess={ insertSuccess }
    ></ApplySiteTransferModal>

    <ApplyUnitTransferModal
      ref={ applyUnitTransferModalRef }
      selectedKeys={ value }
      insertSuccess={ insertSuccess }
    ></ApplyUnitTransferModal>

    <ApplySubjectTransferModal
      ref={ applySubjectTransferModalRef }
      selectedKeys={ value }
      insertSuccess={ insertSuccess }
    ></ApplySubjectTransferModal>
  </>)
}

export default ConfigBatchFormContent
