import { Button, Form, Input, Modal, notification, Spin } from 'antd'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { confirmPasswordValidate, passwordValidate } from '../plugins/validates'
import { updateInitPassword } from '../common/request'
import ResponseCode from '../common/response-code'

const UpdateInitPasswordModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      updateInitPassword(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功',
            description: '修改密码成功'
          })
          hideModal()
          props.logout?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，修改密码失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <Modal
      width={ 600 }
      title={ '修改初始密码' }
      open={ modalVisible }
      maskClosable={ false }
      closeIcon={ <></> }
      onOk={ onOk }
      footer={ [
        <Button
          key={ 'onOk' }
          type={ 'primary' }
          onClick={ onOk }
        >确定</Button>
      ] }
    >
      <Spin spinning={ loading }>
        <div className={ 'my-3 text-danger text-center' }>您好，您的密码仍为系统默认密码，为了您的帐号安全，请您重置密码</div>
        <Form
          className={ 'mb-4' }
          form={ form }

          initialValues={ {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
          } }
        >
          <Form.Item
            label={ '原密码' }
            name={ 'oldPwd' }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            rules={ [{ required: true }] }
          >
            <Input
              type={ 'password' }
              placeholder={ '输入旧密码' }
            ></Input>
          </Form.Item>
          <Form.Item
            label={ '新密码' }
            name={ 'newPwd' }
            required
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            rules={ [
              { validator: passwordValidate }
            ] }
          >
            <Input
              type={ 'password' }
              placeholder={ '输入新密码' }
            ></Input>
          </Form.Item>
          <Form.Item
            label={ '确认密码' }
            name={ 'confirmPassword' }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            rules={ [
              {
                required: true,
                message: '请再次输入新密码'
              },
              confirmPasswordValidate.bind(this, 'newPwd')
            ] }
          >
            <Input
              type={ 'password' }
              placeholder={ '再次输入新密码' }
            ></Input>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
})
export default UpdateInitPasswordModal
