import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Modal, Table } from 'antd'
import { getExamineeApplyRecord } from '../../common/request'
import ResponseCode from '../../common/response-code'

const ExamineeApplyRecordModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getTableData()
    }
  }, [modalVisible, pageNum, pageSize])

  const columns = [
    {
      title: '考试名称',
      key: 'examName',
      dataIndex: 'examName'
    },
    {
      title: '报考单位',
      key: 'unitName',
      dataIndex: 'unitName',
      render: (record, row) => {
        return row.applyInfo.unitName
      }
    },
    {
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode',
      render: (record, row) => {
        return row.applyInfo.unitCode
      }
    },
    {
      title: '报考职位',
      key: 'positionName',
      dataIndex: 'positionName',
      render: (record, row) => {
        return row.applyInfo.positionName
      }
    },
    {
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode',
      render: (record, row) => {
        return row.applyInfo.positionCode
      }
    }
  ]

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const getTableData = () => {
    setLoading(true)
    const params = {
      pageNum,
      pageSize,
      userId: props.examineeInfo.id
    }

    getExamineeApplyRecord(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生报考记录失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 760 }
        title={ '报考记录' }
        open={ modalVisible }
        onCancel={ hideModal }
        footer={ [
          <Button key={ 'close-btn' } type={ 'primary' } onClick={ hideModal }>关闭</Button>
        ] }
      >
        <Table
          columns={ columns }
          loading={ loading }
          dataSource={ tableData.elements }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
          rowKey={ record => record.id }
        ></Table>
      </Modal>
    </div>
  )
})

export default ExamineeApplyRecordModal
