import React, { useState, useEffect, useRef } from 'react'
import { ConfigProvider, Modal, Space, Table } from 'antd'
import { useSelector } from 'react-redux'

import SelectExam from '../../components/select-exam/SelectExam'
import { getPoorList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ExamineeInfoModal from './ExamineeInfoModal'
import PoorExamineeCheckPersonConfigModal from './PoorExamineeCheckPersonConfigModal'
import AppButton from '../../components/AppButton'

function PoorExamineeCheck () {
  const examineeInfoModalRef = useRef()
  const poorExamineeCheckPersonConfigModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [modalType, setModalType] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const saveStatusMap = {
    '-1': '不通过',
    0: '待提交',
    1: '待审核',
    2: '审核通过'
  }

  const columns = [
    {
      title: '考生姓名',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '身份证号',
      key: 'idCard',
      dataIndex: 'idCard'
    },
    {
      title: '所属报名点',
      key: 'applySiteName',
      dataIndex: 'applySiteName'
    },
    {
      title: '审核人员',
      key: 'checkerList',
      dataIndex: 'checkerList',
      render: (record, row) => {
        return row.checkerList?.map(item => {
          return item.name
        })
      }
    },
    {
      title: '状态',
      key: 'saveStatus',
      dataIndex: 'saveStatus',
      render: (record) => {
        return saveStatusMap[record]
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record, row) => {
        return (<>
          <Space className={ 'd-flex justify-content-end' }>
            <AppButton
              size={ 'small' }
              authCode={ 'button_pkssh-ck' }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showExamineeInfoViewModal) }
            >查看</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ 'button_pkssh-sh' }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showExamineeInfoCheckModal) }
            >审核</AppButton>
            <AppButton
              size={ 'small' }
              authCode={ 'button_pkssh-zdshry' }
              type={ 'primary' }
              ghost
              onClick={ openModal.bind(this, row, showCheckPersonConfigModal) }
            >指定审核人员</AppButton>
          </Space>
        </>)
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageSize, pageNum])

  const getTableData = () => {
    const params = {
      examId: selectedExam.id,
      orderBy: 'id',
      pageNum,
      pageSize
    }

    setLoading(true)
    getPoorList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，加载贫困生数据失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn?.(row)
  }

  const showExamineeInfoViewModal = () => {
    setModalType('view')
    examineeInfoModalRef.current.showModal()
  }

  const showExamineeInfoCheckModal = () => {
    setModalType('check')
    examineeInfoModalRef.current.showModal()
  }

  const showCheckPersonConfigModal = (row) => {
    poorExamineeCheckPersonConfigModalRef.current.showModal([row.id])
  }

  const onSelectedChange = (keys) => {
    setSelectedRowKeys(keys)
  }

  const showPoorCheckModal = (idList) => {
    if (idList.length > 0) {
      poorExamineeCheckPersonConfigModalRef.current.showModal(idList)
    } else {
      Modal.error({
        title: '错误',
        content: '未选择考生'
      })
    }
  }

  return (<>
    <SelectExam>
      <div className={ 'mb-3 d-flex justify-content-end' }>
        <AppButton
          authCode={ 'button_pkssh-plzdshry' }
          type={ 'primary' }
          onClick={ showPoorCheckModal.bind(this, selectedRowKeys) }
        >批量指定审核人员</AppButton>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ record => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
          rowSelection={ {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedChange
          } }
        ></Table>
      </ConfigProvider>
    </SelectExam>

    <ExamineeInfoModal
      ref={ examineeInfoModalRef }
      examineeItem={ currentEditItem }
      modalType={ modalType }
      insertSuccess={ getTableData }
    ></ExamineeInfoModal>

    <PoorExamineeCheckPersonConfigModal
      ref={ poorExamineeCheckPersonConfigModalRef }
      examineeList={ selectedRowKeys }
      insertSuccess={ getTableData }
    ></PoorExamineeCheckPersonConfigModal>
  </>)
}

export default PoorExamineeCheck
