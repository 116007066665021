import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, notification, Spin, Form, Input, Select } from 'antd'
import { useSelector } from 'react-redux'

import { createExamFile, getExamFileContent, updateExamFile } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import AppEditor from '../../../../components/AppEditor'
import { useForm } from 'antd/es/form/Form'

const EditExamFileModal = forwardRef((props, ref) => {
  const [form] = useForm()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)
  const [editorInstance, setEditorInstance] = useState(null)
  const [fileTypeOptions, setFileTypeOptions] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setFileTypeOptions(dictInfo['sys_fileKind'].map(item => {
        return {
          label: item.dictLabel,
          value: item.dictValue,
          disabled: item.dictValue !== 'sys_fileKind_qt'
            && props.fileList.map(item => item.fileType).includes(item.dictValue)
        }
      }))

      if (editorInstance) {
        if (props.modalType === 'create') {
          setModalTitle('创建文件')
        } else {
          setModalTitle(props.modalType === 'edit' ? '修改文件' : '预览')
          form.setFieldsValue({
            fileName: props.fileItem.fileName,
            fileType: props.fileItem.fileType
          })
          getFileContent()
        }
        setModalLoading(false)
      }
    }
  }, [modalVisible, editorInstance])

  const getFileContent = () => {
    setModalLoading(true)
    const params = {
      examId: props.examInfo.id,
      fileType: props.fileItem.fileType
    }

    getExamFileContent(params).then(res => {
      setModalLoading(false)
      if (res.code === ResponseCode.success) {
        editorInstance.setContent(res.data.content)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setModalLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询考试文件失败',
        centered: true
      })
    })
  }

  const onInstanceReady = (e, editor) => {
    setEditorInstance(editor)
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    setModalLoading(true)
    editorInstance.setContent('')
    form.resetFields()
  }

  const onOk = () => {
    if (props.modalType === 'view') {
      hideModal()
      return
    }
    form.validateFields().then(fields => {
      setModalLoading(true)
      const content = editorInstance.getContent()
      let request

      const params = {
        ...fields,
        content: content,
        state: 1,
        examId: props.examInfo.id,
        id: props.fileItem.id
      }

      if (props.fileItem.id) {
        request = updateExamFile(params)
      } else {
        request = createExamFile(params)
      }

      request.then(res => {
        setModalLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        setModalLoading(false)
        Modal.error({
          title: '网络错误',
          content: `保存考试文件【${ props.fileItem.fileName }】失败`,
          centered: true
        })
      })
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ modalLoading }
        onCancel={ hideModal }
        width={ '900px' }
      >
        <Spin spinning={ modalLoading }>
          <div className="mb-2">
            <Form
              layout={ 'inline' }
              form={ form }
              initialValues={ {
                fileName: '',
                fileType: ''
              } }
            >
              <Form.Item
                label={ '文件名称' }
                name={ 'fileName' }
                rules={ [{ required: true }] }
              >
                <Input
                  placeholder={ '请输入文件名' }
                  disabled={ props.modalType === 'view' }
                ></Input>
              </Form.Item>
              <Form.Item
                label={ '文件类型' }
                name={ 'fileType' }
                rules={ [{ required: true }] }
              >
                <Select
                  options={ fileTypeOptions }
                  disabled={ props.modalType !== 'create' }
                  style={{ width: 120 }}
                ></Select>
              </Form.Item>
            </Form>
          </div>
          <div style={ { minHeight: '400px' } }>
            <AppEditor disabled={ props.modalType === 'view' } onInit={ onInstanceReady }/>
          </div>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamFileModal
