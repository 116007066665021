import React, { useEffect, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import { useSelector } from 'react-redux'

import { examMonitor, examPublish, getExamProcessTime } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { createExamMonitorMap } from '../../common/common'
import ProcessCard from './ProcessCard'

function ApplyPublishProcess (props) {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [loading, setLoading] = useState(false)
  const [monitorData, setMonitorData] = useState({})
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (selectedExam.id) {
      getExamMonitorInfo()
    }
  }, [selectedExam])

  useEffect(() => {
    setTableData(Object.keys(createExamMonitorMap).map(key => {
      return {
        label: createExamMonitorMap[key].label,
        tabsKey: createExamMonitorMap[key].tabsKey,
        value: key,
        finished: monitorData[key]
      }
    }))
  }, [monitorData])

  const getExamMonitorInfo = () => {
    setLoading(true)
    examMonitor({ id: selectedExam.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setMonitorData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '更新考试监控数据失败',
        centered: true
      })
    })
  }

  const generateProcessMonitorItem = (list) => {
    return list.map(item => {
      return (
        <div className={ 'text-center' } key={ item.value }>
          <div className={ 'mb-2' }>{ item.label }</div>
          <div>
            {
              item.finished
                ? <span className="text-success">已配置</span>
                : <span className="text-danger">未配置</span>
            }
          </div>
        </div>
      )
    })
  }

  const disabledPublishExam = (list) => {
    return list.some(item => {
      return !item.finished
    })
  }

  const examPublishAction = () => {
    Modal.confirm({
      title: `${ props.examStatus.examinationStatus === 0 ? '发布' : '停止' }发布考试`,
      content: `确定要${ props.examStatus.examinationStatus === 0 ? '发布' : '停止' }发布考试吗？`,
      centered: true,
      onOk: () => {
        setLoading(true)
        examPublish({ id: selectedExam.id }).then(res => {
          setLoading(false)
          if (res.code === ResponseCode.success) {
            notification.success({
              message: '操作成功',
              description: `已${ props.examStatus.examinationStatus === 0 ? '发布' : '停止' }发布考试`
            })

            props.updateExamStatus?.()
          } else {
            Modal.error({
              title: '错误',
              content: res.msg,
              centered: true
            })
          }
        }).catch(() => {
          setLoading(false)
          Modal.error({
            title: '网络错误',
            content: `${ props.examStatus.examinationStatus === 0 ? '发布' : '停止' }发布考试失败`,
            centered: true
          })
        })
      }
    })
  }

  return (
    <div>
      <ProcessCard
        title={ '发布考试' }
        headerBtnList={ <>
          {
            props.examStatus.examinationStatus === 0
              ? <Button
                type={ 'primary' }
                loading={ loading }
                disabled={ disabledPublishExam(tableData) }
                onClick={ examPublishAction }
              >发布考试</Button>
              : <Button
                type={ 'primary' }
                loading={ loading }
                ghost
                danger
                onClick={ examPublishAction }
              >停止发布</Button>
          }
        </> }
      >
        <div className={ 'my-3 d-flex align-items-center justify-content-around' }>
          { generateProcessMonitorItem(tableData) }
        </div>
      </ProcessCard>
    </div>
  )
}

export default ApplyPublishProcess
