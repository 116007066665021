import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Spin } from 'antd'
import { updateCheckAccount } from '../../common/request'
import ResponseCode from '../../common/response-code'

const EditCheckAccountModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        name: props.accountInfo.name,
        phone: props.accountInfo.phone
      })
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then(field => {
      const params = {
        ...field,
        examId: props.examInfo.id,
        id: props.accountInfo.accountId,
        userId: props.accountInfo.userId
      }

      setLoading(true)
      updateCheckAccount(params).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess?.()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，修改审核账号失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <div ref={ ref }>
      <Modal
        title={ '修改审核账号' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
      >
        <Spin spinning={loading}>
          <Form
            className={ 'my-3' }
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
          >
            <Form.Item
              label={ '姓名' }
              name={ 'name' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入姓名' }></Input>
            </Form.Item>
            <Form.Item
              label={ '手机号' }
              name={ 'phone' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入手机号' }></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditCheckAccountModal
