import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Form, Input, Modal, notification, Spin } from 'antd'
import { updateExamineeAccount } from '../../common/request'
import ResponseCode from '../../common/response-code'

const EditExamineeAccountModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notifyApi, contextHolder] = notification.useNotification()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        name: props.examineeInfo.name,
        idCard: props.examineeInfo.idCard,
        phone: props.examineeInfo.phone,
        email: props.examineeInfo.email,
        id: props.examineeInfo.id
      })
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      updateExamineeAccount(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          hideModal()
          props.insertSuccess?.()
          notifyApi.success({
            message: '操作成功'
          })
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(err => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，更新考生账号失败'
        })
      })
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ '修改考生账号信息' }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={loading}>
          <Form
            className={'my-4'}
            form={form}
            labelCol={{
              span: 8
            }}
            wrapperCol={{
              span: 12
            }}
            initialValues={{
              name: '',
              idCard: '',
              phone: '',
              email: ''
            }}
          >
            <Form.Item
              label={'姓名'}
              name={'name'}
              rules={[{required: true}]}
            >
              <Input placeholder={'请输入姓名'}></Input>
            </Form.Item>
            <Form.Item
              label={'身份证号'}
              name={'idCard'}
              rules={[{required: true}]}
            >
              <Input placeholder={'请输入身份证号'}></Input>
            </Form.Item>
            <Form.Item
              label={'手机号'}
              name={'phone'}
              rules={[{required: true}]}
            >
              <Input placeholder={'请输入手机号'}></Input>
            </Form.Item>
            <Form.Item
              label={'邮箱'}
              name={'email'}
              rules={[{required: true}]}
            >
              <Input placeholder={'请输入邮箱'}></Input>
            </Form.Item>
            <Form.Item
              label={'账号ID'}
              name={'id'}
              hidden
            >
              <Input></Input>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditExamineeAccountModal
