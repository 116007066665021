import { createSlice } from '@reduxjs/toolkit'

export const DictSlice = createSlice({
  name: 'region-list',
  initialState: {
    dictList: [],
    dictInfo: {}
  },
  reducers: {
    updateDictList: (state, { payload }) => {
      state.dictList = payload.dictList
    },
    updateDictInfo: (state, { payload }) => {
      state.dictInfo[payload.dictKey] = payload.dictValue
    }
  }
})

export const { updateDictList, updateDictInfo } = DictSlice.actions

export default DictSlice.reducer
