import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Form, Input, Modal, notification, Spin, Table } from 'antd'
import ConfigBatchFormContent from './ConfigBatchFormContent'
import { updateBatchFilterRule } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { useSelector } from 'react-redux'

const ConfigExamBatchModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [notifyApi, contextHolder] = notification.useNotification()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        name: props.batchItem.name
      })
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    setLoading(true)
    const searData = form.getFieldsValue()

    const params = {
      ...searData,
      examId: selectedExam.id,
      batchId: props.batchItem.id,
      takeExamCheck: selectedExam.takeExamCheck
    }

    updateBatchFilterRule(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notifyApi.success({
          message: '操作成功'
        })
        props.insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，保存考试批次筛选规则失败'
      })
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }

      <Modal
        title={ '配置考试批次' }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            className={ 'my-4' }
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 10
            } }
            initialValues={ {
              applySiteNumberList: [],
              majorCodeList: [],
              unitCodeList: []
            } }
          >
            <Form.Item label={ '考试批次' } name={ 'name' }>
              <Input disabled></Input>
            </Form.Item>
            <Form.Item label={ '报名点' } name={ 'applySiteNumberList' }>
              <ConfigBatchFormContent type={ 'applySite' }></ConfigBatchFormContent>
            </Form.Item>
            <Form.Item label={ '考试类别' } name={ 'majorCodeList' }>
              <ConfigBatchFormContent type={ 'applySubject' }></ConfigBatchFormContent>
            </Form.Item>
            <Form.Item label={ '报考单位' } name={ 'unitCodeList' }>
              <ConfigBatchFormContent type={ 'applyUnit' }></ConfigBatchFormContent>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default ConfigExamBatchModal
