import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfigProvider, Modal, notification, Spin, Table, Tabs } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { updateFieldsSort } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={ {
      cursor: 'grab',
      color: '#999'
    } }
  />
))

const SortableItem = SortableElement((props) => <tr { ...props } />)
const SortableBody = SortableContainer((props) => <tbody { ...props } />)

const FieldSortModal = forwardRef((props, ref) => {
  const [ordinaryFieldList, setOrdinaryFieldList] = useState([])
  const [accessoryFieldList, setAccessoryFieldList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('ordinary')

  const columns = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle/>
    },
    {
      title: '字段名',
      key: 'fieldName',
      dataIndex: 'fieldName'
    },
    {
      title: '字段编码',
      key: 'fieldCode',
      dataIndex: 'fieldCode'
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setOrdinaryFieldList(props.fieldList.filter(item => {
        return item.fieldType !== '11'
      }).map((item, index) => {
        return {
          ...item,
          index
        }
      }))

      setAccessoryFieldList(props.fieldList.filter(item => {
        return item.fieldType === '11'
      }).map((item, index) => {
        return {
          ...item,
          index
        }
      }))
    }
  }, [modalVisible])

  const getActiveTableData = () => {
    if (activeTab === 'ordinary') {
      return ordinaryFieldList
    } else {
      return accessoryFieldList
    }
  }

  const setActiveTableData = (data) => {
    if (activeTab === 'ordinary') {
      return setOrdinaryFieldList(data)
    } else {
      return setAccessoryFieldList(data)
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(getActiveTableData().slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )

      setActiveTableData(newData)
    }
  }

  const DraggableContainer = (props) => {
    return <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  }

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = getActiveTableData().findIndex((x) => x.index === restProps['data-row-key'])
    return <SortableItem index={ index } { ...restProps } />
  }

  const tabItems = [
    {
      label: '普通字段',
      key: 'ordinary',
      children: (
        <ConfigProvider componentSize={ 'small' }>
          <Table
            columns={ columns }
            dataSource={ ordinaryFieldList }
            rowKey="index"
            pagination={ false }
            scroll={ {
              y: 500
            } }
            components={ {
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            } }
          ></Table>
        </ConfigProvider>
      )
    },
    {
      label: '附件字段',
      key: 'accessory',
      children: (
        <ConfigProvider componentSize={ 'small' }>
          <Table
            columns={ columns }
            dataSource={ accessoryFieldList }
            rowKey="index"
            pagination={ false }
            components={ {
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            } }
          ></Table>
        </ConfigProvider>
      )
    }
  ]

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    const params = {
      examId: props.examInfo.id,
      fieldsList: ordinaryFieldList.map((item, index) => {
        return {
          ...item,
          fieldSort: index
        }
      }),
      fileFieldsList: accessoryFieldList.map((item, index) => {
        return {
          ...item,
          fieldSort: index
        }
      })
    }
    setLoading(true)
    updateFieldsSort(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.success({
          message: '操作成功'
        })
        props.insertSuccess?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，修改字段排序失败'
      })
    })
  }

  const activeTabOnChange = (e) => {
    setActiveTab(e)
  }

  return (
    <div ref={ ref }>
      <Modal
        width={ 700 }
        title={ '字段排序' }
        open={ modalVisible }
        onOk={ onOk }
        confirmLoading={ loading }
        onCancel={ hideModal }
        maskClosable={ false }
      >
        <Spin spinning={ loading }>
          <Tabs items={ tabItems } onChange={ activeTabOnChange }></Tabs>
        </Spin>
      </Modal>
    </div>
  )
})

export default FieldSortModal
