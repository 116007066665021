import React, { useEffect, useRef, useState } from 'react'
import { Button, Empty, Modal, notification, Space, Spin, Table } from 'antd'
import Styles from './ExamBatch.module.scss'
import { examBatchMap } from '../../../common/common'
import { RightOutlined } from '@ant-design/icons'
import ConfigExamBatchModal from './ConfigExamBatchModal'
import { deleteExamBatch, getExaminationRoomPositionList } from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import { useSelector } from 'react-redux'

function ExamBatchItem (props) {
  const configExamBatchModalRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [batchInfo, setBachInfo] = useState({})
  const [batchCollapse, setBatchCollapse] = useState(true)
  const [batchItemClass, setBatchItemClass] = useState('')
  const [tableData, setTableData] = useState({})
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [pageNum, setPageNum] = useState(1)

  useEffect(() => {
    setBachInfo(props.batchInfo)
  }, [props.batchInfo])

  useEffect(() => {
    if (batchCollapse) {
      setBatchItemClass([Styles.batchItem, Styles.isCollapseActive].join(' '))
    } else {
      setBatchItemClass(Styles.batchItem)
    }
  }, [batchCollapse])

  useEffect(() => {
    getTableData()
  }, [])

  const columns = [
    {
      title: '报名点代码',
      key: 'siteCode',
      dataIndex: 'siteCode'
    },
    {
      title: '报名点名称',
      key: 'siteName',
      dataIndex: 'siteName'
    },
    {
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode'
    },
    {
      title: '单位名称',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode'
    },
    {
      title: '职位名称',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '大类代码',
      key: 'levelCode',
      dataIndex: 'levelCode'
    },
    {
      title: '大类名称',
      key: 'levelName',
      dataIndex: 'levelName'
    },
    {
      title: '小类代码',
      key: 'majorCode',
      dataIndex: 'majorCode'
    },
    {
      title: '小类名称',
      key: 'majorName',
      dataIndex: 'majorName'
    },
    {
      title: '参考人数',
      key: 'applyCount',
      dataIndex: 'applyCount'
    }
  ]

  const getTableData = () => {
    setLoading(true)
    const params = {
      batchId: props.batchInfo.id,
      examId: selectedExam.id,
      pageNum,
      pageSize
    }

    getExaminationRoomPositionList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询职位数据失败'
      })
    })
  }

  const toggleBatchCollapse = () => {
    setBatchCollapse(!batchCollapse)
  }

  const openModal = (fn, e) => {
    e.stopPropagation()
    fn?.()
  }

  const showConfigExamBatchModal = () => {
    configExamBatchModalRef.current.showModal()
  }

  const showDeleteExamBatchModal = () => {
    Modal.confirm({
      title: '删除批次',
      content: '确定要删除考试批次吗？',
      onOk: () => {
        deleteExamBatchAction()
      }
    })
  }

  const deleteExamBatchAction = () => {
    const params = {
      examId: selectedExam.id,
      batchId: props.batchInfo.id
    }
    deleteExamBatch(params).then(res => {
      if (res.code === ResponseCode.success) {
        props.batchListChange?.()
        notifyApi.success({
          message: '操作成功'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询职位数据失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const generatePositionTable = (data) => {
    if (data.elements?.length > 0) {
      return (
        <Table
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ record => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      )
    } else {
      return <Empty description={ '未配置考试批次参考规则' }/>
    }
  }

  return (<>
    { contextHolder }
    <div className={ batchItemClass }>
      <div className={ Styles.batchItemHeader } onClick={ toggleBatchCollapse }>
        <Space size={ 64 }>
          <div className={ 'text-primary' }>{ batchInfo.name }</div>
          <div>
            <span>考试安排方式：</span>
            <span>{ examBatchMap[batchInfo.batchType] }</span>
          </div>
          {
            batchInfo.startTime
              ? <div>
                <span>考试时间：</span>
                <span>{ `${batchInfo.examDate} ${ batchInfo.startTime }-${ batchInfo.endTime }` }</span>
              </div>
              : <></>
          }

        </Space>

        <Space size={ 16 }>
          <Button
            type={ 'primary' }
            ghost
            onClick={ openModal.bind(this, showConfigExamBatchModal) }
          >配置参考规则</Button>
          <Button
            type={ 'primary' }
            ghost
            danger
            onClick={ openModal.bind(this, showDeleteExamBatchModal) }
          >删除</Button>
          <Button className={ 'ml-3' } type={ 'link' }>
            <RightOutlined/>
          </Button>
        </Space>
      </div>
      <div className={ Styles.batchItemBody }>
        <Spin spinning={ loading }>
          { generatePositionTable(tableData) }
        </Spin>
      </div>
    </div>

    <ConfigExamBatchModal
      ref={ configExamBatchModalRef }
      batchItem={ batchInfo }
      insertSuccess={ getTableData }
    ></ConfigExamBatchModal>
  </>)
}

export default ExamBatchItem
