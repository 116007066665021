import { createSlice } from '@reduxjs/toolkit'

export const ExamInfoSlice = createSlice({
  name: 'exam-info',
  initialState: {
    examList: [],
    selectedExam: {}
  },
  reducers: {
    updateExamList: (state, { payload }) => {
      state.examList = payload.examList
    },
    updateSelectedExam: (state, { payload }) => {
      state.selectedExam = payload.selectedExam
    },
  }
})

export const { updateExamList, updateSelectedExam } = ExamInfoSlice.actions

export default ExamInfoSlice.reducer
